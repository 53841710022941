import React, { useState } from 'react';
import classnames from 'classnames';
import { postData, putData } from '../../helpers/AxiosService';

function Step(props){
    const { step, idx, deleteStep, handleChange, deleting, deletingId, workflowType,
        emailTemplateOptions, textTemplateOptions, toggleModal, agentOptions, 
        toggleIsCreateTask, editEmailTemplate, editTextTemplate, dueDateOptions = {}, roleOptions, taskTypeOptions, tagOptions, searchOptions } = props;
    const { DueDateRule } = step;

    const [recipientRoleId, setRecipientRoleId] = useState(step.RecipientPeopleRole);
    
    const { DateTypes, TimingOptions, DayTypes } = dueDateOptions;
    const [editDueDate, setEditDueDate ] = useState(false);
    
    const [dueDate, setDueDate] = useState(Object.assign({}, DueDateRule));

    let isDueDateEmpty = Object.keys(dueDate).length === 0 && dueDate.constructor === Object;
    let dueDateString = `${(dueDate.Timing || dueDate.Timing === 0) && TimingOptions[dueDate.Timing].Value || "select"} ${(dueDate.DateType || dueDate.DateType === 0) && DateTypes[dueDate.DateType].Value || "select"}`
    if (dueDate.Timing != 1) {
        dueDateString = `${dueDate.DayAmount || "select"} ${(dueDate.DayType || dueDate.DayType === 0) && DayTypes[dueDate.DayType].Value || "select"}` + ' ' + dueDateString;
    } 

    const [ isSaving, setIsSaving ] = useState(false);

    const saveRecipientRoleId = async (roleId) => {
        if (!roleId) {
            roleId = 0;
        }

        setRecipientRoleId(roleId);       
        await putData(`api/workflow/${step.WorkflowStepId}/recipientrole?roleId=${roleId}`);
    }

    const saveDueDate = async () => {
        //TO DO: validate
        setIsSaving(true);
        var data = dueDate;
        data.WorkflowStepId = step.WorkflowStepId;

        // if Timing is "On" then we shouldn't set day amount or day type
        if (dueDate.Timing == 1) {
            data.DayAmount = null;
            data.DayType = null;
        }
        
        await postData('api/workflow/step/duedate', data).fail(() => setIsSaving(false));

        setIsSaving(false);
        setEditDueDate(false);
    }
    
    return(
        <div {...props.provided.draggableProps} {...props.provided.dragHandleProps} ref={props.innerRef}>
            <div className="widget widget-rounded m-b-10 with-shadow">
                <div className="with-border-top p-t-20 p-b-15 p-l-15 p-r-15">
                    <div className="row m-b-15">
                        <div className="col-md-7">
                            <div className={classnames({ 'hide': step.TaskTypeId != 1 && step.TaskTypeId != 4 }, "checkbox checkbox-css")}>
                                <input type="checkbox" id={`check_${step.WorkflowStepId}`} checked={step.IsCreateTask} onChange={() => toggleIsCreateTask(idx)} />
                                <label htmlFor={`check_${step.WorkflowStepId}`}>Create as a task</label>
                            </div>
                            
                        </div>
                        <div className="col-md-5 p-l-0">
                            <label>Due Date:</label>
                            { workflowType === 'Transaction' &&
                                <span>
                                { isDueDateEmpty && !editDueDate && 
                                    <span 
                                        className="m-l-20 text-primary cursor-pointer" 
                                        onClick={() => setEditDueDate(true)}>
                                        Add a due date rule
                                    </span> 
                                }
                                { !isDueDateEmpty && 
                                <span 
                                    className="m-l-20 text-primary cursor-pointer" 
                                    onClick={() => setEditDueDate(true)}>
                                    {dueDateString}
                                </span> }
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="row form-group">
                                <label>&nbsp;</label>
                                <div className="text-center col-md-12 p-0">
                                    <i className="fas fa-bars f-s-24"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label>Action Type</label>
                                    <select className="form-control" value={step.TaskTypeId} onChange={(e) => handleChange(e, 'TaskTypeId', idx)}>
                                        <option></option>
                                        { taskTypeOptions.map(o => <option value={o.TaskTypeId}>{o.TaskType}</option>)}
                                        {/* <option value={1}>Email</option>
                                        <option value={2}>Phone Call</option>
                                        <option value={4}>Text Message</option>
                                        <option value={5}>Other</option>
                                        <option value={6}>Enroll In Search</option> */}
                                    </select>
                                </div>
                                <div className={classnames({ "hide": step.TaskTypeId != 1 || step.IsCreateTask}, "col-md-6 form-group")}>
                                    <label>Select Template</label>
                                    <div className="input-group">
                                        <select className="form-control" value={step.EmailTemplateId || 0} onChange={(e) => handleChange(e, 'EmailTemplateId', idx)}>
                                            <option></option>
                                            {emailTemplateOptions.length > 0 && emailTemplateOptions.map((temp, i) => <option key={i} value={temp.EmailTemplateId}>{temp.Name}</option> )}
                                        </select>
                                        <button className="btn btn-white" onClick={() => editEmailTemplate(step.EmailTemplateId)} disabled={!step.EmailTemplateId}><i className="far fa-edit"></i></button>
                                    </div>
                                </div>
                                <div className={classnames({ "hide": step.TaskTypeId != 4 || step.IsCreateTask}, "col-md-6 form-group")}>
                                    <label>Select Template</label>
                                    <div className="input-group">
                                        <select className="form-control" value={step.TextTemplateId} onChange={(e) => handleChange(e, 'TextTemplateId', idx)}>
                                            <option></option>
                                            {textTemplateOptions.length > 0 && textTemplateOptions.map((temp, i) => <option key={i} value={temp.TextTemplateId}>{temp.Name}</option> )}
                                        </select>
                                        <button className="btn btn-white" onClick={() => editTextTemplate(step.TextTemplateId)} disabled={!step.TextTemplateId}><i className="far fa-edit"></i></button>
                                    </div>
                                </div>
                                <div className={classnames({ "hide": step.TaskTypeId != 7 }, "col-md-6")}>
                                    <label>Tag</label>
                                    <select className="form-control" value={step.TagId} onChange={e => handleChange(e, 'TagId', idx)}>
                                        <option value={null}></option>
                                        { tagOptions.map(o => <option value={o.TagId}>{o.Name}</option>)}
                                    </select>
                                </div>
                                <div className={classnames({ "hide": step.TaskTypeId != 6 }, "col-md-6 form-group")}>
                                    <label>Enter Search Id</label>
                                    {/* <input className="form-control" value={step.SearchGuid} onChange={(e) => handleChange(e, 'SearchGuid', idx)} /> */}
                                    <select className="form-control" value={step.SearchGuid} onChange={(e) => handleChange(e, 'SearchGuid', idx)}>
                                        <option value="0">Select a search</option>
                                        { searchOptions && searchOptions.map(o => <option value={o.SearchId}>{o.SearchName}</option>)}
                                    </select>
                                </div>
                                <div className={classnames({ "hide": (step.TaskTypeId == 4 || step.TaskTypeId == 1 || step.TaskTypeId == 6 || step.TaskTypeId == 7) && !step.IsCreateTask}, "col-md-6 form-group")}>
                                    <label>Task Title</label>
                                    <input className="form-control" value={step.TaskTitle} onChange={(e) => handleChange(e, 'TaskTitle', idx)} />
                                </div>
                                <div className={classnames({ "hide": (step.TaskTypeId != 1 && step.TaskTypeId != 4) || step.IsCreateTask}, "col-md-2 m-t-25")}>
                                    <button className="btn btn btn-primary" onClick={() => toggleModal(step.TaskTypeId)}>New</button>
                                </div>
                                <div className={classnames({ "hide": (step.TaskTypeId == 1 || step.TaskTypeId == 4 || step.TaskTypeId == 6 || step.TaskTypeId == 7) && !step.IsCreateTask}, "col-md-6")}>
                                    <label>Assign To</label>
                                    <select className="form-control" value={step.AssignToId} onChange={e => handleChange(e, 'AssignToId', idx)}>
                                        <option value={null}>{ workflowType === 'Contact' ? "Assigned Agent" : "Transaction Agent" }</option>
                                        {agentOptions}
                                    </select>
                                </div>
                                <div className={classnames({ "hide": (step.TaskTypeId != 1 && step.TaskTypeId != 4) || step.IsCreateTask || workflowType !== "Transaction"}, "col-md-6 form-group")}>
                                    <label>Recipient</label>
                                    <select className="form-control" value={step.RecipientPeopleRole} onChange={e => saveRecipientRoleId(e.target.value)}>
                                        <option></option>
                                       { roleOptions.map(r => <option key={r.Key} value={r.Key}>{r.Value}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            { workflowType === 'Contact' ?
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Complete After</label>
                                        <input className="form-control" value={step.WaitTime} onChange={(e) => handleChange(e, 'WaitTime', idx)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>&nbsp;</label>
                                        <select className="form-control" value={step.TimeUnit} onChange={(e) => handleChange(e, 'TimeUnit', idx)} >
                                            <option></option>
                                            <option value="minutes">Minutes</option>
                                            <option value="hours">Hours</option>
                                            <option value="days">Days</option>
                                            <option value="months">Months</option>
                                        </select>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    { editDueDate && 
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <label>Amount</label>
                                            <input disabled={dueDate.Timing == 1} value={dueDate.DayAmount} className="form-control" type="number" placeholder="Amount" onChange={(e) => setDueDate({...dueDate, DayAmount: e.target.value})} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Interval</label>
                                            <select disabled={dueDate.Timing == 1}  value={dueDate.DayType} className="form-control" type="text" placeholder="Day Type" onChange={(e) => setDueDate({...dueDate, DayType: e.target.value})} >
                                                <option></option>
                                                <option value={0}>Days</option>
                                                {/* <option>Business Days</option> */}
                                            </select>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Timing</label>
                                            <select value={dueDate.Timing} className="form-control" placeholder="Timing" onChange={(e) => setDueDate({...dueDate, Timing: e.target.value})} >
                                                <option></option>
                                                { TimingOptions.map(d => <option value={d.Key}>{d.Value}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Event Type</label>
                                            <select value={dueDate.DateType} className="form-control" placeholder="Timing" onChange={(e) => setDueDate({...dueDate, DateType: e.target.value})} >
                                                <option></option>
                                                { DateTypes.map(d => <option value={d.Key}>{d.Value}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-6 m-b-10 m-t-25">
                                            <button className="btn btn-primary pull-right" onClick={() => saveDueDate()}>
                                                { isSaving ?
                                                    <div className="button-spinner"></div>
                                                    :
                                                    "Save Rule"
                                                }
                                            </button>
                                        </div>
                                    </div> }
                                </div>
                            }
                        </div>

                        <div className="col-md-1">
                            <div className="row form-group">
                                <label>&nbsp;</label>
                                <div className="text-center col-md-12 p-0">
                                    {deleting && deletingId === step.WorkflowStepId ?
                                        <div className="button-spinner"></div>
                                        :
                                        <i className="far fa-times-circle text-danger f-s-24 cursor-pointer" onClick={() => deleteStep(step)}></i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step;