import React from 'react';
import { getDateStampUtc } from '../../helpers/Functions';

export default function Conversation({ messages, loading }){
    let conversation = messages.map((message, i) => {
        let direction = message.IsMine ? "right" : "left";
        let background = message.IsMine ? 'bg-rco-green text-white' : 'bg-white';
        // let background = message.IsMine ? 'bg-green text-white' : 'bg-white';
        let imageClass = message.IsMine ? 'image bg-white' : 'image bg-gradient-green text-white';
        let dateStamp = getDateStampUtc(message.DateSent);

        return (
            <div key={i} className={direction + ' m-b-10'}>
                <span className="date-time">{dateStamp}</span>
                <span className="name">&nbsp;</span>
                <span className={imageClass}>{message.AuthorName ? message.AuthorName.charAt(0) : ''}</span>
                <div className={`message ${background}`}>
                    {message.Body}
                </div>
            </div>
        )
      })  
    return (
        <div>
            { conversation }
        </div>
    )
}

Conversation.defaultProps = {
    messages: []
}