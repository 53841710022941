import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { patchData } from '../../helpers/AxiosService';

export default function ActivityDescription({ isOpen, toggleModal, title, activity, updateList }) {
    const [description, setDescription] = useState('');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (activity) {
            setDescription(activity.Description);
        }
    }, [activity]);

    function closeModal() {
        setDescription('');
        toggleModal('ActivityDescription');
    }

    async function handleSave() {
        const data = { description };
        try {
            setSaving(true);
            await patchData(`api/activity/${activity.ActivityId}/description`, data);
            updateList();
            setSaving(false);
            closeModal('ActivityDescription');
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => closeModal()}>{title}</ModalHeader>
            <ModalBody>
                <textarea 
                    className="form-control" 
                    rows="7" 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}/>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary-outline pull-right m-r-10" onClick={() => closeModal()}>Cancel</button>
                <button className="btn btn-primary pull-right" onClick={() => handleSave()}>
                    { saving ?
                        <div className="btn-spinner"></div> 
                        :
                        "Save"
                    }   
                </button>
            </ModalFooter>
        </Modal>
    )
}