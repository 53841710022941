export function formatTransactionsBySource(groups) {
    const results = [];
    groups.forEach(grp => {
        const source = grp.reduce((src, t) => {
            src.name = t.SourceName;
            src.sourceId = t.SourceId;
            src.gross += t.GrossCommission;
            src.brokerSplit += t.BrokerSplit;
            src.net += t.NetCommission;
            src.concessions += t.Concessions;
            src.count++;
            return src;
        }, {
            name: 'Unknown',
            sourceId: 0,
            gross: 0,
            net: 0,
            brokerSplit: 0,
            concessions: 0,
            count: 0
        });

        results.push(source);
    });
    results.sort((a, b) => (a.gross < b.gross) ? 1 : -1);
    return results;
}