import React, { useContext } from 'react';
import { PageSettings } from '../../config/page-settings';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { formatPhoneNumber } from '../../helpers/Functions';

const phoneTypes = ['CellPhone', 'HomePhone', 'WorkPhone'];

CallWrapper.defaultProps = {
    togglePopover: () => {},
    contact: { ContactDetails: {} },
    placement: 'top',
}

export default function CallWrapper({ isOpen, buttonId, togglePopover, contact, placement, children }) {
    const cxt = useContext(PageSettings);

    function handleCallClick(e, contact) {
        if (e) e.stopPropagation();

        if (isOpen) togglePopover(false);

        const {  CellPhone, HomePhone, WorkPhone } = contact.ContactDetails;
        if ((CellPhone && HomePhone) || (CellPhone && WorkPhone) || (HomePhone && WorkPhone)) {
            togglePopover(true);
            return;
        }
        
        let phoneType;
        phoneTypes.forEach(pt => {
            if (contact.ContactDetails[pt]) {
                phoneType = pt;
            }
        });

        cxt.dialContact(contact, phoneType);
    }

    function handleNumberClick(e, contact, phoneType) {
        if (e) e.stopPropagation();
        togglePopover(false);
        cxt.dialContact(contact, phoneType);
    }

    return (
        <div className="d-inline">
            {children({ handleCallClick })}
            <Popover
                placement={placement}
                isOpen={isOpen}
                target={buttonId}
                toggle={() => togglePopover(false)}
                trigger="legacy"
            >
                <PopoverHeader>Select a number</PopoverHeader>
                <PopoverBody className="with-row">
                    { phoneTypes.map((type) => {
                        const number = contact.ContactDetails[type];
                        if (number) {
                            return (
                                <div key={type} className="popover-row width-200 p-10" onClick={(e) => handleNumberClick(e, contact, type)}>
                                    <h5 className="m-b-0">{formatPhoneNumber(number)}</h5>
                                    <span className="text-muted">{type}</span>
                                </div>
                            )
                        }
                    })}
                </PopoverBody>
            </Popover>
        </div>
    )
}