import React from 'react';
import { Op } from '../../helpers/OperationTypes';

export default function BooleanInput({ filter, handleFilterOperationChange, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Field}</label>
            <select 
                className="form-control col-md-4 m-r-10"  
                name={filter.Field}
                value={filters[filter.Field].Value} 
                onChange={handleFilterValueChange}>
                <option></option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
        </div>
    )
}