import React from 'react';
import InputMask from 'react-input-mask';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { postData } from '../../helpers/AxiosService';
import classnames from 'classnames';
import { renderStateList } from '../../helpers/StateService';

const initialState = {
    sending: false,
    title: "Add Property",
    address: '',
    city: '',
    state: '',
    zip: '',
    value: '',
    mortgage: '',
    sqFootage: '',
    beds: '',
    baths: '',
    hasError: false,
    propertyId: null
}

class PropertyModal extends React.Component{
    constructor(props){
        super(props);
        this.state = initialState
    }

    componentDidUpdate(prevProps){
        if (prevProps.selectedProperty !== this.props.selectedProperty && this.props.selectedProperty){
            const property = this.props.selectedProperty;
            this.setState({
                address: property.Address,
                city: property.City,
                state: property.StateCode,
                zip: property.ZipCode,
                value: property.EstimatedValue,
                mortgage: property.MortgageBalance,
                sqFootage: property.SquareFootage,
                beds: property.Bedrooms,
                baths: property.Bathrooms,
                propertyId: property.ContactPropertyId
            })
        }
    }

    handleChange(item, val){
        let cleanValue = val.replace("$", "").replace(/,/g, "");
        this.setState({ [item]: cleanValue });
    }

    handleSubmit(){
        let data = {
            Address: this.state.address,
            City: this.state.city,
            StateCode: this.state.state,
            ZipCode: this.state.zip,
            EstimatedValue: this.state.value,
            MortgageBalance: this.state.mortgage,
            SquareFootage: this.state.sqFootage,
            Bedrooms: this.state.beds,
            Bathrooms: this.state.baths,
            ContactPropertyId: this.state.propertyId
        }

        if (!data.City || !data.StateCode){
            this.setState({ hasError: true });
            return;
        }

        this.setState({ sending: true });

        postData(`api/contact/property?contactId=${this.props.contactId}`, data).then(response => {
            //this.setState({ sending: false, hasError: false });
            this.props.resetProperties(response.data);
            this.closeResetModal();
        })
    }

    closeResetModal(){
        this.setState(initialState);
        this.props.toggleModal();
    }

    render(){
        const { address, city, state, zip, value, mortgage, sqFootage, beds, baths, title, hasError } = this.state;
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.closeResetModal()}>{title}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Address</label>
                            <input type="text" className="form-control" value={address} onChange={(e) => this.handleChange('address', e.target.value)}/>
                        </div>
                        <div className={classnames({ 'has-error': hasError && !city}, "form-group col-md-6")}>
                            <label>City</label>
                            <input type="text" className="form-control" value={city} onChange={(e) => this.handleChange('city', e.target.value)}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className={classnames({ 'has-error': hasError && !state}, "form-group col-md-3")}>
                            <label>State</label>
                            <select className="form-control" value={state} onChange={(e) => this.handleChange('state', e.target.value)}>
                                <option></option>
                                { renderStateList() }
                            </select>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Zip Code</label>
                            <InputMask mask="99999" type="text" className="form-control" value={zip} onChange={(e) => this.handleChange('zip', e.target.value)}></InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Square Footage</label>
                            <NumberFormat className="form-control" thousandSeparator={true} value={sqFootage} onChange={(e) => this.handleChange('sqFootage', e.target.value)} />
                            {/* <input type="text" className="form-control" value={sqFootage} onChange={(e) => this.handleChange('sqFootage', e.target.value)}/> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Estimated Value</label>
                            <NumberFormat className="form-control" thousandSeparator={true} prefix={'$'} value={value} onChange={(e) => this.handleChange('value', e.target.value)}/>
                            {/* <InputMask type="text" className="form-control" value={value.toLocaleString()} onChange={(e) => this.handleChange('value', e.target.value)}></InputMask> */}
                        </div>
                        <div className="form-group col-md-6">
                            <label>Mortgage Balance</label>
                            <NumberFormat className="form-control" thousandSeparator={true} prefix={'$'} value={mortgage} onChange={(e) => this.handleChange('mortgage', e.target.value)}/>
                            {/* <input type="text" className="form-control" value={mortgage} onChange={(e) => this.handleChange('mortgage', e.target.value)}/> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Bedrooms</label>
                            <input type="text" className="form-control" value={beds} onChange={(e) => this.handleChange('beds', e.target.value)}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Bathrooms</label>
                            <input type="text" className="form-control" value={baths} onChange={(e) => this.handleChange('baths', e.target.value)}/>
                        </div>
                    </div>
                    
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-white" onClick={() => this.closeResetModal()}>Cancel</button>
                    <button className="btn btn-primary m-l-5" onClick={() => this.handleSubmit()}>
                        {this.state.sending ? 
                            <div className="button-spinner"></div>
                            :
                            "Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default PropertyModal;