import React from 'react'
import Auth from './auth/Auth';
import AuthorizedApp from './AuthorizedApp';
import UnauthorizedApp from './UnauthorizedApp';


//const AuthenticatedApp = React.lazy(() => import('./AuthorizedApp'))
//const UnauthenticatedApp = React.lazy(() => import('./UnauthorizedApp'))


function App() {
  const auth = new Auth();

  return auth.isAuthenticated() ? <AuthorizedApp /> : <UnauthorizedApp />
}


export default App;