import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import OpportunityCard from './OpportunityCard';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { volumeFormatter } from '../../helpers/Functions';

export default function StageLane({ stage, handleCardClick, handleDelete }) {

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 8
            }}
        >
            <div style={{ background: '#f3f3f3', width: '330px', padding: '15px 10px 0'}}>
                <h4>{stage.StageName}</h4>
                <div>
                    {stage.Opportunities.length} Deals {volumeFormatter(stage.TotalVolume)} Volume {volumeFormatter(stage.TotalGCI)} GCI
                </div>
            </div>
            <Droppable droppableId={stage.StageName}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                            background: snapshot.isDraggingOver
                              ? "#e0e0e0"
                              : "#f3f3f3",
                            padding: '10px',
                            width: 330,
                            height: 'calc(100vh - 237px)',
                            overflow: 'auto'
                          }}
                    >
                        <PerfectScrollBar style={{ height: '100%' }}>
                            {!stage.Opportunities.length ? <div></div> : stage.Opportunities.map((opp, idx) => {
                                return (
                                    <Draggable
                                        draggableId={opp.Id}
                                        key={opp.Id}
                                        index={idx}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                provided={provided}
                                                key={opp.Id}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <OpportunityCard 
                                                    opportunity={opp}
                                                    index={opp.Id}
                                                    handleCardClick={handleCardClick}
                                                    handleDelete={handleDelete}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                        </PerfectScrollBar>
                    </div>
                )}
            </Droppable>
        </div>
    )
}