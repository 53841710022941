import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getData, postData, deleteData, putData } from '../helpers/AxiosService';
import classnames from 'classnames';
import { PageSettings } from '../config/page-settings.js';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Op } from '../helpers/OperationTypes';
import { Panel } from '../components/panel/panel';

class Sources extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            rawSources: [],
            sources: [], 
            agentOptions: [],
            leadRouting: '',
            showAdd: false,
            sourceName: '',
            assign: '',
            hasError: false,
            sending: false,
            selectedSource: {},
            btnDropdownOpen: false,
            loading: true,
            searchTerm: '',
        }
        this.toggleButton = this.toggleButton.bind(this);
    }

    componentDidMount(){
        this.getTenantLeadAssignType();
        this.getSources();
        this.getAgentOptions();
    }

    getSources(){
        getData('api/settings/contact/sources').then(response => {
            this.setState({ sources: response.data, rawSources: response.data, loading: false });
        });
    }

    getTenantLeadAssignType() {
        getData('api/settings/contact/lead-assign').then(resp => {
            this.setState({ leadRouting: resp.data });
        });
    }

    getAgentOptions() {
        getData('api/user/agentoptions').then(resp => {
            this.setState({ agentOptions: resp.data });
        });
    }

    toggleModal(){
        this.setState({ hasError: false, sourceName: '', assign: '', showAdd: !this.state.showAdd, selectedSource: {} });
    }

    toggleButton(buttonId) {
        this.setState({
          [`${buttonId}-isOpen`]: !this.state[`${buttonId}-isOpen`]
        });
    }

    handleChange(e, item){
        let value = e.target.value;
        this.setState({ [item]: value }, () => {
            if (item === 'leadRouting') {
                this.submitLeadAssignChange();
            };
        });
    }

    submitLeadAssignChange() {
        putData(`api/settings/contact/lead-assign?leadAssign=${this.state.leadRouting}`);
    }

    handleAddNew(){
        if (!this.state.sourceName){
            this.setState({ hasError: true });
            return;
        };

        this.setState({ sending: true });

        let data = {
            SourceId: this.state.selectedSource.SourceId,
            Name: this.state.sourceName,
            AssignToId: this.state.assign,
        };

        postData('api/settings/contact/sources', data).then(response => {
            this.getSources();
            this.setState({ sending: false });
            this.toggleModal();
        }).fail(error => this.toggleModal());
    }

    handleEditClick(sourceIndex){
        let selectedSource = this.state.sources[sourceIndex];
        this.setState({ selectedSource, sourceName: selectedSource.Name, assign: selectedSource.AssignToId, showAdd: true });
    }

    handleDeleteClick(sourceId){
        this.context.messageConfirmation('Delete Source', 'Are you sure you would like to delete this source', this.handleDelete.bind(this, sourceId), 'Source deleted!');
    }

    handleDelete(sourceId){
        deleteData(`api/settings/contact/sources?sourceId=${sourceId}`).then(response => {
            this.getSources();
            this.context.toggleSweetAlert('confirm');
            this.context.toggleSweetAlert('success');
        }).fail(error => {
            this.getSources();
            this.context.toggleSweetAlert('confirm');
        });
    }

    handleReassignClick(currentSource, newSource){
        this.context.messageConfirmation('Reassign Source Contacts', `Do you want to reassign all contacts tied to ${currentSource.Name} to the ${newSource.Name}?`, this.handleReassign.bind(this, currentSource.SourceId, newSource.SourceId), 'Contacts Reassigned!', 'Reassign')
    }

    handleReassign(sourceId, newSourceId){
        console.log(sourceId, newSourceId);
        let data = {
            SourceId: sourceId,
            NewSourceId: newSourceId
        }

        postData('api/settings/contact/sources/reassign', data).then(response => {
            this.getSources();
            this.context.toggleSweetAlert('confirm');
            this.context.toggleSweetAlert('success');
        }).fail(error => {
            this.getSources();
            this.context.toggleSweetAlert('confirm');
        });
    }

    renderOptions(currentSource){
        return this.state.sources.map((source, i) => {
            if (source.SourceId == currentSource.SourceId) return;

            return <DropdownItem onClick={() => this.handleReassignClick(currentSource, source)}>{source.Name}</DropdownItem>
        })
    }

    createSourceQueryString(sourceId, sourceName) {
        const filter = { field: 'SourceId', value: [{ value: sourceId, label: sourceName }], operation: Op.equals };
        return `filters=${JSON.stringify([filter])}`;
    }

    handleSearch(e) {
        const { value } = e.target;
        this.setState({ searchTerm: value }, this.handleFilter);
    }

    handleFilter() {
        const { searchTerm, rawSources } = this.state;
        
        if (!searchTerm) {
            this.setState({ sources: rawSources.slice() });
            return;
        }

        const sources = rawSources.slice().filter((s) => {
    
            const term = new RegExp(`${searchTerm.toLowerCase()}*`);
            return (
                term.test(s.Name.toLowerCase())
            )
        });

        this.setState({ sources });
    }

    renderRows(){
        return this.state.sources.map((source, i) => (
            <tr key={source.SourceId}>
                <td><strong>{source.Name}</strong></td>
                <td>{source.SourceGuid}</td>
                <td>{source.CreatedByName}</td>
                <td>{source.Type}</td>
                <td>{source.AssignToName || "Use Lead Routing"}</td>
                <td><Link to={`/contacts?${this.createSourceQueryString(source.SourceId, source.Name)}`}>{source.CurrentContactsCount}</Link></td>
                <td>
                    <button className="btn btn-white m-r-5" onClick={() => this.handleEditClick(i)}><i className="far fa-edit text-primary"></i> Edit</button>
                    <ButtonDropdown 
                        className="m-r-5" 
                        isOpen={this.state[`${source.SourceId}-isOpen`]} 
                        toggle={() => this.toggleButton(source.SourceId)}
                        onChange={(e) => this.handleReassign(source, e)}
                    >
                        <DropdownToggle caret color="white">
                            <i className="far fa-share-square text-lime"></i> Reassign
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.state.sources.length !== 0 && this.renderOptions(source)}
                        </DropdownMenu>
                    </ButtonDropdown>
                     <button href="#" id={`delete_${i}`} className={classnames({ "invisible": source.Type === "Default" }, "btn btn-white m-r-5")} onClick={() => this.handleDeleteClick(source.SourceId)} disabled={source.CurrentContactsCount > 0}><i className="far fa-times-circle text-danger"></i> Delete</button>
                </td>
            </tr>
        ))
    }

    render() {
        const { showAdd, sourceName, assign, hasError, selectedSource, agentOptions, leadRouting, loading, searchTerm } = this.state;
        return(
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <button className="btn btn-lime p-l-40 p-r-40 pull-right" onClick={() => this.toggleModal()}><i className="fa fas-plus"></i> New Source</button>
                <h1 className="page-header">Sources <small className="f-w-100">Manage where your leads come from</small></h1>
                <Panel className="p-15 m-t-20">
                    
                    <div className="row">
                        <p className="col-md-6">
                            Sources allow you to track where your leads and contacts are coming from. They are also used to kick off workflows for
                            automated follow up.
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-md-6">
                            If a source has an Assigned To agent, then leads generated from that source will be assigned to that agent. 
                            Otherwise, the Lead Routing setting you choose below will be used to assign new leads.
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-md-6">
                            Agents can be included/excluded from participating in round robin on the Admin > Manage Users page.
                        </p>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <select className="form-control" value={leadRouting} onChange={(e) => this.handleChange(e, 'leadRouting')}>
                                <option>Select...</option>
                                <option value="RoundRobin">Round Robin</option>
                                <option value="Manual">Manual</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <p className="col-md-6">
                            *Default sources are used to track leads that are generated from certain sections of your IDX site.
                        </p>
                    </div>
                </Panel>
                <div className="row">
                    <div className="col-md-4 offset-md-8 pull-right">
                        <div className="action-container">
                            <div className="search-bar">
                                <div style={{ height: '100%' }}>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <input 
                                        className="search-input with-thin-shadow"
                                        placeholder="Search by source name"
                                        value={searchTerm}
                                        onChange={(e) => this.handleSearch(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive with-thin-shadow">
                    <table className="table table-bordered table-striped thick-rows bg-white m-b-0">
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Source Id</th>
                                <th>Created By</th>
                                <th>Type</th>
                                <th>Assign To</th>
                                <th>Current Contacts</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sources.length !== 0 && this.renderRows()}
                        </tbody>
                    </table>
                </div>
                <Modal isOpen={showAdd}>
                    <ModalHeader toggle={() => this.toggleModal()}>Add Source</ModalHeader>
                    <ModalBody>
                        <div className={classnames({ "has-error": hasError && !sourceName }, "form-group")}>
                            <label>Source Name</label>
                            <input className="form-control" disabled={selectedSource.Type === 'Default'} value={sourceName} onChange={(e) => this.handleChange(e, 'sourceName')} />
                        </div>
                        <div className="form-group">
                            <label>Assign</label>
                            <select className="form-control" value={assign} onChange={(e) => this.handleChange(e, 'assign')}>
                                <option value="0">Use Lead Routing Setting</option>
                                { agentOptions.length > 0 && agentOptions.map(a => <option key={a.UserId} value={a.UserId}>{a.Name}</option>)}
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => this.toggleModal()}>Cancel</button>
                        <button className="btn btn-primary m-l-5" onClick={() => this.handleAddNew()}>
                            {this.state.sending ? 
                                <div className="button-spinner"></div>
                                :
                                "Save"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

Sources.contextType = PageSettings;
export default Sources;