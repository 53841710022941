import React from 'react';
import { Op } from '../../helpers/OperationTypes';

export default function StringInput({ filter, handleFilterOperationChange, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10" 
                name={filter.Field}
                value={filters[filter.Field].Operation} 
                onChange={handleFilterOperationChange}
            >
                <option></option>
                <option value={Op.contains}>Contains</option>
                <option value={Op.equals}>Is</option>
                <option value={Op.notEqualTo}>Is Not</option>
                <option value={Op.isEmpty}>Is Blank</option>
                <option value={Op.isNotEmpty}>Is Not Blank</option>
            </select>
            <input 
                className="form-control col-md-4" 
                name={filter.Field}
                value={filters.City.Value} 
                onChange={handleFilterValueChange}/>
        </div>
    )
}