import React from 'react';
import { getData, postData } from '../../../helpers/AxiosService';
import { Collapse } from 'reactstrap';
import './AddContact.css';
import classnames from 'classnames';
import AddPerson from '../AddPerson';
import ContactSelect from '../../ContactSelect';
import { PageSettings } from '../../../config/page-settings';

// This class is to add either a seller or buyer 
// from existing contacts or create a new contact to use
class AddContact extends React.Component{
    static contextType = PageSettings;
    static defaultProps = {
        transactionType: 'listing',
        selectRole: false,
        setTransactionContactId: () => {},
        toggleAddNew: () => {},
        fetchPeople: () => {},
        transactionAgentId: this.contextType.currentUserId,
    }

    constructor(props) {
        super(props);
        this.state = {
            isCreatingNew: false,
            roleOptions: [],
            selectedRoleId: null,
            selectedContactId: null,
            selectedContactName: '',
            linkingContact: false,
            hasError: false,
            sending: false,
        }
    }

    componentDidMount() {
        if (this.props.selectRole) {
            this.fetchRoleOptions();
        }
    }

    fetchRoleOptions() {
        getData('api/transaction/people/roles').then(response => {
            this.setState({ roleOptions: response.data });
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCreateNewClick() {
        this.setState({ isCreatingNew: true });
    }

    // link to transaction, then set  contact on props
    setContact(contact) {
        this.props.setTransactionContactId(contact.Key);
        this.setState({ selectedContactId: contact.Key, selectedContactName: contact.Value });

        const { transactionId } = this.props;
        if (transactionId) {
            const { selectedRoleId } = this.state;
            this.setState({ linkingContact: true, linkingContactId: contact.Key });

            postData(`api/transaction/${transactionId}/contact?contactId=${contact.Key}&roleId=${selectedRoleId}`).then(() => {
                this.props.fetchPeople(transactionId);
                this.props.toggleAddNew();
            }).fail(() => this.setState({ linkingContact: false }));
        }
    }

    async HandlePersonSelect(person) {
        const { selectedRoleId } = this.state;
        const transId = this.props.transactionId;

        if (!selectedRoleId || selectedRoleId === '5' || selectedRoleId === '6') {
            this.setContact(person);
        } else {
            await postData(`api/transaction/${transId}/link-person?trxPeopleId=${person.Key}&roleId=${this.state.selectedRoleId}`);
            this.props.fetchPeople(transId);
            this.props.toggleAddNew(true);
        }
    }

    // create a new transaction person
    async handleSavePerson(person) {
        const { selectedRoleId } = this.state;
        const isContact = selectedRoleId === '5' || selectedRoleId === '6';

        if (!person.FirstName || !person.LastName || !person.Email || (!person.CellPhone && isContact)){
            this.setState({ hasError: true });
            return;
        }
        
        if (!selectedRoleId || isContact) {
            const createdContact = await this.createNewContact(person);
            this.setContact({ Key: createdContact.ContactId, Value: `${person.FirstName} ${person.LastName}` });
            return;
        }

        this.setState({ sending: true });

        const transId = this.props.transactionId;
        if (transId) {
            person.FullName = person.FirstName + ' ' + person.LastName;
            person.PeopleRoleId = this.state.selectedRoleId;
            try {
                const resp = await postData(`api/transaction/${transId}/person`, person);
                const { data } = resp;
                await postData(`api/transaction/${transId}/link-person?trxPeopleId=${data.TransactionPeopleId}&roleId=${this.state.selectedRoleId}`);

                this.setState({ sending: false });
                this.props.fetchPeople(transId);
                this.props.toggleAddNew(true);
            } catch(e) {
                console.log(e);
                this.setState({ sending: false })
            };
        }
    }

     // create a new contact
    async createNewContact(person) {
        let data = { ...person, ContactTypeId: 2, AssignedAgentId: this.props.transactionAgentId }

        const response = await postData('api/contact', data);
        return response.data;
    }

    render() {
        const { transactionType, selectRole, transactionId } = this.props;
        const { isCreatingNew, roleOptions, selectedRoleId, sending,
            selectedContactId, selectedContactName, linkingContact, hasError } = this.state;
        let include = 'transactionPeople';
        if (!selectedRoleId || selectedRoleId === '5' || selectedRoleId === '6') {
            include = 'contacts';
        }
        if (!selectedContactId) {
            return (
                <div>
                    { !isCreatingNew ? 
                    <div>
                        { !transactionId && <div className="row m-b-30">
                            <div className="col-md-12">
                                <h5>Add a {transactionType.toLowerCase() === 'listing' ? 'seller' : 'buyer'} client to the transaction</h5> 
                            </div>
                        </div> }
                        { selectRole && <div className="row m-b-20">
                            <div className="col-md-8 offset-md-2 text-center">
                                <select name="selectedRoleId" className="form-control width-250" style={{ marginRight: 'auto', marginLeft: 'auto' }} onChange={e => this.handleChange(e)}>
                                    <option></option>
                                    { roleOptions && roleOptions.map(r => <option key={r.Key} value={r.Key}>{r.Value}</option>) }
                                </select>
                            </div> 
                        </div> }
                        <Collapse isOpen={!selectRole || selectedRoleId}>
                        <div className="row">
                            <div className="col-md-8 offset-md-2 text-center">
                                <button className="btn btn-lime width-250 m-b-20" onClick={() => this.handleCreateNewClick()}>Add New Contact</button> <br />
                                - or select from contacts - <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 offset-md-2 m-t-25">
                                <div className="width-250 text-center" style={{ margin: 'auto' }}>
                                    <ContactSelect
                                        onSelect={this.HandlePersonSelect.bind(this)}
                                        settingContact={linkingContact}
                                        listType={include}
                                    />
                                </div>
                            </div>
                        </div>
                        </Collapse>
                    </div>
                    :
                    <div>
                        <h5 className="m-b-30">Add New Contact</h5>
                        <AddPerson 
                            transactionId={this.props.transactionId}                    
                            isCreateContact={!selectedRoleId || selectedRoleId === '5' || selectedRoleId === '6'}
                            setContact={this.setContact.bind(this)}
                            toggleAddNew={this.props.toggleAddNew}
                            fetchPeople={this.props.fetchPeople}
                            roleId={selectedRoleId}
                            hasError={hasError}
                            sending={sending}
                            handleSavePerson={this.handleSavePerson.bind(this)}
                        />
                    </div>
                    }
                </div>
            )
        } else {
            return (
                <div className="col-md-12 text-center p-t-40 p-b-40">
                    <b className="f-s-18">
                    <i className="far fa-check-circle text-lime m-r-10"></i>
                    {selectedContactName}
                    </b>
                </div>
            )
        }
    }
}

export default AddContact;

