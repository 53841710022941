import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import { getData, putData } from '../../../helpers/AxiosService';
import { PageSettings } from '../../../config/page-settings';

class TextMessages extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
            dropdownOpen: false,
            unreadCount: 0,
            messages: [],
            hubConnection: null,
            messagePoll: null,
		};
    }

    static contextType = PageSettings;
    
    componentDidMount(){
        this.fetchMessages();
        const messagePoll = setInterval(() => {
            this.fetchMessages();
        }, 30000);

        this.setState({ messagePoll });
    }

    componentWillUnmount() {
        clearInterval(this.state.messagePoll);
    }

    fetchMessages(){
        getData('api/message/text/unread').then(response => {
            let unreadCount = response.data.length === 0 ? 0 : response.data.reduce((accum, current) => {
                if (!current.IsRead) {
                    this.props.updateConversation(current.Number);
                }
                return accum + current.UnreadMessageCount
            }, 0) 
            this.setState({ messages: response.data, unreadCount });
        });
    }

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
    }

    markMessageRead(message){
        if (message.FromContactId) {
            putData(`api/message/text/mark-read?contactId=${message.FromContactId}`).then(response => {
                this.fetchMessages();
            });
        } else {
            putData(`api/message/text/mark-read/message-id?messageId=${message.IncomingMessageId}`).then(response => {
                this.fetchMessages();
            });
        }
	} 

    displayMessages(){
        return this.state.messages.map((message, i) => (
            <DropdownItem 
                key={message.Number} 
                onClick={() => this.handleMessageSelect(message)}
            >
                    {message.UnreadMessageCount > 0 && <span className="badge badge-primary">{message.UnreadMessageCount}</span>}
                    <strong> {message.AuthorName ? message.AuthorName : message.Number}</strong> 
                <br />
                {message.Body}
            </DropdownItem>
        ))
    }

    handleMessageSelect(message){
        this.markMessageRead(message);
        this.context.openConversation({ contactId: message.FromContactId, name: message.AuthorName, number: message.Number });

    }
  
	render() {
        const { unreadCount, messages } = this.state;
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown" tag="li">
				<DropdownToggle className="dropdown-toggle f-s-14" tag="a">
					<i className="fa fa-comments"></i>
					<span className={classnames('label', { 'hide': unreadCount === 0 })}>{unreadCount}</span>
				</DropdownToggle>
				<DropdownMenu className="media-list dropdown-menu-right" tag="ul" modifiers={{ computeStyle: { gpuAcceleration: false }}}>
					<DropdownItem className="dropdown-header" tag="li" header>UNREAD MESSAGES ({unreadCount})</DropdownItem>
                    {
                        messages.length > 0 ?
                        this.displayMessages()
                        :
                        <DropdownItem className="text-center width-300 p-b-10" tag="li">
                            No notification found
                        </DropdownItem>
                    }
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default TextMessages;
