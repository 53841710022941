import React from 'react';
import ReportContainer from './ReportContainer'
import { DateOp } from '../../helpers/OperationTypes';
import { displayDate, displayDateTimeUtc } from '../../helpers/Functions';
import { Link } from 'react-router-dom';


export default function ExpiringTimeframes() {
    return (
        <ReportContainer 
            title="Expiring Timeframes"
            description="Contacts who have indicated a future timeframe and that timeframe is near expiration"
            reportType="timeframe"
            startingRange="This Week"
            startingRangeOp={DateOp.thisWeek}
            reportField="Timeframe"
            rangeDirection="future"
        >
            {data => (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Timeframe</th>
                            <th>Date Active</th>
                            <th>Agent</th>
                            <th>Lender</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.length > 0 && data.map((contact) => (
                            <tr key={contact.ContactId}>
                                <td><Link to={`/contact/${contact.ContactId}`}>{contact.Name || "Unknown"}</Link></td>
                                <td>{displayDate(contact.Timeframe)}</td>
                                <td>{displayDateTimeUtc(contact.DateCreated)}</td>
                                <td>{contact.AgentName || 'Unassigned'}</td>
                                <td>{contact.LenderName || 'Unassigned' }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </ReportContainer>
    )
}