import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactAudioPlayer from 'react-audio-player';

export default function AudioPlayer(props) {

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Call Recording</ModalHeader>
            <ModalBody className="text-center">
                <ReactAudioPlayer 
                    src={props.audioUrl}
                    autoPlay
                    controls
                />
            </ModalBody>
        </Modal>
    )
}

AudioPlayer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    audioUrl: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired
}