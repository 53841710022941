import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import AgentOptions from '../shared/AgentOptions';
import { PageSettings } from '../../config/page-settings';

export default function withReportWrapper(WrappedComponent, range = 'mtd') {
    class WithReportWrapper extends React.Component {
        static contextType = PageSettings;
        constructor(props) {
            super(props);
            this.state = {
                startDate: moment().startOf(range === 'ytd' ? 'year' : 'month').startOf('day'),
                endDate: moment().endOf('month'),
                userId: null,
            }
            this.handleApplyDate = this.handleApplyDate.bind(this);
            this.handleUserChange = this.handleUserChange.bind(this);
        }

        componentDidMount() {
            const userId = this.context.userInfo.RoleId === 2 ? 0 : this.context.userInfo.UserId;
            this.setState({ userId });
        }

        handleApplyDate(e, picker) {
            this.setState({ startDate: picker.startDate, endDate: picker.endDate });
        }

        handleUserChange(e) {
            const { value } = e.target;
            this.setState({ userId: Number(value) });
        }

        render() {
            const { startDate, endDate, userId } = this.state;
            return (
                <div>
                    <div className="m-t-15 m-b-15">
                        <div>
                            <DateRangePicker
                                initialSettings={{ startDate, endDate }}
                                startDate={startDate.format()}
                                endDate={endDate.format()}
                                onApply={this.handleApplyDate}
                            >
                                <button className="btn btn-inverse mr-2 text-truncate">
                                    <i className="fa fa-calendar fa-fw text-white-transparent-5 ml-n1"></i> 
                                    <span>{startDate.format('D MMMM YYYY')} - {endDate.format('D MMMM YYYY')}</span>
                                    <b className="caret m-l-5"></b>
                                </button>
                            </DateRangePicker>
                            <div className="d-inline-block" style={{ width: '290px' }}>
                                <AgentOptions
                                    all={true}
                                    agentId={userId}
                                    handleSelect={this.handleUserChange}
                                />
                            </div>
                        </div>
                    </div>
                    <WrappedComponent
                        startDate={startDate.format()} 
                        endDate={endDate.format()} 
                        userId={userId} 
                        {...this.props}
                    />
                </div>
            )
        }
    }
    WithReportWrapper.displayName = `WithSubscription(${getDisplayName(WrappedComponent)})`;
    return WithReportWrapper;
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}