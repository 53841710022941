import React from 'react';
import { Link } from 'react-router-dom';


export default function ContactTransactions(props){
    return (
        <div>
            <div className="row">
                <div className="col-md-12 m-b-10">
                    <button className="btn btn-lime" onClick={() => props.toggleTransactionModal()}>Add Transaction</button>
                    {/* <span className="cursor-pointer pull-right text-primary" onClick={() => { this.togglePill('0'); }}>Show Less <i className="fas fa-chevron-up"></i></span> */}
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered bg-white">
                    <thead>
                        <tr>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.transactions && props.transactions.length > 0 && 
                            props.transactions.map(t => (
                                <tr>
                                    <td>{t.Role}</td>
                                    <td>{t.Status}</td>
                                    <td><Link to={`/transactions/transaction/${t.TransactionId}`}>{t.Address}</Link></td>
                                    <td>{t.City}</td>
                                    <td>{t.StateCode}</td>
                                </tr>
                            ))
                        }
                        {(!props.transactions || props.transactions.length) === 0 && 
                            <tr>
                                <td className="text-center" colSpan="5">Contact has no transactions yet</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}