import React, { useEffect, useState } from 'react';
import { getData } from '../../helpers/AxiosService';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { formatTransactionsBySource } from '../../modules/Commissions';

function CommissionsBySource(props) {
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({
        gross: 0,
        net: 0, 
        brokerSplit: 0,
        concessions: 0,
        count: 0
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const resp = await getData('api/report/closed-transactions?groupby=source');
                const results = formatTransactionsBySource(resp.data);
                totalCommisions(results);
                setData(results);
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, []);

    const totalCommisions = (sources) => {
        let commissionTotals = sources.reduce((total, src) => {
            total.gross += src.gross;
            total.net += src.net;
            total.brokerSplit += src.brokerSplit;
            total.concessions += src.concessions;
            total.count += src.count;
            return total;
        }, totals);
        
        setTotals(commissionTotals);
    }

    return (
        <div className="card border-0 bg-dark-darker text-white mb-3">
            <div className="card-body bg-black-darker">
                <div className="mb-3 text-grey">
                    <b>CLOSED TRANSACTIONS BY SOURCE</b>
                    <span className="text-grey ml-2">
                        <i className="fa fa-info-circle" id="popover6"></i>
                        <UncontrolledPopover trigger="hover" placement="top" target="popover6">
                            <PopoverHeader>Closed Transactions by source</PopoverHeader>
                            <PopoverBody>Total net commissions and transaction count by source for the current month.</PopoverBody>
                        </UncontrolledPopover>
                    </span>
                </div>
                <h3 className="m-b-10 text-white">${totals.net.toLocaleString()} net</h3>
                <div className="text-grey m-b-1">${totals.gross.toLocaleString()} gross</div>
                { totals.concessions > 0 && <div className="text-grey m-b-1">${totals.concessions.toLocaleString()} concessions</div> }
            </div>
            <div className="widget-list widget-list-rounded inverse-mode">
                { data.map((src, i) => (
                    <div key={i} className="widget-list-item rounded-0 p-t-3">
                        <div className="widget-list-media icon">
                            <span className="label bg-indigo">{src.count}</span>
                        </div>
                        <div className="widget-list-content">
                            <div className="widget-list-title">{src.name}</div>
                            
                        </div>
                        <div className="widget-list-action text-nowrap text-grey">
                            ${src.gross.toLocaleString()} gross
                        </div>
                        <div className="widget-list-action text-nowrap text-grey">
                            ${src.net.toLocaleString()} net
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CommissionsBySource;
