import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { postData, putData } from '../helpers/AxiosService';

class AddNote extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            note: '',
            sending: false,
            noteObject: {},
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedNote && prevProps.selectedNote.NoteId !== this.props.selectedNote.NoteId) {
            this.setState({ note: this.props.selectedNote.Note, noteObject: this.props.selectedNote });
        } 
    }

    handleChange(value) {
        this.setState({ note: value });
    }

    handleSave() {
        this.setState({ sending: true });
        let data = {
            Note: this.state.note,
            ContactEntityId: this.props.entity.ContactEntityId,
        };

        postData('api/notes', data).then(response => {
            this.props.updateNotes();
            this.setState({ sending: false, note: '' });
            this.props.toggleModal('addNote');
        })
    }

    handleUpdate() {
        this.setState({ sending: true });
        const { noteObject, note } = this.state;
        let data = {
            NoteId: noteObject.NoteId,
            Note: note,
            ContactEntityId: this.props.entity.ContactEntityId,
            DateCreated: noteObject.DateCreated,
            UserId: noteObject.UserId,
        };

        putData('api/notes', data).then(resp => {
            this.props.updateNotes();
            this.setState({ sending: false, note: '', noteObject: {} });
            this.props.toggleModal('addNote');
        });
    }

    closeResetModal() {
        this.setState({ note: '' });
        this.props.toggleModal('addNote');
    }

    render() {
        const { noteObject } = this.state;
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.closeResetModal()}>{ noteObject.NoteId ? "Edit Note" : "Add Note"}</ModalHeader>
                <ModalBody>
                    <textarea className="form-control m-b-15" rows="5" placeholder="Enter note..." value={this.state.note} onChange={(e) => this.handleChange(e.target.value)}></textarea>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-white" onClick={() => this.closeResetModal()}>Cancel</button>
                    <button className="btn btn-primary  m-l-5" onClick={() =>  noteObject.NoteId ? this.handleUpdate() : this.handleSave()}>
                        {this.state.sending ? 
                            <div className="button-spinner"></div>
                            :
                            "Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddNote;