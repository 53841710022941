import React from 'react';
import { Panel, PanelBody } from '../panel/panel.jsx';
import { CardTitle, UncontrolledTooltip } from 'reactstrap';
import TaskView from '../TaskView';
import PerfectScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


export default function TransactionTaskReport() {
    return (
        <Panel className="with-shadow height-md">
            <PanelBody style={{ position: 'relative' }}>
                <CardTitle tag="h4" className="m-b-20">Today's Tasks <i className="fas fa-info-circle text-primary f-s-14" id="task_cardTitle"></i></CardTitle>
                <UncontrolledTooltip target="task_cardTitle">
                    Tasks that are due today as well as any tasks that are currently past due
                </UncontrolledTooltip>
                <PerfectScrollBar className="height-smedium m-t-20">
                    <TaskView taskType="transaction" viewType="report" />
                </PerfectScrollBar>
            </PanelBody>
        </Panel>
    )
}