import React, { useContext } from 'react';
import { Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import { Switch, Route, Link } from "react-router-dom";
import withReportWrapper from '../components/reports/ReportingWrapper';
import AppointmentReport from '../components/reports/Appointments/AppointmentReport';
import LeadPerformance from '../components/reports/Leads/LeadPerformance';
import AgentActivity from '../components/reports/agents/AgentActivity';
import CallActivity from '../components/reports/agents/CallActivity';
import Commissions from './Commissions';
import { PageSettings } from '../config/page-settings';
import ClosedTransactionSource from '../components/reports/ClosedTransactionSource';
 
const Appointments = withReportWrapper(AppointmentReport);
const LeadPerformanceReport = withReportWrapper(LeadPerformance);
const AgentActivityReport = withReportWrapper(AgentActivity);
const CallActivityReport = withReportWrapper(CallActivity);

export default function Reporting(props) {
    const cxt = useContext(PageSettings);
    const { path, url } = props.match;
    const { pathname } = props.location;
    const isAdmin = cxt.userInfo.RoleId === 2;
    
    return (
        <div>
            <Nav pills className="m-b-0 slate p-5 p-l-30" id="navElement" style={{ marginTop: '-15px', marginLeft: '-30px', marginRight: '-30px' }}>
                <NavItem>
                    <Link
                        to={`${url}/agent-activity`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/agent-activity` })}
                    >
                        <span>Agent Activity</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to={`${url}/lead-performance`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/lead-performance` })}
                    >
                        <span>Lead Performance</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to={`${url}/call-activity`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/call-activity` })}
                    >
                        <span>Calls</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to={`${url}/appointments`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/appointments` })}
                    >
                        <span>Appointments</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to={`${url}/commissions`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/commissions` })}
                    >
                        <span>Commissions</span>
                    </Link>
                </NavItem>
                { isAdmin && <NavItem>
                    <Link
                        to={`${url}/team-commissions`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/team-commissions` })}
                    >
                        <span>Team Production</span>
                    </Link>
                </NavItem> }
                { isAdmin && <NavItem>
                    <Link
                        to={`${url}/source-performance`}
                        className={classnames('text-center nav-link', { active: pathname === `${url}/source-performance` })}
                    >
                        <span>Source Performance</span>
                    </Link>
                </NavItem> }
            </Nav>
            <Switch>
                <Route exact path={path}>
                    <div className="p-t-25">
                        <h4>Please select a report from the menu</h4>
                    </div>
                </Route>
                <Route path={`${path}/appointments`}>
                    <Appointments />
                </Route>
                <Route path={`${path}/agent-activity`}>
                    <AgentActivityReport />
                </Route>
                <Route path={`${path}/lead-performance`}>
                    <LeadPerformanceReport />
                </Route>
                <Route path={`${path}/call-activity`}>
                    <CallActivityReport />
                </Route>
                { isAdmin && <Route exact path={`${path}/team-commissions`}>
                    <Commissions isTeam={true} />
                </Route> }
                { isAdmin && <Route exact path={`${path}/source-performance`}>
                    <ClosedTransactionSource />
                </Route> }
                <Route path={`${path}/commissions`}>
                    <Commissions />
                </Route>
            </Switch>
        </div>
    )
}