import React from 'react';
import { DateOp } from '../../helpers/OperationTypes';

export default function DayForward({ filter, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10" 
                name={filter.Field}
                value={filters[filter.Field].Value} 
                onChange={handleFilterValueChange}
            >
                <option></option>
                <option value={DateOp.today}>Today</option>
                <option value={DateOp.lastWeek}>Within Last Week</option>
                <option value={DateOp.last30}>Within Last 30 Days</option>
                <option value={DateOp.last90}>Within Last 90 Days</option>
                <option value={DateOp.last6mos}>Within Last 6 Months</option>
            </select>
        </div>
    )
}