import React from 'react';
import { Link } from 'react-router-dom';
import Auth from '../auth/Auth';
import logo from '../assets/rcoLogo.svg';
const appUrl = 'https://www.realconnectone.com';


class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            UserName: '',
            Password: '',
			hasError: false,
			sending: false,
			credentialError: false,
			credentialErrorMsg: ''
        }

        this.auth = new Auth();
    }

    handleChange(item, val){
        this.setState({ [item]: val });
    }

    handleSubmit(e){
		e.preventDefault();

        let data = {
            UserName: this.state.UserName,
            Password: this.state.Password,
            grant_type: 'Password'
        }

        if (!data.UserName || !data.Password){
            this.setState({ hasError: true });
            return;
		}
		
		this.setState({ sending: true });

        this.auth.login(this.state.UserName, this.state.Password, this.props.history).catch(response => {
			const { data } = response.response;
			this.setState({ credentialError: true, sending: false, credentialErrorMsg: data.error });
        });
    }
  
	render() {
		const { credentialError, credentialErrorMsg, sending } = this.state;
		return (
			<div className="login login-v2 animated fadeIn with-thin-shadow" style={{ background: '#fff' }}>
				{/* <div className="news-feed bg-bgGradient p-40 d-flex flex-column justify-content-between align-items-center">
						<h1 className="text-white m-b-40"><strong>Real Connect</strong> One</h1>
						<h4 className="m-t-15 text-white">Learn how you can earn $100 by referring someone to the Real Connect One platform.</h4>
					<div className="news-image" style={{backgroundImage: `url(${house})`}}>
					</div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Real Connect</b> One</h4>
						<p>
							Learn how you can earn $100 by referring someone to the Real Connect One platform.
						</p>
					</div>
				</div> */}
				<div className="right-content bg-white">
					<div className="login-header">
						<div className="brand p-t-20">
							<img src={logo} alt="Real Connect" style={{ width: '50%' }}/>
							<small style={{ color: '#707478' }}>Make more connections all in one platform</small>
						</div>
						<div className="icon">
							<i className="fa fa-sign-in"></i>
						</div>
					</div>
					<div className="login-content">
						{ credentialError && <div className="text-danger">{credentialErrorMsg}</div> }
						<form className="margin-bottom-0" onSubmit={(e) => this.handleSubmit(e)}>
							<div className="form-group m-b-15">
								<input type="text" className="form-control form-control-lg" placeholder="Email Address" required onChange={(e) => this.handleChange('UserName', e.target.value)}/>
							</div>
							<div className="form-group m-b-15">
								<input type="password" className="form-control form-control-lg" placeholder="Password" required onChange={(e) => this.handleChange('Password', e.target.value)}/>
							</div>
							{/* <div className="checkbox checkbox-css m-b-30">
								<input type="checkbox" id="remember_me_checkbox" value="" />
								<label htmlFor="remember_me_checkbox">
									Remember Me
								</label>
							</div> */}
							<div className="login-buttons">
								<button type="submit" className="btn btn-lime btn-block btn-lg">
									{ sending ?
										<div className="button-spinner"></div>
										:
										'Log In'
									}
								</button>
							</div>
							<div className="m-t-20 text-inverse">
								<Link to="/account-recovery" className="text-primary">I forgot my password</Link>
							</div>
							<div className="m-t-20 m-b-40 p-b-40 text-inverse">
								Not a member yet? Click <Link to="/register" className="text-primary">here</Link> to register.
							</div>
							<div className="text-center">
								<a href={appUrl} className="text-primary">Back To Real Connect One</a>
							</div>
							<hr />
							<p className="text-center text-grey-darker">
								&copy; Real Connect One All Right Reserved 2020
							</p>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default Login;