import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import EmailTextEditor from '../../email/EmailTextEditor';
import { postData, getData } from '../../../helpers/AxiosService';
import { PageSettings } from '../../../config/page-settings';

class MassText extends React.Component{
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            text: '',
            editor: {
                height: 150,
            },
            templateOptions: [],
            templateId: 0,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
    }

    componentDidMount() {
        this.fetchTemplateOptions();

        const mergeFieldPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-mergeField .ql-picker-item'));

        mergeFieldPickerItems.forEach(item => item.textContent = item.dataset.value);
    }

    fetchTemplateOptions(){
        getData('api/template/text-templates').then(response => {
            this.setState({ templateOptions: response.data });
        });
    }

    handleChange(value) {
		this.setState({ text: value })
    }

    handleSelectTemplate(template){
        this.setState({ text: template.Body, templateId: template.TextTemplateId });
    }

    closeResetModal() {
        this.setState({ text: '' });
        this.props.toggleModal('textModal');
    }

    handleSendText() {
        let data = {
            ContactIds: this.props.checkedContacts,
            Body: this.state.text,
            TemplateId: this.state.templateId,
        }
        this.setState({ sending: true });

        postData('api/message/mass/text', data, this).then(() => {
            this.setState({ sending: false });
            this.closeResetModal();
            this.context.addNotification("Success!", "Your message was sent successfully.");
        }).fail(() => this.setState({ sending: false }));
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.closeResetModal()}>Send Text</ModalHeader>
                <ModalBody>
                    <div className="row m-b-25">
                        <div className="col-md-12">
                            <div className="form-group">
                                <EmailTextEditor 
                                    handleChange={this.handleChange}
                                    text={this.state.text}
                                    editor={this.state.editor}
                                    handleSelectTemplate={this.handleSelectTemplate}
                                    templates={this.state.templateOptions}
                                    templateIcon="far fa-comment"
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                <button className="btn btn-lime-outline" onClick={() => this.closeResetModal()}>Cancel</button>
                <button className="btn btn-lime" onClick={() => this.handleSendText()}>
                    { this.state.sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default MassText;