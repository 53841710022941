import React from 'react';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import { renderStateList } from '../../../helpers/StateService';


export default function ListingStep2(props){
    const { hasError, handleChange, transaction } = props;
    const { statusId, listingPrice, listingDate, listingExpDate, address, unitNumber, city, stateCode, zipCode } = transaction;
    const statusOptions = props.statusOptions.map(status => <option key={status.TransactionStatusId} value={status.TransactionStatusId}>{status.Name}</option>)
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <h5>Transaction Details</h5>
                    <div className="row">
                        <div className={classnames({ 'has-error': !statusId && hasError }, "form-group col-md-6")}>
                            <label>Status</label>
                            <select className="form-control" name="statusId" value={statusId} onChange={(e) => handleChange(e)} >
                                <option></option>
                                {statusOptions}
                            </select>
                        </div>
                        <div className={classnames({ 'has-error': !listingPrice && hasError }, "form-group col-md-6")}>
                            <label>Listing Price</label>
                            <NumberFormat prefix={'$'} thousandSeparator={true} name="listingPrice" className="form-control" value={listingPrice} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={classnames({ 'has-error': !listingDate && hasError }, "form-group col-md-6")}>
                            <label>Listing Date</label>
                            <DateTime closeOnSelect={true} name="listingDate" value={listingDate} onChange={(date) => handleChange({ target: {value: date, name: 'listingDate'}})} timeFormat={false} />
                        </div>
                        <div className={classnames({ 'has-error': !listingExpDate && hasError }, "form-group col-md-6")}>
                            <label>Listing Expiration Date</label>
                            <DateTime closeOnSelect={true} name="listingExpDate" value={listingExpDate} onChange={(date) => handleChange({ target: {value: date, name: 'listingExpDate'}})} timeFormat={false} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <h5>Property Details</h5>
                    <div className="row">
                        <div className={classnames({ 'has-error': !address && hasError }, "form-group col-md-12")}>
                            <label>Address</label>
                            <input className="form-control" name="address" value={address} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={classnames({ 'has-error': !unitNumber && hasError }, "form-group col-md-4")}>
                            <label>Unit #</label>
                            <input name="unitNumber" className="form-control" value={unitNumber} onChange={(e) => handleChange(e)} />
                        </div>
                        <div className={classnames({ 'has-error': !city && hasError }, "form-group col-md-8")}>
                            <label>City</label>
                            <input className="form-control" name="city" value={city} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={classnames({ 'has-error': !stateCode && hasError }, "form-group col-md-4")}>
                            <label>State</label>
                            <select name="stateCode" className="form-control" value={stateCode} onChange={(e) => handleChange(e)} >
                                <option></option>
                                {renderStateList()}
                            </select>
                        </div>
                        <div className={classnames({ 'has-error': !zipCode && hasError }, "form-group col-md-8")}>
                            <label>Zip Code</label>
                            <input className="form-control" name="zipCode" value={zipCode} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}