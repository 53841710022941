import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function AssignWorkflow({ isOpen, toggleModal, workflowOptions, handleChange, handleAssignWorkflow, sending }){
    const workflows = workflowOptions.length === 0 ? null : workflowOptions.map((wf) => <option key={wf.WorkflowId} value={wf.WorkflowId}>{wf.Name}</option>)
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('workflowModal')}>Assign Workflow</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label>Workflow</label>
                    <select name="selectedWorkflow" className="form-control" onChange={e => handleChange(e)}>
                        <option></option>
                        { workflows }
                    </select>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-lime-outline" onClick={() => toggleModal('workflowModal')}>Cancel</button>
                <button className="btn btn-lime" disabled={sending} onClick={() => handleAssignWorkflow()}>
                    { sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}