import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { postData, getData, putData, deleteData } from '../../helpers/AxiosService';
import ContactOptions from '../shared/ContactOptions';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { PageSettings } from '../../config/page-settings';
import classnames from 'classnames';

momentLocalizer();

export default function AddAppointment(props) {
    const cxt = useContext(PageSettings);
    const [appointment, setAppointment] = useState({
        Id: null,
        Title: '',
        Description: '',
        ContactId: null,
        AppointmentTypeId: 0,
        AppointmentResultId: 0,
        DateTime: new Date(),
        DurationMinutes: 15,
        Location: '',
        UserId: cxt.userInfo.UserId
    });
    const [typeOptions, setTypeOptions] = useState([]);
    const [resultOptions, setResultOptions] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [agents, setAgents] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const { appointmentId } = props;

    let aptPromise = null;

    useEffect(() => {
        async function fetchAppointment() {
            try {
                const aptPromise = getData(`api/appointments/${appointmentId}`);
                const resp = await aptPromise;
                let apt = resp.data;
                apt.DateTime = moment.utc(apt.DateTime).toDate()
                setAppointment(apt);
            } catch (e) {
                console.log(e);
            }
        }

        async function fetchAptOptions() {
            try {
                const typeTask = getData('api/appointments/options/type');
                const resultTask = getData('api/appointments/options/result');
                const agentTask = getData('api/user/agentoptions');

                const [typeResp, resultResp, agentsResp] = await Promise.all([typeTask, resultTask, agentTask]);
                
                setTypeOptions(typeResp.data);
                setResultOptions(resultResp.data);
                setAgents(agentsResp.data);
            } catch (e) {
                console.log(e);
            }
        }

        if (appointmentId) {
            fetchAppointment();
        }
        fetchAptOptions();
    }, []);

    async function handleSubmit() {
        setHasError(false);
        //verify
        if (!appointment.Title || !appointment.AppointmentTypeId || !appointment.DateTime) {
            setHasError(true);
            return;
        }
        setUpdating(true);
        if (appointment.Id) {
            await putData(`api/appointments/${appointment.Id}`, appointment, this).then(resp => {
                setUpdating(false);
                props.close();
                props.updateCallback();
            }).fail(() => {
                setUpdating(false);
                setHasError(true);
            });
        } else {
            postData('api/appointments', appointment, { context: cxt }).then(resp => {
                setUpdating(false);
                props.close();
                props.updateCallback();
            }).fail(() => {
                setUpdating(false);
                setHasError(true);
            });
        }
    }

    function handleChange(e) {
        const { value, name } = e.target;

        setAppointment({...appointment, [name]: value });
    }

    function handleDateChange(date) {
        setAppointment({...appointment, DateTime: date });
    }

    async function handleDeleteApt() {
        setDeleting(true);
        try {
            await deleteData(`api/appointments?appointmentId=${appointmentId}`);
        } catch (e) {
            console.log(e);
        }
        setDeleting(false);
        props.updateCallback();
        props.close();
    }

    async function setContactId(contactId) {
        await aptPromise;
        appointment.ContactId = contactId;
    }

    return (
        <div>
            <div className={classnames({ 'has-error': hasError && !appointment.Title }, "form-group m-b-15")}>
                <label className="form-label">Title</label>
                <input
                    className="form-control" 
                    name="Title" 
                    value={appointment.Title}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group m-b-15">
                <label className="form-label">Description</label>
                <input
                    className="form-control" 
                    name="Description" 
                    value={appointment.Description}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group m-b-15">
                <label className="form-label">Contact</label>
                <ContactOptions 
                    contactId={appointment.ContactId ? appointment.ContactId : props.contactId}
                    setContactId={setContactId}
                />
            </div>
            <div className="form-group m-b-15">
                <label className="form-label">Agent</label>
                <select 
                    className="form-control"
                    name="UserId"
                    value={appointment.UserId}
                    onChange={handleChange}
                >
                    <option value={0}>Select an agent...</option>
                    { agents.map(a => <option key={a.UserId} value={a.UserId}>{a.Name}</option>) }
                </select>
            </div>
            <div className="row">
                <div className={classnames({ 'has-error': hasError && !appointment.AppointmentTypeId }, "form-group col-6 m-b-15")}>
                    <label className="form-label">Type</label>
                    <select className="form-control" name="AppointmentTypeId" value={appointment.AppointmentTypeId} onChange={handleChange}>
                        <option>Select appointment type...</option>
                        { typeOptions.map(type => <option key={type.Id} value={type.Id}>{type.Name}</option>) }
                    </select>
                </div>
                <div className="form-group col-6 m-b-15">
                    <label className="form-label">Result</label>
                    <select className="form-control" name="AppointmentResultId" value={appointment.AppointmentResultId} onChange={handleChange}>
                        <option>Select result...</option>
                        { resultOptions.map(res => <option key={res.Id} value={res.Id}>{res.Name}</option>) }
                    </select>
                </div>
            </div>
            <div className="row m-b-15">
                <div className={classnames({ 'has-error': hasError && !appointment.DateTime }, "form-group col-8")}>
                    <label className="form-label">Date and Time</label>
                    <DateTimePicker 
                        value={appointment.DateTime}
                        onChange={handleDateChange}
                        min={new Date()}
                    />
                </div>
                <div className="form-group col-4">
                    <label className="form-label">Duration</label>
                    <select className="form-control" name="DurationMinutes" value={appointment.DurationMinutes} onChange={handleChange}>
                        <option value="15">15 minutes</option>
                        <option value="30">30 minutes</option>
                        <option value="45">45 minutes</option>
                        <option value="60">1 hour</option>
                        <option value="75">1 hr 15 mins</option>
                        <option value="90">1 hr 30 mins</option>
                        <option value="105">1 hr 45 mins</option>
                        <option value="120">2 hours</option>
                    </select>
                </div>
            </div>
            <div className="form-group m-b-15">
                <label className="form-label">Location</label>
                <input
                    className="form-control" 
                    name="Location" 
                    value={appointment.Location}
                    onChange={handleChange}
                />
            </div>
            <div className="row m-t-25">
                <div className="col-md-6">
                    { appointmentId && <button className="btn btn-outline-danger" onClick={() => handleDeleteApt()}>
                        {
                            deleting ?
                            <div className="button-spinner"></div>
                            :
                            "Delete"
                        }    
                    </button> }
                </div>
                <div className="col-md-6 text-right">
                    <button className="btn btn-primary-outline" onClick={() => props.close()}>Cancel</button>
                    <button className="btn btn-primary m-l-5" onClick={handleSubmit}>
                        {
                            updating ?
                            <div className="button-spinner"></div>
                            :
                            "Save Appointment"
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

AddAppointment.defaultProps = {
    close: () => {},
    updateCallback: () => {},
}

AddAppointment.propTypes = {
    appointmentId: PropTypes.number,
    close: PropTypes.func,
    // used to set the selected contact
    contactId: PropTypes.number,
    // callback after update or save is complete
    updateCallback: PropTypes.func, 
}