import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactSelect from '../ContactSelect';
import { postData, getData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';

function getInitialState() {
    const initState = {
        recipients: [],
        searchName: '',
        sending: false,
    }

    return initState;
}
class SaveSearch extends React.Component {
    static defaultProps = {
        getFilters: () => {},
    }

    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = getInitialState();

        this.addRecipient = this.addRecipient.bind(this);
    }

    componentDidMount() {
        // take a passed in contact and add it to recipients
        if (this.props.contactId) {
            this.setContact(this.props.contactId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contactId !== this.props.contactId) {
            this.setContact(this.props.contactId);
        }
    }

    setContact(contactId) {
        getData(`api/contact?contactId=${contactId}`).then(resp => {
            const { Contact } = resp.data;
            const contact = {
                Key: Contact.ContactId,
                Value: Contact.FirstName + ' ' + Contact.LastName,
            };

            this.addRecipient(contact);
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    addRecipient(contact) {
        this.setState({ recipients: [...this.state.recipients, contact]})
    }

    removeRecipient(id) {
        let recipients = this.state.recipients.filter(r => r.Key !== id);
        this.setState({ recipients });
    }

    handleSaveSearch() {
        this.setState({ sending: true });
        let contactIds = [];
        this.state.recipients.forEach(c => contactIds.push(c.Key));
        const data = {
            ContactIds: contactIds,
            Filters: this.props.getFilters(),
            SearchName: this.state.searchName,
        }

        postData('api/search/save-search', data, this).then(resp => {
            this.context.addNotification("Success!", "Your notification was created.");
            this.resetModal();
        }).fail(() => this.setState({ sending: false }));
    }

    resetModal() {
        this.setState(getInitialState());
        if (this.props.contactId) {
            this.setContact(this.props.contactId);
        }
        this.props.toggleModal();
    }

    render() {
        const { isOpen } = this.props;
        const { recipients, searchName } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg">
                <ModalHeader toggle={() => this.resetModal()}>Create Listing Alert</ModalHeader>
                <ModalBody>
                   <div className="row m-b-25">
                       <div className="col-md-6 text-center">
                            - Add Contact -
                            <ContactSelect
                                onSelect={this.addRecipient}
                                clearInput={true}
                            />
                       </div>
                       <div className="col-md-6 text-center">
                           - Recipients -
                           <div className="m-r-5 p-10 border text-left" style={{ minHeight: '100px', borderRadius: '3px' }}>
                                { recipients.map(r => (
                                    <div key={r.Key}>
                                        {r.Value}
                                        <span className="pull-right text-blue cursor-pointer" onClick={() => this.removeRecipient(r.Key)}>
                                            <small>Remove</small>
                                        </span>
                                    </div>
                                ))}
                           </div>
                       </div>
                   </div>
                   <hr />
                   <div className="row">
                       <div className="form-group col-md-12">
                           <label>Listing Alert Name</label>
                           <input name="searchName" className="form-control" onChange={(e) => this.handleChange(e)} value={searchName} />
                       </div>
                   </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary pull-right width-100" onClick={() => this.handleSaveSearch()} disabled={recipients.length === 0}>
                        { this.state.sending ? 
                            <div className="button-spinner"></div> 
                            :
                            "Send"
                        }
                    </button>
                    <button className="btn btn-primary-outline pull-right width-100" onClick={() => this.resetModal()}>Cancel</button>
                </ModalFooter>
            </Modal>
        )
    }
}

SaveSearch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default SaveSearch;