import React, { useState, useEffect } from 'react';
import { getData } from '../../../helpers/AxiosService';
import { CardTitle, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { displayDateTimeUtc } from '../../../helpers/Functions';
import AudioPlayer from '../../shared/AudioPlayer';
import moment from 'moment';

export default function CallActivity({ userId, startDate, endDate }) {
    const [users, setUsers] = useState([]);
    const [calls, setCalls] = useState([]);
    const [audioUrl, setAudioUrl] = useState('');
    const [reportData, setReportData] = useState({});
    const [showPlayer, setShowPlayer] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activePill, setActivePill] = useState('1');

    useEffect(() => {
        fetchReport();
    }, []);

    async function fetchReport() {
        // sometime userId not set yet
        if (userId === null) return;

        setLoading(true);
        // const formattedEndDate = moment.utc(moment(endDate).startOf('day')).format();
        const formattedEndDate = moment(endDate).format('MM/DD/YYYY');
        const formattedStartDate = moment(startDate).format('MM/DD/YYYY');
        // moment.utc(startDate).startOf('day').format()

        try {
            const response = await getData(`api/report/performance/call-activity?userId=${userId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            setUsers(response.data.Users);
            setCalls(response.data.Calls);
            setReportData(response.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchReport();
    }, [userId, startDate, endDate])

    function openModal(audioUrl) {
        setAudioUrl(audioUrl);
        setShowPlayer(true);
    }

    function closeModal() {
        setAudioUrl('');
        setShowPlayer(false);
    }

    return (
        <div className="p-t-20">
            <CardTitle tag="h4" className="m-b-20">
                Call Activity
                { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i>}
            </CardTitle>
            <div className="row m-b-20">
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">TOTAL CALLS</div>
                            <div className="stats-number">{reportData.TotalCalls}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">DIALS <i className="fas fa-info-circle text-primary" id="dial-info"></i></div>
                            <UncontrolledPopover target="dial-info" trigger="hover">
                                <PopoverHeader>Dials</PopoverHeader>
                                <PopoverBody>Dials are calls lasting less than 60 seconds</PopoverBody>
                            </UncontrolledPopover>
                            <div className="stats-number">{reportData.Dials}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">CONTACTED <i className="fas fa-info-circle text-primary" id="contacted-info"></i></div>
                            <UncontrolledPopover target="contacted-info" trigger="hover">
                                <PopoverHeader>Contacted</PopoverHeader>
                                <PopoverBody>Contacted Calls are calls lasting more than a minute and less than 2 minutes</PopoverBody>
                            </UncontrolledPopover>
                            <div className="stats-number">{reportData.Contacted}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">CONVERSATIONS <i className="fas fa-info-circle text-primary" id="conv-info"></i></div>
                            <UncontrolledPopover target="conv-info" trigger="hover">
                                <PopoverHeader>Conversations</PopoverHeader>
                                <PopoverBody>Conversations are calls lasting more than 2 minutes</PopoverBody>
                            </UncontrolledPopover>
                            <div className="stats-number">{reportData.Conversations}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Nav pills>
                <NavItem>
                    <NavLink className={classnames({ active: activePill === '1' })} onClick={() => setActivePill('1')}>
                        <span className="d-sm-none">Agent Stats</span>
                        <span className="d-sm-block d-none">Agent Stats</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activePill === '2' })} onClick={() => setActivePill('2')}>
                        Call Details
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activePill} style={{ background: 'none', padding: '0' }}>
                <TabPane tabId="1">
                    <table className="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Total Calls</th>
                                <th>Dials</th>
                                <th>Contacted</th>
                                <th>Conversations</th>
                            </tr>
                        </thead>
                        <tbody>
                            { users.map((user) => (
                                <tr key={user.UserId}>
                                    <td>{user.FirstName} {user.LastName}</td>
                                    <td>{user.TotalCalls}</td>
                                    <td>{user.Dials}</td>
                                    <td>{user.Contacted}</td>
                                    <td>{user.Conversations}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tabId="2">
                    <table className="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th>Contact Name</th>
                                <th>Call Date</th>
                                <th>Call Duration Seconds</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { calls.map((call) => (
                                <tr key={call.CallId}>
                                    <td>{call.ContactName}</td>
                                    <td>{displayDateTimeUtc(call.DateCompleted)}</td>
                                    <td>{call.DurationSeconds}</td>
                                    <td>
                                        <button className="btn btn-sm btn-white" onClick={() => openModal(call.RecordingUrl)}>
                                            <i className="fas fa-play text-primary"></i> Play
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </TabPane>
            </TabContent>
            <AudioPlayer 
                isOpen={showPlayer}
                audioUrl={audioUrl}
                closeModal={closeModal}
            />
        </div>
    )
}