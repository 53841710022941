import React from 'react';
import { postData, getData } from '../../helpers/AxiosService';
import DateTime from 'react-datetime';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import ContactOptions from '../shared/ContactOptions';

momentLocalizer();
class AddActivity extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            typeOptions: [],
            typeId: 0,
            time: new Date(),
            description: '',
            sending: false,
            hasError: false,
            selectedActivityType: {},
            selectedContactId: 0,
        }
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setSelectedContactId = this.setSelectedContactId.bind(this);
    }

    static defaultProps = {
        title: 'Add Activity',
    }

    componentDidMount(){
        this.getActivityTypeOptions();
    }

    getActivityTypeOptions(){
        getData('api/activity/options').then(response => {
            const options = response.data.filter(o => o.ActorType === 'user');
            this.setState({ typeOptions: options }, this.filterTypeOptions);
        });
    }

    filterTypeOptions(){
        if (this.props.title === 'Log Phone Call') {
            var callTypes = [1, 2, 3];
            const options = this.state.typeOptions.filter(o => callTypes.includes(o.ActivityTypeId));
            this.setState({ typeOptions: options });
        }
    }

    handleChange(item, val){
        this.setState({ [item]: val});

        if (item === 'typeId') {
            const option = this.state.typeOptions.find(o => o.ActivityTypeId === Number(val));
            this.setState({ selectedActivityType: option });
        }
    }

    handleDateChange(val){
        this.setState({ time: val });
    }

    setSelectedContactId(contactId) {
        this.setState({ selectedContactId: contactId });
    }

    handleSubmit(){
        let { typeId, description, time, selectedContactId } = this.state;
        let data = {
            ActivityTypeId: typeId,
            Description: description,
            DateCreated: moment.utc(time).format(),
            ContactId: selectedContactId,
        }

        if (!data.ActivityTypeId || !data.DateCreated){
            this.setState({ hasError: true });
            return;
        }

        this.setState({ sending: true });

        postData('api/activity/user', data).then(response => {
            this.setState({ 
                sending: false,
                typeId: 0,
                time: new Date(),
                description: '',
                hasError: false
             });

             if (this.props.updateList && typeof(this.props.updateList) === 'function') {
                 this.props.updateList();
             }

             if (this.props.toggleModal){
                 this.props.toggleModal('activity');
             }
        });
    }

    render(){
        const { time, typeId, hasError, sending, selectedActivityType } = this.state;
        const options = this.state.typeOptions.length === 0 ? null : this.state.typeOptions.map((option, idx) => {
            return <option key={idx} value={option.ActivityTypeId}>{option.ActivityType}</option>
        });
        return (
            <div>
                <div className={classnames({ "has-error": hasError && !typeId }, "form-group m-b-15")}>
                    <label className="form-label">Activity Type</label>
                    <select className="form-control" value={this.state.ActivityTypeId} onChange={(e) => this.handleChange('typeId', e.target.value)}>
                        <option value="">Select...</option>
                        { options }
                    </select>
                </div>
                { selectedActivityType.ContactRequired !== false && 
                    <div className="form-group m-b-15">
                        <label className="form-label">Contact</label>
                        <ContactOptions
                            contactId={this.props.contactId}
                            setContactId={this.setSelectedContactId}
                        />
                    </div>
                }
                <div className={classnames({ "has-error": hasError && !time }, "form-group m-b-15")}>
                    <label className="form-label">Time</label>
                    <DateTimePicker 
                        value={time}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="form-group m-b-15">
                    <label className="form-label">Notes</label>
                    <textarea className="form-control" rows="7" onChange={(e) => this.handleChange('description', e.target.value)}/>
                </div>
                <div className="row m-t-25">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary m-l-5" onClick={this.handleSubmit}>
                            {
                                sending ?
                                <div className="button-spinner"></div>
                                :
                                "Save Action"
                            }
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddActivity;

AddActivity.propTypes = {
    contactId: PropTypes.number,
    title: PropTypes.string,
    toggleModal: PropTypes.func,
    updateList: PropTypes.func
}