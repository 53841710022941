import React, { useEffect, useState, useContext } from 'react';
import { getData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';
import { displayFormalDateTimeUtc } from '../../helpers/Functions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddAppointment from './AddAppointment';
import RangePicker from '../reports/RangePicker';
import { DateOp, getDateFilters, Op } from '../../helpers/OperationTypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const aptColor = {
    1001: 'note-primary',
    1002: 'note-success',
    1003: 'note-green',
}

export default function Appointments(props) {
    const cxt = useContext(PageSettings);
    const [appointments, setAppointments] = useState([]);
    const [activeApt, setActiveApt] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dateRange, setDateRange] = useState('Today');
    const [refStartDate, setRefStartDate] = useState(null);
    const [refEndDate, setRefEndDate] = useState(null);

    async function fetchAppointments(startDate, endDate) {
        setRefStartDate(startDate);
        setRefEndDate(endDate);

        let ownerId = cxt.userInfo.UserId;
        if (props.appointmentOwner === 'tenant') {
            ownerId = cxt.userInfo.TenantId;
        } else if (props.appointmentOwner === 'contact') {
            ownerId = props.contactId;
        }
        
        try {
            const resp = await getData(`api/appointments/${props.appointmentOwner}/${ownerId}?startDate=${startDate}&endDate=${endDate}`);
            setAppointments(resp.data);
        } catch (e) {
            console.log(e);
        }
    }

    function loadAppointmentList() {
        const filters = getDateFilters('DateTime', DateOp.today);
        fetchAppointments(filters[0].Value, props.appointmentOwner === 'contact' ? null : filters[1].Value);
    }

    useEffect(() => {
        loadAppointmentList();
    }, []);

    function toggleModal(apt) {
        setActiveApt(apt);
        setModalOpen(!modalOpen);
    }

    function handleRangeChange(e) {
        const { name, value } = e.target;
        setDateRange(name);
        const filters = getDateFilters('DateTime', value);
        const startFilter = filters.find(f => f.Operation === Op.greaterThanOrEqual);
        const endFilter = filters.find(f => f.Operation === Op.lessThanOrEqual);

        fetchAppointments(startFilter.Value, endFilter.Value || null)
    }

    function refreshList() {
        // use currently selected dates
        fetchAppointments(refStartDate, refEndDate);
    }

    return (
        <div className="m-b-20 height-md scrollbar-container">
            {
                props.appointmentOwner !== 'contact' ?
                <div className="d-flex justify-content-between align-items-start m-b-15" id="list-header">
                    <h4>
                        Upcoming Appointments {appointments.length > 0 ? `(${appointments.length})` : null}
                    </h4>
                    <RangePicker 
                        handleChange={handleRangeChange}
                        value={dateRange}
                        direction='future'
                    />
                </div> 
                :
                <h5 className="m-b-15">Upcoming Appointments</h5>
            }
            <PerfectScrollBar style={{ height: 'calc(100% - 45px)' }}>
                {appointments.map((apt) => (
                    <div key={apt.Id} className={"note " + aptColor[apt.AppointmentTypeId]}>
                        <div className="note-content">
                            <h5 className="m-b-5 cursor-pointer" onClick={() => toggleModal(apt)}>{apt.AppointmentType} - {apt.Title}</h5>
                            <p className="m-b-5">{displayFormalDateTimeUtc(apt.DateTime)}</p>
                            <p className="text-muted m-b-0">
                                {apt.UserName} 
                                {apt.ContactName && <i className="fas fa-angle-right m-l-10 m-r-10"></i>} 
                                <Link to={`contact/${apt.ContactId}`}>{apt.ContactName}</Link>
                            </p>
                        </div>
                    </div>
                ))}
                { appointments.length === 0 && <div className="text-center">No Appointments To Show</div>}
            </PerfectScrollBar>
            <Modal isOpen={modalOpen}>
                <ModalHeader toggle={() => toggleModal({})}>Appointment</ModalHeader>
                <ModalBody>
                    <AddAppointment
                        close={() => toggleModal({})}
                        appointmentId={activeApt.Id}
                        contactId={activeApt.ContactId}
                        updateCallback={refreshList}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

Appointments.defaultProps = {
    // options are user or tenant or contact
    appointmentOwner: 'user',
    contactId: 0
}

Appointments.propTypes = {
    appointmentOwner: PropTypes.string,
    // required if using contact as owner type
    contactId: PropTypes.number,
}