import React, { useContext } from 'react';
import { PageSettings } from '../../config/page-settings';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { putData } from '../../helpers/AxiosService';
import { Link } from 'react-router-dom';

const ProfileProgress = (props) => {
    const context = useContext(PageSettings);
    let totalSteps = 0;
    let completeSteps = 0;
    let percentComplete = 0;

    if (Object.keys(context.profileProgress).length > 0) {
        for (let key in context.profileProgress) {
            totalSteps++;
            if (context.profileProgress[key]) {
                completeSteps++;
            }
        }

        percentComplete = Math.floor((completeSteps / totalSteps) * 100);
    }

    const hideProgress = async () => {
        try {
            await putData(`api/user/profile/${context.currentUserId}/toggleshow`);
            context.toggleShowProfileProgress();
        }
        catch (err) {
            console.log(err);
        }
    }

    let textColor = 'text-white';
    let bgColor = 'bg-dark';
    let trailColor = 'rgba((255,255,255,.1)';
    let mBottom = '';

    if (props.type === 'menu') {
        textColor = 'text-black';
        bgColor = '';
        trailColor = 'rgba(0,0,0,.1)';
        mBottom = 'm-b-0';
    }

    return (
        <div className={`card ${bgColor} border-0 ${textColor} ${mBottom}`} style={{ width: '300px' }}>
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-3">
                        <CircularProgressbar
                            value={percentComplete}
                            text={`${percentComplete}%`}
                            styles={buildStyles({
                                pathColor: '#90ca4b',
                                textColor: '#90ca4b',
                                textSize: '24px',
                                trailColor: trailColor,
                            })}
                        />
                    </div>
                    <div className="col-8">
                        <h5 className={textColor}>Profile Setup</h5>
                        <Link className={textColor} to={'/profile/setup'}><p className="card-text cursor-pointer">Click to view setup steps</p></Link>
                    </div>
                    { props.type !== 'menu' && <div className="col-1 border-left">
                        <i 
                            className="fas fa-times cursor-pointer" 
                            onClick={() => hideProgress()}
                        ></i>
                    </div> } 
                </div>
            </div>
        </div>
    )
}

export default ProfileProgress;