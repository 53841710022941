import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../components/panel/panel.jsx';
import PropertyTable from '../components/contact-property/PropertyTable';
import PropertyModal from '../components/contact-property/PropertyModal.js';
import classnames from 'classnames';

class ContactProperties extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            properties: [], 
            loading: true, 
            propertyModal: false,
            selectedProperty: null,
            updateProperties: false,
            alertConfirm: false,
            alertSuccess: false
        }
        this.stopLoading = this.stopLoading.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.resetProperties = this.resetProperties.bind(this);
        this.selectProperty = this.selectProperty.bind(this);
    }

    stopLoading(){
        this.setState({ loading: false });
    }

    toggleModal(){
        this.setState({ propertyModal: !this.state.propertyModal });
    }

    addNew(){
        this.setState({ selectedProperty: null, propertyModal: !this.state.propertyModal });
    }

    resetProperties(properties){
        this.setState({ updateProperties: true });
    }

    selectProperty(property){
        this.setState({ selectedProperty: property }, this.toggleModal);
    }

    render(){
        const { contactId } = this.props.match.params;
        return (
            <div>
                <div className={classnames({ 'show': this.state.loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
				<ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item"><Link to={`/contact/${contactId}`}>Contact Details</Link></li>
					<li className="breadcrumb-item active">Property Details</li>
				</ol>
				<h1 className="page-header">Property Details <small>Manage contact's property information</small></h1>
				<Panel>
					<PanelHeader noButton>Properties</PanelHeader>
					<PanelBody>
                        <div className="row m-b-15">
                            <div className="col-12">
                                <button className="btn btn-lime pull-right" onClick={() => this.addNew()}>Add Property</button>
                                <Link to={`/contact/${contactId}`} className="btn btn-lime-outline pull-left">Back To Contact</Link>
                                {/* <Link to={`/contact/propertydetails/${primary.ContactId}`} className="btn btn-white btn-sm pull-right m-r-5">Edit Property</Link> */}
                            </div>
                        </div>
						<PropertyTable selectProperty={this.selectProperty} updateProperties={this.state.updateProperties} stopLoading={this.stopLoading} canEdit={true} contactId={contactId} />
					</PanelBody>
				</Panel>
                <PropertyModal selectedProperty={this.state.selectedProperty} resetProperties={this.resetProperties} contactId={contactId} toggleModal={this.toggleModal} isOpen={this.state.propertyModal} />
			</div>
        )
    }
}

export default ContactProperties;