const Menu = [
  { 
  	path: '/', icon: 'fa fa-th', title: 'Home', exact: true
  },
  // {
  //   path: '/contacts', icon: 'fas fa-address-book', title: 'Contacts', 
  //   children: [
  //     {
  //       path: '/contacts/leads', icon: '', title: 'Leads', exact: true
  //     },
  //     {
  //       path: '/contacts/prospects', icon: '', title: 'Prospects', exact: true
  //     },
  //     {
  //       path: '/contacts/sphere', icon: '', title: 'Sphere of Influence', exact: true
  //     },
  //     {
  //       path: '/contacts', icon: '', title: 'All Contacts', exact: true
  //     },
  //     {
  //       path: '/contacts/newcontact', icon: '', title: 'Create New Contact', exact: true
  //     }
  //   ]
  // },
  // {
  //   path: '/partners', icon: 'fas fa-address-book', title: 'Partners', 
  //   children: [
  //     {
  //       path: '/partners/lenders', icon: '', title: 'Lenders', exact: true
  //     },
  //     {
  //       path: '/partners/lenders/new', icon: '', title: 'Add Lender', exact: true
  //     },
  //     {
  //       path: '/partners/referral', icon: '', title: 'Referral Partners', exact: true
  //     },
  //     {
  //       path: '/partners/referral/new', icon: '', title: 'Add Referral Partner', exact: true
  //     }
  //   ]
  // },
  // {
  //   path: '/admin', icon: 'fas fa-link', title: 'Admin',
  //   children: [
  //     {
  //       path: '/admin/users', icon: 'fas fa-users', title: 'Users', exact: true
  //     }
  //   ]
  // }

  // { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
  //   children: [
  //     { path: '/menu/menu-1-1', title: 'Menu 1.1',
  //       children: [
  //         { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
  //           children: [
  //             { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
  //             { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
  //           ]
  //         },
  //         { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
  //         { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
  //       ]
  //     },
  //     { path: '/menu/menu-1-2', title: 'Menu 1.2' },
  //     { path: '/menu/menu-1-3', title: 'Menu 1.3' },
  //   ]
  // }
]

export default Menu;
