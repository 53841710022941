const local_dev = {
    baseUrl: "http://localhost/agentone/",
    baseClientUrl: "http://localhost:3000",
    stripeApiKey: "pk_test_JSHZ29aAsf6lARXclMDLopWK00oBS9UlxU",
};

const dev = {
    baseUrl: "http://devrealconnectapi.azurewebsites.net/",
    baseClientUrl: "http://devrealconnect.azurewebsites.net",
    stripeApiKey: "pk_test_JSHZ29aAsf6lARXclMDLopWK00oBS9UlxU",
}

const prod = {
    baseUrl: "https://api.realconnectone.com/",
    baseClientUrl: "https://app.realconnectone.com",
    stripeApiKey: "pk_live_Zf8dguTkzrLdhEVThwWdostr00aEf1Xg4e",
}

const config = process.env.REACT_APP_STAGE === 'prod' 
    ? prod
    : process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : local_dev;

export default config;