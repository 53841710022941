import React from 'react';
import { PageSettings } from './config/page-settings.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import FloatSubMenu from './components/float-sub-menu/float-sub-menu.jsx';
import TextWindow from './components/text/TextWindow.js';
import { getData } from './helpers/AxiosService.js';
import Auth from './auth/Auth.js';
import DialerWindow from './components/voice/DialerWindow.js';


class AuthorizedApp extends React.Component {
	constructor(props) {
		super(props);
		
		this.toggleSidebarMinify = (e) => {
			e.preventDefault();
			if (this.state.pageSidebarMinify) {
				this.setState(state => ({
					pageFloatSubMenuActive: false
				}));
			}
			this.setState(state => ({
				pageSidebarMinify: !this.state.pageSidebarMinify
			}));
		}
		this.toggleMobileSidebar = (e) => {
			this.setState(state => ({
				pageSidebarToggled: !this.state.pageSidebarToggled
			}));
		}
		this.handleSetPageSidebar = (value) => {
			this.setState(state => ({
				pageSidebar: value
			}));
		}
		this.handleSetPageSidebarMinified = (value) => {
			this.setState(state => ({
				pageSidebarMinify: value
			}));
		}
		this.handleSetPageSidebarWide = (value) => {
			this.setState(state => ({
				pageSidebarWide: value
			}));
		}
		this.handleSetPageSidebarLight = (value) => {
			this.setState(state => ({
				pageSidebarLight: value
			}));
		}
		this.handleSetPageSidebarTransparent = (value) => {
			this.setState(state => ({
				pageSidebarTransparent: value
			}));
		}
		this.handleSidebarOnMouseOver = (e, menu) => {
			if (this.state.pageSidebarMinify) {
				if (menu.children) {
					clearTimeout(floatSubMenuRemove);
			
					this.setState(state => ({
						pageFloatSubMenu: menu,
						pageFloatSubMenuActive: true
					}));
			
					const windowHeight = window.innerHeight;
					const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
					const targetTop = e.currentTarget.getBoundingClientRect().top;
					const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
					const left = (e.currentTarget.getBoundingClientRect().left + document.getElementById('sidebar').offsetWidth) + 'px';
					const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
					const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
					const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
					const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
					const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
			
					this.setState(state => ({
						pageFloatSubMenuTop: top,
						pageFloatSubMenuLeft: left,
						pageFloatSubMenuBottom: bottom,
						pageFloatSubMenuLineTop: lineTop,
						pageFloatSubMenuLineBottom: lineBottom,
						pageFloatSubMenuArrowTop: arrowTop,
						pageFloatSubMenuArrowBottom: arrowBottom
					}));
				} else {
					floatSubMenuRemove = setTimeout(() => {
						this.setState(state => ({
							pageFloatSubMenu: '',
							pageFloatSubMenuActive: false
						}));
					}, floatSubMenuRemoveTime);
				}
			}
		}
		this.handleSidebarOnMouseOut = (e) => {
			if (this.state.pageSidebarMinify) {
				floatSubMenuRemove = setTimeout(() => {
					this.setState(state => ({
						pageFloatSubMenuActive: false
					}));
				}, floatSubMenuRemoveTime);
			}
		}
		
		this.toggleRightSidebar = (e) => {
			e.preventDefault();
			this.setState(state => ({
				pageRightSidebarCollapsed: !this.state.pageRightSidebarCollapsed
			}));
		}
		this.toggleMobileRightSidebar = (e) => {
			e.preventDefault();
			this.setState(state => ({
				pageMobileRightSidebarToggled: !this.state.pageMobileRightSidebarToggled
			}));
		}
		this.handleSetPageRightSidebar = (value) => {
			this.setState(state => ({
				pageRightSidebar: value
			}));
		}
		
		var floatSubMenuRemove;
		var floatSubMenuRemoveTime = 250;
		this.handleFloatSubMenuOnMouseOver = (e) => {
			clearTimeout(floatSubMenuRemove);
		}
		this.handleFloatSubMenuOnMouseOut = (e) => {
			floatSubMenuRemove = setTimeout(() => {
				this.setState(state => ({
					pageFloatSubMenuActive: false
				}));
			}, floatSubMenuRemoveTime);
		}
		
		this.handleSetPageContent = (value) => {
			this.setState(state => ({
				pageContent: value
			}));
		}
		this.handleSetPageContentClass = (value) => {
			this.setState(state => ({
				pageContentClass: value
			}));
		}
		this.handleSetPageContentFullHeight = (value) => {
			this.setState(state => ({
				pageContentFullHeight: value
			}));
		}
		this.handleSetPageContentFullWidth = (value) => {
			this.setState(state => ({
				pageContentFullWidth: value
			}));
		}
		
		this.handleSetPageHeader = (value) => {
			this.setState(state => ({
				pageHeader: value
			}));
		}
		this.handleSetPageHeaderMegaMenu = (value) => {
			this.setState(state => ({
				pageHeaderMegaMenu: value
			}));
		}
		this.handleSetPageHeaderLanguageBar = (value) => {
			this.setState(state => ({
				pageHeaderLanguageBar: value
			}));
		}
		
		this.handleSetPageFooter = (value) => {
			this.setState(state => ({
				pageFooter: value
			}));
		}
		this.handleSetPageTopMenu = (value) => {
			this.setState(state => ({
				pageTopMenu: value
			}));
		}
		this.handleSetPageTwoSidebar = (value) => {
			this.setState(state => ({
				pageTwoSidebar: value
			}));
		}
		this.handleSetPageBoxedLayout = (value) => {
			if (value === true) {
				document.body.classList.add('boxed-layout');
			} else {
				document.body.classList.remove('boxed-layout');
			}
		}
		this.handleSetBodyWhiteBg = (value) => {
			if (value === true) {
				document.body.classList.add('bg-white');
			} else {
				document.body.classList.remove('bg-white');
			}
		}

		this.messageConfirmation = (title, confirmationMessage, confirmCb, successMessage, confirmBtnText ) => {
			this.setState({ confirmationTitle: title, confirmationMessage, successMessage, confirmCb, confirmBtnText, sweetAlertConfirm: true });
		}

		this.toggleSweetAlert = (name) => {
			switch(name) {
				case 'primary':
					this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
					break;
				case 'info':
					this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
					break;
				case 'success':
					this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
					break;
				case 'warning':
					this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
					break;
				case 'error':
					this.setState({ sweetAlertError: !this.state.sweetAlertError });
					break;
				case 'confirm':
					this.setState({ sweetAlertConfirm: !this.state.sweetAlertConfirm });
					break;
				default:
					break;
			}
		}

		this.notificationDOMRef = React.createRef();

		this.addNotification = (title, message, type = "success", insert = "top", container = "top-right", duration = 4000) => {
			this.notificationDOMRef.current.addNotification({
				title,
				message,
				type,
				insert,
				container,
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: { duration },
				dismissable: { click: true }
			})
		}

		this.toggleShowProfileProgress = () => {
			this.setState({ showProfileProgress: !this.state.showProfileProgress });
		}
		
		this.state = {
			pageHeader: true,
			pageheaderMegaMenu: false,
			pageHeaderLanguageBar: false,
			handleSetPageHeader: this.handleSetPageHeader,
			handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
			handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,
			
			pageSidebar: true,
			pageSidebarWide: false,
			pageSidebarLight: false,
			pageSidebarMinify: false,
			pageSidebarToggled: false,
			pageSidebarTransparent: false,
			handleSetPageSidebar: this.handleSetPageSidebar,
			handleSetPageSidebarWide: this.handleSetPageSidebarWide,
			handleSetPageSidebarLight: this.handleSetPageSidebarLight,
			handleSetPageSidebarMinified: this.handleSetPageSidebarMinified,
			handleSetPageSidebarTransparent: this.handleSetPageSidebarTransparent,
			handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
			handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
			toggleSidebarMinify: this.toggleSidebarMinify,
			toggleMobileSidebar: this.toggleMobileSidebar,
			
			pageFloatSubMenuActive: false,
			pageFloatSubMenu: '',
			pageFloatSubMenuTop: 'auto',
			pageFloatSubMenuLeft: 'auto',
			pageFloatSubMenuBottom: 'auto',
			pageFloatSubMenuLineTop: 'auto',
			pageFloatSubMenuLineBottom: 'auto',
			pageFloatSubMenuArrowTop: 'auto',
			pageFloatSubMenuArrowBottom: 'auto',
			handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
			handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
			
			pageContent: true,
			pageContentClass: '',
			pageContentFullHeight: false,
			pageContentFullWidth: false,
			handleSetPageContent: this.handleSetPageContent,
			handleSetPageContentClass: this.handleSetPageContentClass,
			handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
			handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
			
			pageFooter: false,
			handleSetPageFooter: this.handleSetPageFooter,
			
			pageTopMenu: false,
			handleSetPageTopMenu: this.handleSetPageTopMenu,
			
			pageTwoSidebar: false,
			handleSetPageTwoSidebar: this.handleSetPageTwoSidebar,
			
			pageRightSidebar: false,
			pageRightSidebarToggled: true,
			pageMobileRightSidebarToggled: false,
			toggleRightSidebar: this.toggleRightSidebar,
			toggleMobileRightSidebar: this.toggleMobileRightSidebar,
			handleSetPageRightSidebar: this.handleSetPageRightSidebar,
			
			handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
			handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

			//Sweet Alert
			toggleSweetAlert: this.toggleSweetAlert,
			messageConfirmation: this.messageConfirmation,
			confirmationTitle: '',
			confirmationMessage: '',
			successMessage: '',
			confirmCb: '',
			confirmBtnText: '',
			sweetAlertConfirm: false,
			sweetAlertSuccess: false,

			//react notification
			addNotification: this.addNotification,

			//Text Conversations
			textConversations: [],
			openConversation: this.openConversation,
			updateConversation: this.updateConverstaion,
			
			//active user info
			userInfo: {},
			currentUserId: 0,
			showProfileProgress: false,
			profileProgress: {},
			toggleShowProfileProgress: this.toggleShowProfileProgress,

			//dialer info
			dialerWindow: false,
			dialerContact: {},
			phoneType: 'CellPhone',
			closeDialer: this.closeDialer,
			dialContact: this.dialContact,
		};

		this.textWindows = new Map();
	}

	renderConversations = () => {
		const conversations = this.state.textConversations.map((c, i) => (
			<TextWindow 
				key={c.number} 
				index={i} 
				toggleExpand={this.toggleExpand} 
				expand={c.expand}
				closeConversation={this.closeConversation}
				name={c.name}
				number={c.number}
				contactId={c.contactId}
				onRef={(ref) => this.textWindows.set(c.number, ref)}
			/>
		));

		return conversations;
	}

	updateConverstaion = (number) => {
		const textWindow = this.textWindows.get(number);
		if (textWindow) {
			textWindow.fetchConversation();
		}
	}

	async componentDidMount(){
		await this.getUserInfo();
	}

	getUserInfo = async () => {
		try {
			const response = await getData('api/user/currentuser').fail((error) => {
				if (error.response && error.response.status === 404) {
					const auth = new Auth();
					auth.logout();
				}
			});

			this.setState({ userInfo: response.data, currentUserId: response.data.UserId });

			const profileResponse = await getData(`api/user/profile/${response.data.UserId}`);
			this.setState({ showProfileProgress: response.data.ShowProfileProgress, profileProgress: profileResponse.data });
		} catch (err) {
			const auth = new Auth();
			auth.logout();
		}
	}

	toggleExpand = (idx) => {
		let textConversations = this.state.textConversations.slice();

		textConversations[idx].expand = !textConversations[idx].expand;

		this.setState({ textConversations });
	}

	closeConversation = (idx) => {
		let textConversations = this.state.textConversations.filter((c, i) => i !== idx);
		this.setState({ textConversations });
	}

	openConversation = (contact) => {
		const { textConversations } = this.state;
		let conversations = textConversations.slice();
		let conversation;

		let convoIndex = textConversations.findIndex(c => c.number === contact.number);
		if (convoIndex === -1){
			conversation = {
				contactId: contact.contactId,
				name: contact.name,
				number: contact.number,
				expand: true
			}

			conversations.push(conversation);
		} else {
			conversations[convoIndex] = {...conversations[convoIndex], expand: true };
		}

		this.setState({ textConversations: conversations });
	}

	dialContact = (contact, phoneType = 'CellPhone') => {
		this.setState({ dialerContact: contact, phoneType, dialerWindow: true });
	}

	closeDialer = () => {
		this.setState({ dialerWindow: false });
	}
	
	render() {
		if (this.state.currentUserId) {
			return (
				<PageSettings.Provider value={this.state}>
					{this.state.dialerWindow && 
						<DialerWindow 
							contact={this.state.dialerContact} 
							closeDialer={this.closeDialer.bind(this)}
							phoneType={this.state.phoneType}
						/>}
					<div className={
						'fade page-sidebar-fixed show page-container ' + 
						(this.state.pageHeader ? 'page-header-fixed ' : '') + 
						(this.state.pageSidebar ? '' : 'page-without-sidebar ') + 
						(this.state.pageRightSidebar ? 'page-with-right-sidebar ' : '') +
						(this.state.pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
						(this.state.pageSidebarLight ? 'page-with-light-sidebar ' : '') +
						(this.state.pageSidebarMinify ? 'page-sidebar-minified ' : '') + 
						(this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') + 
						(this.state.pageTopMenu ? 'page-with-top-menu ' : '') + 
						(this.state.pageContentFullHeight ? 'page-content-full-height ' : '') + 
						(this.state.pageTwoSidebar ? 'page-with-two-sidebar ' : '') + 
						(this.state.pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') + 
						(this.state.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '')
					}>
						<ReactNotification ref={this.notificationDOMRef} />
						{this.state.pageHeader && (<Header history={this.props.history}/>)}
						{this.state.pageSidebar && (<Sidebar />)}
						{this.state.pageTwoSidebar && (<SidebarRight />)}
						{this.state.pageTopMenu && (<TopMenu />)}
						{this.state.pageContent && (<Content />)}
						{this.state.pageFooter && (<Footer />)}
						<FloatSubMenu />
					</div>
					{( this.state.sweetAlertConfirm && 
						<SweetAlert
							primary 
							showCancel
							confirmBtnText={this.state.confirmBtnText || "Delete"}
							cancelBtnBsStyle="default"
							title={this.state.confirmationTitle}
							onConfirm={() => this.state.confirmCb()}
							onCancel={() => this.toggleSweetAlert('confirm')}
						>
							{this.state.confirmationMessage}
						</SweetAlert>
					)}
					{( this.state.sweetAlertSuccess && 
						<SweetAlert
							success 
							confirmBtnText="Ok"
							confirmBtnBsStyle="success"
							title="Success!"
							onConfirm={() => this.toggleSweetAlert('success')}
						>
							{this.state.successMessage}
						</SweetAlert>
					)}
					{ this.state.textConversations.length > 0 && <div className="conversation-container">
						 { this.renderConversations() }
					</div> }
				</PageSettings.Provider>
			)
		} else {
			return <div className="show fade" id="full-page-loader"><div className="spinner"></div></div>
		}
	}
}

export default AuthorizedApp;