import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Collapse, CardTitle } from 'reactstrap';
import { Panel, PanelBody } from '../panel/panel.jsx';
import { getData, postData, putData, patchData } from '../../helpers/AxiosService';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import ReactTags from 'react-tag-autocomplete';
import { phoneClean, displayDateWorded, formatPhoneNumber, displayDate } from '../../helpers/Functions';
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import AddJointContact from './AddJointContact';
import { renderStateList } from '../../helpers/StateService';
import ContactTransactions from './details/ContactTransactions.js';
import ContactProperties from './details/ContactProperties.js';
import AdditionalContacts from './AdditionalContacts';
import { PageSettings } from '../../config/page-settings';
import { Link } from 'react-router-dom';
import Appointments from '../appointments/Appointments';
import CallWrapper from '../shared/CallWrapper.js';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FieldWrapper from './FieldWrapper.js';
import ContactNotes from './ContactNotes.js';
import ContactActivity from '../contact-activity/ContactActivity.js';
import TaskView from '../TaskView.js';
import WorkflowModal from '../workflows/WorkflowModalView';
import './ContactDetails.css';
import PerfectScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ContactDocuments from './ContactDocuments.js';
class ContactDetails extends React.Component{
	static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            activePill: '1', 
            entity: {
				kids: ""
			},
            primary: {},
            additional: [],
            additionalCount: 0,
			hasChanges: false,
			urgencyOptions: [],
			contactTypeOptions: [],
			tags: [],
			suggestions: [],
			sending: false,
			agentOptions: [],
			lenderOptions: [],
			referralOptions: [],
			sourceOptions: [],
			referralSourceId: 0,
			isNew: false,
			dob: '',
			homeAnniversary: '',
			profession: '',
			salary: '',
			detailsChanges: false,
			occupancy: '',
			showAddJoint: false,
			entitySnapshot: {},
			primarySnapshot: {}, 
			transactions: [],
			panelHeight: 0,
			markingContacted: false,
			showCallPopover: false,
			editModal: '',
			showAdditional: false,
		}
		
        this.togglePill = this.togglePill.bind(this);
        this.fetchContactEntity = this.fetchContactEntity.bind(this);
        this.renderContactNavPills = this.renderContactNavPills.bind(this);
        this.submitPrimaryUpdate = this.submitPrimaryUpdate.bind(this);
        this.setReferralSourceId = this.setReferralSourceId.bind(this);
		this.clearNewFlag = this.clearNewFlag.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleEntityChange = this.handleEntityChange.bind(this);
        this.handleDeleteContact = this.handleDeleteContact.bind(this);
		this.toggleShowCallPopover = this.toggleShowCallPopover.bind(this);
		this.handleClearPopover = this.handleClearPopover.bind(this);
		this.toggleEditModal = this.toggleEditModal.bind(this);
    }

    componentDidMount(){
        let contactId = this.props.contactId;
		this.fetchContactEntity(contactId)
		this.fetchContactTransactions();
	}

	handleClearPopover(e) {
        if (!e.target.classList.contains('popover') && !e.target.classList.contains('popover-launch')) {
            this.toggleShowCallPopover(false);
        }
    }
	
	updateDimensions(isResize){
		let navHeight = document.getElementById('navElement').clientHeight + 2;
		const panelWidth = document.getElementById('detailsPanel').clientWidth;
		const contactPanelHeight = document.getElementById('contactPanel').clientHeight;

		if (panelWidth < 745 && navHeight < 45) {
			navHeight = navHeight + 40;
		} 

		let panelHeight = Math.floor(contactPanelHeight - navHeight);

		if (!isResize) {
			panelHeight += 20;
		}

		this.setState({ panelHeight });
	}

    fetchContactEntity(contactId){
        getData(`api/contact?contactId=${contactId}`, this).then(response => {
            this.setState({ 
				entity: response.data, 
				primary: response.data.Contact, 
				additional: response.data.AdditionalContacts, 
				additionalCount: response.data.AdditionalContacts.length,
				urgencyOptions: response.data.UrgencyOptions,
				contactTypeOptions: response.data.ContactTypeOptions,
				agentOptions: response.data.AssignedAgentOptions,
				lenderOptions: response.data.AssignedLenderOptions,
				referralOptions: response.data.ReferralOptions,
				sourceOptions: response.data.SourceOptions,
				referralSourceId: response.data.ReferralSourceId,
				isNew: response.data.IsNew
			}, () => {
				this.setTags(response.data.ContactTags);
				this.setTagSuggestions(response.data.TagSuggestions);
				this.props.stopLoading();
				this.props.setEntity(response.data);
				this.takeDetailsSnapshot();
			});
        }).fail(err => {
			this.props.stopLoading();
			if (err.response && (err.response.status === 403 || err.response.status === 404)) {
				//this.props.history.push('/contacts');
				this.props.stopLoading(true);
			} else {
				this.props.stopLoading();
			}
		});
	}
	
	fetchContactTransactions(){
		getData(`api/transaction/${this.props.contactId}/contact-transactions`).then(response => {
			this.setState({ transactions: response.data });
		});
	}

    togglePill(pill) {
		if (this.state.activePill !== pill) {
			this.setState({
				activePill: pill
			});
		}
	}
	
	toggleModal(name) {
		switch (name) {
			case 'addJointContact':	
				this.setState({ showAddJoint: !this.state.showAddJoint });
				break;
			default:
				break;
		}
	}

	toggleShowCallPopover(show = true) {
		this.setState({ showCallPopover: show });
	}

    handleChange(item, val, changeType = 'hasChanges', cleanVal = false){
		let cleanValue = cleanVal ? val.replace("$", "").replace(/,/g, "") : val;
        this.setState({ primary: {...this.state.primary, [item]: cleanValue }, [changeType]: true });
    };

    handleEntityChange(item, val, changeType = 'hasChanges'){
        this.setState({ entity: {...this.state.entity, [item]: val }, [changeType]: true });
	};
	
	takeDetailsSnapshot(){
		this.setState({ entitySnapshot: {...this.state.entity}, primarySnapshot: {...this.state.primary} });
	}

	resetToSnapshot(){
		this.setState({ entity: {...this.state.entitySnapshot}, primary: {...this.state.primarySnapshot}, hasChanges: false, editModal: '' });
	}

    submitPrimaryUpdate(){
		this.setState({ sending: true });
        let data = {
            Contact: this.state.primary,
            Entity: this.state.entity
		}
		data.Contact.CellPhone = phoneClean(data.Contact.CellPhone);
		data.Contact.HomePhone = phoneClean(data.Contact.HomePhone);
		data.Contact.WorkPhone = phoneClean(data.Contact.WorkPhone);

        postData('api/contact/update', data, this).then(() => {
			this.setState({ hasChanges: false, sending: false, detailsChanges: false, editModal: '' }, this.takeDetailsSnapshot)
        }).fail(() => {
			this.setState({ sending: false });
			this.resetToSnapshot();
		});
	}

	setTags(tags){
		let tagList = [];
		tags.forEach((tag) => {
			tagList.push({id: tag.TagId, name: tag.Name, TagContactEntityId: tag.TagContactEntityId});
			return;
		});

		this.setState({ tags: tagList });
	}
	
	setTagSuggestions(suggestions){
		let sugList = [];
		suggestions.forEach((tag) => {
			sugList.push({ id: tag.TagId, name: tag.Name });
			return;
		});

		this.setState({ suggestions: sugList }); 
	}

	setReferralSourceId(id){
		this.setState({ referralSourceId: id});
	}
	
	handleAddition(tag){
		let data = {
			Name: tag.name,
		}

		postData(`api/contact/tag/${this.state.entity.ContactEntityId}`, data).then(response => {
			let data = response.data;
			let tag = { id: data.TagId, name: data.Name, TagContactEntityId: data.TagContactEntityId };
			const tags = [].concat(this.state.tags, tag);
			this.setState({ tags });
		}).fail(error => console.log(error.response));
	}

	handleDelete(i){
		let tag = this.state.tags[i];

		postData(`api/contact/removetag/${tag.TagContactEntityId}`).then(response => {
			const tags = this.state.tags.slice(0)
			tags.splice(i, 1)
			this.setState({ tags })
		});
	}

	handleDeleteClick(){
        this.context.messageConfirmation('Delete Contact', `Are you sure you want to delete this contact?`, 
                    this.handleDeleteContact, "Contact deleted successfully", "Delete");
    }

    handleDeleteContact(){
        this.context.toggleSweetAlert('confirm');
		const contacts = [this.props.contactId];
		
        putData('api/contact/mass/delete', contacts, this).then(response => {
            if (response.data){
                this.context.toggleSweetAlert('success');
                this.props.history.push('/contacts')
            }
        })
    }

	clearNewFlag(){
		postData(`api/contact/clearnew?entityId=${this.state.entity.ContactEntityId}`).then(response => {
			this.setState({ isNew: false });
		});
	}

	markContacted() {
		this.setState({ markingContacted: true });
		patchData(`api/contact/contacted/${this.state.primary.ContactId}`).then(() => {
			const primary = Object.assign({}, this.state.primary, { IsContacted: true });
			this.setState({ markingContacted: false, primary })
		}).fail(() => {
			this.setState({ markingContacted: false });
		});
	}

    renderContactNavPills(){
        return this.state.additional.map((contact, idx) => {
            let id = idx++;
            return (
                <NavItem key={idx}>
                    <NavLink
                        className={classnames('text-center', { active: this.state.activePill === id })}
                        onClick={() => { this.togglePill(id); }}
                    >
                        <span className="d-sm-none">Joint: {contact.FirstName} {contact.LastName}</span>
                        <span className="d-sm-block d-none">Joint: {contact.FirstName} {contact.LastName}</span>
                    </NavLink>
                </NavItem>
            )
        });
    }

	toggleEditModal(fieldName) {
		this.setState({ editModal: fieldName });
	}

	renderEditModalBody() {
		const { primary, entity } = this.state;
		switch(this.state.editModal) {
			case 'Phone Numbers':
				return (
					<div className="row">
						<div className="form-group col-12 m-b-15">
							<label>Cell</label>
							<InputMask 
								mask="(999) 999 9999" 
								className="form-control" 
								value={primary.CellPhone} 
								onChange={(e) => this.handleChange('CellPhone', e.target.value)}
							></InputMask>
						</div>
						<div className="form-group col-12 m-b-15">
							<label>Home</label>
							<InputMask 
								mask="(999) 999 9999" 
								className="form-control" 
								value={primary.HomePhone} 
								onChange={(e) => this.handleChange('HomePhone', e.target.value)}
							></InputMask>
						</div>
						<div className="form-group col-12 m-b-15">
							<label>Work</label>
							<InputMask 
								mask="(999) 999 9999" 
								className="form-control" 
								value={primary.WorkPhone} 
								onChange={(e) => this.handleChange('WorkPhone', e.target.value)}
							></InputMask>
						</div>
					</div>
				)
			case 'Email':
				return (
					<div className="row">
						<div className="form-group col-12 m-b-15">
							<label>Email</label>
							<input 
								className="form-control" 
								value={primary.Email} 
								onChange={(e) => this.handleChange('Email', e.target.value)} 
							/>
						</div>
					</div>
				)
			case 'Address':
				return (
					<div className="row">
						<div className="form-group col-12 m-b-15">
							<label>Address</label>
							<input 
								className="form-control" 
								value={primary.Address} 
								onChange={(e) => this.handleChange('Address', e.target.value)} 
							/>
						</div>
						<div className="form-group col-8 m-b-15">
							<label>City</label>
							<input className="form-control" value={primary.City} onChange={(e) => this.handleChange('City', e.target.value)} />
						</div>
						<div className="form-group col-4 m-b-15">
							<label>State</label>
							<select className="form-control" value={primary.StateCode} onChange={(e) => this.handleChange('StateCode', e.target.value)}>
								<option></option>
								{ renderStateList() }	
							</select>
						</div>
						<div className="form-group col-6 m-b-15">
							<label>Zip Code</label>
							<InputMask 
								mask="99999" 
								className="form-control" 
								value={primary.ZipCode} 
								onChange={(e) => this.handleChange('ZipCode', e.target.value)}
							></InputMask>
						</div>
					</div>
				)
			case 'Name':
				return (
					<div className="row">
						<div className="form-group col-6">
							<label className="">First Name</label>
								<input className="form-control" value={primary.FirstName} onChange={(e) => this.handleChange('FirstName', e.target.value)} />
						</div>
						<div className="form-group col-6">
							<label className="">Last Name</label>
							<input className="form-control" value={primary.LastName} onChange={(e) => this.handleChange('LastName', e.target.value)} />
						</div>
					</div>
				)
			case 'Source':
				return (
					<div className="form-group m-b-15">
						<label>Source</label>
						<select className="form-control" value={entity.SourceId} onChange={(e) => this.handleEntityChange('SourceId', e.target.value)} >
							<option value=""></option>
							{ this.state.sourceOptions.length === 0 ? null : this.state.sourceOptions.map((option, idx) => {
								return <option key={idx} value={option.SourceId}>{option.Name}</option>
							}) }
						</select>
					</div>
				)
			case 'Urgency':
				return (
					<div className="form-group m-b-15">
						<label>Urgency</label>
						<select className="form-control" value={entity.UrgencyTypeId} onChange={(e) => this.handleEntityChange('UrgencyTypeId', e.target.value)}>
							<option value=""></option>
							{ this.state.urgencyOptions.length === 0 ? null : this.state.urgencyOptions.map((option, idx) => {
								return <option key={idx} value={option.UrgencyTypeId}>{option.Description}</option>
							}) }
						</select>
					</div>
				)
			case 'Assigned Agent':
				return (
					<div className="form-group m-b-15">
						<label>Assigned Agent</label>
						<select className="form-control" value={entity.AssignedAgentId} onChange={(e) => this.handleEntityChange('AssignedAgentId', e.target.value)}>
							<option value="">Unassigned</option>
							{ this.state.agentOptions.length === 0 ? null : this.state.agentOptions.map((option, idx) => {
								return <option key={idx} value={option.UserId}>{option.Name}</option>
							}) }
						</select>
					</div>
				)
			case 'Assigned Lender':
				return (
					<div className="form-group m-b-15">
						<label>Assigned Lender</label>
						<select className="form-control" value={entity.AssignedLenderId} onChange={(e) => this.handleEntityChange('AssignedLenderId', e.target.value)}>
							<option value="">Unassigned</option>
							{ this.state.lenderOptions.length === 0 ? null : this.state.lenderOptions.map((option, idx) => {
								return <option key={idx} value={option.Key}>{option.Value}</option>
							}) }
						</select>
					</div>
				)
			case 'Contact Type':
				return (
					<div className="form-group m-b-15">
						<label>Contact Type</label>
						<select className="form-control" value={entity.ContactTypeId} onChange={(e) => this.handleEntityChange('ContactTypeId', e.target.value)}>
							<option value="">Unassigned</option>
							{ this.state.contactTypeOptions.length === 0 ? null : this.state.contactTypeOptions.map((option, idx) => {
								return <option key={idx} value={option.ContactTypeId}>{option.ContactType}</option>
							}) }
						</select>
					</div>
				)
			case 'Timeframe':
				const timeframe = entity.Timeframe ? moment(entity.Timeframe).format('MM/DD/YYYY') : '';
				return (
					<div className="form-group m-b-15">
						<label>Timeframe</label>
						<DateTime closeOnSelect={true} value={timeframe} onChange={(date) => this.handleEntityChange('Timeframe', date)} timeFormat={false} />
					</div>
				)
			case 'Important Dates':
				const dob = primary.DateOfBirth ? moment(primary.DateOfBirth).format('MM/DD/YYYY') : '';
				const purchaseAnn = entity.PurchaseAnniversary ? moment(entity.PurchaseAnniversary).format('MM/DD/YYYY') : '';
				const saleAnn = entity.SaleAnniversary ? moment(entity.SaleAnniversary).format('MM/DD/YYYY') : '';
				return (
					<div>
						<div className="form-group">
							<label>Date of Birth</label>
							<DateTime closeOnSelect={true} value={dob} onChange={(date) => this.handleChange('DateOfBirth', date, 'detailsChanges')} timeFormat={false} />
						</div>
						<div className="form-group">
							<label>Home Purchase Anniversary</label>
							<DateTime closeOnSelect={true} value={purchaseAnn} onChange={(date) => this.handleEntityChange('PurchaseAnniversary', date, 'detailsChanges')} timeFormat={false} />
						</div>
						<div className="form-group">
							<label>Home Sale Anniversary</label>
							<DateTime closeOnSelect={true} value={saleAnn} onChange={(date) => this.handleEntityChange('SaleAnniversary', date, 'detailsChanges')} timeFormat={false} />
						</div>
					</div>
				)
			case 'Occupancy Information':
				return (
					<div className="form-group">
						<label>Own/Rent</label>
						<select className="form-control" value={entity.Occupancy} onChange={(e) => this.handleEntityChange('Occupancy', e.target.value, 'detailsChanges')}>
							<option value="">Select...</option>
							<option value="rent">Rent</option>
							<option value="own">Own</option>
						</select>
					</div>
				)
			case 'Professional Information':
				return (
					<div>
						<div className="form-group m-t-20">
							<label>Profession</label>
							<input className="form-control" value={primary.Profession} onChange={(e) => this.handleChange('Profession', e.target.value, 'detailsChanges')} />
						</div>
						<div className="form-group">
							<label>Annual Income</label>
							<NumberFormat prefix={'$'} thousandSeparator={true} className="form-control" value={primary.Income} onChange={(e) => this.handleChange('Income', e.target.value, 'detailsChanges', true)} />
						</div>
					</div>
				)
			default:
				break;
		}
	}

    render(){
		const { additionalCount, primary, entity, urgencyOptions, primarySnapshot, entitySnapshot, showAdditional,
			contactTypeOptions, agentOptions, lenderOptions, sending, markingContacted } = this.state;
		const contactWithDetails = { ...primary, 
			ContactDetails: { 
				CellPhone: primary.CellPhone, 
				WorkPhone: primary.WorkPhone, 
				HomePhone: primary.HomePhone,
				Name: `${primary.FirstName} ${primary.LastName}`
			}
		};
		const agent = agentOptions.find(a => a.UserId == entitySnapshot.AssignedAgentId);
		const agentName = agent ? agent.Name : '';
		const lender = lenderOptions.find(l => l.Key == entitySnapshot.AssignedLenderId);
		const lenderName = lender ? lender.Value : '';
		const source = this.state.sourceOptions.find(s => s.SourceId == entitySnapshot.SourceId);
		const sourceName = source ? source.Name : '';
		const urgency = urgencyOptions.find(u => u.UrgencyTypeId == entitySnapshot.UrgencyTypeId);
		const urgencyName = urgency ? urgency.Description : '';
		const contactType = contactTypeOptions.find(ct => ct.ContactTypeId == entitySnapshot.ContactTypeId);
		const contactTypeName = contactType ? contactType.ContactType : '';
		const ocupancy = entitySnapshot.Occupancy === 'rent' ? 'Rent' : entitySnapshot.Occupancy === 'own' ? 'Own' : '';

        let transTab = additionalCount + 1;
		let propTab = transTab + 1;
		let refTab = propTab + 1;
		let detailsTab = refTab + 1;
		let aptTab = detailsTab + 1;
		let docTab = aptTab + 1;
        return(
            <div>
			{this.state.isNew && 
				<div className="row">
					<div className="alert alert-green fade show w-100">
						<span className="close" data-dismiss="alert" onClick={() => this.clearNewFlag()}>×</span>
						<i className="far fa-flag pull-left m-r-10"></i>
						<p className="m-b-0 f-w-600">NEW CONTACT!</p>
					</div>
				</div>}
				<div className="row">
					<div className="contact-details-panel col-12 col-md-3">
						<div id="contactPanel">
							<Panel className="with-shadow">
								<PanelBody>
									<div className="row">
										<div className="col-8">
											<div className="media media-sm">
												<div className="media-body">
													<h4 className="media-heading">{primarySnapshot.FirstName} {primarySnapshot.LastName}<i className="fas fa-pen f-s-12 m-l-10 cursor-pointer" onClick={() => this.toggleEditModal('Name')}></i></h4>
													<p>Active since {displayDateWorded(primary.DateCreated)}</p>
												</div>
											</div>
										</div>
										<div className="col-4 text-right">
										<UncontrolledButtonDropdown tag="li" className="dropdown">
											<DropdownToggle caret className="btn-sm" color="white">
												{ markingContacted && <i className="fas fa-spinner fa-pulse m-r-10"></i> }
												Actions
											</DropdownToggle>
											<DropdownMenu>
												{ !primary.IsContacted && <DropdownItem onClick={() => this.markContacted()}>Mark Contacted</DropdownItem> }
												<DropdownItem onClick={() => this.toggleModal('addJointContact')}>Add Joint Contact</DropdownItem>
												<DropdownItem onClick={() => this.handleDeleteClick()}>Delete Contact</DropdownItem>
											</DropdownMenu>
										</UncontrolledButtonDropdown>
										</div>
									</div>

									<div className="row m-t-10 m-b-10">
										<div className="" style={{ margin: 'auto' }}>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="email-btn" onClick={() => this.props.toggleModal('emailBlank')}><i className="far fa-envelope text-primary"></i></button>
											<UncontrolledTooltip target="email-btn">Send Email</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="text-btn" onClick={() => this.context.openConversation({ contactId: primary.ContactId, name: `${primary.FirstName} ${primary.LastName}`, number: primary.CellPhone })}><i className="far fa-comment text-primary"></i></button>
											<UncontrolledTooltip target="text-btn">Send Text</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="note-btn" onClick={() => this.props.toggleModal('addNote')}><i className="far fa-edit text-primary"></i></button>
											<UncontrolledTooltip target="note-btn">Add Note</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="task-btn" onClick={() => this.props.toggleModal('addTask')}><i className="far fa-check-square text-primary"></i></button>
											<UncontrolledTooltip target="task-btn">Add Task</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="activity-btn" onClick={() => this.props.toggleModal('activity')}><i className="fas fa-user-check text-primary"></i></button>
											<UncontrolledTooltip target="activity-btn">Log Activity</UncontrolledTooltip>
										</div>
									</div>
									<div className="row m-b-20">
										<div className="" style={{ margin: 'auto' }}>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="search-btn"><Link to={`/mls-search?c=${primary.ContactId}`}><i className="fas fa-search text-primary"></i></Link></button>
											<UncontrolledTooltip target="search-btn" placement="bottom">Create Saved Search</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="property-btn"><Link to={`/mls-search?c=${primary.ContactId}`}><i className="fas fa-home text-primary"></i></Link></button>
											<UncontrolledTooltip target="property-btn" placement="bottom">Send Property</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="video-btn"><Link to={`/video?c=${primary.CellPhone}&em=${primary.Email}`}><i className="fas fa-video text-primary"></i></Link></button>
											<UncontrolledTooltip target="video-btn" placement="bottom">Send Video</UncontrolledTooltip>
											<button className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle" id="appointment-btn" onClick={() => this.props.toggleModal('appointment')}><i className="far fa-calendar-alt text-primary"></i></button>
											<UncontrolledTooltip target="appointment-btn" placement="bottom">Add Appointment</UncontrolledTooltip>
											<CallWrapper
												isOpen={this.state.showCallPopover}
												togglePopover={this.toggleShowCallPopover}
												buttonId="call-btn"
												contact={contactWithDetails}
												placement="bottom"
											>
												{ renderProps => (
													<React.Fragment>
														<button 
															className="btn btn-default btn-lg m-l-5 m-r-5 btn-icon btn-circle popover-launch" 
															id="call-btn" 
															onClick={() => renderProps.handleCallClick(null, contactWithDetails)}
														>
															<i className="fas fa-phone text-primary popover-launch"></i>
														</button>
													</React.Fragment>
												)}
											</CallWrapper>
										</div>
									</div>
									<div>
										{ primary.Email ?
											<FieldWrapper
												fieldName="Email"
												handleEditClick={this.toggleEditModal}
											>
												<i className="fa fa-envelope m-r-10 text-body"></i>
												{primarySnapshot.Email}
											</FieldWrapper>
											:
											<div className="text-primary cursor-pointer m-b-5" onClick={() => this.toggleEditModal('Email')}>
												<i className="fas fa-plus f-s-14 m-r-10 text-body"></i>
												Add Email
											</div>
										}
										{ primary.CellPhone ? 
											<FieldWrapper
												fieldName="Phone Numbers"
												handleEditClick={this.toggleEditModal}
											>
												<i className="fas fa-phone m-r-10 text-body"></i>
												{formatPhoneNumber(primarySnapshot.CellPhone)}<span className="text-muted m-l-5">(mobile)</span>
											</FieldWrapper> 
											:
											<div className="text-primary cursor-pointer m-b-5" onClick={() => this.toggleEditModal('Phone Numbers')}>
												<i className="fas fa-phone f-s-14 m-r-10 text-body"></i>
												Add Mobile Number
											</div>
										}
										{ primary.HomePhone ? 
											<FieldWrapper
												fieldName="Phone Numbers"
												handleEditClick={this.toggleEditModal}
											>
												<i className="fas fa-phone m-r-10 text-body"></i>
												{formatPhoneNumber(primarySnapshot.HomePhone)}<span className="text-muted m-l-5">(home)</span>
											</FieldWrapper> 
											:
											<div className="text-primary cursor-pointer m-b-5" onClick={() => this.toggleEditModal('Phone Numbers')}>
												<i className="fas fa-phone f-s-14 m-r-10 text-body"></i>
												Add Home Number
											</div>	
										}
										{ primary.WorkPhone ? 
											<FieldWrapper
												fieldName="Phone Numbers"
												handleEditClick={this.toggleEditModal}
											>
												<i className="fas fa-phone m-r-10 text-body"></i>
												{formatPhoneNumber(primarySnapshot.WorkPhone)}<span className="text-muted m-l-5">(work)</span>
											</FieldWrapper> 
											:
												<div className="text-primary cursor-pointer m-b-5 p-t-5 p-b-5" onClick={() => this.toggleEditModal('Phone Numbers')}>
													<i className="fas fa-phone f-s-14 m-r-10 text-body"></i>
													Add Work Number
												</div>
											}
										{ (primary.Address || primary.City || primary.StateCode || primary.ZipCode) ?
											<FieldWrapper
												fieldName="Address"
												handleEditClick={this.toggleEditModal}
											>
												<i className="fas fa-home m-r-10 text-body"></i>
												{primarySnapshot.Address}<br/>
												{primarySnapshot.City}, {primarySnapshot.StateCode} {primarySnapshot.ZipCode}
											</FieldWrapper>	
											:
											<div className="text-primary cursor-pointer m-b-5 p-t-5 p-b-5" onClick={() => this.toggleEditModal('Address')}>
												<i className="fas fa-home f-s-14 text-body m-r-10"></i>
												Add Address
											</div>
										}
										<hr />	
										<FieldWrapper
											fieldName="Assigned Agent"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Assigned Agent</span> {agentName}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Assigned Lender"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Assigned Lender</span> {lenderName}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Source"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Source</span> {sourceName}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Urgency"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Urgency</span> {urgencyName}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Contact Type"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Contact Type</span> {contactTypeName}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Occupancy Information"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Own/Rent</span> {ocupancy}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Timeframe"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Timeframe</span> {displayDate(entitySnapshot.Timeframe)}
										</FieldWrapper>
										<div className="m-b-15">
											<label>Tags:</label>
											<ReactTags 
												autoFocus={false} 
												allowNew={true} 
												tags={this.state.tags} 
												suggestions={this.state.suggestions} 
												onDelete={this.handleDelete.bind(this)} 
												onAddition={this.handleAddition.bind(this)}
												minQueryLength={1}
											/>
										</div>
									</div>
									<hr />
									<div className="cursor-pointer" onClick={() => this.setState({ showAdditional: !this.state.showAdditional })}>
										<label className="cursor-pointer">Additional Details 
										<i className={classnames({ 'fas fa-chevron-down': !showAdditional, 'fas fa-chevron-up': showAdditional }, 'm-l-10')}></i>
										</label>
									</div>
									<Collapse isOpen={showAdditional}>
										<FieldWrapper
											fieldName="Important Dates"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Date of Birth</span> {displayDate(primarySnapshot.DateOfBirth)}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Important Dates"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Home Purchase Anniversary</span> {displayDate(entitySnapshot.PurchaseAnniversary)}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Important Dates"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Home Sale Anniversary</span> {displayDate(entitySnapshot.SaleAnniversary)}
										</FieldWrapper>
										<hr />
										<FieldWrapper
											fieldName="Professional Information"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Profession</span> {primarySnapshot.Profession}
										</FieldWrapper>
										<FieldWrapper
											fieldName="Professional Information"
											handleEditClick={this.toggleEditModal}
										>
											<span className="text-black f-w-600">Annual Income</span> {primarySnapshot.Income}
										</FieldWrapper>
									</Collapse>
								</PanelBody>
							</Panel>
						</div>
					</div>
					<div className="contact-activity col-12 col-md-6">
						<Nav pills className="m-b-0 white contact bg-white" id="navElement">
							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === '1' })}
									onClick={() => { this.togglePill('1'); }}
								>
									<span className="d-sm-none">Activity</span>
									<span className="d-sm-block d-none">Activity</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === '2' })}
									onClick={() => { this.togglePill('2'); }}
								>
									<span className="d-sm-none">Tasks</span>
									<span className="d-sm-block d-none">Tasks</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === '3' })}
									onClick={() => { this.togglePill('3'); }}
								>
									<span className="d-sm-none">Workflows</span>
									<span className="d-sm-block d-none">Workflows</span>
								</NavLink>
							</NavItem>
							{ this.state.additional.length > 0 && 
								<NavItem>
									<NavLink
										className={classnames('text-center', { active: this.state.activePill === '4' })}
										onClick={() => { this.togglePill('4'); }}
									>
										<span className="d-sm-none">Joint Contacts</span>
										<span className="d-sm-block d-none">Joint Contacts</span>
									</NavLink>
								</NavItem>
							 }

							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === transTab })}
									onClick={() => { this.togglePill(transTab); }}
								>
									<span className="d-sm-none">Transactions</span>
									<span className="d-sm-block d-none">Transactions</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === propTab })}
									onClick={() => { this.togglePill(propTab); }}
								>
									<span className="d-sm-none">Owned Properties</span>
									<span className="d-sm-block d-none">Owned Properties</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === aptTab })}
									onClick={() => { this.togglePill(aptTab); }}
								>
									<span className="d-sm-none">Appointments</span>
									<span className="d-sm-block d-none">Appointments</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames('text-center', { active: this.state.activePill === docTab })}
									onClick={() => { this.togglePill(docTab); }}
								>
									<span className="d-sm-none">Documents</span>
									<span className="d-sm-block d-none">Documents</span>
								</NavLink>
							</NavItem>
						</Nav>
						<PerfectScrollBar className="activity-list p-b-0 with-thin-shadow">
							<TabContent activeTab={this.state.activePill} className="m-b-0" id="detailsPanel">
								<TabPane tabId={'1'}>
									<ContactActivity 
										contactId={this.props.contactId} 
										toggleModal={this.props.toggleModal} 
										handleSelectTemplate={this.props.handleSelectTemplate}
										contactEntityId={entity.ContactEntityId}
										getPhoneCalls={this.props.getPhoneCalls}
										updatePhoneCalls={this.props.updatePhoneCalls}
										handleCallEditClick={this.props.handleCallEditClick}
										onRef={ref => this.props.setContactActivityRef(ref)}
									/>
								</TabPane>
								<TabPane tabId={'2'}>
									<TaskView 
										taskType="contactEntity"
										typeId={this.props.contactEntityId}
									/>
								</TabPane>
								<TabPane tabId={'3'}>
									<WorkflowModal contactId={this.props.contactId} />
								</TabPane>
								{ this.state.additional.length > 0 && <AdditionalContacts additional={this.state.additional} /> }
								<TabPane tabId={transTab}>
									<ContactTransactions 
										toggleTransactionModal={this.props.toggleTransactionModal}
										transactions={this.state.transactions}
									/>
								</TabPane>
								<TabPane tabId={propTab}>
									<ContactProperties primary={primary} />
								</TabPane>
								<TabPane tabId={aptTab}>
									<Appointments 
										appointmentOwner="contact"
										contactId={this.props.contactId}
									/>
								</TabPane>
								<TabPane tabId={docTab}>
									<ContactDocuments 
										contactId={this.props.contactId} 
										contactEntityId={entity.ContactEntityId}
									/>
								</TabPane>
							</TabContent>
						</PerfectScrollBar>
					</div>
					<Panel className="notes-panel col-12 col-md-3">
						<PanelBody>
							<div className="row m-b-30 p-r-15 p-l-15">
								<h4 className="col-6">Notes</h4>
								<div className="col-6">
									<button className="btn btn-lime btn-sm p-r-40 p-l-40 pull-right" onClick={() => this.props.toggleModal('addNote')}>Add Note</button>
								</div>
							</div>
							<PerfectScrollBar className="notes-list">
								<ContactNotes toggleModal={this.props.toggleModal} entityNotes={this.props.entityNotes} renderNotes={this.props.renderNotes} />
							</PerfectScrollBar>
						</PanelBody>
					</Panel>
				</div>
				<AddJointContact 
					isOpen={this.state.showAddJoint} 
					toggleModal={this.toggleModal} 
					fetchContactEntity={this.fetchContactEntity} 
					contactEntityId={entity.ContactEntityId}
					tenantId={primary.TenantId}
					contactId={primary.ContactId}
				/>
				<Modal isOpen={this.state.editModal}>
					<ModalHeader toggle={() => this.setState({ editModal: '' })}>
						Edit {this.state.editModal}
					</ModalHeader>
					<ModalBody>
							{ this.renderEditModalBody() }
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-primary-outline" onClick={() => this.resetToSnapshot()}>Cancel</button>
						<button className="btn btn-primary" onClick={() => this.submitPrimaryUpdate()}>
							{ sending ?
								<div className="button-spinner"></div>
								:
								"Save"
							}
						</button>
					</ModalFooter>
				</Modal>
            </div>
        )
    }
}

export default ContactDetails;