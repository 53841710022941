import React from 'react';
import { Panel, PanelBody } from '../components/panel/panel';
import { Link } from 'react-router-dom';
import TaskView from '../components/TaskView';

class Tasks extends React.Component{
    render(){
        return(
            <div>
				<ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item active">Tasks</li>
				</ol>
				<h1 className="page-header">Tasks <small>View and manage all your tasks</small></h1>
                <Panel>
                    <PanelBody>
                        <TaskView taskType="all" /> 
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default Tasks;