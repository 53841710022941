import React from 'react';
import { DateOp } from '../../helpers/OperationTypes';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


export default function RangePicker({ handleChange, value, direction, includeAll = false }) {
    return (
        <UncontrolledButtonDropdown className="m-l-15">
            <DropdownToggle className="btn-sm btn-primary-outline" caret>{value}</DropdownToggle>
            { direction === 'past' &&
            <DropdownMenu>
                <DropdownItem name="Today" value={DateOp.today} onClick={(e) => handleChange(e)}>Today</DropdownItem>
                <DropdownItem name="Last Week" value={DateOp.lastWeek} onClick={(e) => handleChange(e)}>Last Week</DropdownItem>
                <DropdownItem name="Last 30 days" value={DateOp.last30} onClick={(e) => handleChange(e)}>Last 30 days</DropdownItem>
                <DropdownItem name="Last 90 days" value={DateOp.last90} onClick={(e) => handleChange(e)}>Last 90 days</DropdownItem>
                <DropdownItem name="Last 6 months" value={DateOp.last6mos} onClick={(e) => handleChange(e)}>Last 6 months</DropdownItem>
            </DropdownMenu> }
            { direction === 'future' &&
            <DropdownMenu>
                <DropdownItem name="Today" value={DateOp.today} onClick={(e) => handleChange(e)}>Today</DropdownItem>
                <DropdownItem name="This Week" value={DateOp.thisWeek} onClick={(e) => handleChange(e)}>This Week</DropdownItem>
                <DropdownItem name="Next 30 days" value={DateOp.next30} onClick={(e) => handleChange(e)}>Next 30 days</DropdownItem>
                <DropdownItem name="Next 90 days" value={DateOp.next90} onClick={(e) => handleChange(e)}>Next 90 days</DropdownItem>
                { includeAll && <DropdownItem name="All" value={DateOp.allFuture} onClick={(e) => handleChange(e)}>All</DropdownItem> }
            </DropdownMenu> }
        </UncontrolledButtonDropdown>
    )
}