import React, { useState, useEffect } from 'react';
import SalesListCard from './SalesListCard';
import PropTypes from 'prop-types';
import { getData } from '../../helpers/AxiosService';

export default function SalesList(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [salesList, setSalesList] = useState({});

    async function fetchResults() {
        if (!props.salesList || !props.salesList.Id) return;
        setIsLoading(true);
        try {
            const resp = await getData(`api/salesreport/${props.salesList.Id}?includeResults=true&targetUserId=${props.userId}`)
            setSalesList(resp.data);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchResults();
        setSalesList(props.salesList);
    }, [props.salesList])

    useEffect(() => {
        if (props.salesList) {
            setSalesList(props.salesList);
        }
    }, [])

    useEffect(() => {
       fetchResults();
    }, [props.userId])

    return (
        <SalesListCard 
            loading={isLoading}
            list={salesList} 
            handleEditClick={props.handleEditClick}
            handleEmail={props.handleEmail}
            handleDeleteClick={props.handleDeleteClick}
        />
    )
}

SalesList.propTypes = {
    handleEditClick: PropTypes.func.isRequired,
    handleEmail: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    salesList: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
}