import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { CardBody, Card, Dropdown, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TransactionCard from '../components/transactions/TransactionCard';
import { getData } from '../helpers/AxiosService';
import CreateTransactionModal from '../components/transactions/CreateTransactionModal';
import { PageSettings } from '../config/page-settings';
import AgentOptions from '../components/shared/AgentOptions';
import '../components/transactions/Transactions.css';
import { debounce } from 'lodash';

class Transactions extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.dropdownRef = React.createRef();
        this.state = {
            loading: true,
            transactions: [],
            agentOptions: [],
            statuses: [1, 2],
            type: "Both",
            selectedAgentId: 0,
            createModal: false,
            statusOptions: [],
            searchTerm: '',
            searchResults: [],
            showResults: false,
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.redirectToTransaction = this.redirectToTransaction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.postSearch = this.postSearch.bind(this);
        this.throttledPostSearch = debounce(this.postSearch, 300);
    }

    componentDidMount() {
        this.setState({ selectedAgentId: this.context.userInfo.IsAdmin ? 0 : this.context.currentUserId }, () => {
            this.fetchOptions();
            this.fetchTransactions();
            this.getCreateOptions();
        });

        window.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(e) {
        console.log(this.dropdownRef);
        console.log(e.target);
        if (this.dropdownRef && !this.dropdownRef.current.contains(e.target)) {
            this.setState({ showResults: false, searchTerm: '', searchResults: [] });
        }
    }

    fetchTransactions(){
        this.setState({ loading: true });
        const { selectedAgentId, type, statuses } = this.state;
        let statusQuery = '';
        statuses.forEach(s => statusQuery += `&statuses=${s}`);
        getData(`api/transaction/transactions?selectedUserId=${selectedAgentId}&type=${type}${statusQuery}`).then(response => {
            this.setState({ transactions: response.data, loading: false });
        }).fail(error => this.setState({ loading: false }));
    }

    fetchOptions(){
        getData('api/user/agentoptions').then(response => {
            this.setState({ agentOptions: response.data });
        });
    }

    getCreateOptions(){
        getData('api/transaction/create-options').then(response => {
            this.setState({ statusOptions: response.data });
        })
    }

    handleChange(e){
        let {name, value} = e.target;
        this.setState({ [name]: value }, this.fetchTransactions);
    }

    handleCheckChange(e) {
        const isChecked = e.target.checked;
        let statuses = this.state.statuses.slice();
        if (isChecked) {
            statuses = [...statuses, Number(e.target.value)];
        } else {
            const idx = statuses.indexOf(Number(e.target.value));
            if (idx !== -1) {
                statuses.splice(idx, 1);
            }
        }

        this.setState({ statuses }, this.fetchTransactions); 
    }

    toggleSelectAll(e) {
        let selected = e.target.checked;
        if (selected) {
            this.setState({ statuses: [1, 2, 3, 4, 5] }, this.fetchTransactions);
        } else {
            this.setState({ statuses: [] });
        }
    }

    handleSearch(e) {
        const { value } = e.target;
        this.setState({ searchTerm: value }, this.throttledPostSearch);
    }

    postSearch() {
        getData(`api/transaction/search?searchTerm=${this.state.searchTerm}`).then(resp => {
            this.setState({ searchResults: resp.data, showResults: true });
        });
    }

    renderTransactions(){
        return this.state.transactions.map((transaction, i) => (
            <Link key={transaction.TransactionId} className="no-decoration" to={`/transactions/transaction/${transaction.TransactionId}`}>
                <TransactionCard key={transaction.TransactionId} transaction={transaction} />
            </Link>
        ))
    }

    toggleModal(){
        this.setState({ createModal: !this.state.createModal });
    }

    redirectToTransaction(transId){
        this.props.history.push(`/transactions/transaction/${transId}`);
    }

    toggleDropdown() {
        this.setState({ showResults: !this.state.showResults });
    }

    render(){
        const { loading, type, selectedAgentId, statuses, searchTerm, searchResults, showResults } = this.state;
        return(
            <div>
                <button className="btn btn-lime p-r-20 p-l-20 pull-right" onClick={() => this.toggleModal()}>Create New Transaction</button>
                <h1 className="page-header">Transactions <small className="f-w-100">List of your transactions</small></h1>
                <div className="action-container m-t-25">
                    <Dropdown isOpen={showResults} toggle={() => {}} className="search-bar with-thin-shadow">
                        <div ref={this.dropdownRef} style={{ height: '100%' }}>
                        <DropdownToggle tag="text">
                            <div className="search-icon"><i className="fa fa-search"></i></div>
                            <input 
                                className="search-input"
                                placeholder="Search by address, MLS #, Transaction Id..."
                                value={searchTerm}
                                onChange={(e) => this.handleSearch(e)}
                            />
                        </DropdownToggle>
                        <DropdownMenu className="results-container">
                            {searchResults && searchResults.map(t => (
                                <DropdownItem key={t.TransactionId}>
                                    <Link to={`/transactions/transaction/${t.TransactionId}`} className="no-decoration">
                                        <h5 className="text-primary">{t.Address} <small>{t.City}, {t.StateCode} {t.ZipCode}</small></h5>
                                        { t.ListingKey && <span>MLS # {t.ListingKey} | </span> }{t.Type} | Status: {t.Status}
                                    </Link>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                        </div>
                    </Dropdown>

                    <UncontrolledButtonDropdown className="p-l-0 p-r-0 m-l-5">
                        <DropdownToggle className="btn btn-white m-b-5 width-150 with-thin-shadow">Status ({statuses.length}) <i className="fas fa-angle-down m-l-10"></i></DropdownToggle>
                        <DropdownMenu className="p-20">
                            {this.state.statusOptions.map((option, i) => (
                                <div key={i} className="checkbox checkbox-css">
                                    <input type="checkbox" name="status" id={`csscheckbox${i}`} checked={statuses.includes(option.TransactionStatusId)} value={option.TransactionStatusId} onChange={e => this.handleCheckChange(e)} />
                                    <label htmlFor={`csscheckbox${i}`}>{option.Name}</label>
                                </div>
                            ))}
                            <div className="checkbox checkbox-css">
                                <input type="checkbox" id="checkboxAll" value="All" onChange={e => this.toggleSelectAll(e)} />
                                <label htmlFor="checkboxAll">All</label>
                            </div>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="p-l-0 p-r-0 m-l-5">
                        <DropdownToggle className="btn btn-white m-b-5 width-150 with-thin-shadow">{type} <i className="fas fa-angle-down m-l-10"></i></DropdownToggle>
                        <DropdownMenu className="p-20">
                            <div className="radio radio-css">
                                <input type="radio" name="type" id="typeRadio1"  checked={type === "Listing"} value="Listing" onChange={e => this.handleChange(e)}/>
                                <label htmlFor="typeRadio1">Listing</label>
                            </div>
                            <div className="radio radio-css">
                                <input type="radio" name="type" id="typeRadio2" checked={type === "Purchase"} value="Purchase" onChange={e => this.handleChange(e)} />
                                <label htmlFor="typeRadio2">Purchase</label>
                            </div>
                            <div className="radio radio-css">
                                <input type="radio" name="type" id="typeRadio3" checked={type === "Both"} value="Both" onChange={e => this.handleChange(e)} />
                                <label htmlFor="typeRadio3">Both</label>
                            </div>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <div className="agent-options">
                        <AgentOptions 
                            agentId={selectedAgentId}
                            handleSelect={this.handleChange}
                            selectName="selectedAgentId"
                            className="with-thin-shadow"
                        />
                    </div>
                </div>
                <div className={classnames({ 'show': loading }, 'fade')} id="modal-loader" style={{ zIndex: '999' }}>
                    <div className="spinner" style={{ top: '250px' }}></div>
                </div>
                <div className="row">
                    {this.state.transactions.length > 0 && this.renderTransactions()}
                    {this.state.transactions.length === 0 && <div className="col-md-12 text-center">No transactions to display</div>}
                </div>
                <CreateTransactionModal 
                    isOpen={this.state.createModal} 
                    toggleModal={this.toggleModal} 
                    redirectToTransaction={this.redirectToTransaction}
                />
            </div>
        )
    }
}

export default Transactions;