import React from 'react';
import { deleteData, getData, postData } from '../../helpers/AxiosService';
import { Link } from 'react-router-dom';
import PersonModal from './PersonModal';
import { phoneClean } from '../../helpers/Functions';
import AddContact from './steps/AddContact';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class People extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            buyers: []
            , sellers: []
            , bAgents: []
            , sAgents: []
            , escrow: []
            , lenders: []
            , others: [],
            selectedPerson: {},
            personModal: false,
            roleOptions: [],
            sending: false,
            hasError: false,
            addContactModal: false,
            removingPerson: null,
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdatePerson = this.handleUpdatePerson.bind(this);
    }

    componentDidMount(){
        let id = this.props.transactionId;
        if (id){
            this.fetchPeople(id)
        }
        this.fetchRoleOptions();
    }

    fetchPeople(id){
        getData(`api/transaction/people/${id}`).then(response => this.categorizePeople(response.data));
    }

    fetchRoleOptions(){
        getData('api/transaction/people/roles').then(response => this.setState({ roleOptions: response.data }));
    }

    categorizePeople(people){
        let buyers = []
        , sellers = []
        , bAgents = []
        , sAgents = []
        , escrow = []
        , lenders = []
        , others = [];

        people.forEach(person => {
            const id = person.PeopleRoleId;
            if (id === 5){
                buyers.push(person);
            } else if (id === 6){
                sellers.push(person);
            } else if (id === 1 || id === 2){
                bAgents.push(person);
            } else if (id === 3 || id === 4){
                sAgents.push(person);
            } else if (id === 9 || id === 10){
                escrow.push(person);
            } else if (id === 12 || id === 13){
                lenders.push(person);
            } else {
                others.push(person);
            }
        });

        this.setState({ buyers, sellers, bAgents, sAgents, escrow, lenders, others });
    }

    toggleModal(isClose){
        if (isClose){
            this.setState({ selectedPerson: {}, personModal: false, sending: false, hasError: false });
        } else {
            this.setState({ personModal: !this.state.personModal, sending: false, hasError: false });
        }
    }

    toggleAddNew() {
        this.setState({ addContactModal: !this.state.addContactModal });
    }

    handleChange(e, isPhone){
        let { name, value } = e.target;
        if (isPhone){
            value = phoneClean(value);
        }
        this.setState({ selectedPerson: { ...this.state.selectedPerson, [name]: value }});
    }

    handlePersonClick(person){
        this.setState({ selectedPerson: person }, this.toggleModal);
    }

    
    handleUpdatePerson(){
        let person = this.state.selectedPerson;
        const isContact = person.PeopleRoleId === '5' || person.PeopleRoleId === 6;
        if (!person.FullName || !person.Email || (!person.CellPhone && isContact)) {
            this.setState({ hasError: true });
            return;
        }

        this.setState({ sending: true });
        const transId = this.props.transactionId;
        postData(`api/transaction/${transId}/person`, person).then(response => {
            this.fetchPeople(transId);
            this.toggleModal(true);
        });
    }

    handleRemoveClick(e, peopleId, roleId) {
        e.stopPropagation();
        const { transactionId } = this.props;
        this.setState({ removingPerson: `${peopleId}-${roleId}` });
        deleteData(`api/transaction/${transactionId}/people-link/${peopleId}/${roleId}`).then(() => {
            this.setState({ removingPerson: null });
            this.fetchPeople(transactionId);
        }).fail((e) => {
            console.log(e);
            this.setState({ removingPerson: null });
        });
    }

    renderPeople(people){
        return people.map(person => (
            <div key={person.TransactionPeopleId} className="col-md-6 m-b-2">
                <div className="p-10 bg-white p-t-20 p-b-20 cursor-pointer" onClick={() => this.handlePersonClick(person)}>
                    <div className="media media-xs overflow-visible">
                            {/* <img src="/assets/img/user/user-13.jpg" alt="" className="media-object img-circle" /> */}
                            <div className="image image-icon f-s-20 bg-black text-grey-darker text-center m-r-10" style={{width: '30px', borderRadius: '30px'}}>
                                <i className="fa fa-user"></i>
                            </div>
                        <div className="media-body valign-middle">
                            <b className="text-inverse">{person.FullName}</b> {person.ContactId && <Link to={`/contact/${person.ContactId}`}><small>View Profile</small></Link>}  <br />
                            <span>{person.Email}</span>
                        </div>
                        <div className="media-body valign-middle text-right overflow-visible">
                            <b className="text-inverse">{person.RoleName}</b><br />
                            <span className="text-danger" onClick={(e) => this.handleRemoveClick(e, person.TransactionPeopleId, person.PeopleRoleId)}>
                                {this.state.removingPerson === `${person.TransactionPeopleId}-${person.PeopleRoleId}`  ? 
                                    <i className="fas fa-spinner fa-pulse text-primary"></i>
                                    :
                                    "Remove"    
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    render(){
        const { personModal, selectedPerson, roleOptions, hasError, sending, addContactModal } = this.state;
        return(
            <div>
                <div className="row" style={{ marginBottom: '-10px' }}>
                    <div className="col-md-12">
                        <button className="btn btn-lime pull-right p-l-20 p-r-20" onClick={() => this.toggleAddNew()}>Add Person</button>
                    </div>
                </div>

                { this.state.buyers.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">BUYERS</h5>
                    <div className="row">
                        {this.renderPeople(this.state.buyers)}
                    </div>
                </div>}

                { this.state.sellers.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">SELLERS</h5>
                    <div className="row">
                        {this.renderPeople(this.state.sellers)}
                    </div>
                </div>}

                { this.state.bAgents.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">BUYER'S AGENTS</h5>
                    <div className="row">
                        {this.renderPeople(this.state.bAgents)}
                    </div>
                </div>}

                { this.state.sAgents.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">SELLER'S AGENTS</h5>
                    <div className="row">
                        {this.renderPeople(this.state.sAgents)}
                    </div>
                </div>}

                { this.state.lenders.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">LENDERS</h5>
                    <div className="row">
                        {this.renderPeople(this.state.lenders)}
                    </div>
                </div>}

                { this.state.escrow.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">ESCROW / TITLE</h5>
                    <div className="row">
                        {this.renderPeople(this.state.escrow)}
                    </div>
                </div>}

                { this.state.others.length > 0 && <div className="m-b-30">
                    <h5 className="m-t-10 text-theme with-border-bottom p-b-10 m-b-15">OTHER</h5>
                    <div className="row">
                        {this.renderPeople(this.state.others)}
                    </div>
                </div>}

                <PersonModal 
                    isOpen={personModal} 
                    handleChange={this.handleChange} 
                    toggleModal={this.toggleModal} 
                    person={selectedPerson} 
                    roleOptions={roleOptions}
                    handleSavePerson={this.handleUpdatePerson}
                    hasError={hasError}
                    sending={sending}
                />

                <Modal isOpen={addContactModal}>
                    <ModalHeader toggle={() => this.toggleAddNew()}>Add Person</ModalHeader>
                    <ModalBody>
                        <AddContact 
                            fetchPeople={this.fetchPeople.bind(this)}
                            toggleAddNew={this.toggleAddNew.bind(this)}
                            transactionType={this.props.transactionType}
                            selectRole={true}
                            transactionId={this.props.transactionId} 
                            transactionAgentId={this.props.transactionAgentId}
                        />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default People;