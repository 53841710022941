import React from 'react';
import { ListGroup } from 'reactstrap';
import ActivityItem from './ActivityItem';

function ActivityText({ messages }){
    return (
        <div>
            <ListGroup>
                {messages.map((m, i) => (
                    <ActivityItem item={m} key={i} iconCss={"far fa-comment-alt"} textColor="text-orange" />
                ))}
            </ListGroup>
        </div>
    )
}

export default ActivityText;