import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel.jsx';
import Step from '../components/workflows/Step';
import { getData, postData, putData, deleteData } from '../helpers/AxiosService.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import Select from 'react-select';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import EmailTemplateModal from '../components/email/EmailTemplateModal';
import { PageSettings } from '../config/page-settings';
import Conditions from '../components/workflows/Conditions.js';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class EditWorkflow extends React.Component {
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            steps: [],
            name: '',
            createdByName: '',
            shareWithGroup: false,
            pauseOnComm: false,
            status: '',
            secondaryContacts: false,
            loading: true,
            dragging: false,
            deleting: false,
            deletingId: 0,
            publishing: false,
            agentOptions: [],
            emailTemplateOptions: [],
            textTemplateOptions: [],
            selectedEmailTemplate: null,
            selectedTextTemplate: null,
            selectedAgents: [],
            contactCount: null,
            templateModal: false,
            textTemplateModal: false,
            eventDateOptions: [],
            type: '',
            roleOptions: [],
            eventOptions: [],
            updatingEvent: false,
            selectedEventCode: '',
            eventConditions: [],
            selectedConditions: [],
            showEditName: false,
            updatingName: false,
            taskTypeOptions: [],
            tagOptions: [],
            searchOptions: [],
        }
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.deleteStep = this.deleteStep.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.throttledStepUpdate = debounce(this.stepUpdate, 500);
        this.toggleCheck = this.toggleCheck.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.updateEmailTemplates = this.updateEmailTemplates.bind(this);
        this.updateTextTemplates = this.updateTextTemplates.bind(this);
        this.toggleIsCreateTask = this.toggleIsCreateTask.bind(this);
        this.handleConditionChange = this.handleConditionChange.bind(this);
    }

    componentDidMount(){
        this.fetchTagOptions();
        this.fetchSearches();
        let id = this.props.match.params.workflowId;
        if (id){
            this.getWorkflow(id);
        } else {
            this.setState({ loading: false });
        }

        const { type } = this.props;
        this.setState({ type });
        if (this.props.type === 'Transaction') {
            this.getDueDateOptions();
            this.fetchRoleOptions();
        }
    }

    getWorkflow(id){
        getData(`api/workflow?workflowId=${id}`, this).then(response => {
            let data = response.data;
            this.setState({
                steps: data.Steps,
                name: data.Name,
                createdByName: data.CreatedByName,
                shareWithGroup: data.ShareWithGroup,
                pauseOnComm: data.PauseOnCommunication,
                status: data.Status,
                secondaryContacts: data.SecondaryContacts, 
                loading: false,
                deactivating: false,
                sourceOptions: data.SourceOptions,
                agentOptions: data.AgentOptions,
                emailTemplateOptions: data.EmailTemplates,
                textTemplateOptions: data.TextTemplates,
                contactCount: data.ContactsCount,
                selectedAgents: this.formatSelectedAgents(data.SelectedAgents),
                eventOptions: data.EventOptions,
                selectedConditions: this.formatKeyValuePairs(data.SelectedConditions),
                taskTypeOptions: data.TaskTypeOptions,
            }, () => this.handleEventChange(data.SelectedEvent.EventCode, true));

        });
    }

    getDueDateOptions() {
        getData('api/workflow/step/date-options', this).then(response => {
            this.setState({ dueDateOptions: response.data });
        });
    }

    fetchRoleOptions() {
        getData('api/transaction/people/roles').then(response => this.setState({ roleOptions: response.data }));
    }

    fetchTagOptions() {
        getData('api/settings/contact/tags').then(resp => this.setState({ tagOptions: resp.data }));
    }

    formatSelectedAgents(wfAgents) {
        if (!wfAgents) return [];

        let selectedAgents = wfAgents.length === 0 ? [] : wfAgents.map((agent, i) => {
            return ({ value: agent.AgentId, label: agent.Name });
        });

        return selectedAgents;
    }

    async fetchSearches() {
        try {
            const resp = await getData('api/search/user/searches');
            this.setState({ searchOptions: resp.data });
        } catch (e) {
            console.log(e);
        } 
    }

    formatKeyValuePairs(pairs) {
        if (!pairs) return [];

        let options = pairs.length === 0 ? [] : pairs.map(c => { 
            return ({ value: c.Key, label: c.Value });
        });

        return options;
    }

    toggleModal(taskTypeId){
        if (taskTypeId == 4) {
            this.setState({ selectedTextTemplate: null }, () => {
                this.setState({ textTemplateModal: !this.state.textTemplateModal });
            });
        } else {
            this.setState({ selectedEmailTemplate: null }, () => {
                this.setState({ templateModal: !this.state.templateModal });
            });
        }
    }

    handleUpdateName() {
        this.setState({ updatingName: true });
        putData(`api/workflow/name/${this.props.match.params.workflowId}/${this.state.name}`).then(res => {
            this.setState({ updatingName: false, showEditName: false });
        });
    }

    updateEmailTemplates(){
        getData('api/template/user').then(response => {
            this.setState({ emailTemplateOptions: response.data });
        })
    }

    updateTextTemplates(){
        getData('api/template/text-templates').then(response => {
            this.setState({ textTemplateOptions: response.data });
        })
    }

    deleteStep(step){
        this.setState({ deleting: true, deletingId: step.WorkflowStepId });
        postData('api/workflow/step/delete', step).then(response => {
            this.setState({ steps: response.data, deleting: false, deletingId: 0 });
        });
    }

    updateStepOrder(){
        postData('api/workflow/steporder', this.state.steps).then(response => {
            //TO DO what should we do here?
        });
    }

    addStep(index){
        if (!index){
            index = -1;
        }

        const data = { 
            Index: index,
            WorkflowId: this.props.match.params.workflowId  
        };
        
        postData('api/workflow/step/new', data).then(response => {
            this.setState({ steps: response.data });
        });
    }

    async handleEventChange(eventCode, isInitialLoad = false) {
        const { workflowId } = this.props.match.params;
        this.setState({ updatingEvent: true, selectedEventCode: eventCode });
        if (!isInitialLoad) {
            await deleteData(`api/workflow/conditions/${workflowId}`);
        }

        if (!eventCode) {
            // delete event from workflow
            await deleteData(`api/workflow/event/${workflowId}`);
            this.setState({ selectedEventCode: '', updatingEvent: false });
            return;
        }
        
        let data = {
            WorkflowId: workflowId,
            EventCode: eventCode,
        }
        const result = await postData('api/workflow/event', data);
        let resp = await getData(`api/workflow/conditions/${eventCode}`);
        
        let eventConditions = resp.data.map(c => {
            // format options to be used in multi select dropdown
            c.Options = this.formatKeyValuePairs(c.Options);
            return c;
        });

        this.setState({ eventConditions }, () => {
            this.setState({ updatingEvent: false });
        });
    }

    handleChange(e, item, stepIndex){
        let stepsCopy = this.state.steps.slice();
        stepsCopy[stepIndex][item] = e.target.value;

        if (item === 'TaskTypeId'){
            stepsCopy[stepIndex].IsCreateTask = false;
            stepsCopy[stepIndex].TaskTitle = "";
        }

        this.setState({ steps: stepsCopy }, () => this.throttledStepUpdate(stepIndex));
    }

    editEmailTemplate(templateId) {
        let template = this.state.emailTemplateOptions.find(t => t.EmailTemplateId == templateId);
        this.setState({ selectedEmailTemplate: template, templateModal: true });
    }

    editTextTemplate(templateId) {
        let template = this.state.textTemplateOptions.find(t => t.TextTemplateId == templateId);
        this.setState({ selectedTextTemplate: template, textTemplateModal: true });
    }

    toggleIsCreateTask(stepIndex){
        let stepsCopy = this.state.steps.slice();
        stepsCopy[stepIndex].IsCreateTask = !stepsCopy[stepIndex].IsCreateTask;

        this.setState({ steps: stepsCopy }, () => this.throttledStepUpdate(stepIndex));
    }

    stepUpdate(index){
        const step = this.state.steps[index];
        putData('api/workflow/step/update', step).then(response => {
            //TO DO: what should we do here?
        });
    }

    toggleCheck(box){
        this.setState({ [box]: !this.state[box]}, () => this.updateSetting(box));
    }

    updateSetting(setting){
        let path;
        let value;
        switch(setting){
            case 'shareWithGroup':
                path = 'share';
                value = this.state.shareWithGroup;
                break;
            case 'pauseOnComm':
                path = 'pause';
                value = this.state.pauseOnComm;
                break;
            case 'secondaryContacts':
                path = 'secondaryContacts';
                value = this.state.secondaryContacts;
                break;
            default:
                break;
        };

        let data = {
            Setting: value,
            WorkflowId: this.props.match.params.workflowId
        };

        putData(`api/workflow/${path}`, data).then(response => {
        });
    }

    publishWorkflow(){
        // TO DO: validate there is a trigger and agent...
        this.setState({ publishing: true });
        let data = {
            WorkflowId: this.props.match.params.workflowId,
            Status: "Active"
        };

        putData('api/workflow/status', data, this).then(response => {
            this.setState({ publishing: false, status: "Active" });
        }).fail(() => this.setState({ publishing: false }));
    }

    deactivateWorkflow(){
        this.setState({ deactivating: true });
        let data = {
            WorkflowId: this.props.match.params.workflowId,
            Status: "Inactive"
        };

        putData('api/workflow/status', data).then(response => {
            this.setState({ deactivating: false, status: "Inactive" });
        })
    }

    onDragStart(){
        this.setState({ dragging: true });
    }
    
    onDragEnd(result){
        if (!result.destination) return;

        this.draggedIdx = null;
        this.setState({ dragging: false });

        if (result.source.index === result.destination.index) return;
        
        const steps = this.reorder(this.state.steps, result.source.index, result.destination.index);
        
        for (let i = 0; i < steps.length; i++){
            steps[i].StepOrder = i + 1;
        }
        this.setState({ steps }, this.updateStepOrder)
    };

    reorder(list, startIndex, endIndex){
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    handleConditionChange(value, { action, removedValue }) {
        let stateConditions = this.state.selectedConditions.slice();
        
        switch(action){
            case 'select-option':
                var newValues = this.findNewValue(value, stateConditions);
                stateConditions = [];
                value.forEach(condition => stateConditions.push(condition));
                this.addPropToWorkflow('condition', newValues[0]);
                break;
            case 'remove-value':
                stateConditions = stateConditions.filter(condition => condition.value != removedValue.value);
                this.deletePropFromWorkflow('condition', removedValue);
                break;
            case 'clear':
                stateConditions = [];
                break;
            default:
                break;
        }

        this.setState({ selectedConditions: stateConditions });
    }

    handleAgentChange(value, { action, removedValue}){
        let stateAgents = this.state.selectedAgents.slice();

        switch(action){
            case 'select-option':
                var newValues = this.findNewValue(value, stateAgents);
                stateAgents = [];
                value.forEach(agent => stateAgents.push(agent));
                this.addPropToWorkflow('agent', newValues[0]);
                break;
            case 'remove-value':
                stateAgents = stateAgents.filter(agent => agent.value != removedValue.value);
                this.deletePropFromWorkflow('agent', removedValue);
                break;
            case 'clear':
                stateAgents = [];
                break;
            default:
                break;
        }

        this.setState({ selectedAgents: stateAgents });
    }

    findNewValue(arr, arr2){
        if (arr2.length === 0 && arr.length === 1){
            return arr;
        }
        return arr.filter(item => {
            return !arr2.some(property => {
                return item.value === property.value;
            });
        });
    }

    addPropToWorkflow(type, property) {
        let data = {
            WorkflowId: this.props.match.params.workflowId
        }
        let path = '';
        if (type === 'agent') {
            data.AgentId = property.value;
        }
        if (type === 'condition') {
            data.Value = property.value;
            path = `/${this.state.selectedEventCode}`;
        }

        postData(`api/workflow/${type}${path}`, data).then(response => {
        });
    }

    deletePropFromWorkflow(type, property){
        let data = {
            WorkflowId: this.props.match.params.workflowId
        }
        let path = '';
        if (type === 'agent'){
            data.AgentId = property.value
        }
        if (type === 'condition') {
            data.Value = property.value;
            path = `/${this.state.selectedEventCode}`;
        }

        postData(`api/workflow/${type}${path}/delete`, data).then(response => {
            //TO DO??
        });
    }


    render(){
        const { type } = this.props;
        const { contactCount, selectedAgents, name, pauseOnComm, 
            secondaryContacts, steps, dragging, shareWithGroup, deletingId, deleting, 
            status, publishing, deactivating, loading, agentOptions, 
            dueDateOptions, roleOptions, eventOptions, selectedEventCode, updatingEvent,
            eventConditions, selectedConditions, showEditName, updatingName, selectedEmailTemplate, selectedTextTemplate, taskTypeOptions, tagOptions, searchOptions } = this.state;
        const renderedAgentOptions = agentOptions.length === 0 ? [{ value: '', label: ''}] : agentOptions.map((agent, idx) => ({ value: agent.UserId, label: agent.Name }));
        const selectAgentOptions = agentOptions.length === 0 ? null : agentOptions.map((agent, idx) => <option key={idx} value={agent.UserId}>{agent.Name}</option>)
        return(
            <div style={{ marginBottom: '145px' }}>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <Panel>
                    <PanelHeader noButton>
                        {status !== "Active" ? 
                        <button className="btn btn-lime btn-xs pull-right p-l-20 p-r-20" onClick={() => this.publishWorkflow()}>{publishing ? <div className="button-spinner"></div> : "Publish"}</button> 
                        : 
                        <button className="btn btn-danger btn-xs pull-right p-l-20 p-r-20" onClick={() => this.deactivateWorkflow()}>{deactivating ? <div className="button-spinner"></div> : "Deactivate"}</button>
                        }
                        {name ? name : 'New Workflow'}<i className="fas fa-pen text-white m-l-5 cursor-pointer f-s-14" onClick={() => this.setState({ showEditName: true })}></i><span className="label label-primary m-l-10 f-s-10">{status}</span>
                        </PanelHeader>
                    { type !== 'Transaction' && <PanelBody>
                        <div className="row p-b-10" style={{borderBottom: '1px solid #e2e7eb'}}>
                            <div className="col-md-12">
                                <div className="form-group-row pull-right">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="PauseOnComm" checked={pauseOnComm} onChange={() => this.toggleCheck('pauseOnComm')}/>
                                        <label className="form-check-label" htmlFor="PauseOnComm">Pause on communication</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="SendToSecondary" checked={secondaryContacts} onChange={() => this.toggleCheck('secondaryContacts')}/>
                                        <label className="form-check-label" htmlFor="SendToSecondary">Send to secondary contacts</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="ShareWithGroup" checked={shareWithGroup} onChange={() => this.toggleCheck('shareWithGroup')}/>
                                        <label className="form-check-label" htmlFor="ShareWithGroup">Share with group</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-t-15">
                            <div className="col-md-6">
                                {/* <div className="form-group">
                                    <label>Use when contact source is:</label>
                                    <Select value={selectedSources} isMulti closeMenuOnSelect={false} options={renderedSourceOptions} className="p-l-0 p-r-0" onChange={this.handleSourceChange}/>
                                </div> */}
                                <div className="form-group">
                                    <label>Trigger workflow on: <i className={classnames({ hide: !updatingEvent }, "fas fa-spinner fa-pulse text-primary m-l-5")}></i></label>
                                    <select className="form-control" value={selectedEventCode} onChange={e => this.handleEventChange(e.target.value)}>
                                        <option></option>
                                        { eventOptions.map(e => (
                                            <option key={e.EventCode} value={e.EventCode}>{e.ActivityType}</option>
                                        ))}
                                    </select>
                                </div>
                                { !eventConditions.length ? null : <div className="m-b-10">
                                    <Conditions 
                                        eventConditions={eventConditions}
                                        eventCode={selectedEventCode}
                                        handleConditionChange={this.handleConditionChange}
                                        selectedConditions={selectedConditions}
                                    />
                                </div> }
                                <div className="m-b-10"><strong>AND</strong></div>
                                <div className="form-group">
                                    <label>Assigned agent is:</label>
                                    <Select value={selectedAgents} isMulti closeMenuOnSelect={false} options={renderedAgentOptions} className="p-l-0 p-r-0" onChange={this.handleAgentChange}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-6 m-t-30" style={{ margin: 'auto' }}>
                                    <div className="widget widget-stats bg-grey-darker">
                                        <div className="stats-icon"><i className="fa fa-users"></i></div>
                                        <div className="stats-info">
                                            <h4>Contacts Enrolled</h4>
                                            <p>{contactCount}</p>	
                                        </div>
                                        <div className="stats-link">
                                            <Link to="/dashboard/v1">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PanelBody> }
                </Panel>
                <div className="row m-b-10">
                    <div className="col-md-12">
                        <button className="btn btn-lime pull-right p-l-20 p-r-20" onClick={() => this.addStep()}>Add Step</button>
                    </div>
                </div>
                {steps.length == 0 && <div className="widget widget-rounded p-25 text-center"><label>Add steps to get started</label></div>}
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {provided => (
                            <div ref={provided.innerRef}>
                                {steps.length == 0 ? null : steps.map((step, i) => (
                                    <div key={i}>
                                        <Draggable key={step.WorkflowStepId} draggableId={step.WorkflowStepId} index={i}>
                                            {(provided, snapshot) => (
                                                <Step 
                                                    innerRef={provided.innerRef}
                                                    provided={provided}
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps} 
                                                    key={i} idx={i} step={step} 
                                                    onDragOver={this.onDragOver} 
                                                    onDragStart={this.onDragStart} 
                                                    onDragEnd={this.onDragEnd} 
                                                    deleteStep={this.deleteStep}
                                                    handleChange={this.handleChange}
                                                    deleting={deleting}
                                                    deletingId={deletingId}
                                                    toggleCheck={this.toggleCheck}
                                                    emailTemplateOptions={this.state.emailTemplateOptions}
                                                    textTemplateOptions={this.state.textTemplateOptions}
                                                    toggleModal={this.toggleModal}
                                                    agentOptions={selectAgentOptions}
                                                    toggleIsCreateTask={this.toggleIsCreateTask}
                                                    workflowType={type}
                                                    dueDateOptions={dueDateOptions}
                                                    roleOptions={roleOptions}
                                                    editEmailTemplate={this.editEmailTemplate.bind(this)}
                                                    editTextTemplate={this.editTextTemplate.bind(this)}
                                                    taskTypeOptions={taskTypeOptions}
                                                    tagOptions={tagOptions}
                                                    searchOptions={searchOptions}
                                                    />
                                            )}
                                        </Draggable>
                                        <div className={classnames({ "invisible": dragging }, "text-primary m-b-10 m-l-10")}><span className="cursor-pointer" onClick={() => this.addStep(i + 1)}><i className="fas fa-caret-left"></i> Insert Step Here <i className="fas fa-caret-right"></i></span></div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <EmailTemplateModal 
                    isOpen={this.state.templateModal}
                    toggleModal={this.toggleModal}
                    fetchTemplates={this.updateEmailTemplates}
                    selectedTemplate={selectedEmailTemplate}
                    isText={false}
                />

                <EmailTemplateModal 
                    isOpen={this.state.textTemplateModal}
                    toggleModal={this.toggleModal.bind(this, 4)}
                    fetchTemplates={this.updateTextTemplates}
                    selectedTemplate={selectedTextTemplate}
                    isText={true}
                />

                <Modal isOpen={showEditName} >
                    <ModalHeader toggle={() => this.setState({ showEditName: false })}>Edit Workflow Name</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Workflow Name</label>
                                <input 
                                    className="form-control w-100" 
                                    name="name" 
                                    value={name} 
                                    onChange={(e) => this.setState({ name: e.target.value })} 
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary-outline" onClick={() => this.setState({ showEditName: false })}>Cancel</button>
                        <button className="btn btn-primary" onClick={() => this.handleUpdateName()}>
                            { updatingName ?
                                <div className="button-spinner"></div>
                                :
                                "Update"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default EditWorkflow;