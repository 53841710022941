import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardFooter, Card, CardTitle, CardText, Alert, Modal, ModalHeader,
ModalBody } from 'reactstrap';
import { postData, getData } from '../helpers/AxiosService';
import FileUpload from '../components/file-upload/FileUpload';
import { handleFile } from '../helpers/FileUpload';
import { PageSettings } from '../config/page-settings';


class BulkUpload extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            activePill: '1',
            uploadFile: {},
            agent: 0,
            lender: 0,
            source: 0,
            contactType: 0,
            noWorkflows: false,
            agentOptions: [],
            lenderOptions: [],
            sourceOptions: [],
            contactTypeOptions: [],
            sending: false,
            rejectString: '',
            showSuccess: false,
        }
        this.handleFile = this.handleFile.bind(this);
    }

    componentDidMount(){
        this.fetchOptions();
    }

    fetchOptions(){
        getData('api/contact/bulkupload').then(response => {
            const {AgentOptions, LenderOptions, SourceOptions, ContactTypeOptions } = response.data;
            const lenderOptions = LenderOptions.length === 0 ? null : LenderOptions.map((lender, i) => <option key={i} value={lender.Key}>{lender.Value}</option>);
            const agentOptions = AgentOptions.length === 0 ? null : AgentOptions.map((agent, i) => <option key={i} value={agent.UserId}>{agent.Name}</option>);
            const sourceOptions = SourceOptions.length === 0 ? null : SourceOptions.map((source, i) => <option key={i} value={source.SourceId}>{source.Name}</option>);
            const contactTypeOptions = ContactTypeOptions.length === 0 ? null : ContactTypeOptions.map((type, i) => <option key={i} value={type.ContactTypeId}>{type.ContactType}</option>);
            this.setState({ agentOptions, lenderOptions, sourceOptions, contactTypeOptions });
        });
    }

    togglePill(pill) {
		if (this.state.activePill !== pill) {
			this.setState({
				activePill: pill
			});
		}
    }

    handleFile(event){
        handleFile(event, this, ['xlsx', 'csv']);
    }

    clearFile() {
        this.setState({ uploadFile: {} });
    }

    setFile(file){
        this.setState({ uploadFile: file });
    }

    sendFile(event){
        event.preventDefault();

        postData('api/contact/bulkupload', this.state.uploadFile).then(response => console.log(response));
    }

    handleSubmit(){
        if (!this.state.uploadFile.FileString){
            this.context.addNotification("", "Please upload a file to continue", "warning", "top", "top-right");
            return;
        }

        this.setState({ sending: true });
        let data = {
            AssignedAgentId: this.state.agent,
            AssignedLenderId: this.state.lender,
            SourceId: this.state.source,
            EnrollInWorkFlows: !this.state.noWorkflows,
            ContactTypeId: this.state.contactType,
            File: this.state.uploadFile
        };

        postData('api/contact/bulkupload', data).then(response => {
            const { data } = response;
            this.setState({ sending: false, rejectString: response.data.RejectString });

            if (data.Successful) {
                if (data.RejectString && data.RejectString.length > 0) {
                    this.setState({ showSuccess: true });
                } else {
                    this.context.addNotification("Success!", "Contacts uploaded successfully", "success", "top", "top-right");
                    this.props.history.push("/contacts");
                }
            } else {
                const error = data.Errors[0].Message;
                this.context.addNotification("Error", error, "warning", "top", "top-right");
            }
        }).fail(error => this.setState({ sending: false }));
    }

    handleChange(item, e){
        let val = e.target.value;
        this.setState({ [item]: val });
    }

    toggleNoWorkflows(){
        this.setState({ noWorkflows: !this.state.noWorkflows });
    }

    render(){
        const { loading, rejectString, showSuccess, lenderOptions, agentOptions, sourceOptions, contactTypeOptions } = this.state;
        // const agentOptions = this.state.agentOptions.length === 0 ? null : this.state.agentOptions.map((agent, i) => <option key={i} value={agent.UserId}>{agent.Name}</option>);
        // const sourceOptions = this.state.sourceOptions.length === 0 ? null : this.state.sourceOptions.map((source, i) => <option key={i} value={source.SourceId}>{source.Name}</option>);
        // const contactTypeOptions = this.state.contactTypeOptions.length === 0 ? null : this.state.contactTypeOptions.map((type, i) => <option key={i} value={type.ContactTypeId}>{type.ContactType}</option>);
        return(
            <div>
            <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item active">Contact Import</li>
				</ol>
                <h1 className="page-header">Contact Import <small className="f-w-100">Upload your contacts all at once</small></h1>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '1' })}
                        >
                            <span className="d-sm-none">Upload</span>
                            <span className="d-sm-block d-none">Upload</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '2' })}
                        >
                            <span className="d-sm-none">Details And Submit</span>
                            <span className="d-sm-block d-none">Details And Submit</span>
                        </NavLink>
                    </NavItem>
                    
                </Nav>
                <TabContent className="m-b-0" activeTab={this.state.activePill}>
                    <TabPane tabId="1">
                        <div className="row">
                            <div className="col-md-6">
                                <CardTitle tag="h4" className="m-t-0">Upload Contacts</CardTitle>
                                <CardText>
                                    The uploaded file may be either a .csv file or an .xlsx file. <br />
                                    The first row of the file should be the column headers.
                                    The following column headers are required and must be present:
                                    <ul>
                                        <li>FirstName</li>
                                        <li>LastName</li>
                                        <li>Email</li>
                                        <li>Cell Phone</li>
                                    </ul> 
                                    <Alert color="info" className="m-b-10">
                                        <h5>Available Fields</h5>
                                        <hr />
                                        <p>FirstName*, LastName*, Email*, CellPhone*, HomePhone, WorkPhone, Address, City, StateCode, ZipCode, Profession, Income, DateOfBirth,
                                            PurchaseAnniversary, SaleAnniversary
                                        </p>
                                        <p>* Denotes a required field</p>
                                    </Alert>
                                    You may also download a sample file to see all the fields available for an upload. <br />
                                    {/* <button className="btn btn-lime m-t-15" onClick={() => this.handleDownloadDemo()} style={{ margin: 'auto' }}>Download Sample</button> */}
                                    <a 
                                        className="btn btn-lime m-t-15"
                                        download="Sample-Upload.csv"
                                        href={'data:,FirstName, LastName, Email, CellPhone, HomePhone, WorkPhone, Address, City, StateCode, ZipCode, Profession, Income, DateOfBirth, PurchaseAnniversary, SaleAnniversary\nRoss, Geller, ross@testemail.com, 2129798899, 2125661677, , 123 Broadway Ave, New York, NY, 90210, Paleontologist, 78000, 11/01/1976, 05/06/2016, ,'}
                                    >Download Sample</a>
                                </CardText>
                            </div>
                            <div className="col-md-6">
                                <CardTitle tag="h4" className="m-t-0">Select a File</CardTitle>
                                <div style={{ width: '140px', margin: 'auto' }}>
                                    <FileUpload 
                                        handleFile={this.handleFile}  
                                        clearFile={this.clearFile.bind(this)}
                                        customSubmitHandling={true}
                                        FileName={this.state.uploadFile.FileName}/>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="row">
                            <Card className="col-md-6">
                                <CardTitle tag="h4" className="m-t-0">Contact Details</CardTitle>
                                <CardText>Set the details that will be assigned to this batch of contact uploads. 
                                    These settings can be changed later, but only at the individual contact level.<br />
                                    You may also leave this blank.<br />
                                    Assigning a source will also enroll them in any workflows you have assigned to that source.
                                    Check Do Not Enroll In Workflows if you do not wish for these contacts to be enrolled.
                                    </CardText>
                                <div className="form-group">
                                    <label>Assigned Agent</label>
                                    <select className="form-control" value={this.state.agent} onChange={e => this.handleChange('agent', e)}>
                                        <option></option>
                                        {agentOptions}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Assigned Lender</label>
                                    <select className="form-control" value={this.state.lender} onChange={e => this.handleChange('lender', e)}>
                                        <option></option>
                                        {lenderOptions}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Source of Contacts</label>
                                    <select className="form-control" value={this.state.source} onChange={e => this.handleChange('source', e)}>
                                        <option></option>
                                        {sourceOptions}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Type of Contacts</label>
                                    <select className="form-control" value={this.state.contactType} onChange={e => this.handleChange('contactType', e)}>
                                        <option></option>
                                        {contactTypeOptions}
                                    </select>
                                </div>
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" id="enrollWorkflow" checked={this.state.noWorkflows} onChange={() => this.toggleNoWorkflows()} />
                                    <label htmlFor="enrollWorkflow">Do Not Enroll In Workflows</label>
                                </div>
                            </Card>
                            <Modal isOpen={showSuccess}>
                                <ModalHeader toggle={() => this.setState({ showSuccess: false })}>Success</ModalHeader>
                                <ModalBody className="text-center">
                                    <h5>Your contacts were uploaded successfully, however some were not able to be uploaded. To view the rejected contacts, onClick
                                    the button below.</h5>
                                    <a className="btn btn-primary m-r-5" href={'data:text/csv;charset=utf-8,' + rejectString} download="RejectedContacts.csv">View Rejects</a>
                                    <Link className="btn btn-lime m-l-5" to="/contacts">Go To Contacts</Link>
                                </ModalBody>
                            </Modal>
                        </div>
                    </TabPane>
                </TabContent>
                <CardFooter className="bg-white">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.activePill === '2' ? 
                            <button className="btn btn-white pull-right" onClick={() => this.handleSubmit()}>
                                {this.state.sending ? <div className="button-spinner"></div> : "Submit"}
                            </button>
                            :
                            <button className="btn btn-white pull-right" disabled={!this.state.uploadFile.FileString} onClick={() => this.togglePill('2')}>Next</button>
                            }
                            <button className={classnames({ 'hide': this.state.activePill === '1' }, "btn btn-white pull-left")} onClick={() => this.togglePill('1')}>Back</button>
                        </div>
                    </div>
                </CardFooter>
            </div>
        )
    }
}

export default BulkUpload;