import React from 'react';
import { Link } from 'react-router-dom';
import background from '../../assets/banner-bg1.jpg';
import house from '../../assets/real_estate_isometric_illustration-06.jpg';

class UnauthHome extends React.Component{

    render(){
        return(
            <div style={{ background: `url(${background})`, height: '100vh', width: '100vw' }}>
                <div className="login">
					<div className="login-header text-center">
						<div className="brand">
							<b>Real Connect</b> One 
						</div>
						{/* <div className="icon">
							<i className="fa fa-lock"></i>
						</div> */}
					</div>
					<div className="login-content text-center p-t-0 p-b-0">
						<Link to="/login" className="btn btn-lime-outline width-150 m-r-5 p-t-10 p-b-10">Login</Link>
						<Link to="/register" className="btn btn-lime width-150 m-l-5 p-t-10 p-b-10">Register</Link>
					</div>
                </div>
                <div style={{ width: '50vw', height: '25vh', background: `url(${house})`}}></div>
            </div>
        )
    }
}

export default UnauthHome;