import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelBody } from '../../panel/panel.jsx';
import { CardTitle } from 'reactstrap';
import { getData } from '../../../helpers/AxiosService.js';
import { PageSettings } from '../../../config/page-settings.js';
import { displayDateTimeUtc } from '../../../helpers/Functions';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddAppointment from '../../appointments/AddAppointment.js';
import { Link } from 'react-router-dom';

export default function AppointmentReport(props) {
    const cxt = useContext(PageSettings);
    const [appointments, setAppointments] = useState([]);
    const [filteredApts, setFilteredApts] = useState([]);
    const [listingApts, setListingApts] = useState([]);
    const [buyerApts, setBuyerApts] = useState([]);
    const [showingApts, setShowingApts] = useState([]);
    const [listingResult, setListingResult] = useState([]);
    const [buyerResult, setBuyerResult] = useState([]);
    const [noShow, setNoShow] = useState([]);
    const [showNoResult, setShowNoResult] = useState([]);
    const [showingResult, setShowingResult] = useState([]);
    const [shownApts, setShownApts] = useState([]);
    const [stats, setStats] = useState({
        listConvRatio: 0,
        buyConvRation: 0,
        showRatio: 0,
        showingConvRatio: 0,
    });
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeApt, setActiveApt] = useState({});

    const { startDate, endDate, userId } = props;

    async function fetchAppointments() {
        if (userId === null) return;

        setLoading(true);
        let appointmentOwner = 'user';
        let ownerId = props.userId;
        if (userId === 0) {
            appointmentOwner = 'tenant';
            ownerId = cxt.userInfo.TenantId;
        }  

        try {
            const resp = await getData(`api/appointments/${appointmentOwner}/${ownerId}?startDate=${startDate}&endDate=${endDate}`);
            setAppointments(resp.data);
            setFilteredApts(resp.data);
            sortAppointments(resp.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAppointments();
    }, []);

    useEffect(() => {
        fetchAppointments();
    }, [props.userId, props.startDate, props.endDate])

    function toggleModal(apt) {
        setActiveApt(apt);
        setModalOpen(!modalOpen);
    }

    function sortAppointments(apts) {
        if (!apts) return;

        const listingApts = apts.filter(a => a.AppointmentTypeId === 1002);
        const buyerApts = apts.filter(a => a.AppointmentTypeId === 1001);
        const showingApts = apts.filter(a => a.AppointmentTypeId === 1003);
        const shownApts = apts.filter(a => a.AppointmentResultId && a.AppointmentResultId !== 1001);

        setListingApts(listingApts);
        setBuyerApts(buyerApts);
        setShowingApts(showingApts);
        setShownApts(shownApts);

        const listingResult = apts.filter(a => a.AppointmentResultId === 1004);
        const buyerResult = apts.filter(a => a.AppointmentResultId === 1003);
        const noShow = apts.filter(a => a.AppointmentResultId === 1001);
        const showNoResult = apts.filter(a => a.AppointmentResultId === 1002);
        const showingResult = showingApts.filter(a => a.AppointmentResultId === 1003);

        setListingResult(listingResult);
        setBuyerResult(buyerResult);
        setShowingResult(showingResult);

        setNoShow(noShow);
        setShowNoResult(showNoResult);
    }

    useEffect(() => {
        calculateStats();
    }, [listingResult, buyerResult, noShow, showNoResult, appointments, shownApts, showingResult])

    function calculateStats() {
        const stats = {
            listConvRatio: Math.floor(listingResult.length / listingApts.length  * 100) || 0,
            buyConvRation: Math.floor(buyerResult.length / buyerApts.length  * 100) || 0,
            showRatio: Math.floor(shownApts.length / appointments.length  * 100) || 0,
            showingConvRatio: Math.floor(showingResult.length / showingApts.length  * 100) || 0,
        }

        setStats(stats);
    }

    return (
        <div className="p-t-20">
            <CardTitle tag="h4" className="m-b-20">
                Appointments
                { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i>}
            </CardTitle>
            <div className="row m-b-20">
                <div className="col-6 col-md-3">
                    <div className="widget widget-stats inverse-mode m-b-10 cursor-pointer" onClick={() => setFilteredApts(appointments)}>
                        <div className="stats-content">
                            <div className="stats-title text-white m-b-10"><b>APPOINTMENTS</b></div>
                            <div className="row">
                                <div className="col-6">
                                    <div  className="text-grey">Appts</div>
                                    <div className="stats-number">{appointments.length}</div>
                                </div>
                                <div className="col-6">
                                    <div  className="text-grey">Shown</div>
                                    <div className="stats-number">{shownApts.length}</div>
                                </div>
                            </div>
                            <hr className="bg-rco-blue"/>
                            <div className="text-grey">{stats.showRatio}% show rate</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="widget widget-stats inverse-mode m-b-10 cursor-pointer" onClick={() => setFilteredApts(listingApts)}>
                        <div className="stats-content">
                            <div className="stats-title text-white m-b-10"><b>LISTINGS</b></div>
                            <div className="row">
                                <div className="col-6">
                                    <div  className="text-grey">Listing Apts</div>
                                    <div className="stats-number">{listingApts.length}</div>
                                </div>
                                <div className="col-6">
                                    <div  className="text-grey">Agreements</div>
                                    <div className="stats-number">{listingResult.length}</div>
                                </div>
                            </div>
                            <hr className="bg-rco-blue"/>
                            <div className="text-grey">{stats.listConvRatio}% conversion rate</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="widget widget-stats inverse-mode m-b-10 cursor-pointer" onClick={() => setFilteredApts(buyerApts)}>
                        <div className="stats-content">
                            <div className="stats-title text-white m-b-10"><b>BUYER CONSULTATIONS</b></div>
                            <div className="row">
                                <div className="col-6">
                                    <div  className="text-grey">Buyer Apts</div>
                                    <div className="stats-number">{buyerApts.length}</div>
                                </div>
                                <div className="col-6">
                                    <div  className="text-grey">Agreements</div>
                                    <div className="stats-number">{buyerResult.length}</div>
                                </div>
                            </div>
                            <hr className="bg-rco-blue"/>
                            <div className="text-grey">{stats.buyConvRation}% conversion rate</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="widget widget-stats inverse-mode m-b-10 cursor-pointer" onClick={() => setFilteredApts(showingApts)}>
                        <div className="stats-content">
                            <div className="stats-title text-white m-b-10"><b>INITIAL SHOWINGS</b></div>
                            <div className="row">
                                <div className="col-6">
                                    <div  className="text-grey">Showings</div>
                                    <div className="stats-number">{showingApts.length}</div>
                                </div>
                                <div className="col-6">
                                    <div  className="text-grey">Agreements</div>
                                    <div className="stats-number">{showingResult.length}</div>
                                </div>
                            </div>
                            <hr className="bg-rco-blue"/>
                            <div className="text-grey">{stats.showingConvRatio}% conversion rate</div>
                        </div>
                    </div>
                </div>
            </div>
            <table className="table table-bordered bg-white">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Agent</th>
                        <th>Created By</th>
                        <th>Contact</th>
                        <th>Type</th>
                        <th>Result</th>
                        <th>Date</th>
                        <th>Source</th>
                    </tr>
                </thead>
                <tbody>
                    { filteredApts.map((apt) => (
                        <tr key={apt.Id}>
                            <td className="text-primary cursor-pointer" onClick={() => toggleModal(apt)}>{apt.Title}</td>
                            <td>{apt.UserName}</td>
                            <td>{apt.CreatedByName}</td>
                            <td><Link to={`/contact/${apt.ContactId}`} className="text-primary">{apt.ContactName}</Link></td>
                            <td>{apt.AppointmentType}</td>
                            <td>{apt.AppointmentResult}</td>
                            <td>{displayDateTimeUtc(apt.DateTime)}</td>
                            <td>{apt.SourceName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal isOpen={modalOpen}>
                <ModalHeader toggle={() => toggleModal({})}>Appointment</ModalHeader>
                <ModalBody>
                    <AddAppointment
                        close={() => toggleModal({})}
                        appointmentId={activeApt.Id}
                        contactId={activeApt.ContactId}
                        updateCallback={fetchAppointments}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

AppointmentReport.defaultProps = {

}

AppointmentReport.propTypes = {
}
