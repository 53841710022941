import axios from 'axios';
import qs from 'qs';
import config from '../config';
const { baseClientUrl, baseUrl } = config;

// const baseClientUrl = "http://localhost:3000";

// const baseUrl = 'http://localhost:5001/';

export default class Auth {
    login(username, password, history){
        let loginBody = qs.stringify({ "grant_type": "password", "username": username, "password": password });

        // let config = {
        //     headers: {
        //         'Content-Type': 'application/x-www-urlencoded'
        //     }
        // }

        const loginPromise = axios.post(`${baseUrl}token`, loginBody).then(response => {
            this.setSession(response.data);
            history.push("/")
        })
        // .catch(response => {
        //     console.log(response.response);
        //     return 
        // }); 

        return Promise.resolve(loginPromise);
    }

    refreshToken(){
        let data = {
            token: this.getToken(),
            refreshtoken: this.getRefreshToken()
        };
        return axios.post(`${baseUrl}api/token/refresh`, data).then(response => {
            if (response.data.successful){
                this.setSession(response.data.data)
                return true;
            } else {
                return false;
            }
        })
    }

    setSession(authResult){
        localStorage.setItem('access_token', authResult.access_token);
        localStorage.setItem('expires_in', authResult.expires_in);
        localStorage.setItem('.expires', authResult[".expires"]);
    }

    isAuthenticated(){
        let expiresAt = new Date(localStorage.getItem('.expires')).getTime();
        if (new Date().getTime() < expiresAt){
            return true;
        } else {
            return false;
        }
    }

    logout(){
        axios.post(`${baseUrl}api/account/logout`).then(response => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('ref_token');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('.expires');
            
            window.location = `${baseClientUrl}/login`;
        })
    }

    getToken(){
        return localStorage.getItem('access_token');
    }

    getRefreshToken(){
        return localStorage.getItem('ref_token');
    }
}