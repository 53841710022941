import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactOptions from '../shared/ContactOptions';
import { getData, postData, putData } from '../../helpers/AxiosService';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import AgentOptions from '../shared/AgentOptions';
import DateTime from 'react-datetime';
import moment from 'moment';
import { PageSettings } from '../../config/page-settings';

export default function OpportunityModal(props) {
    const cxt = useContext(PageSettings);
    const [opportunity, setOpportunity] = useState({ UserId: cxt.userInfo.UserId });
    const [sending, setSending] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [stages, setStages] = useState([]);

    async function getStageOptions() {
        try {
            var resp = await getData('api/stages');
            setStages(resp.data);
        } catch (e) {
            console.log(e);
        }
    } 

    useEffect(() => {
        getStageOptions();
    }, []);

    useEffect(() => {
        if (Object.keys(props.opportunity).length) {
            setOpportunity(props.opportunity);
        }
    }, [props.opportunity]);

    function handleChange(e) {
        const { name, value } = e.target;
        setOpportunity({ ...opportunity, [name]: value });
    }

    function setContactId(contactId) {
        setOpportunity({ ...opportunity, ContactId: contactId });
    }

    function closeModal() {
        setHasError(false);
        setOpportunity({ UserId: cxt.userInfo.UserId });
        props.toggleModal();
    }

    async function handleSaveOpp() {
        setHasError(false);

        //validate
        if (!opportunity.UserId
            || !opportunity.ContactId
            || !opportunity.Type
            || !opportunity.Stage
        ) {
            setHasError(true);
            return;
        } 

        const data = { ...opportunity }

        if (data.SalesPrice && typeof data.SalesPrice === 'string') {
            data.SalesPrice = opportunity.SalesPrice.replace("$", "").replace(/,/g, "");
        }
        if (data.GCI && typeof data.GCI === 'string') {
            data.GCI = opportunity.GCI.replace("$", "").replace(/,/g, "");
        }

        try {
            setSending(true);
            if (opportunity.Id) {
                //update
                putData('api/opportunities', data);
            } else {
                //create
                await postData('api/opportunities', data);
            }
            props.updateList();
            closeModal();
        } catch (e) {
            console.log(e);
        }

        setSending(false);
    }

    const projCloseDate = opportunity.ProjectedCloseDate ? moment(opportunity.ProjectedCloseDate).format('MM/DD/YYYY') : '';
    
    return (
        <Modal isOpen={props.isOpen} >
            <ModalHeader toggle={() => closeModal()}>Create Opportunity</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className={classnames({ 'has-error': hasError && !opportunity.Name }, "form-group col-12")}>
                        <label>Opportunity Name</label>
                        <input 
                            className="form-control w-100" 
                            name="Name" 
                            value={opportunity.Name} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className={classnames({ "has-error": hasError && !opportunity.Type }, "form-group col-12 m-b-15")}>
                        <label className="form-label">Type</label>
                        <select 
                            className="form-control" 
                            name="Type"
                            value={opportunity.Type} 
                            onChange={handleChange}
                        >
                            <option value="">Select...</option>
                            <option value="Purchase">Purchase</option>
                            <option value="Listing">Listing</option>
                        </select>
                    </div>
                    <div className={classnames({ "has-error": hasError && !opportunity.Stage }, "form-group col-12 m-b-15")}>
                        <label className="form-label">Pipeline Stage</label>
                        <select 
                            className="form-control" 
                            name="Stage"
                            value={opportunity.Stage} 
                            onChange={handleChange}
                        >
                            <option value="">Select...</option>
                            { stages.map(s => <option key={s.Id} value={s.Name}>{s.Name}</option>)}
                        </select>
                    </div>
                    <div className={classnames({ 'has-error': hasError && !opportunity.ContactId }, "form-group col-12 m-b-15")}>
                        <label className="form-label">Contact</label>
                        <ContactOptions
                            contactId={opportunity.ContactId}
                            setContactId={setContactId}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label>Projected Close Date</label>
                        <DateTime 
                            inputProps={{ className:"form-control" }} 
                            closeOnSelect={true} 
                            timeFormat={false}  
                            value={projCloseDate} 
                            onChange={(date) => handleChange({ target: { name: 'ProjectedCloseDate', value: date } })}
                        />
                    </div>
                    <div className="form-group col-6">
                        <label>Sales Price</label>
                        <NumberFormat 
                            prefix={'$'} 
                            thousandSeparator={true} 
                            name="SalesPrice"
                            className="form-control" 
                            value={opportunity.SalesPrice} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className="form-group col-6">
                        <label>Potential GCI</label>
                        <NumberFormat 
                            prefix={'$'} 
                            thousandSeparator={true} 
                            name="GCI"
                            className="form-control" 
                            value={opportunity.GCI} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className={classnames({ "has-error": hasError && !opportunity.UserId }, "form-group col-12 m-b-15")}>
                        <label className="form-label">Agent</label>
                        <AgentOptions
                            all={false}
                            agentId={opportunity.UserId}
                            handleSelect={handleChange}
                            selectName="UserId"
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary-outline" onClick={() => closeModal()}>Cancel</button>
                <button className="btn btn-primary" onClick={() => handleSaveOpp()}>
                    { sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}

OpportunityModal.defaultProps = {
    updateList: () => {},
}

OpportunityModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}