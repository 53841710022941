import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { Link } from 'react-router-dom';
import { Panel, PanelBody } from '../components/panel/panel';
import { PageSettings } from '../config/page-settings';
import { getData } from '../helpers/AxiosService';
import moment from 'moment';

class Calendar extends React.Component {
    static contextType = PageSettings
	constructor(props) {
		super(props);
		this.state = {
			events: [],
			headerToolbar: {
				left: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				right: 'prev,next today'
			},
			buttonText: {
				today:    'Today',
				month:    'Month',
				week:     'Week',
				day:      'Day'
			}
		}
	}

    componentDidMount() {
        this.fetchAppointments();
    }

    async fetchAppointments() {
        try {
            const resp = await getData(`api/appointments/user/${this.context.userInfo.UserId}`);
            const formattedApts = resp.data.map(apt => {
                return {
                    title: apt.Title,
                    start: moment.utc(apt.DateTime).local().format(),
                    end: moment.utc(apt.DateTime).add(apt.DurationMinutes, 'm').local().format(),
                }
            });
            this.setState({ events: formattedApts });
        } catch (e) {
            console.log(e);
        }
    }
	
	render() {
        console.log(this.state.events);
		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item active">Calendar</li>
				</ol>
				<h1 className="page-header">Appointment Calendar</h1>
				<hr />
				<Panel>
                    <PanelBody>
                        <FullCalendar 
                            plugins={[ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin ]}
                            initialView="dayGridMonth"
                            themeSystem="bootstrap"
                            headerToolbar={this.state.headerToolbar}
                            buttonText={this.state.buttonText}
                            events={this.state.events}
                        />
                    </PanelBody>
                </Panel>
			</div>
		)
	}
}

export default Calendar;