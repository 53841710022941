import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup } from 'reactstrap';
import ActivityItem from './ActivityItem';


export default function ActivityEmail({ toggleModal, templates, handleSelectTemplate, messages }){
    return (
        <div className="m-b-25">
            <button className="btn btn-lime p-r-40 p-l-40" onClick={() => toggleModal('emailBlank')}><i className="fas fa-pencil-alt"></i> New Email</button>
            <UncontrolledButtonDropdown className="m-l-15">
                <DropdownToggle className="btn btn-lime-outline" caret><i className="far fa-envelope"></i> Send a Template</DropdownToggle>
                <DropdownMenu>
                    { templates.length > 0 && templates.map((t, i) => (
                        <DropdownItem key={i} onClick={() => handleSelectTemplate(t)}>{t.Name}</DropdownItem>
                    )) }
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <ListGroup className="m-t-20">
                {messages.map((m, i) => (
                    <ActivityItem item={m} key={i} iconCss={"far fa-envelope"} textColor="text-primary" />
                ))}
            </ListGroup>
        </div>
    )
}