import React from 'react';
import classnames from 'classnames';
import { Panel, PanelBody, PanelFooter } from '../components/panel/panel.jsx';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { getData, postData, deleteData } from '../helpers/AxiosService.js';
import { PageSettings } from '../config/page-settings.js';
import '../components/transactions/Transactions.css';
import AgentOptions from '../components/shared/AgentOptions';

class Workflows extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            totalWorkflows: 0,
            workflowsPerPage: 50,
            activePage: 1,
            workflows: [],
            filteredWorkflows: [],
            title: '',
            copyingId: 0,
            searchTerm: '',
            selectedAgentId: 0,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
    }

    componentDidMount(){
        let type = this.props.type;
        if (!type) {
            type = 'Contact';
        }

        this.setState({ type, title: type }, this.fetchWorkflows);
    }

    fetchWorkflows(){
        getData(`api/workflow/accessibleworkflows/${this.state.type}`).then(response => {
            let data = response.data;
            this.setState({ 
                workflows: data,
                filteredWorkflows: data,
                totalWorkflows: data.length
            });
        });
    }

    handlePageChange(pageNumber){
        this.setState({ activePage: pageNumber }, this.renderRows);
    }

    handlePerPageChange(val){

        this.setState({ workflowsPerPage: val }, this.renderRows);
    }

    handleCopyWorkflow(workflowId) {
        if (!workflowId) return;

        this.setState({ copyingId: workflowId });
        postData(`api/workflow/copy/${workflowId}`).then(resp => {
            this.fetchWorkflows();
            this.setState({copyingId: null });
        });
    }

    handleDeleteClick(workflowId){
        this.context.messageConfirmation('Delete Workflow', 'Are you sure you would like to delete this workflow?', this.handleDelete.bind(this, workflowId), 'Workflow deleted!');
    }

    handleDelete(workflowId){
        this.setState({ loading: true });
        deleteData(`api/workflow/${workflowId}`).then(response => {
            this.fetchWorkflows();
            this.context.toggleSweetAlert('confirm');
            this.context.toggleSweetAlert('success');
        }).fail(error => {
            this.fetchWorkflows();
            this.context.toggleSweetAlert('confirm');
        });
        this.setState({ loading: false });
    }

    handleAgentChange(e) {
        const { value, name } = e.target;
        this.setState({ [name]: value }, () => this.handleFilter());
    }

    handleSearch(e) {
        const { value } = e.target;
        this.setState({ searchTerm: value }, this.handleFilter);
    }

    handleFilter() {
        const { searchTerm, selectedAgentId, workflows } = this.state;
        const agentId = Number(selectedAgentId);
        if (!searchTerm && !(agentId)) {
            this.setState({ filteredWorkflows: workflows.slice() });
            return;
        }

        let filteredWorkflows = workflows.slice();

        if (agentId) {
            filteredWorkflows = filteredWorkflows.filter(wf => wf.CreatedById === agentId);
        }

        if (searchTerm) {
            filteredWorkflows = filteredWorkflows.slice().filter((wf) => {
    
                const term = new RegExp(`${searchTerm.toLowerCase()}*`);
    
                return (
                    term.test(wf.Name.toLowerCase())
                )
            })
        }

        this.setState({ filteredWorkflows });
    }

    renderRows(){
        let page = this.state.activePage;
        let count = this.state.workflowsPerPage;
        let start = page === 1 ? 0 : page * count - count;
        let end = start + count;
        
        let workflows = this.state.filteredWorkflows.slice(start, end);
        const isAdmin = this.context.userInfo.IsAdmin;

        return workflows.map((w, i) => (
            <tr key={i}>
                <td><Link to={w.Type === 'Contact' ? `/workflows/workflow/${w.WorkflowId}` : `/transactions/workflow/${w.WorkflowId}`}>{w.Name}</Link></td>
                <td>{w.Type}</td>
                <td>{w.StepCount}</td>
                <td>{w.Status}</td>
                <td>{w.CreatedByName}</td>
                <td>{w.ContactsCount}</td>
                <td>{ w.ShareWithGroup ? "Group" : "Personal" }</td>
                <td>
                    <button className="btn btn-white m-r-5" onClick={() => this.handleCopyWorkflow(w.WorkflowId)}>
                     { this.state.copyingId === w.WorkflowId ?
                      <div><i className="fas fa-spinner fa-pulse text-primary m-r-5"></i> Copy</div>
                      :
                      <div><i className="far fa-copy text-primary m-r-5"></i> Copy</div>
                     }
                    </button>
                    <button 
                        id={`delete_${i}`} 
                        className={classnames({ "invisible": (!isAdmin && w.CreatedById !== this.context.currentUserId) }, "btn btn-white m-r-5")} 
                        onClick={() => this.handleDeleteClick(w.WorkflowId)} 
                    >
                        <i className="far fa-times-circle text-danger"></i> Delete
                    </button>
                </td>
            </tr>
        ))
    }

    render(){
        const { loading, title, type, searchTerm, selectedAgentId } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <Link to={`/workflows/create?type=${type}`}><button className="btn btn-lime p-r-20 p-l-20 pull-right">Create Workflow</button></Link>
                <h1 className="page-header">{title} Workflows <small className="f-w-100">Manage, add and update your workflows</small></h1>
                <div className="action-container">
                    <div className="search-bar">
                        <div style={{ height: '100%' }}>
                            <div className="search-icon"><i className="fa fa-search"></i></div>
                            <input 
                                className="search-input with-thin-shadow"
                                placeholder="Search by workflow name"
                                value={searchTerm}
                                onChange={(e) => this.handleSearch(e)}
                            />
                        </div>
                    </div>
                    <div className="agent-options with-thin-shadow">
                        <AgentOptions 
                            agentId={selectedAgentId}
                            handleSelect={this.handleAgentChange}
                            selectName="selectedAgentId"
                        />
                    </div>
                </div>
                
                <div className="row m-b-10">
                    <div className="col-md-2">
                        <select className="form-control col-6 with-thin-shadow" value={this.state.workflowsPerPage} onChange={(e) => this.handlePerPageChange(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25} >25</option>
                            <option value={50} >50</option>
                            <option value={100} >100</option>
                        </select>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered bg-white with-thin-shadow">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th># Steps</th>
                                <th>Status</th>
                                <th>Creator</th>
                                <th># Subscribed</th>
                                <th>Accessibility</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.workflows.length > 0 && this.renderRows()}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="pull-right">
                            <Pagination 
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.workflowsPerPage}
                                totalItemsCount={this.state.totalWorkflows}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Workflows;