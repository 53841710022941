import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';
import StringInput from './StringInput';
import { filterTypes } from '../../helpers/FilterHelper';
import BooleanInput from './BooleanInput';
import DateSelect from './DateSelect';
import DayForward from './DayForward';
import DatePast from './DatePast';
import DatePastInverse from './DatePastInverse';

export default function DynamicSearchFilters(props) {
    const [activeTab, setActiveTab] = useState('1');
    let nonFilterBuilder = props.isFilterBuilder ? ['AssignedAgentId'] : [];

    const renderFields = (filter) => {
        switch (filter.FilterType) {
            case filterTypes.singleSelect:
                return <SingleSelect filter={filter} {...props} />
            case filterTypes.multiSelect:
                return <MultiSelect filter={filter} {...props} />
            case filterTypes.string:
                return <StringInput filter={filter} {...props} />
            case filterTypes.boolean:
                return <BooleanInput filter={filter} {...props} />
            case filterTypes.Date:
                return <DateSelect filter={filter} {...props} />
            case filterTypes.DayForward:
                return <DayForward filter={filter} {...props} />
            case filterTypes.DateForward:
                return <DayForward filter={filter} {...props} />
            case filterTypes.DatePast:
                return <DatePast filter={filter} {...props} />
            case filterTypes.DatePastInverse:
                return <DatePastInverse filter={filter} {...props} />
            default:
                break;
        }
    }

    return (
        <div>
            <Nav tabs className="nav-tabs-bordered">
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { setActiveTab('1'); }}
                    >
                        <span className="d-sm-none">Contact Information</span>
                        <span className="d-sm-block d-none">Contact Information</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { setActiveTab('2'); }}
                    >
                        <span className="d-sm-none">Activity</span>
                        <span className="d-sm-block d-none">Activity</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { setActiveTab('3'); }}
                    >
                        <span className="d-sm-none">Details</span>
                        <span className="d-sm-block d-none">Details</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} style={{backgroundColor: '#f2f3f4'}}>
                <TabPane tabId="1">
                    <div className="row">
                        {
                            props.filterOptions.filter(f => f.Category === 'Contact Information').map((filter, idx) => {
                                if (!nonFilterBuilder.includes(filter.Field)) {
                                    return (
                                        <div key={idx} className="col-6">
                                            {renderFields(filter)}
                                        </div>
                                    )
                                }
                                
                                return null;
                            })
                        }
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="row">
                        {
                            props.filterOptions.filter(f => f.Category === 'Activity').map((filter, idx) => (
                                <div key={idx} className="col-6">
                                    {renderFields(filter)}
                                </div>
                            ))
                        }
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div className="row">
                        {
                            props.filterOptions.filter(f => f.Category === 'Details').map((filter, idx) => (
                                <div key={idx} className="col-6">
                                    {renderFields(filter)}
                                </div>
                            ))
                        }
                    </div>
                </TabPane>
            </TabContent>
            { !props.isFilterBuilder && <div>
                <button className="btn btn-outline-danger" onClick={props.clearAllFilters}>Clear Filters</button>
                <button className="btn btn-lime pull-right" onClick={props.submitFilteredSearch}>Apply Filters</button>
                <button className="btn btn-primary pull-right m-r-10" onClick={() => props.toggleModal('searchsave')}>Save This Search</button>
            </div> }
        </div>
    )
}