import React from 'react';
import ActivityModal from '../activity/ActivityModal';
import { UncontrolledTooltip } from 'reactstrap';

class AddActivityButton extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			modalOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			modalOpen: !prevState.modalOpen
		}));
	}
  
	render() {
		return (
			<li className="dropdown dropdown">
                <i 
					id="add-activity-btn"
                    className="fas fa-user-check dropdown-toggle cursor-pointer" 
                    onClick={this.toggle}
                ></i>
				<UncontrolledTooltip target="add-activity-btn">Log Activity</UncontrolledTooltip>
                <ActivityModal 
                    isOpen={this.state.modalOpen}
                    toggleModal={this.toggle}
                />
            </li>
		);
	}
};

export default AddActivityButton;
