import React from 'react';
import VideoRecorder from 'react-video-recorder';
import VideoEmail from '../components/video/VideoEmail';
import { postData } from '../helpers/AxiosService';
import querySearch from 'stringquery';
import { displayDateWorded } from '../helpers/Functions';
import { PageSettings } from '../config/page-settings';
import VideoText from '../components/video/VideoText';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class Video extends React.Component{
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            videoName: 'Video - ' + displayDateWorded(new Date()),
            videoBlob: {},
            thumbBlob: {},
            thumbnailUrl: '',
            showOptions: false,
            emailModal: false,
            textModal: false,
            savingVideo: false,
            contactEmail: '',
            submittedVideoGuid: '',
            activeTab: '1',
            uploadVideo: {},
            uploadVideoName: '',
            completedVideo: {},
        }

        this.handleRecordingComplete = this.handleRecordingComplete.bind(this);
        this.resetVideoState = this.resetVideoState.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.submitVideo = this.submitVideo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search){
            let q = querySearch(this.props.location.search);

            if (q.em) {
                this.setState({ contactEmail: q.em });
            }

            if (q.c) {
                this.videoText.handleAddition({ name: q.c });
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleRecordingComplete(blob, arg, thumbnailBlob) {
        debugger;
        this.setState({ videoBlob: blob, thumbBlob: thumbnailBlob, showOptions: true });
    }

    resetVideoState() {
        this.setState({ videoBlob: {}, thumbBlob: {}, showOptions: false, submittedVideoGuid: '', thumbnailUrl: '', completedVideo: {} });
    }

    async submitVideo() {
        const { videoName, videoBlob } = this.state;
        let formData = new FormData();
        formData.append(videoName, videoBlob, videoName + '.webm');
        formData.append('VideoName', videoName);

        try {
            const resp = await postData('api/video/upload', formData);
            this.setState({ submittedVideoGuid: resp.data.VideoGuid, thumbnailUrl: resp.data.ThumbnailUrl, completedVideo: resp.data });
            return resp.data.videoGuid;
        }
        catch (err) {
            console.log('error uploading video', err);
            return '';
        }
    }

    toggleModal(modal) {
        switch(modal) {
            case 'email': 
                this.setState({ emailModal: !this.state.emailModal });
                break;
            case 'text': 
                this.setState({ textModal: !this.state.textModal });
                break;
            default: 
                break;
        }
    }

    handleSendByEmailClick() {
        this.toggleModal('email');
    }

    async handleSaveForLater() {
        this.setState({ savingVideo: true });
        await this.submitVideo();

        this.setState({ savingVideo: false });
    }

    toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
    }

    async handleFileChange(e) {
        if (this.state.savingVideo) return;
        
        const file = e.target.files[0];
        if (!file) return;

        this.setState({ savingVideo: true, uploadVideoName: file.name })
        console.log(file);

        let formData = new FormData();
        const videoName = file.name.split('.').slice(0, -1).join('.');
        console.log(videoName);
        console.log(file.name);
        formData.append(videoName, file, file.name);
        formData.append('VideoName', videoName);
        
        try {
            const resp = await postData('api/video/upload', formData);
            this.setState({ submittedVideoGuid: resp.data.VideoGuid, thumbnailUrl: resp.data.ThumbnailUrl, completedVideo: resp.data });
        }
        catch (err) {
            console.log('error uploading video', err);
        }

        this.setState({ savingVideo: false, uploadVideoName: '' });
    }

    render() {
        const { videoName, showOptions, emailModal, textModal, completedVideo,
            thumbnailUrl, contactEmail, submittedVideoGuid, savingVideo, activeTab, uploadVideoName } = this.state;
        return (
            <div className="row justify-content-center">
                { !thumbnailUrl && <div style={{ height: '650px', width: '665px'}}>
                    <Nav pills className="activity-nav">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' }, "text-center")}
                                onClick={() => this.toggleTab('1')}
                            >
                                <span className="f-s-16 m-r-5"><i className="fas fa-video"></i></span>
                                <span>Webcam</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' }, "text-center")}
                                onClick={() => this.toggleTab('2')}
                            >
                                <span className="f-s-16 m-r-10"><i className="fas fa-cloud"></i></span>
                                <span>Upload</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="with-thin-shadow">
                        <TabPane tabId="1">
                            <div className="form-group">
                                <label>Video Name</label>
                                <input 
                                    className="form-control"
                                    name="videoName"
                                    value={videoName}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <VideoRecorder 
                                isFlipped={true}
                                isOnInitially={true}
                                replayVideoAutoplayAndLoopOff={true}
                                showReplayControls={true}
                                onRecordingComplete={this.handleRecordingComplete}
                                onTurnOnCamera={this.resetVideoState}
                            />
                            { showOptions && <div className="row m-t-25">
                            
                            <div className="col-md-12">
                                <button className="btn btn-primary w-100" onClick={() => this.handleSaveForLater()}>
                                    { savingVideo ?
                                        <div className="button-spinner"></div>
                                        :
                                        "Save"
                                    }
                                </button>
                            </div>
                        </div> }
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="text-center">
                                <input 
                                    type="file" 
                                    className="hide" 
                                    onChange={this.handleFileChange}
                                    id="video-upload"
                                    disabled={savingVideo}
                                />
                                <label 
                                    htmlFor="video-upload" 
                                    className="btn btn-white"
                                >
                                    <i className={ (savingVideo ? 'fa-circle-notch fa-spin ' : 'fa-cloud ') + 'fas text-primary'}></i>  Upload Video
                                </label>
                                <div>
                                    { uploadVideoName }
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </div> }
                { thumbnailUrl && <div style={{ height: '650px', width: '665px'}}>
                    <h3 className="m-t-25 m-b-25">{completedVideo.VideoName}</h3>
                    <img src={thumbnailUrl} alt={uploadVideoName} style={{ maxWidth: '100%' }} />
                    <div className="row m-t-25">
                        <div className="col-md-6">
                            <button className="btn btn-primary w-100" onClick={() => this.handleSendByEmailClick()}>Send By Email</button>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-primary w-100" onClick={() => this.toggleModal('text')}>Send By Text</button>
                        </div>
                    </div>
                </div>}
                <VideoEmail
                    isOpen={emailModal}
                    toggleModal={this.toggleModal}
                    thumbnailImg={thumbnailUrl}
                    toAddress={contactEmail}
                    videoName={videoName}
                    submitVideo={this.submitVideo}
                    submittedVideoGuid={submittedVideoGuid}
                    resetVideoState={this.resetVideoState}
                />
                <VideoText 
                    isOpen={textModal}
                    toggleModal={this.toggleModal}
                    submitVideo={this.submitVideo}
                    videoGuid={this.submittedVideoGuid}
                    videoName={videoName}
                    onRef={ref => (this.videoText = ref)}
                />
            </div>
        )
    }
}

export default Video;