import React from 'react';
import { ListGroup } from 'reactstrap';
import ActivityItem from './ActivityItem';
import { getActivityDetails } from './activity-helper';

function SearchActivity({ searches }){
    return (
        <div>
            <ListGroup>
                {searches.map((m, i) => {
                    const activity = getActivityDetails(m.ActivityTypeId);
                    return <ActivityItem item={m} key={i} iconCss={activity.icon} title={activity.title} textColor={activity.textColor} />
                })}
            </ListGroup>
        </div>
    )
}

export default SearchActivity;