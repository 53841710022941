import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputMask from 'react-input-mask';
import { postData } from '../../helpers/AxiosService';
import { phoneClean } from '../../helpers/Functions.js';


class AddJointContact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            jointFirstName: '',
            jointLastName: '',
            jointCellPhone: '',
            jointHomePhone: '',
            jointWorkPhone: '',
            jointEmail: '',
            jointAddress: '',
            jointCity: '',
            jointState: '',
            jointZip: '',
            sending: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSave(){
        //validate
        this.setState({ sending: true });

        let data = {
            FirstName: this.state.jointFirstName,
            LastName: this.state.jointLastName,
            Address: this.state.jointAddress,
            City: this.state.jointCity,
            StateCode: this.state.jointState,
            ZipCode: this.state.jointZip,
            CellPhone: phoneClean(this.state.jointCellPhone),
            HomePhone: phoneClean(this.state.jointHomePhone),
            WorkPhone: phoneClean(this.state.jointWorkPhone),
            Email: this.state.jointEmail,
            TenantId: this.props.tenantId,
            ContactEntityId: this.props.contactEntityId
        }

        postData('api/contact/joint', data).then(response => {
            this.setState({ sending: false });
            this.props.fetchContactEntity(this.props.contactId);
            this.props.toggleModal('addJointContact');
        }).fail(error => this.props.toggleModal('addJointContact'));
    }

    closeResetModal(){
        this.setState({ note: '' });
        this.props.toggleModal('addJointContact');
    }

    render(){
        const { jointFirstName,
            jointLastName,
            jointCellPhone,
            jointHomePhone,
            jointWorkPhone,
            jointEmail,
            jointAddress,
            jointCity,
            jointState,
            jointZip } = this.state;
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.closeResetModal()}>Add Joint Contact</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>First Name</label>
                            <input className="form-control" name="jointFirstName" value={jointFirstName} onChange={this.handleChange} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Last Name</label>
                            <input className="form-control" name="jointLastName" value={jointLastName} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Cell Phone</label>
                            <InputMask 
                                mask="(999) 999 9999" 
                                className="form-control" 
                                name="jointCellPhone" 
                                value={jointCellPhone} 
                                onChange={this.handleChange}
                            ></InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Home Phone</label>
                            <InputMask 
                                mask="(999) 999 9999" 
                                className="form-control" 
                                name="jointHomePhone" 
                                value={jointHomePhone} 
                                onChange={this.handleChange}
                            ></InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Work Phone</label>
                            <InputMask 
                                mask="(999) 999 9999" 
                                className="form-control" 
                                name="jointWorkPhone" 
                                value={jointWorkPhone} 
                                onChange={this.handleChange}
                            ></InputMask>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input className="form-control" name="jointEmail" value={jointEmail} onChange={this.handleChange}  />
                    </div>
                    <div className="form-group">
                        <label>Address</label>
                        <input className="form-control" name="jointAddress" value={jointAddress} onChange={this.handleChange}  />
                    </div>
                    <div className="row">
                        <div className="form-group col-md-8">
                            <label>City</label>
                            <input className="form-control" name="jointCity" value={jointCity} onChange={this.handleChange} />
                        </div>
                        <div className="form-group col-md-4">
                            <label>State</label>
                            <input className="form-control" name="jointState" value={jointState} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Zip</label>
                        <input className="form-control col-md-6" name="jointZip" value={jointZip} onChange={this.handleChange}  />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-white" onClick={() => this.closeResetModal()}>Cancel</button>
                    <button className="btn btn-primary  m-l-5" onClick={() => this.handleSave()}>
                        {this.state.sending ? 
                            <div className="button-spinner"></div>
                            :
                            "Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddJointContact;