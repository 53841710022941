import React from 'react';
import { Link } from 'react-router-dom';
import { postData } from '../../helpers/AxiosService';
import SweetAlert from 'react-bootstrap-sweetalert';
import background from '../../assets/banner-bg1.jpg';


class LoginHelp extends React.Component {
	// static contextType = PageSettings;

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			sending: false,
			showConfirmation: false
		}
	}

	handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

	handleSubmit(e){
        e.preventDefault();
		if (!this.state.email) return;

        this.setState({ sending: true });
        let data = {
            Email: this.state.email
        }

        postData('api/account/request-reset', data).then(() => {
            this.setState({ sending: false, showConfirmation: true });
        }).fail(err => {
			console.error(err);
			this.setState({ sending: false });
		});
    }
  
	render() {
		const { showConfirmation } = this.state;
		return (
			<div style={{ background: `url(${background})`, height: '100vh', width: '100vw' }}>
				{ !showConfirmation &&
				<div className="login">
					<div className="login-header">
						<div className="brand">
							<b>Password</b> Recovery
							<small className="text-black">Enter your email to recover your password.</small>
						</div>
						{/* <div className="icon">
							<i className="fa fa-lock"></i>
						</div> */}
					</div>
					<div className="login-content p-t-0">
						<form onSubmit={e => this.handleSubmit(e)} className="margin-bottom-0">
							<div className="form-group m-b-20">
								<input 
                                    type="text" 
                                    name="email" 
                                    className="form-control form-control-lg bg-white text-black" 
                                    placeholder="Email" 
                                    required 
                                    value={this.state.email}
                                    onChange={e => this.handleChange(e)}
                                    /> 
							</div>
							<div className="login-buttons">
								<button type="submit" className="btn btn-lime-outline btn-block btn-lg">
                                    { this.state.sending ?
                                        <div className="button-spinner"></div>
                                        :
                                        "Recover Password"
                                    }
                                </button>
								<Link to="/login" className="btn btn-lime btn-block btn-lg">Cancel</Link>
							</div>
							<div className="m-t-20 text-black">
								Not a member yet? Click <Link to="/register" className="text-primary">here</Link> to register.
							</div>
						</form>
					</div>
				</div> }
				{ showConfirmation && 
					<SweetAlert
                        success 
                        confirmBtnText="Return To Login"
                        confirmBtnBsStyle="lime"
                        title="Thank you!"
                        onConfirm={() => this.props.history.push('/login')}
                    >
                        An email has been sent to your email on file with a link to reset your password.
                    </SweetAlert>
				
				}
			</div>
		)
	}
}

export default LoginHelp;

	{/* <div className="login login-v2">
						<Card className="with-shadow">
							<CardBody>
								Thanks
							</CardBody>
						</Card>
					</div> */}