import React, { useEffect, useState, useContext } from 'react';
import SalesListModal from '../components/SalesReports/SalesListModal';
import { deleteData, getData } from '../helpers/AxiosService';
import AgentOptions from '../components/shared/AgentOptions';
import { PageSettings } from '../config/page-settings';
import SalesList from '../components/SalesReports/SalesList';
import EmailModalBlank from '../components/email/EmailModalBlank';

export default function SalesDashboard() {
    const context = useContext(PageSettings);
    const [salesLists, setSalesLists] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedList, setSelectedList] = useState({});
    const [userId, setUserId] = useState(context.userInfo.RoleId === 2 ? 0 : context.userInfo.UserId)
    const [emailInfo, setEmailInfo] = useState({});
    const [showEmailModal, setShowEmailModal] = useState(false);

    async function fetchTenantLists() {
        try {
            const resp = await getData(`api/salesreport/tenant?selectedUserId=${userId}`);
            if (resp.data.length) {
                setSalesLists(resp.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function handleEditClick(id) {
        const list = salesLists.find(sl => sl.Id === id);
        setSelectedList(list);
        setShowEditModal(true);
    }

    function handleUserChange(e) {
        const { value } = e.target;
        setUserId(Number(value));
    }

    function handleEmail(email, entityId, contactId) {
        setEmailInfo({
            toAddress: email,
            selectedEntityId: entityId,
            selectedContactId: contactId,
        });
        setShowEmailModal(true);
    }

    function toggleEmailModal() {
        setShowEmailModal(!showEmailModal);
    }

    async function deleteSalesList(id) {
        context.toggleSweetAlert('confirm');
        try {
            await deleteData(`api/salesreport/${id}`);
            context.toggleSweetAlert('success');
            fetchTenantLists();
        } catch (e) {
            console.log(e);
            context.toggleSweetAlert('success');
        }
    }

    function handleDeleteClick(id) {
        context.messageConfirmation('Delete List', 'Are you sure you want to delete this list?', 
            deleteSalesList.bind(null, id), "List deleted successfully", "Delete");
    }

    useEffect(() => {
        fetchTenantLists();
    }, []);

    useEffect(() => {
        fetchTenantLists();
    }, [userId]);

    function toggleModal() {
        if (showEditModal) {
            setSelectedList({});
        }

        setShowEditModal(!showEditModal);
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6 mt-auto">
                    <h1 className="page-header">Heat Sheet <small>The hottest follow ups</small></h1>
                </div>
                <div className="col-md-6">
                    <button
                        className="btn btn-lime pull-right"
                        onClick={() => setShowEditModal(true)}
                    >
                        Create List
                    </button>
                </div>
            </div>
            <div className="d-inline-block m-b-20" style={{ width: '290px' }}>
                <AgentOptions
                    all={true}
                    agentId={userId}
                    handleSelect={handleUserChange}
                    className="with-thin-shadow"
                />
            </div>
            { showEditModal && <SalesListModal 
                isOpen={showEditModal}
                toggleModal={toggleModal}
                selectedList={selectedList}
                fetchLists={fetchTenantLists}
            />}
            <EmailModalBlank 
                toAddress={emailInfo.toAddress} 
                openModal={showEmailModal} 
                toggleModal={toggleEmailModal} 
                keyType="ContactEntityId"
                keyId={emailInfo.selectedEntityId}
                contactId={emailInfo.selectedContactId}
            />
            <div className="row">
                { salesLists.map(sl => (
                    <div className="col-md-6" key={sl.Id}>
                        <SalesList 
                            salesList={sl} 
                            handleEditClick={handleEditClick}
                            handleEmail={handleEmail}
                            userId={userId}
                            handleDeleteClick={handleDeleteClick}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}