import axios from 'axios';
import Deferred from './Deferred';
import config from '../config';
const { baseUrl, baseClientUrl } = config;
// const baseClientUrl = "http://localhost:3000";

// const baseUrl = 'http://localhost:5001/';

export function postData(url, data, self){
    var token = localStorage.getItem('access_token');
    var d = new Deferred();
    axios({
        method: 'post',
        url: `${baseUrl}${url}`,
        data: data,
         headers: {
             'Authorization': `bearer ${token}`
        }
    }).then(resp => {
        d.resolve(resp);
    }).catch(error => {
        console.log('error', error)
        console.log(error.response);
        if (error.response && error.response.status === 400){
            let data = error.response.data;
            let message = data.Message;
            if (data.ModelState && data.ModelState.data && data.ModelState.data.length > 0){
                for (let i = 0; i < data.ModelState.data.length; i++){
                    message.concat(data.ModelState.data[i]);
                };
            };
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 500){
            let data = error.response.data;
            let message = data.Message + ' ' + data.ExceptionMessage;
            if (typeof data === 'string') {
                message = data;
            }
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }
        d.reject(error);
    })

    return d;
}

export function patchData(url, data, self){
    var token = localStorage.getItem('access_token');
    var d = new Deferred();
    axios({
        method: 'patch',
        url: `${baseUrl}${url}`,
        data: data,
         headers: {
             'Authorization': `bearer ${token}`
        }
    }).then(resp => {
        d.resolve(resp);
    }).catch(error => {
        console.log('error', error)
        console.log(error.response);
        if (error.response && error.response.status === 400){
            let data = error.response.data;
            let message = data.Message;
            if (data.ModelState && data.ModelState.data && data.ModelState.data.length > 0){
                for (let i = 0; i < data.ModelState.data.length; i++){
                    message.concat(data.ModelState.data[i]);
                };
            };
            alert(message);
        }
        if (error.response && error.response.status === 500){
            let data = error.response.data;
            let message = data.Message + ' ' + data.ExceptionMessage;
            if (typeof data === 'string') {
                message = data;
            }
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }
        d.reject(error);
    })

    return d;
}

export function getData(url, self){
    var token = localStorage.getItem('access_token');
    var d = new Deferred();
    axios({
        method: 'get',
        url: `${baseUrl}${url}`,
        headers: {
        'Authorization': `bearer ${token}`
        }
    }).then(resp => {
        d.resolve(resp);
    }).catch(error => {
        console.log(error.response)
        if (error.response && error.response.status === 400){
            let data = error.response.data;
            let message = data.Message;
            if (data.ModelState && data.ModelState.data.length > 0){
                data.ModelState.data.forEach(mssg => {
                    return message.concat(" " + mssg);
                });
            };
        }
        if (error.response && error.response.status === 500){
            let data = error.response.data;
            let message = data.Message + ' ' + data.ExceptionMessage;
            if (typeof data === 'string') {
                message = data;
            }
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }
        d.reject(error);
    })

    return d;
}

export function putData(url, data, self){
    var token = localStorage.getItem('access_token');
    var d = new Deferred();
    axios({
        method: 'put',
        url: `${baseUrl}${url}`,
        data: data,
         headers: {
             'Authorization': `bearer ${token}`
        }
    }).then(resp => {
        d.resolve(resp);
    }).catch(error => {
        console.log(error.response);
        if (error.response && error.response.status === 400){
            let data = error.response.data;
            let message = data.Message;
            if (data.ModelState && data.ModelState.data && data.ModelState.data.length > 0){
                for (let i = 0; i < data.ModelState.data.length; i++){
                    message.concat(data.ModelState.data[i]);
                };
            };
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 500){
            let data = error.response.data;
            let message = data.Message + ' ' + data.ExceptionMessage;
            if (typeof data === 'string') {
                message = data;
            }
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }
        d.reject(error);
    })

    return d;
}

export function deleteData(url, self){
    var token = localStorage.getItem('access_token');
    var d = new Deferred();
    axios({
        method: 'delete',
        url: `${baseUrl}${url}`,
        headers: {
             'Authorization': `bearer ${token}`
        }
    }).then(resp => {
        d.resolve(resp);
    }).catch(error => {
        console.log(error.response);
        if (error.response && error.response.status === 400){
            let data = error.response.data;
            let message = data.Message;
            if (data.ModelState && data.ModelState.data && data.ModelState.data.length > 0){
                for (let i = 0; i < data.ModelState.data.length; i++){
                    message.concat(data.ModelState.data[i]);
                };
            };
            alert(message);
        }
        if (error.response && error.response.status === 500){
            let data = error.response.data;
            let message = data.Message + ' ' + data.ExceptionMessage;
            alert(message);
        }
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }
        d.reject(error);
    })

    return d;
}

export function getDownloadDoc(url, self){
    var token = localStorage.getItem('access_token');
    url = baseUrl + url;
    axios.get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        console.log(response);
        let contentDisposition = response.request.getResponseHeader('content-disposition');
        console.log(contentDisposition);
        let startIndex = contentDisposition.indexOf('filename=') + 9;
        const fileName = contentDisposition.substring(startIndex);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        console.log('filename: ', fileName)
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(error => {
        console.log(error)
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }  
    });
}

export function postFormData(url, formData, self) {
    const token = localStorage.getItem('access_token');
    const d = new Deferred();
    url = baseUrl + url;
    axios.post(url, formData, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    }).then(resp => {
        d.resolve(resp);
    }).catch(error => {
        console.log('error', error)
        console.log(error.response);
        if (error.response && error.response.status === 400){
            let data = error.response.data;
            let message = data.Message;
            if (data.ModelState && data.ModelState.data && data.ModelState.data.length > 0){
                for (let i = 0; i < data.ModelState.data.length; i++){
                    message.concat(data.ModelState.data[i]);
                };
            };
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 500){
            let data = error.response.data;
            let message = data.Message + ' ' + data.ExceptionMessage;
            if (typeof data === 'string') {
                message = data;
            }
            if (self){
                self.context.addNotification("", message, "danger", "top", "top-right");
            } else {
                alert(message);
            }
        }
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }
        d.reject(error);
    })

    return d;
}

export function postDownloadDoc(url, data, self){
    var token = localStorage.getItem('access_token');
    url = baseUrl + url;
    axios.post(url, data, {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        console.log(response);
        let contentDisposition = response.request.getResponseHeader('content-disposition');
        console.log(contentDisposition);
        let startIndex = contentDisposition.indexOf('filename=') + 9;
        const fileName = contentDisposition.substring(startIndex);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        console.log('filename: ', fileName)
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(error => {
        console.log(error)
        if (error.response && error.response.status === 403){
            if (error.response.statusText === "Redirect"){
                window.location.href = `${baseClientUrl}/access-denied`;
            } else if (self) {
                let data = error.response.data;
                self.context.addNotification("Unauthorized", data, "warning", "top", "top-right");
            }
        }  
    });
}