import React from 'react';
import { ListGroup } from 'reactstrap';
import ActivityItem from './ActivityItem';
import { getActivityDetails } from './activity-helper';

function ActivityText({ calls, toggleModal, handleEditClick }){
    return (
        <div>
            <div className="row m-b-20">
                <div className="col-md-12">
                    <button className="btn btn-lime pull-right" onClick={() => toggleModal('activity')}>Log Phone Call</button>
                </div>
            </div>
            <ListGroup>
                {calls.map((m, i) => {
                    const activity = getActivityDetails(m.ActivityTypeId);
                    return (
                        <ActivityItem 
                            item={m} 
                            key={i} 
                            iconCss={"fas fa-phone"} 
                            title={activity.title} 
                            textColor={activity.textColor} 
                            isEditable={true}
                            handleEditClick={handleEditClick}
                        />
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default ActivityText;