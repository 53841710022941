import React from 'react';
import { Link } from 'react-router-dom';
import { PageSettings } from '../config/page-settings.js';

class Forbidden extends React.Component {
	static contextType = PageSettings;
	
	componentDidMount() {
		this.context.handleSetPageSidebar(false);
		this.context.handleSetPageHeader(false);
	}

	componentWillUnmount() {
		this.context.handleSetPageSidebar(true);
		this.context.handleSetPageHeader(true);
	}
  
	render() {
		return (
			<div className="error">
				<div className="error-code m-b-10">403</div>
				<div className="error-content">
					<div className="error-message">Access denied...</div>
					<div className="error-desc m-b-30">
						Unfortunately you do not have access to this content. <br />
						Please contact us if you have any questions.
					</div>
					<div>
						<Link to="/" className="btn btn-lime p-l-20 p-r-20">Go Home</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default Forbidden;