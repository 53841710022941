import React from 'react';
import { getData } from '../helpers/AxiosService';
import { displayDate } from '../helpers/Functions';
import { CardTitle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import classnames from 'classnames';
import CommissionDetails from '../components/transactions/CommissionDetails';
import { Link } from 'react-router-dom';
import withReportWrapper from '../components/reports/ReportingWrapper';
import { PageSettings } from '../config/page-settings';

const volumeFormatter = (num) => {
    return Math.abs(num) > 999999 ? (Math.abs(num) / 1000000).toFixed(2) + 'M'
    : Math.floor(Math.abs(num) / 1000) + 'K';
}

const entryTypes = {
    deduction: 0,
    credit: 1,
}

const amountTypes = {
    percent: 0,
    dollar: 1,
}
class Commissions extends React.Component {
    static contextType = PageSettings;
    constructor(props) {
        super(props);
        this.state = {
            rawData: [],
            filteredData: [],
            filterStatus: 'All',
            totalTransactions: 0,
            totalVolume: 0,
            selectedTransId: 0,
            editModal: false,
            loading: true,
            totalGross: 0.00,
            totalSplit: 0.00,
            totalConcessions: 0.00,
            totalNet: 0.00,
            purchaseCount: 0,
            listingCount: 0,
            isAdmin: false,
        }
    }

    componentDidMount() {
        this.getReportData();
        this.setState({ isAdmin: this.context.userInfo.RoleId === 2 });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId
            || prevProps.startDate !== this.props.startDate
            || prevProps.endDate !== this.props.endDate
            ) {
            this.getReportData();
        }
    }

    getReportData() {
        if (this.props.userId === null) return;

        this.setState({ loading: true });

        let url = `api/report/performance/commission-report?userId=${this.props.userId}&startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        
        getData(url).then(response => {
            this.setState({ rawData: response.data, filteredData: response.data, loading: false }, this.calculateTotals);
        }).fail(() => this.setState({ loading: false }));
    }

    filterCommStatus(status) {
        if (status === 'All') {
            this.setState({ filteredData: this.state.rawData, filterStatus: status }, this.calculateTotals);
            return;
        }

        const rawData = this.state.rawData.slice();

        const results = rawData.filter(t => {
            if (t.CommRealized && status === 'Closed') return true;
            if (!t.CommRealized && status === 'Pending') return true;

            return false;
        });

        this.setState({ filteredData: results, filterStatus: status }, this.calculateTotals);
    }

    calculateTotals() {
        const { filteredData } = this.state;
        let totalTransactions = filteredData.length;
        let totalVolume = 0;
        let totalGross = 0;
        let totalConcessions = 0;
        let totalSplit = 0;
        let totalNet = 0;
        let purchaseCount = 0;
        let listingCount = 0;

        filteredData.forEach(t => {
            const bSplit = (t.Financials.BrokerSplitPerc / 100) * t.Financials.GrossCommission;
            totalVolume += t.Financials.CalcPrice;
            totalGross += t.Financials.GrossCommission;
            totalConcessions += t.Financials.Concessions;
            totalSplit += bSplit;
            totalNet += (t.Financials.GrossCommission - t.Financials.Concessions - bSplit);
            if (t.Type === 'Listing') listingCount++;
            if (t.Type === 'Purchase') purchaseCount++;
        });

        this.setState({ totalGross, totalConcessions, totalSplit, totalNet, totalTransactions, totalVolume, purchaseCount, listingCount });
    }

    handleEditClick(transId) {
        this.setState({ selectedTransId: transId }, () => this.toggleModal());
    }

    toggleModal() {
        this.setState({ 
            editModal: !this.state.editModal
        });
    }

    adjustmentsTotal(gci, preSplitAdjustments) {
        return preSplitAdjustments.reduce((total, adj) => {
            let amount = adj.Amount;
            if (adj.AmountType === amountTypes.percent) {
                amount = gci * (adj.Amount / 100);
            }
            
            if (adj.EntryType === entryTypes.deduction) {
               amount = amount * -1;
            }

            return total + amount;
        }, 0)
    }

    calculateCommission(gci, splitPercent, preSplits, concessions, salesPrice ) {
        const preSplitTotal = this.adjustmentsTotal(gci, preSplits);
        const adjustedGross = gci + preSplitTotal;
        const brokerSplit = (splitPercent / 100) * adjustedGross;
        const net = adjustedGross - concessions - brokerSplit;

        return {
            gci,
            preSplitTotal,
            adjustedGross,
            brokerSplit,
            concessions,
            net,
        }
    }

    render() {
        const { filteredData, filterStatus, editModal, selectedTransId, loading, totalVolume, isAdmin,
            totalConcessions, totalGross, totalNet, totalSplit, totalTransactions, listingCount, purchaseCount } = this.state;
        return (
            <div>
                <CardTitle tag="h4">
                    Commissions
                    { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i>}
                </CardTitle>
                <div className="btn-group m-t-10 with-thin-shadow">
                    <button 
                        className={classnames({ 'active': filterStatus === 'All' }, "btn btn-white width-150")}
                        onClick={() => this.filterCommStatus('All')}
                    >All</button>
                    <button 
                        className={classnames({ 'active': filterStatus === 'Closed' }, "btn btn-white width-150")}
                        onClick={() => this.filterCommStatus('Closed')}
                    >Closed</button>
                    <button 
                        className={classnames({ 'active': filterStatus === 'Pending' }, "btn btn-white width-150")}
                        onClick={() => this.filterCommStatus('Pending')}
                    >Pending</button>
                </div>
                <div className="row m-b-20 m-t-25">
                    <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">TRANSACTIONS</div>
                                <div className="stats-number">{totalTransactions}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">LISTINGS</div>
                                <div className="stats-number">{listingCount}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">PURCHASES</div>
                                <div className="stats-number">{purchaseCount}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">TOTAL VOLUME</div>
                                <div className="stats-number">${volumeFormatter(totalVolume)}</div>
                            </div>
                        </div>
                    </div>
                    { this.props.isTeam && <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">GROSS COMMISSIONS</div>
                                <div className="stats-number">${totalGross.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                            </div>
                        </div>
                    </div> }
                    <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">CONCESSIONS</div>
                                <div className="stats-number">${totalConcessions.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">AGENT COMMISSION</div>
                                <div className="stats-number">${totalNet.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                            </div>
                        </div>
                    </div>
                    { this.props.isTeam && <div className="col-6 col-md-2">
                        <div className="widget widget-stats inverse-mode m-b-10">
                            <div className="stats-content">
                                <div className="stats-title text-grey f-w-600 m-b-10">Brokerage Split</div>
                                <div className="stats-number">${totalSplit.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                            </div>
                        </div>
                    </div> }
                </div>
                <div className="table-responsive">
                    <table className="table bg-white table-striped table-bordered with-thin-shadow">
                        <thead>
                            <tr>
                                <th>Transaction</th>
                                <th>Agent</th>
                                <th>Type</th>
                                <th>Commission Status</th>
                                <th>Transaction Status</th>
                                <th>Source</th>
                                <th>Closing Date</th>
                                <th>Price</th>
                                <th>Gross</th>
                                <th>Pre-Split Adj</th>
                                <th>Adjusted Commission</th>
                                <th>Concessions</th>
                                <th>Broker Split</th>
                                <th>Net</th>
                                { isAdmin && <th>Edit</th> }
                            </tr>
                        </thead>
                        <tbody>
                            { filteredData.length > 0 && 
                                filteredData.map((t, i) => {
                                    const { GrossCommission, BrokerSplitPerc, Concessions, CalcPrice, PreSplitAdjustments } = t.Financials;
                                    // const gross = GrossCommission;
                                    // const bSplit = (BrokerSplitPerc / 100) * gross;
                                    // const net = gross - bSplit - Concessions;

                                    const {
                                        gci,
                                        preSplitTotal,
                                        adjustedGross,
                                        brokerSplit,
                                        concessions,
                                        net,
                                    } = this.calculateCommission(GrossCommission, BrokerSplitPerc, PreSplitAdjustments, Concessions, CalcPrice);

                                    return (
                                        <tr key={t.TransactionId}>
                                            <td><Link to={`/transactions/transaction/${t.TransactionId}`}>{t.Address}</Link></td>
                                            <td>{t.AgentName}</td>
                                            <td>{t.Type}</td>
                                            <td>{t.CommRealized ? "Realized" : "Unrealized"}</td>
                                            <td>{t.Status}</td>
                                            <td>{t.SourceName}</td>
                                            <td>{t.DateClosed ? displayDate(t.DateClosed) : displayDate(t.ClosingDate)}</td>
                                            <td>${CalcPrice ? CalcPrice.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            <td>${gci ? gci.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            <td>${preSplitTotal ? preSplitTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            <td>${adjustedGross ? adjustedGross.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            <td>${concessions ? concessions.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            <td>${brokerSplit ? brokerSplit.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            <td>${net ? net.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</td>
                                            { isAdmin && <td><i className="far fa-edit" onClick={() => this.handleEditClick(t.TransactionId)}></i></td> }
                                        </tr>
                                    )
                                })
                            }
                            { filteredData.length === 0 &&
                                <tr>
                                    <td className="text-center" colSpan="14">No transactions matching search criteria</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <Modal size="xl" isOpen={editModal}>
                    <ModalHeader toggle={() => this.toggleModal()}>Commission Details</ModalHeader>
                    <ModalBody>
                        <CommissionDetails 
                            transactionId={selectedTransId}
                            refreshCommissionReport={this.getReportData.bind(this)}
                            toggleModal={this.toggleModal.bind(this)} />
                    </ModalBody>
                </Modal>

            </div>
        )
    }
}


export default withReportWrapper(Commissions, 'ytd');
