import React, { useState, useEffect, useContext } from 'react';
import { getData, postData, putData, deleteData } from '../helpers/AxiosService';
import { Panel, PanelBody } from '../components/panel/panel';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PageSettings } from '../config/page-settings';
import { formatPhoneNumber } from '../helpers/Functions';

export default function TextCodes() {
    const [textCodes, setTextCodes] = useState([]);
    const [loading, setLoading] = useState([]);
    const [sources, setSources] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCode, setSelectedCode] = useState({});
    const [sendingUpdate, setSendingUpdate] = useState(false);
    const [phoneConfig, setPhoneConfig] = useState({});
    const cxt = useContext(PageSettings);

    async function getTextCodes() {
        setLoading(true);
        try {
            const resp = await getData('api/phonecodes/tenant');
            setTextCodes(resp.data);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    async function getPhoneCodeConfig() {
        setLoading(true);
        try {
            const resp = await getData('api/phonecodes/config');
            setPhoneConfig(resp.data);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    async function getSourceOptions() {
        setLoading(true);
        try {
            const resp = await getData('api/settings/contact/sources');
            const sourceOptions = resp.data.map(s => {
                return { value: s.SourceGuid, name: s.Name };
            });
            setSources(sourceOptions);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    useEffect(() => {
        getTextCodes();
        getSourceOptions();
        getPhoneCodeConfig();
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        // enforce 4 digit codes
        if (name === "Code" && value.length > 4) return;

        setSelectedCode({ ...selectedCode, [name]: value });
    }

    function handleEditClick(code) {
        setSelectedCode(code);
    }

    function handleSaveCode() {
        setSendingUpdate(true);
        if (selectedCode.Id) {
            putData('api/phonecodes', selectedCode, { context: cxt }).then(() => {
                handleAfterUpdate();
            }).fail(e => setSendingUpdate(false));
        } else {
            postData('api/phonecodes', selectedCode, { context: cxt }).then(() => {
                handleAfterUpdate();
            }).fail(e => setSendingUpdate(false));
        }
    }

    function handleAfterUpdate() {
        setSendingUpdate(false);
        setShowModal(false);
        getTextCodes();
    }

    function handleDeleteClick(code) {
        cxt.messageConfirmation('Delete Text Code', `Are you sure you want to delete this code: ${code.Code}?`, 
                    deleteCode.bind(null, code.Id), "Code deleted successfully", "Delete");
    }

    async function deleteCode(codeId) {
        cxt.toggleSweetAlert('confirm');
		try {
            await deleteData(`api/phonecodes/${codeId}`, { context: cxt });
            cxt.toggleSweetAlert('success');
            await getTextCodes();
        } catch (e) {
            console.log(e);
            cxt.toggleSweetAlert('success');
        }
    }

    useEffect(() => {
        if (Object.keys(selectedCode).length && !showModal) {
            setShowModal(true);
        }
    }, [selectedCode]);

    useEffect(() => {
        if (!showModal) {
            setSelectedCode({});
        }
    }, [showModal]);

    return (
        <div>
            <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
            <button
                className="btn btn-lime p-r-20 p-l-20 pull-right"
                onClick={() => setShowModal(true)}
            >
                Create Text Code
            </button>
            <h1 className="page-header">Text Codes <small className="f-w-100">Manage, add and update your text codes</small></h1>
            <Panel className="m-t-20">
                <PanelBody>
                    { phoneConfig.PhoneNumber ? <div>Your text code phone number: <strong>{formatPhoneNumber(phoneConfig.PhoneNumber.replace("1", ""))}</strong></div> : <div>Your text code phone number has not been set up, please contact support@realconnectone.com to request a number.</div>}
                </PanelBody>
            </Panel>
            <div className="table-responsive m-t-25 with-thin-shadow">
                <table className="table thick-rows bg-white m-b-0">
                    <thead>
                        <tr>
                            <th>Text Code</th>
                            <th>Message</th>
                            <th>Message Link</th>
                            <th>MLS Number</th>
                            <th>Source</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { textCodes.map(code => {
                            const source = sources.find(s => s.value === code.SourceGuid);
                            const sourceName = source ? source.name : '';
                            return (
                                <tr key={code.Id}>
                                    <td>{code.Code}</td>
                                    <td>{code.Message}</td>
                                    <td>{code.MessageLink}</td>
                                    <td>{code.MlsNumber}</td>
                                    <td>{sourceName}</td>
                                    <td>
                                        <button 
                                            className="btn btn-white m-r-5" 
                                            onClick={() => handleEditClick(code)}
                                        >
                                            <i className="far fa-edit text-primary"></i> Edit
                                        </button>
                                        <button 
                                            className="btn btn-white" 
                                            onClick={() => handleDeleteClick(code)}
                                        >
                                            <i className="far fa-times-circle text-danger"></i> Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                        { textCodes.length === 0 && <tr><td className="text-center" colSpan={6}>No text codes</td></tr>}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={showModal} >
                <ModalHeader toggle={() => setShowModal(false)}>Create Text Code</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Code</label> <span className="text-muted">Must be a 4 digit number</span>
                            <input 
                                className="form-control w-100" 
                                name="Code" 
                                type="number"
                                value={selectedCode.Code} 
                                onChange={(e) => handleChange(e)} 
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Message</label> <span className="text-muted">Auto response when someone texts the code</span>
                            <textarea 
                                className="form-control w-100" 
                                rows="5"
                                name="Message" 
                                value={selectedCode.Message} 
                                onChange={(e) => handleChange(e)} 
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Message Link</label> <span className="text-muted">Link that will be sent with the message</span>
                            <input 
                                className="form-control w-100" 
                                name="MessageLink" 
                                value={selectedCode.MessageLink} 
                                onChange={(e) => handleChange(e)} 
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>MLS Number</label> <span className="text-muted">Listing number if marketing a property</span>
                            <input 
                                className="form-control w-100" 
                                name="MlsNumber" 
                                value={selectedCode.MlsNumber} 
                                onChange={(e) => handleChange(e)} 
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Source</label> <span className="text-muted">Default will be Code Inquiry if left blank</span>
                            <select 
                                className="form-control w-100" 
                                name="SourceGuid" 
                                value={selectedCode.SourceGuid} 
                                onChange={(e) => handleChange(e)} 
                            >
                                <option value="">Select a source...</option>
                                { sources.map(s => <option key={s.value} value={s.value}>{s.name}</option>)}
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary-outline" onClick={() => setShowModal(false)}>Cancel</button>
                    <button className="btn btn-primary" onClick={() => handleSaveCode()}>
                        { sendingUpdate ?
                            <div className="button-spinner"></div>
                            :
                            "Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}