import React from 'react';
import NVD3Chart from 'react-nvd3';
import { Panel, PanelBody } from '../panel/panel.jsx';
import { CardTitle, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { getData } from '../../helpers/AxiosService.js';

export default class CommissionChart extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pieChart: {
                data: null,
                options: {
                    showTooltipPercent: 'true',
                    showLegend: 'true'
                }, 
            },
            loading: false
        }
    }

    componentDidMount() {
        this.getChartData();
    }

    getChartData() {
        this.setState({ loading: true });
        getData('api/report/commission-chart').then(response => {
            const { Unrealized, Realized } = response.data;
            const real = { 'label': `Realized $${Realized}`, 'value' : Realized, 'color': '#90ca4b' };
            const unreal = { 'label': `Unrealized $${Unrealized}`, 'value' : Unrealized, 'color': '#348fe2' };

            const data = [real, unreal];

            this.setState({ pieChart: {...this.state.pieChart, data }, loading: false });
        }).fail(() => this.setState({ loading: false }));
    }

    render() {
        const { pieChart, loading } = this.state;
        return (
            <Panel className="with-shadow height-md">
                <PanelBody style={{ position: 'relative' }}>
                    <CardTitle tag="h4" className="m-b-20">MTD Commission Data <i className="fas fa-info-circle text-primary f-s-14" id="comm_cardTitle"></i></CardTitle>
                    <UncontrolledTooltip target="comm_cardTitle">
                        Unrealized commissions vs realized commissions month-to-date
                    </UncontrolledTooltip>
                    <div className={classnames({ 'show': loading }, 'fade')} id="modal-loader"><div className="spinner"></div></div>
                    { pieChart.data && <div className="m-t-40">
                        <NVD3Chart datum={pieChart.data} type="pieChart" id="pieChart" height="300" options={pieChart.options} x="label" y="value" />
                    </div> }
                </PanelBody>
            </Panel>
        )
    }    
}
