import React from 'react';
import MapComponent from '../components/map/MapComponent';

class MapPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    handlePolygonComplete(polygon) {
        console.log('Poly: ', polygon);
        let points = [];
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            points.push(polygon.getPath().getAt(i).toUrlValue(6))
        }
        console.log(points);
    }

    render() {
        return (
            <div>
                <div style={{ width: '50vw', height: '50vh' }}>
                    <MapComponent handlePolygonComplete={this.handlePolygonComplete.bind(this)} />
                </div>
            </div>
        )
    }
}

export default MapPage;