import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { renderStateList } from '../../helpers/StateService';
import classnames from 'classnames';
import InputMask from 'react-input-mask';



function PersonModal({ isOpen, toggleModal, handleChange, person, roleOptions, handleSavePerson, hasError, sending }){
    const roleOptionPairs = roleOptions.length === 0 ? null : roleOptions.map((role, i) => <option key={i} value={role.Key}>{role.Value}</option>);
    return(
        <Modal size="lg" isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal(true)}>Edit Person</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className={classnames({ 'has-error': hasError && !person.FullName }, "form-group col-md-12")}>
                                <label>Full Name</label>
                                <input className="form-control" name="FullName" value={person.FullName} onChange={e => handleChange(e)} />
                            </div>
                            <div className={classnames({ 'has-error': hasError && !person.Email }, "form-group col-md-12")}>
                                <label>Email</label>
                                <input className="form-control" name="Email" value={person.Email} onChange={e => handleChange(e)} />
                            </div>
                            <div className={classnames({ 'has-error': hasError && !person.CellPhone }, "form-group col-md-6")}>
                                <label>Cell Phone</label>
                                <InputMask mask="(999) 999 9999" className="form-control" name="CellPhone" value={person.CellPhone} onChange={e => handleChange(e, true)}></InputMask>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Office Phone</label>
                                <InputMask mask="(999) 999 9999" className="form-control" name="OfficePhone" value={person.OfficePhone} onChange={e => handleChange(e, true)}></InputMask>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group col-md-12">
                            <label>Role In Transaction</label>
                            <select className="form-control" name="PeopleRoleId" value={person.PeopleRoleId} onChange={e => handleChange(e)} >
                                <option></option>
                                {roleOptionPairs}
                            </select>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Company Name</label>
                            <input className="form-control" name="CompanyName" value={person.CompanyName} onChange={e => handleChange(e)} />
                        </div>
                        <div className="form-group col-md-12">
                            <label>Address</label>
                            <input className="form-control" name="Address" value={person.Address} onChange={e => handleChange(e)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>City</label>
                            <input className="form-control" name="City" value={person.City} onChange={e => handleChange(e)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label>State</label>
                            <select className="form-control" name="StateCode" value={person.StateCode} onChange={e => handleChange(e)} >
                                <option></option>
                                {renderStateList()}
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Zip Code</label>
                            <input className="form-control" name="ZipCode" value={person.ZipCode} onChange={e => handleChange(e)} />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div></div>
                <button className="btn btn-primary" onClick={() => handleSavePerson()}>
                    { sending ? <div className="button-spinner"></div> : "Save" }
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default PersonModal;