import React from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';


export default function AdditionalContacts({ additional }) {
    return (
        <TabPane tabId={'4'} >
            { additional.map((contact, idx) => (
                <div key={idx} className="row">
                    { idx > 0 && <div className="m-b-15 m-t-15" style={{width: '100%', borderTop: '1px solid rgba(182,194,201,.6)'}}></div>}
                    <div className="col-md-6">
                        <div className="form-group">
                            <strong className="m-r-10">Name</strong>
                            {contact.FirstName} {contact.LastName}
                        </div>
                        <div className="form-group">
                            <strong className="m-r-10">Address</strong>
                            {contact.Address} {contact.UnitNumber} <br />
                            <strong className="m-r-10 invisible">Address</strong>{contact.City}, {contact.StateCode} {contact.ZipCode}
                        </div>
                        <div className="form-group">
                            <strong className="m-r-10">Cell Phone</strong>
                            {contact.CellPhone}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <strong className="m-r-10">Home Phone</strong>
                            {contact.HomePhone}
                        </div>
                        <div className="form-group">
                            <strong className="m-r-10">Work Phone</strong>
                            {contact.WorkPhone}
                        </div>
                        <div className="form-group">
                            <strong className="m-r-10">Email</strong>
                            {contact.Email}
                        </div>
                        {/* <div className="col-12"> */}
                        <Link to={`/contact/${contact.ContactId}`} className="btn btn-primary-outline btn-sm p-l-30 p-r-30">Edit Contact</Link>
                        {/* </div> */}
                    </div>
                </div>
            ))}
            
        </TabPane>
    )
}