import moment from 'moment';
import querySearch from 'stringquery';

export function phoneClean(phone){
    if (!phone) return '';

    phone = phone.replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace(' ', '')
        .replace('_', '')
        .replace('-', '')
        .replace('-', '');

    return phone;
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

export const volumeFormatter = (num) => {
    if (Math.abs(num) === 0) return 0;
    
    if (Math.abs(num) < 100000) {
        return (Math.abs(num) / 1000).toFixed(1) + 'K';
    }

    return Math.abs(num) > 999999 ? (Math.abs(num) / 1000000).toFixed(2) + 'M'
    : Math.floor(Math.abs(num) / 1000) + 'K';
}

export function getDateStampUtc(date){
    if (!date) return '';
    let dateStamp;

    date = moment.utc(date).local();
    let today = moment.utc().local();
    let yesterday = today.clone().subtract(1, 'days').startOf('day');
    
    if (date.isSame(today, "day")){
        dateStamp = date.format('hh:mm a');
    } else if (date.isSame(yesterday, "day")){
        dateStamp = 'Yesterday ' + date.format('hh:mm a');
    } else {
        dateStamp = date.format('M/D hh:mm a');
    }

    return dateStamp;
}

// export function getDateStamp(date){
//     if (!date) return '';
//     let dateStamp;

//     date = moment(date)
//     let today = moment();
//     let yesterday = today.clone().subtract(1, 'days').startOf('day');
    
//     if (date.isSame(today, "day")){
//         dateStamp = moment(date).format('hh:mm a');
//     } else if (date.isSame(yesterday, "day")){
//         dateStamp = 'Yesterday ' + moment(date).format('hh:mm a');
//     } else {
//         dateStamp = moment(date).format('M/D hh:mm a');
//     }

//     return dateStamp;
// }

export function displayDateTime(date){
    if (!date) return '';

    return moment(date).format('MM/D/YYYY, h:mm a');
}

export function displayDateTimeUtc(date) {
    if (!date) return '';

    return moment.utc(date).local().format('MM/D/YYYY, h:mm a');
}

export function displayDate(date) {
    if (!date) return '';

    return moment(date).format('MM/D/YYYY');
}

export function displayDateUtc(date) {
    if (!date) return '';

    return moment.utc(date).local().format('MM/D/YYYY');
}

/// ex: Thu, Jul 23rd 2020, 11:48 pm
export function displayFormalDateTime(date, includeDayOfWeek = false){
    if (!date) return '';

    let dayOfWeek = '';
    if (includeDayOfWeek) dayOfWeek = 'ddd, ';

    return moment(date).format(`${dayOfWeek}MMM Do YYYY, h:mm a`);
}

/// displays a UTC date from the server in local time
/// ex: Thu, Jul 23rd 2020, 11:48 pm
export function displayFormalDateTimeUtc(date, includeDayOfWeek = false) {
    if (!date) return '';

    let dayOfWeek = '';
    if (includeDayOfWeek) dayOfWeek = 'ddd, ';

    return moment.utc(date).local().format(`${dayOfWeek}MMM Do YYYY, h:mm a`);
}

export function displayDateWorded(date, includeDayOfWeek = false){
    if (!date) return '';

    let dayOfWeek = '';
    if (includeDayOfWeek) dayOfWeek = 'ddd, ';

    return moment(date).format(`${dayOfWeek}MMM Do YYYY`);
}

export function parseQueryString(queryString) {
    let obj = querySearch(decodeURI(queryString));
    console.log('obj: ', obj);
    for (const key in obj) {
        obj[key] = JSON.parse(obj[key]);
    }

    return obj;
}
