import React from 'react'
import { Card, CardImg, CardImgOverlay, CardText, CardBody, CardTitle, CardFooter } from 'reactstrap';
import house from '../../assets/NoImageHouse.jpg';
import './TransactionCard.css';
import { displayDateTimeUtc, displayDateUtc } from '../../helpers/Functions';

function TransactionCard(props){
    const { transaction } = props;
    return(
        <div>
            <Card className="m-10 hover-shadow" style={{ width: '300px', border: '2px solid #f2f3f4' }}>
                <CardImg src={transaction.PropertyImage ? transaction.PropertyImage : house} style={{ width: '100%', height: '195px', objectFit: 'cover' }}/>
                <CardImgOverlay>
                    {/* <CardHeader className=" text-white">Details Go Here</CardHeader> */}
                    <CardTitle tag="h5" className="text-white bg-black-transparent-9 p-5">{transaction.Status} <span className="pull-right">#{transaction.TransactionId}</span></CardTitle>
                </CardImgOverlay>
                <CardBody>
                    <CardText className="m-b-5">{transaction.Type}</CardText>
                    <CardTitle className="m-b-5" tag="h4" style={{ maxWidth: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{transaction.Address}</CardTitle>
                    <CardText>{transaction.City}, {transaction.StateCode} {transaction.ZipCode}</CardText>
                    <h6>Created {displayDateUtc(transaction.DateCreated)}</h6>
                    <div className="p-t-10 m-t-15" style={{borderTop: '1px solid #d9dfe2'}}>
                        <div className="row text-center">
                            <div className="col-6">
                                <h6>Contract Date</h6>
                            </div>
                            <div className="col-6">
                                <h6>Closing Date</h6>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-6">
                                <CardText>{transaction.ContractDateString || 'N/A'}</CardText>
                            </div>
                            <div className="col-6">
                                <CardText>{transaction.Status === 'Closed' ? (transaction.DateClosedString || 'N/A') : (transaction.ClosingDateString || 'N/A')}</CardText>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardFooter className="bg-black-darker text-white"><strong>Assigned Agent:</strong> {transaction.AgentName}</CardFooter>
            </Card>    
        </div>    
    )
}

export default TransactionCard;