import React from 'react';
import { getData, postData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';

class UserPermissions extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            permissions: [],
            updatingPerm: false,
            permToUpdate: ''
        }
    }

    componentDidMount(){
        if (this.props.userId){
            this.getPermissions(this.props.userId);
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.userId !== this.props.userId){
            this.getPermissions(this.props.userId);
        }
    }

    getPermissions(id){
        getData(`api/permission/userpermissiondata/${id}`).then(response => {
            this.setState({ permissions: response.data });
        });
    }

    handlePermissionChange(permissionCode){
        this.setState({ updatingPerm: true, permToUpdate: permissionCode });
        let permissionCopy = this.state.permissions.slice();
        let permIndex = permissionCopy.findIndex(perm => perm.PermissionCode === permissionCode);
        
        postData(`api/permission/userpermissiondata/${this.props.userId}`, permissionCopy[permIndex], this).then(() => {
            if (permIndex !== -1){
                permissionCopy[permIndex].HasPermission = !this.state.permissions[permIndex].HasPermission;
            };
            this.setState({ permissions: permissionCopy, updatingPerm: false, permToUpdate: '' });
        }).fail(() => this.setState({ updatingPerm: false, permToUpdate: '' }));
    }

    renderTableBody(){
        return this.state.permissions.map((perm, i) => {
            return (
                <tr key={i}>
                    <td className="with-checkbox">
                        { this.state.updatingPerm && this.state.permToUpdate === perm.PermissionCode ?
                            <div className="button-spinner"></div>
                            :
                            <div className="row">
                                <div style={{ margin: 'auto' }}>
                                    <div className="checkbox checkbox-css">
                                        <input type="checkbox" checked={perm.HasPermission} id={`permission_checkbox_${i}`} onChange={() => this.handlePermissionChange(perm.PermissionCode)} />
                                        <label for={`permission_checkbox_${i}`}>&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                            
                        }
                    
                    </td>
                    <td>{perm.Name}</td>
                    <td>{perm.Description}</td>
                </tr>
            );
        });
    }

    render(){
        const { permissions } = this.state;
        return(
            <div>
                <h5 className="m-b-25">User Permissions</h5>
                <table className="table thick-rows table-bordered table-striped" id="permission-table">
                    <thead>
                        <tr>
                            <td style={{ width: '75px'}}>Add/Remove</td>
                            <td>Permission</td>
                            <td>Description</td>
                        </tr>
                    </thead>
                    <tbody>
                        { permissions.length != 0 && this.renderTableBody() }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default UserPermissions;