import React from 'react';
import { Op } from '../../helpers/OperationTypes';

export default function SingleSelect({ filter, handleFilterOperationChange, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10 m-b-3" 
                name={filter.Field} 
                value={filters[filter.Field].Operation} 
                onChange={handleFilterOperationChange} 
            >
                <option></option>
                <option value={Op.equals}>Is</option>
                {/* <option value={Op.notEqualTo}>Is Not</option> */}
            </select>
            <select 
                className="form-control col-md-4" 
                name={filter.Field}
                value={filters[filter.Field].Value}
                onChange={handleFilterValueChange}
            >
                <option></option>
                {filter.Options.map(o => <option key={o.Key} value={o.Key}>{o.Value}</option>)}
            </select>
        </div>
    )
}