import React from 'react';
import { Op, DateOp } from '../../helpers/OperationTypes';
import DateTime from 'react-datetime';

export default function DateSelect({ filter, handleFilterOperationChange, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10" 
                name={filter.Field}
                value={filters[filter.Field].Operation} 
                onChange={handleFilterOperationChange}
            >
                <option></option>
                <option value={Op.equals}>Is On</option>
                <option value={Op.lessThanOrEqual}>Is Before</option>
                <option value={Op.greaterThanOrEqual}>Is After</option>
            </select>
            { 
                (filters[filter.Field].Operation !== DateOp.lastWeek && filters[filter.Field].Operation !== DateOp.last30) 
                && 
                <DateTime 
                    inputProps={{ className:"form-control col-md-12" }} 
                    closeOnSelect={true} 
                    timeFormat={false}  
                    value={filters[filter.Field].Value} 
                    onChange={(date) => handleFilterValueChange({ target: { name: filter.Field, value: date } })}/> 
            }
        </div>
    )
}