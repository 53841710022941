import React from 'react';
import { ListGroup } from 'reactstrap';
import ActivityItem from './ActivityItem';
import { getActivityDetails } from './activity-helper';

export default function AllActivity({ activity, handleEditClick }){
    return (
        <div className="m-b-25">
            <ListGroup className="m-t-10">
                {activity.map((a, i) => {
                    let activity = getActivityDetails(a.ActivityTypeId);
                    const isEditable = a.ActivityTypeId === 1;
                    
                    return (
                        <ActivityItem 
                            item={a} 
                            key={i} 
                            iconCss={activity.icon} 
                            title={activity.title} 
                            textColor={activity.textColor} 
                            isEditable={isEditable}
                            handleEditClick={handleEditClick}
                        />
                    )
                })}
            </ListGroup>
        </div>
    )
}