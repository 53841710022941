import React from 'react';
import { GoogleLogout, GoogleLogin } from 'react-google-login';
import { postData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';
import classnames from 'classnames';
import gmailIcon from '../../assets/Gmail_Icon.png';
import outlookIcon from '../../assets/outlookLogo.png'

const BackButton = (props) => {
    return <button className="btn btn-lime-outline m-b-15" onClick={() => props.changeStep('intro')}>Go Back</button>
}

class EmailSetup extends React.Component{
    static contextType = PageSettings;
    constructor(props) {
        super(props);
        this.state = {
            step: 'intro',
            email: '',
            password: '',
            sending: false,
            showLogout: false,
        }

        this.googleResponse = this.googleResponse.bind(this);
        this.googleFailure = this.googleFailure.bind(this);
        this.googleLogout = this.googleLogout.bind(this);
        this.changeStep = this.changeStep.bind(this);
    }

    googleResponse(response) {
        console.log(response);
        let data = {
            Code: response.code,
        }

        postData('api/emailconfig/credentials/gmail', data, this).then(() => {
            // this.setState({ setupComplete: true });
            this.props.toggleModal(true);
        }).fail(err => {
            this.setState({ showLogout: true });
        })
    }

    googleFailure(response) {
        console.log('Failure!', response);
        if (response.details === 'Cookies are not enabled in current environment.' || response.error === 'Cookies are not enabled in current environment.') {
            alert('Email login requires cookies. Please enable cookies for this site, refresh the page, then try again.');
            this.props.toggleModal();
            return;
        }

        alert('There was an error retrieving your google access, please logout, then login again.');
        this.setState({ showLogout: true });
    }

    googleLogout(response) {
        console.log('Logged out successfully');
        this.setState({ showLogout: false });
    }

    changeStep(step) {
        this.setState({ step });
    }

    handleChange(e) {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const data = {
            Email: this.state.email,
            Password: this.state.password,
        }
        this.setState({ sending: true });
        postData('api/emailconfig/office365', data, this).then(() => {
            this.setState({ sending: false });
            this.props.toggleModal(true);
        }).fail(() => this.setState({ sending: false }));
    }

    render() {
        const { step, email, password, sending, showLogout } = this.state;
        return (
            <div>
                <div className={classnames({ 'hide': step !== 'intro' })}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4 className="widget-todolist-header-title">Select Email Provider</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4 text-center p-15 cursor-pointer" onClick={() => this.changeStep('gmail')}>
                            <img src={gmailIcon} alt="Gmail Icon" style={{ width: '80%' }} />
                            <div className="f-s-16">Gmail</div>
                        </div>
                        <div className="col-md-4 text-center p-15 cursor-pointer" onClick={() => this.changeStep('office365')}>
                            <img src={outlookIcon} alt="Gmail Icon" style={{ width: '80%' }} />
                            <div className="f-s-16 m-t-10">Office 365</div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className={classnames({ 'hide': step !== 'gmail' })}>
                    <BackButton changeStep={this.changeStep} />
                    <div className="row m-b-20">
                        <div className="col-md-12 text-center">
                            <h4 className="widget-todolist-header-title">Connect Your Gmail</h4>
                        </div>
                    </div>
                    <div className="row m-b-40">
                        <div className="col-md-8 offset-md-2 text-center">
                            { !showLogout && <GoogleLogin
                                clientId="253530371574-qv7rk2ahjuku0eml18fto11e1dl97h31.apps.googleusercontent.com"
                                buttonText="Google Login"
                                onSuccess={this.googleResponse}
                                onFailure={this.googleFailure}
                                accessType="offline"
                                offline={true}
                                prompt="consent"
                                responseType="code"
                                scope="https://mail.google.com/ email"
                                cookiePolicy={'single_host_origin'}
                            /> }

                            { showLogout && <GoogleLogout
                                clientId="253530371574-qv7rk2ahjuku0eml18fto11e1dl97h31.apps.googleusercontent.com"
                                buttonText="Logout"
                                onLogoutSuccess={this.googleLogout}
                                onFailure={this.googleFailure}
                                id="logout-btn"
                            >
                            </GoogleLogout> }
                        </div>
                    </div>
                </div>
                <div className={classnames({ 'hide': step !== 'office365' })}>
                    <BackButton changeStep={this.changeStep} />
                    <div className="row m-b-20">
                        <div className="col-md-12 text-center">
                            <h4 className="widget-todolist-header-title">Connect Your Office365 Email</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="col-md-8 offset-md-2" onSubmit={e => this.handleSubmit(e)}>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input name="email" className="form-control" required type="email" value={email} onChange={e => this.handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input name="password" className="form-control" required type="password" value={password} onChange={e => this.handleChange(e)} />
                            </div>
                            <div className="form-group text-center">
                                <button className="btn btn-lime" type="submit">
                                    { sending ? <div className="button-spinner"></div>
                                    :
                                    "Connect Email"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailSetup;

