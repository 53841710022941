import React from 'react';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import { getData, putData, deleteData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const getInitialState = () => {
    return {
        structureType: 'Percent',
        structureDollarAmount: '',
        structurePercentAmount: '',
        splitType: 'Percent',
        splitDollarAmount: '',
        splitPercentAmount: '',
        adjustedGross: '',
        concessions: '',
        salesPrice: '',
        hasError: false,
        sending: false,
        hasChanges: false,
        gciType: 'Percent',
        preSplitAdjustments: [],
        fetching: false,
        adjustment: {},
        hasAdjErrors: false,
        showAdjustmentModal: false,
        financialsId: 0,
    }
}

const entryTypes = {
    deduction: 0,
    credit: 1,
}

const amountTypes = {
    percent: 0,
    dollar: 1,
}

export default class CommissionDetails extends React.Component{
    static contextType = PageSettings;
    static defaultProps = {
        refreshCommissionReport: () => {},
    }

    constructor(props){
        super(props);
        this.state = getInitialState();

        this.handleAdjustmentChange = this.handleAdjustmentChange.bind(this);
    }

    componentDidMount() {
        if (this.props.transactionId) {
            this.getTransactionFinancials();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactionId !== this.props.transactionId)
        {
            this.getTransactionFinancials();
        }
    }

    getTransactionFinancials() {
        this.setState({ fetching: true });
        getData(`api/commissions/financials/${this.props.transactionId}`).then(response => {
            const { data } = response;
            this.setState({
                financialsId: data.FinancialsId,
                structureDollarAmount: data.GrossCommission,
                structurePercentAmount: data.CommSplitPerc,
                splitPercentAmount: data.BrokerSplitPerc,
                salesPrice: data.CalcPrice,
                concessions: data.Concessions,
                gciType: data.GCIType,
                structureType: data.GCIType,
                preSplitAdjustments: data.PreSplitAdjustments,
                fetching: false,
            }, () => {
                const adjustedGross = data.GrossCommission + this.adjustmentsTotal();
                const splitDollarAmt = (data.BrokerSplitPerc / 100) * adjustedGross;
                this.setState({ adjustedGross, splitDollarAmount: splitDollarAmt.toFixed(2) });
            });
        });
    }

    handleSave(maintainModal = false) {
        if (!this.state.structurePercentAmount) {
            this.setState({ hasError: true });
            this.context.addNotification("Missing Info", "Structure percent or dollar amount is required.", "danger", "top", "top-right");
            return;
        }

        this.setState({ sending: true });
        const data = {
            Concessions: this.state.concessions,
            BrokerSplitPerc: this.state.splitPercentAmount,
            CommSplitPerc: this.state.structurePercentAmount,
            GrossCommission: this.state.structureDollarAmount,
            GCIType: this.state.gciType,
            PreSplitAdjustments: this.state.preSplitAdjustments,
        }

        putData(`api/commissions/financials/${this.props.transactionId}`, data, this).then(response => {
            this.setState({ sending: false, hasError: false });
            if (this.props.toggleModal && !maintainModal) {
                this.setState(getInitialState());
                this.props.toggleModal();
            } 

            this.getTransactionFinancials();
            this.props.refreshCommissionReport();
        });
    }

    handlePercentChange(e, item) {
        let { value } = e.target;
        const p = e.target.value / 100;
        let calcAmount = this.state.salesPrice;

        if (item === 'split') {
            calcAmount = this.state.adjustedGross;
        }
        const dollar = (p*calcAmount).toFixed(2);
        
        const dName = item.concat('DollarAmount');
        const pName = item.concat('PercentAmount');

        this.setState({ [dName]: dollar, [pName]: value, gciType: item === 'structure' ? 'Percent' : this.state.gciType, hasChanges: true });
    }

    handleDollarChange(e, item) {
        let { value } = e.target;
        value = value.replace(',', '');
        let calcAmount = this.state.salesPrice;

        if (item === 'split') {
            calcAmount = this.state.adjustedGross;
        }

        const p = (value / calcAmount * 100);

        const dName = item.concat('DollarAmount');
        const pName = item.concat('PercentAmount');

        this.setState({ [dName]: value, [pName]: p, gciType: item === 'structure' ? 'Dollar' : this.state.gciType, hasChanges: true });
    }

    handleChange(e){
        const { name, value } = e.target;
        let val = value.replaceAll(',', '');
        this.setState({ [name]: val, hasChanges: true });
    }

    toggleType(name, type) {
        this.setState({ [name]: type });
    }

    toggleAdjustmentModal() {
        if (Object.keys(this.state.adjustment).length && this.state.showAdjustmentModal) {
            this.setState({ adjustment: {} });
        }
        
        this.setState({ showAdjustmentModal: !this.state.showAdjustmentModal });
    }

    handleAdjustmentChange(e) {
        const { name, value } = e.target;
        let val = value.replaceAll(',', '');
        const adjustment = {...this.state.adjustment, [name]: val };

        this.setState({ adjustment });
    }

    handleAdjustmentSave() {
        const { adjustment, preSplitAdjustments } = this.state;
        if (
            !adjustment.Description
            || adjustment.EntryType === '' || adjustment.EntryType === null
            || adjustment.AmountType === '' || adjustment.AmountType === null
            || !adjustment.Amount
        ) {
            this.setState({ hasAdjErrors: true });
            return;
        }
        
        const preSplits = [...preSplitAdjustments];
        const existingAdjIdx = preSplits.findIndex(x => x.Description === adjustment.Description);
        if (existingAdjIdx >= 0) {
            preSplits.splice(existingAdjIdx, 1);
        }

        const adj = {
            Description: adjustment.Description,
            AmountType: Number(adjustment.AmountType),
            Amount: Number(adjustment.Amount),
            EntryType: Number(adjustment.EntryType),
            Id: adjustment.Id,
        }

        preSplits.push(adj);
        this.setState({ preSplitAdjustments: preSplits, adjustment: {}, showAdjustmentModal: false, hasChanges: true }, () => {
            if (this.state.financialsId) {
                this.handleSave(true);
            }
        } );
    }

    calculateAdjustment(adj, salesPrice) {
        let amount = adj.Amount;
        if (adj.AmountType === amountTypes.percent) {
            amount = salesPrice * (adj.Amount / 100);
        }

        let prefix = '$';
        if (adj.EntryType === entryTypes.deduction) {
            prefix = '-$';
        }

        return <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={prefix} />
    }

    handleEditClick(adj) {
        this.setState({ adjustment: adj }, this.toggleAdjustmentModal);
    }

    handleDeleteClick(adjustment, adjIdx) {
        const preSplits = [...this.state.preSplitAdjustments];
        preSplits.splice(adjIdx, 1);

        this.setState({ preSplitAdjustments: preSplits });

        if (adjustment.Id) {
            deleteData(`api/commissions/adjustments/${adjustment.Id}`).then(() => {
                this.getTransactionFinancials();
            });
        }
    }

    adjustmentsTotal() {
        const { structureDollarAmount, preSplitAdjustments } = this.state;
        
        return preSplitAdjustments.reduce((total, adj) => {
            let amount = adj.Amount;
            if (adj.AmountType === amountTypes.percent) {
                amount = structureDollarAmount * (adj.Amount / 100);
            }
            
            if (adj.EntryType === entryTypes.deduction) {
               amount = amount * -1;
            }

            return total + amount;
        }, 0)
    }

    calculateAgentCommission(gross, concessions, brokerSplit) {
        const adjustmentTotal = this.adjustmentsTotal();

        return Number(gross) + adjustmentTotal - Number(concessions) - Number(brokerSplit)
    }

    render() {
        const { structureType, structureDollarAmount, structurePercentAmount, fetching, adjustment, hasAdjErrors, showAdjustmentModal, adjustedGross,
            splitType, splitDollarAmount, splitPercentAmount, salesPrice, concessions, hasError, sending, hasChanges, preSplitAdjustments } = this.state;
        return (
            <div className="row">
                <div className="col-md-4 p-15">
                    <h5 className="m-b-40">Gross Commission Structure: </h5>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label">Calculation Type</label>
                        <div className="col-md-6">
                            <div className="btn-group pull-right">
                                <button className={classnames({ 'active': structureType === 'Percent' }, "btn btn-white")} onClick={() => this.toggleType('structureType', 'Percent')}>
                                        <i className="fas fa-percent"></i>
                                </button>
                                <button className={classnames({ 'active': structureType === 'Dollar' }, "btn btn-white")} onClick={() => this.toggleType('structureType', 'Dollar')}>
                                    <i className="fas fa-dollar-sign"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label">{structureType} Amount</label>
                        { structureType === 'Dollar' ?
                            <div className="col-md-6 input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <NumberFormat 
                                    name="structureDollarAmount"
                                    value={structureDollarAmount}
                                    thousandSeparator={true} 
                                    className={classnames({ 'has-error': hasError && !structurePercentAmount }, "form-control" )}
                                    onChange={(e) => this.handleDollarChange(e, 'structure')} 
                                />
                            </div>
                            :
                            <div className="col-md-6 input-group">
                                <input 
                                    name="structurePercentAmount"
                                    value={structurePercentAmount}
                                    className={classnames({ 'has-error': hasError && !structurePercentAmount }, "form-control" )}
                                    onChange={(e) => this.handlePercentChange(e, 'structure')} 
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                        }
                    </div>
                    { structureType === 'Percent' && 
                        <div className="row font-italic">
                            <div className="col-md-6">Gross Commission</div>
                            <div className="col-md-6 text-right">
                                <NumberFormat value={structureDollarAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                        </div>
                    }
                    <div className="form-group row m-t-15 m-b-15">
                        <label className="col-md-6 col-form-label">Pre-Split Adjustments</label>
                        <div className="col-md-6">
                            <button className="btn btn-white pull-right" onClick={() => this.toggleAdjustmentModal()}>
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    { preSplitAdjustments.map((ad, idx) => {
                        return (
                            <div key={idx} className="row font-italic">
                                <div className="col-md-6">{ad.Description}</div>
                                <div className="col-md-6 text-right">
                                    { this.calculateAdjustment(ad, structureDollarAmount) }
                                    <i className="far fa-edit m-l-10 cursor-pointer" onClick={() => this.handleEditClick(ad)}></i>
                                    <i className="far fa-trash-alt m-l-5 cursor-pointer" onClick={() => this.handleDeleteClick(ad, idx)}></i>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="col-md-4 p-15 b-r-1 b-l-1">
                    <h5 className="m-b-40">Broker Split: </h5>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label">Calculation Type</label>
                        <div className="col-md-6">
                            <div className="btn-group pull-right">
                                <button className={classnames({ 'active': splitType === 'Percent' }, "btn btn-white")} onClick={() => this.toggleType('splitType', 'Percent')}>
                                        <i className="fas fa-percent"></i>
                                </button>
                                <button className={classnames({ 'active': splitType === 'Dollar' }, "btn btn-white")} onClick={() => this.toggleType('splitType', 'Dollar')}>
                                    <i className="fas fa-dollar-sign"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label">{splitType} Amount</label>
                        { splitType === 'Dollar' ?
                            <div className="col-md-6 input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <NumberFormat 
                                    name="splitDollarAmount"
                                    value={splitDollarAmount}
                                    thousandSeparator={true} 
                                    className="form-control" 
                                    onChange={(e) => this.handleDollarChange(e, 'split')} 
                                />
                            </div>
                            :
                            <div className="col-md-6 input-group">
                                <input 
                                    name="splitPercentAmount"
                                    value={splitPercentAmount}
                                    className="form-control" 
                                    onChange={(e) => this.handlePercentChange(e, 'split')} 
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-4 p-15">
                    <h5 className="m-b-40">Commission Breakdown: { fetching && <i className="fas fa-spinner fa-pulse text-primary"></i>} </h5>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 text-right">Sales Price</label>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                value={salesPrice}
                                thousandSeparator={true} 
                                displayType={'text'}
                                prefix={'$'}
                            />
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label text-right">{ structureType === 'Percent' ? 'X' : 'Gross Commission' }</label>
                        { structureType === 'Percent' ?
                            <div className="col-md-6 input-group">
                                <input 
                                    name="splitPercentAmount"
                                    value={structurePercentAmount}
                                    className="form-control" 
                                    disabled
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                        :
                            <div className="col-md-6 d-flex" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                <NumberFormat value={structureDollarAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                        }
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 text-right">GCI</label>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                value={structureDollarAmount}
                                thousandSeparator={true} 
                                displayType={'text'}
                                prefix={'$'}
                            />
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 text-right">Pre-Split Adjustments</label>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                value={this.adjustmentsTotal()}
                                thousandSeparator={true} 
                                displayType={'text'}
                                prefix={'$'}
                            />
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 text-right">Adjusted Commission</label>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                value={adjustedGross}
                                thousandSeparator={true} 
                                displayType={'text'}
                                prefix={'$'}
                            />
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label text-right">(Concessions)</label>
                        <div className="col-md-6 input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <NumberFormat 
                                name="concessions"
                                thousandSeparator={true}
                                value={concessions}
                                className="form-control" 
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-md-6 col-form-label text-right">(Broker Split)</label>
                        <div className="col-md-6 input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input 
                                name="splitPercentAmount"
                                value={splitDollarAmount}
                                className="form-control" 
                                disabled 
                            />
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <h5 className="col-md-6 text-right">Net Commission</h5>
                        <div className="col-md-6 text-right">
                            <h5>${ this.calculateAgentCommission(structureDollarAmount, concessions, splitDollarAmount) }</h5>
                        </div>
                    </div>
                </div>
                { hasChanges && 
                <div className="col-md-12 m-t-25">
                    <button className="btn btn-primary pull-right m-l-10 m-r-5" onClick={() => this.handleSave()}>
                        { sending ? 
                            <div className="button-spinner"></div>
                            :
                            "Save Commission Details"
                        }
                    </button>
                    { this.props.toggleModal && 
                    <button className="btn btn-primary-outline pull-right" onClick={() => this.props.toggleModal()}>Cancel</button>
                    }
                </div>
                }
                <Modal isOpen={showAdjustmentModal}>
                    <ModalHeader toggle={() => this.toggleAdjustmentModal()}>Commission Adjustment</ModalHeader>
                    <ModalBody>
                        <div className={classnames({ 'has-error': hasAdjErrors && !adjustment.Description }, "form-group m-b-15")}>
                            <label className="form-label">Description</label>
                            <input
                                className="form-control" 
                                name="Description" 
                                value={adjustment.Description}
                                onChange={this.handleAdjustmentChange}
                            />
                        </div>
                        <div className={classnames({ 'has-error': hasAdjErrors && !adjustment.EntryType }, "form-group m-b-15")}>
                            <label className="form-label">Adjustment Type</label>
                            <select
                                className="form-control" 
                                name="EntryType" 
                                value={adjustment.EntryType}
                                onChange={this.handleAdjustmentChange}
                            >
                                <option value="">Select One</option>
                                <option value={entryTypes.credit}>Credit</option>
                                <option value={entryTypes.deduction}>Deduction</option>
                            </select>
                        </div>
                        <div className={classnames({ 'has-error': hasAdjErrors && !adjustment.AmountType }, "form-group m-b-15")}>
                            <label className="form-label">Calculation Type</label>
                            <select
                                className="form-control" 
                                name="AmountType" 
                                value={adjustment.AmountType}
                                onChange={this.handleAdjustmentChange}
                            >
                                <option value="">Select One</option>
                                <option value={amountTypes.percent}>Percent</option>
                                <option value={amountTypes.dollar}>Dollar</option>
                            </select>
                        </div>
                        <div className={classnames({ 'has-error': hasAdjErrors && !adjustment.Amount }, "form-group m-b-15")}>
                            <label className="form-label">Amount</label>
                            <NumberFormat 
                                className="form-control" 
                                name="Amount"
                                value={adjustment.Amount}
                                thousandSeparator={true} 
                                onChange={this.handleAdjustmentChange} 
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary-outline" onClick={() => this.toggleAdjustmentModal()}>Cancel</button>
                        <button className="btn btn-primary" onClick={() => this.handleAdjustmentSave()}>Save</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}