import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WorkflowModalView from './WorkflowModalView';


function WorkflowModal({contactId, isOpen, toggleModal}){
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('workflow')}>Manage Contact Workflows</ModalHeader>
            <ModalBody>
                <WorkflowModalView toggleModal={toggleModal} contactId={contactId} />
            </ModalBody>
        </Modal>
    )
}

export default WorkflowModal;