import React from 'react';
import classnames from 'classnames';
import { getData } from '../helpers/AxiosService';
import EmailTemplateModal from '../components/email/EmailTemplateModal';
import { Panel } from '../components/panel/panel.jsx';


class TextTemplates extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            templates: [],
            filteredTemplates: [],
            selectedTemplate: {},
            modalOpen: false,
            searchTerm: '',
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.fetchTemplates = this.fetchTemplates.bind(this);
    }

    componentDidMount(){
        this.fetchTemplates();
    }

    fetchTemplates(){
        getData('api/template/text-templates').then(response => {
            this.setState({ templates: response.data, filteredTemplates: response.data });
        });
    }

    handleEditClick(templateIndex){
        let selectedTemplate = this.state.templates[templateIndex];
        this.setState({ selectedTemplate }, this.toggleModal)
    }

    toggleModal(clearSelected){
        this.setState({ modalOpen: !this.state.modalOpen });
        if (clearSelected){
            this.setState({ selectedTemplate: {} });
        }
    }

    handleSearch(e) {
        const { value } = e.target;
        this.setState({ searchTerm: value }, this.handleFilter);
    }

    handleFilter() {
        const { searchTerm, templates } = this.state;
        
        if (!searchTerm) {
            this.setState({ filteredTemplates: templates.slice() });
            return;
        }

        const filteredTemplates = templates.slice().filter((t) => {
    
            const term = new RegExp(`${searchTerm.toLowerCase()}*`);
            return (
                term.test(t.Name.toLowerCase())
            )
        });

        this.setState({ filteredTemplates });
    }

    renderRows(){
        return this.state.filteredTemplates.map((t, i) => (
            <tr key={i}>
                <td>{t.Name}</td>
                <td>{t.CreatedByName}</td>
                <td>{t.SharedWithGroup ? "Group" : t.IsSystem ? "Default" : "Personal"}</td>
                <td>{!t.IsSystem && <button className="btn btn-white m-r-5" onClick={() => this.handleEditClick(i)}><i className="far fa-edit text-primary"></i> Edit</button>}</td>
            </tr>
        ));
    }

    render(){
        const { loading, searchTerm } = this.state;
        return(
            <div>
            <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <button className="btn btn-lime p-l-40 p-r-40 pull-right" onClick={() => this.toggleModal()}>New Text Template</button>
                <h1 className="page-header">Text Templates <small className="f-w-100">Manage your text templates</small></h1>
                <Panel className="bg-white m-t-20 p-15">
                    <div className="row">
                        <p className="col-md-6">
                            Use text templates for common messages that you'll want to send to your contacts. 
                            Templates can be used in automated workflows as well as one off text messages you send.
                            We have provided some great templates for you to go along with the ones that you create yourself.
                        </p>
                    </div>
                </Panel>
                <div className="row">
                    <div className="col-md-4 offset-md-8 pull-right">
                        <div className="action-container">
                            <div className="search-bar">
                                <div style={{ height: '100%' }}>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <input 
                                        className="search-input with-thin-shadow"
                                        placeholder="Search by template name"
                                        value={searchTerm}
                                        onChange={(e) => this.handleSearch(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive with-thin-shadow">
                    <table className="table table-bordered table-striped bg-white m-b-0">
                        <thead>
                            <tr>
                                <th>Template Name</th>
                                <th>Created By</th>
                                <th>Accessibility</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.templates.length !== 0 && this.renderRows()}
                            {this.state.templates.length === 0 && <tr><td colSpan="5" className="text-center">No templates to display</td></tr>}
                        </tbody>
                    </table>
                </div>
                <EmailTemplateModal 
                    isOpen={this.state.modalOpen}
                    toggleModal={this.toggleModal}
                    selectedTemplate={this.state.selectedTemplate}
                    fetchTemplates={this.fetchTemplates}
                    isText={true}
                />
            </div>
        )
    }
}

export default TextTemplates;