import React from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../components/panel/panel.jsx';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import UserProfile from '../components/user/UserProfile';
import UserPermissions from '../components/user/UserPermissions';


class User extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            activeTab: '1',
            permissions: [],
        }		
        
        this.toggleTab = this.toggleTab.bind(this);
        this.stopLoading = this.stopLoading.bind(this);
        this.startLoading = this.startLoading.bind(this);
    }

    toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
    }
    
    stopLoading(){
        this.setState({ loading: false });
    }

    startLoading(){
        this.setState({ loading: true });
    }

    render(){
        const { loading } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item"><Link to="/admin/users">Users</Link></li>
					<li className="breadcrumb-item active">User</li>
				</ol>
                <h1 className="page-header">Add / Edit Users <small className="f-w-100">Create and edit application users</small></h1>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}>
                            Profile
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTab('2'); }}>
                            Permissions
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <UserProfile stopLoading={this.stopLoading} userId={this.props.match.params.userId} />
                    </TabPane>
                    <TabPane tabId="2">
                        <UserPermissions userId={this.props.match.params.userId} />
                    </TabPane>
                </TabContent>
            </div>
        )
       
    }
}

export default User;