import React from 'react';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CommissionDetails from './CommissionDetails';
import AgentOptions from '../shared/AgentOptions';

const status = {
    Active: 1,
    UnderContract: 2,
    Closed: 3,
    Archived: 4,
    Cancelled: 5,
}


const TransactionSummary = React.forwardRef(({ t, handleChange, handleDateChange, handleStatusChange, toggleModal, updateTransaction, handleDeleteTransaction, sourceOptions }, ref) => {
    let isClosed = t.StatusId === status.Closed;
    const sources = sourceOptions.map(s => <option key={s.SourceId} value={s.SourceId}>{s.Name}</option>)
    const salesPriceDisplay = t.SalesPrice ? `$${t.SalesPrice}` : 'N/A';
    const listPriceDisplay = t.ListingPrice ? `$${t.ListingPrice}` : 'N/A';
    return(
        <div>
            <div className="text-right m-b-35">
                <div className="btn-group">
                    { t.StatusId !== 3 &&<button className="btn btn-sm btn-white m-b-5" onClick={() => toggleModal('closeTransactionModal')}><i className="fas fa-file-signature text-primary"></i> Close Transaction</button> }
                    { t.StatusId === 3 && <button className="btn btn-sm btn-white m-b-5" onClick={() => handleStatusChange('Active', status.Active)}><i className="far fa-folder-open text-primary"></i> Re-open Transaction</button> }
                    { t.StatusId !== 3 &&<UncontrolledButtonDropdown className="p-l-0 p-r-0">
                        <DropdownToggle className="btn btn-sm btn-white m-b-5" caret><i className="fas fa-flag text-primary"></i> {t.Status}</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => handleStatusChange('Active', status.Active)}>Active</DropdownItem>
                            <DropdownItem onClick={() => handleStatusChange('UnderContract', status.UnderContract)}>Under Contract</DropdownItem>
                            <DropdownItem onClick={() => handleStatusChange('Cancelled', status.Cancelled)}>Cancelled</DropdownItem>
                            <DropdownItem onClick={() => handleStatusChange('Archived', status.Archived)}>Archived</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown> }
                    <button className="btn btn-sm btn-white m-b-5" onClick={handleDeleteTransaction}><i className="fas fa-trash text-primary"></i> Delete Transaction</button>
                    <button className="btn btn-sm btn-white m-b-5" onClick={() => toggleModal('syncMlsModal')}><i className="fas fa-sync text-primary"></i> Sync With MLS</button>
                </div>
            </div>
            <div className="m-b-25 m-t-25 text-center">
                <div className="row" style={{ float: 'none', margin: 'auto'}}>
                    <div className="col-xs-6 col-sm-4 m-b-20">
                        <div className="m-b-25">
                            {/* <div className="f-s-32 d-inline-block m-r-10"><i className="icon-flag"></i></div> */}
                            <div className="d-inline-block f-s-18">
                                <span className="m-b-0 d-block f-s-14"><strong>Status</strong></span>
                                {t.Status}
                            </div>
                        </div>
                        <div>
                            {/* <div className="f-s-32 d-inline-block m-r-10"><i className="icon-wallet"></i></div> */}
                            <div className="d-inline-block f-s-18">
                                <span className="m-b-0 d-block f-s-14"><strong>{t.Type === "Listing" ? "Listing Price" : "Contract Price"}</strong></span>
                                {t.Type === "Listing" ? listPriceDisplay : salesPriceDisplay}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-4 m-b-20">
                        <div className="m-b-25">
                            {/* <div className="f-s-32 d-inline-block m-r-10"><i className="icon-home"></i></div> */}
                            <div className="d-inline-block f-s-18">
                                <span className="m-b-0 d-block f-s-14"><strong>Transaction Type</strong></span>
                                {t.Type}
                            </div>
                        </div>
                        <div>
                            {/* <div className="f-s-32 d-inline-block m-r-10"><i className="ion-md-business"></i></div> */}
                            <div className="d-inline-block f-s-18">
                                <span className="m-b-0 d-block f-s-14"><strong>Type</strong></span>
                                {t.PropertyDetails.PropertySubType || 'N/A'}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-4 m-b-20">
                        <div className="m-b-25">
                            {/* <div className="f-s-32 d-inline-block m-r-10"><i className="icon-calendar"></i></div> */}
                            <div className="d-inline-block f-s-18">
                                <span className="m-b-0 d-block f-s-14"><strong>{t.Type === "Listing" ? "Listing Expiration" : "Closing Date"}</strong></span>
                                {t.Type === "Listing" ? t.ListingExpDateString || 'N/A' : t.ClosingDateString || 'N/A'}
                            </div>
                        </div>
                        <div>
                            {/* <div className="f-s-32 d-inline-block m-r-10"><i className="icon-book-open"></i></div> */}
                            <div className="d-inline-block f-s-18">
                                <span className="m-b-0 d-block f-s-14"><strong>MLS #</strong></span>
                                {t.ListingKey || 'N/A'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="m-t-10 text-primary with-border-bottom p-b-10 m-b-25 m-r-5 m-l-5">GENERAL</h5>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Agent</label>
                    <AgentOptions 
                        agentId={t.AgentId}
                        all={false}
                        handleSelect={(e) => handleChange(e, true)}
                        selectName={'AgentId'}
                        disabled={isClosed}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Sales Price</label>
                    <NumberFormat disabled={isClosed} prefix={'$'} onBlur={() => updateTransaction()} thousandSeparator={true} name="SalesPrice" className="form-control" value={t.SalesPrice} onChange={(e) => handleChange(e)} />
                </div>
                <div className="form-group col-md-4">
                    <label>Source</label>
                    <select disabled={isClosed} onBlur={() => updateTransaction()} thousandSeparator={true} name="SourceId" className="form-control" value={t.SourceId} onChange={(e) => handleChange(e, true)}>
                        <option value={0}>Select a Transaction Source...</option>
                        { sources }
                    </select>
                </div>
            </div>

            { t.Type === "Listing" && <div>
                <h5 className="m-t-10 text-primary with-border-bottom p-b-10 m-b-25 m-r-5 m-l-5">LISTING DETAILS</h5>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label>Listing Price</label>
                        <NumberFormat disabled={isClosed} prefix={'$'} onBlur={() => updateTransaction()} thousandSeparator={true} name="ListingPrice" className="form-control" value={t.ListingPrice} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label>Listing Date</label>
                        <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed }} dateFormat={'MM/D/YYYY'} timeFormat={false} value={t.ListingDate ? moment(t.ListingDate).format('MM/D/YYYY') : t.ListingDate} closeOnSelect={true} onChange={(date) => handleDateChange('ListingDate', date)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label>Listing Expiration Date</label>
                        <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed }} timeFormat={false} value={t.ListingExpDate ? moment(t.ListingExpDate).format('MM/D/YYYY') : t.ListingExpDate} closeOnSelect={true} onChange={(date) => handleDateChange('ListingExpDate', date)} />
                    </div>
                </div>
            </div>}

            <h5 className="m-t-10 text-primary with-border-bottom p-b-10 m-b-25 m-r-5 m-l-5">IMPORTANT DATES</h5>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Contract Date</label>
                    <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed}} dateFormat={'MM/D/YYYY'} timeFormat={false} value={t.ContractDate ? moment(t.ContractDate).format('MM/D/YYYY') : t.ContractDate} closeOnSelect={true} onChange={(date) => handleDateChange('ContractDate', date)} />
                </div>
                <div className="form-group col-md-4">
                    <label>Closing Date</label>
                    <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed}} dateFormat={'MM/D/YYYY'} timeFormat={false} value={t.ClosingDate ? moment(t.ClosingDate).format('MM/D/YYYY') : t.ClosingDate} closeOnSelect={true} onChange={(date) => handleDateChange('ClosingDate', date)} />
                </div>
                <div className="form-group col-md-4">
                    <label>Date Closed</label>
                    <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed}} dateFormat={'MM/D/YYYY'} timeFormat={false} value={t.DateClosed ? moment(t.DateClosed).format('MM/D/YYYY') : t.DateClosed} closeOnSelect={true} onChange={(date) => handleDateChange('DateClosed', date)} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Appraisal Date</label>
                    <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed}} dateFormat={'MM/D/YYYY'} timeFormat={false} value={t.AppraisalDate ? moment(t.AppraisalDate).format('MM/D/YYYY') : t.AppraisalDate} closeOnSelect={true} onChange={(date) => handleDateChange('AppraisalDate', date)} />
                </div>
                <div className="form-group col-md-4">
                    <label>Inspection Date</label>
                    <DateTime inputProps={{ placeholder: 'Click to select', disabled: isClosed}} dateFormat={'MM/D/YYYY'} timeFormat={false} value={t.InspectionDate ? moment(t.InspectionDate).format('MM/D/YYYY') : t.InspectionDate} closeOnSelect={true} onChange={(date) => handleDateChange('InspectionDate', date)} />
                </div>
            </div>

            <div className="">
                <h5 className="m-t-30 text-primary with-border-bottom p-b-10 m-b-25 m-r-5 m-l-5">COMMISSION DETAILS</h5>
                <CommissionDetails 
                    transactionId={t.TransactionId}
                    transaction={t}
                    ref={ref}
                />
            </div>
        </div>
    )
})

export default TransactionSummary;
