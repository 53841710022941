import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { getData, deleteData } from '../../helpers/AxiosService';
import ActivityEmail from './activity/ActivityEmail';
import ActivityText from './activity/ActivityText';
import AllActivity from './activity/AllActivity';
import ActivityPhone from './activity/ActivityPhone';
import FavoriteProperties from './activity/FavoriteProperties';
import SearchActivity from './activity/SearchActivity';
import SavedSearches from './activity/SavedSearches';


class ContactActivity extends React.Component{
    static defaultProps = {
        onRef: () => {}
    }
    constructor(props){
        super(props);
        this.state = {
            activeActivity: '1',
            activities: [], 
            selectedActivities: [],
            loading: false, 
            userTemplates: [],
            savedSearches: [],
            deletingSearch: false,
        }

        this.toggleActivity = this.toggleActivity.bind(this);
        this.getActivity = this.getActivity.bind(this);
        this.deleteSavedSearch = this.deleteSavedSearch.bind(this);
    }

    componentDidMount(){
        this.props.onRef(this);
        let contactId = this.props.contactId;
        this.getActivity(contactId);
        this.getUserEmailTemplates();
    }

    componentDidUpdate(prevProps){
        if (prevProps.getPhoneCalls !== this.props.getPhoneCalls && this.props.getPhoneCalls) {
            this.getActivityByType(1);
            //call this from props now to reset getPhoneCalls back to false
            this.props.updatePhoneCalls();
        }
    }

    getActivity(id){
        getData(`api/activity/contact/${id}`).then(response => {
            this.setState({ activities: response.data });
        });
    }

    getActivityByType(id){
        this.setState({ loading: true });
        let contactId = this.props.contactId;

        getData(`api/activity?contactId=${contactId}&typeId=${id}`).then(response => {
            this.setState({ selectedActivities: response.data, loading: false });
        });
    }

    getContactSearches() {
        getData(`api/activity/saved-searches?contactId=${this.props.contactId}`).then(resp => {
            this.setState({ savedSearches: resp.data });
        });
    }

    getUserEmailTemplates(){
        getData('api/template/user').then(response => this.setState({ userTemplates: response.data }));
    }

    //type can be email or text
    fetchMessageActivity(type){
        this.setState({ loading: true });
        const id = this.props.contactEntityId;
        getData(`api/activity/message-activity?contactEntityId=${id}&type=${type}`).then(response => {
            this.setState({ selectedActivities: response.data, loading: false });
        });
    }

    toggleActivity(tab) {
		if (this.state.activeActivity !== tab) {
			this.setState({
				activeActivity: tab
			});
		}
    }

    deleteSavedSearch(searchId) {
        this.setState({ deletingSearch: true });
        deleteData(`api/search/${searchId}`).then(() => {
            this.getContactSearches();
            this.setState({ deletingSearch: false });
        }).fail(() => this.setState({ deletingSearch: false }));
    }

    render(){
        const { selectedActivities, activities, loading, savedSearches } = this.state;
        return(
            <div style={{ margin: '-15px' }}>
                <Nav pills className="activity-nav m-b-0 grey">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '1' }, "text-center")}
                            onClick={() => { this.toggleActivity('1'); }}
                        >
                            <span className="f-s-16"><i className="far fa-newspaper"></i></span>
                            <span className="d-sm-none">All Activity</span>
                            <span className="d-sm-block d-none">All Activity</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '3' }, "text-center")}
                            onClick={() => { this.toggleActivity('3'); this.fetchMessageActivity('email')}}
                        >
                            <span className="f-s-16"><i className="far fa-envelope"></i></span>
                            <span className="d-sm-none">Email</span>
                            <span className="d-sm-block d-none">Email</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '4' }, "text-center")}
                            onClick={() => { this.toggleActivity('4');  this.getActivityByType(1)}}
                        >
                            <span className="f-s-16"><i className="fas fa-phone"></i></span>
                            <span className="d-sm-none">Phone</span>
                            <span className="d-sm-block d-none">Phone</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '5' }, "text-center")}
                            onClick={() => { this.toggleActivity('5'); this.fetchMessageActivity('text')}}
                        >
                            <span className="f-s-16"><i className="fas fa-comments"></i></span>
                            <span className="d-sm-none">Texts</span>
                            <span className="d-sm-block d-none">Texts</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '7' }, "text-center")}
                            onClick={() => { this.toggleActivity('7'); this.getActivityByType(15)}}
                        >
                            <span className="f-s-16"><i className="fas fa-search"></i></span>
                            <span className="d-sm-none">Searches</span>
                            <span className="d-sm-block d-none">Searches</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '8' }, "text-center")}
                            onClick={() => { this.toggleActivity('8'); this.getActivityByType(12)}}
                        >
                            <span className="f-s-16"><i className="fas fa-heart"></i></span>
                            <span className="d-sm-none">Favorited Properties</span>
                            <span className="d-sm-block d-none">Favorited Properties</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeActivity === '10' }, "text-center")}
                            onClick={() => { this.toggleActivity('10'); this.getContactSearches(); }}
                        >
                            <span className="f-s-16"><i className="far fa-map"></i></span>
                            <span className="d-sm-none">Saved Searches</span>
                            <span className="d-sm-block d-none">Saved Searches</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeActivity}>
                    <TabPane tabId="1">
                        <AllActivity 
                            activity={activities} 
                            handleEditClick={this.props.handleCallEditClick}
                        />
                    </TabPane>
                    <TabPane tabId="3" style={{position: 'relative'}}>
                        {loading && <div className="spinner"></div>}

                        { !loading && <ActivityEmail 
                            toggleModal={this.props.toggleModal} 
                            templates={this.state.userTemplates} 
                            handleSelectTemplate={this.props.handleSelectTemplate}
                            messages={selectedActivities}
                            /> }
                    </TabPane>
                    <TabPane tabId="4" style={{position: 'relative'}}>
                        {loading && <div className="spinner"></div>}
                        { !loading && <ActivityPhone 
                            toggleModal={this.props.toggleModal} 
                            calls={selectedActivities} 
                            handleEditClick={this.props.handleCallEditClick}
                            /> }
                    </TabPane>
                    <TabPane tabId="5" style={{position: 'relative'}}>
                        {loading && <div className="spinner"></div>}
                        { 
                            !loading && 
                            <ActivityText
                                contactEntityId={this.props.contactEntityId}
                                messages={this.state.selectedActivities} 
                            /> 
                        }
                    </TabPane>
                    <TabPane tabId="7" style={{position: 'relative'}}>
                        {loading && <div className="spinner"></div>}
                        {!loading && 
                            <SearchActivity searches={this.state.selectedActivities} />
                        }
                    </TabPane>
                    <TabPane tabId="8" style={{position: 'relative'}}>
                        {loading && <div className="spinner"></div>}
                        {!loading && 
                            <FavoriteProperties properties={this.state.selectedActivities} />
                        }
                    </TabPane>
                    <TabPane tabId="10" style={{ position: 'relative' }}>
                        {loading && <div className="spinner"></div>}
                        {!loading && 
                            <SavedSearches 
                                searches={savedSearches}
                                deleteSavedSearch={this.deleteSavedSearch}
                                deletingSearch={this.state.deletingSearch}
                                contactId={this.props.contactId}
                            />
                        }
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

export default ContactActivity;

