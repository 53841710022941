import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function DocumentTable({ folders, selectFolder, toggleDocSelect, 
    deleteDocument, archiveDocument, rename, showRename, toggleRename, handleRename, submitRename, 
    toggleFolderRename, downloadDocument, sendDocument, archiveFolder, deleteFolder, handleFileChange, uploadingFile }){
    return (
        <div>
            {folders.length > 0 && 
                folders.map((folder, i) => (
                    <ul key={i} className="p-l-0 col-md-12 m-b-35" style={{ listStyle: 'none' }}>
                        <li className="with-border-bottom row p-b-10">
                            <div className="col-md-6">
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" checked={folder.checked} id={`folder_check_${folder.FolderId}`} onChange={() => selectFolder(folder, i)}/>
                                    <label htmlFor={`folder_check_${folder.FolderId}`}>&nbsp;</label>
                                    <span className="text-primary cursor-pointer"><strong><i className="far fa-folder m-r-10"></i>{folder.Name}</strong></span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="pull-right">
                                    <FolderActionButton 
                                        folder={folder} 
                                        handleFileChange={handleFileChange} 
                                        toggleFolderRename={toggleFolderRename} 
                                        archiveFolder={archiveFolder}
                                        deleteFolder={deleteFolder}
                                        uploadingFile={uploadingFile}
                                        />
                                </div>
                            </div>
                        </li>
                        <ul style={{ listStyle: 'none' }}>
                            { folder.Documents.length > 0 && 
                                folder.Documents.map((doc, idx) => (
                                    <DocumentRow 
                                        key={doc.DocumentId} 
                                        idx={idx} doc={doc} folderIdx={i} 
                                        toggleDocSelect={toggleDocSelect} 
                                        deleteDocument={deleteDocument}
                                        archiveDocument={archiveDocument}
                                        toggleRename={toggleRename}
                                        downloadDocument={downloadDocument}
                                        sendDocument={sendDocument}
                                        />
                                ))
                            }
                        </ul>
                    </ul>
                ))
            }
            <Modal isOpen={showRename}>
                <ModalHeader toggle={() => toggleRename()}>Rename</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>Name</label>
                        <input className="form-control" value={rename.Name}  onChange={(e) => handleRename(e)} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-white" onClick={() => toggleRename()}>Cancel</button>
                    <button className="btn btn-primary" onClick={() => submitRename()}>Submit</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

function DocumentRow({ doc, idx, folderIdx, toggleDocSelect, deleteDocument, archiveDocument, toggleRename, downloadDocument, sendDocument }){
    return (
        <li key={doc.DocumentId} className="with-border-bottom row p-b-10 p-t-10">
            <div className="col-md-6 row">
                <div className="checkbox checkbox-css">
                    <input type="checkbox" checked={doc.checked} id={`checkbox_${doc.DocumentId}`} onChange={() => toggleDocSelect(idx, folderIdx)}/>
                    <label htmlFor={`checkbox_${doc.DocumentId}`}>{doc.Name}</label>
                </div>
            </div>
            <div className="col-md-6">
                <div  className="pull-right">
                    <ActionButton doc={doc} deleteDocument={deleteDocument} archiveDocument={archiveDocument} toggleRename={toggleRename} downloadDocument={downloadDocument} sendDocument={sendDocument} />
                </div>
            </div>
        </li>
    )
}

function ActionButton({ doc, deleteDocument, archiveDocument, sendDocument }){
    return (
        <div key={doc.DocumentId}>
            <UncontrolledButtonDropdown>
                <DropdownToggle className="btn-sm" caret color="white">Actions</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem><a href={doc.FileLocation} target="_blank" download style={{ color: 'inherit' }}>Download</a></DropdownItem>
                    <DropdownItem onClick={() => sendDocument(doc)}>Email Document</DropdownItem>
                    <DropdownItem onClick={() => deleteDocument(doc)}>Delete</DropdownItem>
                    <DropdownItem onClick={() => archiveDocument(doc.DocumentId)}>Archive</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </div>
    )
}

function FolderActionButton({ folder, handleFileChange, toggleFolderRename, archiveFolder, deleteFolder, uploadingFile }){
    return (
        <div key={folder.FolderId}>
            <div className="d-inline" style={{ width: '120px', position: 'relative', height: '30px' }}>
                <input 
                    style={{ opacity: '0', width: '100%', height: '100%', top: '0', left: '0', position: 'absolute' }}
                    type="file"
                    multiple
                    name={folder.FolderId}
                    onChange={handleFileChange}
                />
                <button 
                    className="btn btn-sm btn-white" 
                    disabled={uploadingFile}
                >
                    { uploadingFile ?
                      <i className="fas fa-spinner fa-pulse"></i>
                      :
                      <div><i className="fas fa-plus"></i> Add Document</div>      
                    }
                </button>
            </div>
            
            <UncontrolledButtonDropdown>
                <DropdownToggle className="btn-sm" caret color="white">Actions</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => toggleFolderRename(folder.FolderId, folder.Name)}>Rename Folder</DropdownItem>
                    <DropdownItem onClick={() => archiveFolder(folder.FolderId)}>Archive Folder</DropdownItem>
                    <DropdownItem onClick={() => deleteFolder(folder.FolderId)}>Delete Folder</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </div>
    )
}