import React from 'react';
import { getData, postData, putData } from '../helpers/AxiosService';
import { Panel, PanelBody } from '../components/panel/panel';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { PageSettings } from '../config/page-settings';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { displayDateWorded } from '../helpers/Functions';

class IdxSettings extends React.Component {
    static contextType = PageSettings;
    constructor(props) {
        super(props);
        this.state = {
            mlsOptions: [],
            tenantCatalogs: [],
            setMLSId: null,
            loading: true,
            changeMade: false,
            savingMLSId: false,
            showAdd: false,
            hasError: false,
            catalogName: '',
            savedSearches: [],
            selectedSearchId: '',
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchMLSOptions();
        this.fetchTenantInfo();
        this.fetchSavedSearches();
        this.fetchTenantCatalogs();
    }

    fetchMLSOptions() {
        getData('api/idx/mls-options').then(resp => {
            this.setState({ mlsOptions: resp.data });
        });
    }

    fetchTenantInfo() {
        getData('api/user/tenant').then(resp => {
            this.setState({ setMLSId: resp.data.MLSId, loading: false });
        }).fail(() => this.setState({ loading: false }));
    }

    fetchTenantCatalogs() {
        getData('api/idx/catalogs').then(resp => {
            this.setState({ tenantCatalogs: resp.data });
        });
    }

    fetchSavedSearches() {
        getData('api/search/user/searches').then(resp => {
            this.setState({ savedSearches: resp.data });
        });
    }

    handleMLSChange(id) {
        this.setState({ setMLSId: id, changeMade: true });
    }

    handleMLSSave() {
        this.setState({ savingMLSId: true });
        putData(`api/idx/mls?mlsId=${this.state.setMLSId}`).then(resp => {
            this.setState({ savingMLSId: false, changeMade: false });
            this.context.addNotification("Success!", "Data feed connection saved.");
        }).fail(() => {
            this.setState({ savingMLSId: false });
        });
    }

    toggleModal() {
        this.setState({ 
            showAdd: !this.state.showAdd,
            hasError: false,
            catalogName: ''
         });
    }

    handleChange(e) {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    }

    handleAddNew() {
        if (!this.state.catalogName) {
            this.setState({ hasError: true });
            return;
        }

        const data = {
            Name: this.state.catalogName,
            IdxSearchId: this.state.selectedSearchId,
        }

        postData('api/idx/catalog', data).then(resp => {
            this.toggleModal();
            this.fetchTenantCatalogs();
        });
    }

    renderRows() {
        if (!this.state.tenantCatalogs || !this.state.tenantCatalogs.length) {
            return <tr><td colSpan={5}>No catalogs yet</td></tr>
        }
        const getSearch = (searchId) => {
            const search = this.state.savedSearches.find(s => s.SearchId === searchId);
            if (search) return search;

            return {};
        };
        return this.state.tenantCatalogs.map((catalog, i) => (
            <tr key={catalog.Id}>
                <td><strong>{catalog.Name}</strong></td>
                <td>{catalog.CatalogId}</td>
                <td>{displayDateWorded(catalog.DateCreated)}</td>
                <td>{getSearch(catalog.IdxSearchId).SearchName}</td>
            </tr>
        ))
    }

    render() {
        const { mlsOptions, setMLSId, loading, changeMade, savingMLSId, showAdd, hasError, catalogName, savedSearches, selectedSearchId } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <ol className="breadcrumb pull-right">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active">Idx Settings</li>
                </ol>
                <h1 className="page-header">Idx Settings <small className="f-w-100">Manage your Idx data feed settings</small></h1>
                <Panel>
                    <PanelBody>
                        <h3>Idx Data Feed</h3>
                        <label className="m-t-25">MLS Data Feed</label>
                        <div className="form-inline">
                            <select className="form-control col-md-4 m-r-10" value={setMLSId} onChange={(e) => this.handleMLSChange(e.target.value)}>
                                <option value="0">Not Set</option>
                                { mlsOptions.length > 0 && mlsOptions.map(o => <option key={o.MLSId} value={o.MLSId}>{o.MLSName}</option>)}
                            </select>
                            {changeMade && 
                                <button className="btn btn-primary p-r-20 p-l-20" onClick={() => this.handleMLSSave()}>
                                    {savingMLSId ? <div className="button-spinner"></div>
                                    :
                                    "Save"}
                                </button>
                            }
                        </div>
                    </PanelBody>
                </Panel>
                <Panel>
                    <PanelBody>
                        <button 
                            className="btn btn-lime p-l-40 p-r-40 pull-right m-t-10"
                            onClick={() => this.toggleModal()}
                        >
                            New Catalog
                        </button>
                        <h3>Idx Catalogs <small className="f-w-100">Used for social media ad catalogs</small></h3>
                        <div className="table-responsive with-thin-shadow m-t-40">
                            <table className="table table-bordered table-striped thick-rows bg-white m-b-0">
                                <thead>
                                    <tr>
                                        <th>Catalog Name</th>
                                        <th>Catalog Id</th>
                                        <th>Date Created</th>
                                        <th>Linked Search</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRows()}
                                </tbody>
                            </table>
                        </div>
                    </PanelBody>
                </Panel>
                <Modal isOpen={showAdd}>
                    <ModalHeader toggle={() => this.toggleModal()}>Add Catalog</ModalHeader>
                    <ModalBody>
                        <div className={classnames({ "has-error": hasError && !catalogName }, "form-group")}>
                            <label>Catalog Name</label>
                            <input 
                                className="form-control" 
                                name="catalogName"
                                value={catalogName} 
                                onChange={this.handleChange} />
                        </div>
                        <div className={classnames({ "has-error": hasError && !catalogName }, "form-group")}>
                            <label>Catalog Name</label>
                            <select
                                className="form-control col-md-4 m-r-10"
                                name="selectedSearchId"
                                value={selectedSearchId} 
                                onChange={this.handleChange}
                            >
                                <option value="0">Select...</option>
                                { savedSearches.length > 0 && savedSearches.map(o => <option key={o.SearchId} value={o.SearchId}>{o.SearchName}</option>)}
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => this.toggleModal()}>Cancel</button>
                        <button className="btn btn-primary m-l-5" onClick={() => this.handleAddNew()}>
                            {this.state.sending ? 
                                <div className="button-spinner"></div>
                                :
                                "Save"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default IdxSettings;