import React from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../components/panel/panel.jsx';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { phoneClean } from '../helpers/Functions';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { postData, getData } from '../helpers/AxiosService.js';

class CreateUser extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            user: {
                FirstName: '',
                LastName: '',
                PhoneNumber: '',
                Email: ''
            },
            hasError: false,
            sending: false,
            showModal: true,
            accepted: false,
            loading: true,
        }
        this.validateUser = this.validateUser.bind(this);
    }

    componentDidMount() {
        this.getAvailableSeats();
    }

    getAvailableSeats() {
        getData('api/user/available-seats').then(response => {
            if (response.data > 0) {
                this.closeModal();
                return;
            }

            this.setState({ loading: false });
        });
    }

    toggleAccept(e) {
        this.setState({ accepted: e.target.checked });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    handleChange(item, e){
        let val = e.target.value;

        if (item.includes('Phone')){
            val = phoneClean(val);
        }

        var userCopy = Object.assign({}, this.state.user);
        userCopy[item] = val;
        this.setState({ user: userCopy });
    }

    handleSubmit(){
        if (this.validateUser()){
            this.setState({ sending: true });

            postData('api/account/createuser', this.state.user).then(response => {
                this.props.history.push(`/admin/user/${response.data.Id}`);
            }).fail(() => this.setState({ sending: false }));
        }
    }

    validateUser(){
        let isValid = true;
        for (let property1 in this.state.user){
            if (this.state.user[property1] === ""){
                isValid = false;
            }
        }

        this.setState({ hasError: !isValid });
        return isValid;
    }

    render(){
        const { user, hasError, sending, accepted, showModal, loading } = this.state;
        return(
            <div>
            <ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item"><Link to="/admin/users">Users</Link></li>
					<li className="breadcrumb-item active">Create User</li>
				</ol>
                <h1 className="page-header">Add User <small className="f-w-100">Create application users</small></h1>
                <Panel theme="default">
                    <PanelHeader noButton>User Details</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={classnames({ 'has-error': hasError && !user.FirstName }, "form-group row m-b-15")}>
                                    <label className="col-form-label col-md-3">First Name</label>
                                    <input className="form-control col-md-8" value={user.FirstName} onChange={(e) => this.handleChange('FirstName', e)} />
                                </div>
                                <div className={classnames({ 'has-error': hasError && !user.LastName }, "form-group row m-b-15")}>
                                    <label className="col-form-label col-md-3">Last Name</label>
                                    <input className="form-control col-md-8" value={user.LastName} onChange={(e) => this.handleChange('LastName', e)} />
                                </div>
                                <div className={classnames({ 'has-error': hasError && !user.PhoneNumber }, "form-group row m-b-15")}>
                                    <label className="col-form-label col-md-3">Cell Phone</label>
                                    <InputMask mask="(999) 999 9999" className="form-control col-md-8" value={user.PhoneNumber} onChange={(e) => this.handleChange('PhoneNumber', e)}></InputMask>
                                </div>
                                <div className={classnames({ 'has-error': hasError && !user.Email }, "form-group row m-b-15")}>
                                    <label className="col-form-label col-md-3">Email</label>
                                    <input className="form-control col-md-8" value={user.Email} onChange={(e) => this.handleChange('Email', e)} />
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelFooter>
                        <div className="row">
                            <div className="col-md-12">
                                <Link to="/admin/users"><button className="btn btn-primary-outline p-l-20 p-r-20 pull-right m-l-10">Cancel</button></Link>
                                <button className="btn btn-primary p-l-20 p-r-20 pull-right" onClick={() => this.handleSubmit()}>
                                    { sending ?
                                        <div className="button-spinner"></div>
                                        :
                                        "Create User"
                                    }
                                </button>
                            </div>
                        </div>
                    </PanelFooter>
                </Panel>
                <Modal isOpen={showModal}>
                    <ModalBody>
                        { loading && <div style={{ height: '330px', position: 'relative' }}>
                            <div id="modal-loader"><div className="spinner"></div></div>
                        </div> }
                        { !loading && <div className="text-center p-40">
                            <h4>Add A User To Your Plan</h4>
                            <p>Under you current plan, you will need to add another user. 
                                Adding a user is $19.99 per month and we will simply add it to your bill.
                                Please check the I Agree box below to continue.
                            </p>
                            <div className="checkbox checkbox-css" style={{ margin: 'auto', width: '80px' }}>
                                <input type="checkbox" id="checkboxAll" value="All" onChange={e => this.toggleAccept(e)} />
                                <label htmlFor="checkboxAll">I Agree</label>
                            </div>
                            <button 
                                className="btn btn-primary m-b-10 m-t-25 width-100" 
                                disabled={!accepted}
                                onClick={() => this.closeModal()}
                            >Continue</button> <br/>
                            <Link to="/admin/users" className="btn btn-primary-outline width-100">Cancel</Link>
                        </div> }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default CreateUser;