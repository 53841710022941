import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../components/panel/panel.jsx';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { getData, postData } from '../helpers/AxiosService.js';
import { phoneClean } from '../helpers/Functions.js';
import { PageSettings } from '../config/page-settings';

class NewContact extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            firstName: '',
            lastName: '',
            cellPhone: '',
            homePhone: '',
            workPhone: '',
            email: '',
            sourceId: '',
            contactTypeId: '',
            statusId: '',
            assignedAgentId: '',
            sourceOptions: [],
            contactTypeOptions: [],
            agentOptions: [],
            sending: false,
            hasError: false
        }
    }

    componentDidMount() {
        getData('api/filter/selectoptions').then(response => {
            this.setState({
                loading: false,
                sourceOptions: response.data.SourceOptions,
                contactTypeOptions: response.data.ContactTypeOptions,
                agentOptions: response.data.AgentOptions,
            });
        });
    }

    handleChange(item, e) {
        let val = e.target.value;
        this.setState({ [item]: val });
    }

    handleSubmit() {
        if (!this.state.firstName 
            || !this.state.lastName 
            || !this.state.email 
            || !this.state.contactTypeId
            || !this.state.sourceId
            || !this.state.assignedAgentId) {
            this.setState({ hasError: true });
            return;
        }

        this.setState({ sending: true });

        let data = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            CellPhone: phoneClean(this.state.cellPhone),
            HomePhone: phoneClean(this.state.homePhone),
            WorkPhone: phoneClean(this.state.workPhone),
            Email: this.state.email,
            ContactTypeId: this.state.contactTypeId,
            AssignedAgentId: this.state.assignedAgentId,
            SourceId: this.state.sourceId,
        }

        postData('api/contact', data, this).then(response => {
            // redirect to new contacts page
            this.setState({ sending: false });
            this.props.history.push(`/contact/${response.data.ContactId}`)
        }).fail(() => {
            this.setState({ sending: false });
        });
    }

    render() {
        const { loading, firstName, lastName, cellPhone, homePhone, workPhone, email, sourceId, contactTypeId,  
            assignedAgentId, sourceOptions, contactTypeOptions, agentOptions, hasError } = this.state;

        const sourceSelects = sourceOptions.length === 0 ? null : sourceOptions.map((source, i) => <option key={i} value={source.Key}>{source.Value}</option>);
        const contactTypeSelects = contactTypeOptions.length === 0 ? null : contactTypeOptions.map((source, i) => <option key={i} value={source.Key}>{source.Value}</option>);
        const agentSelects = agentOptions.length === 0 ? null : agentOptions.map((source, i) => <option key={i} value={source.Key}>{source.Value}</option>);
        return(
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item active">New Contact</li>
				</ol>
                <h1 className="page-header">New Contact <small className="f-w-100">Add a new contact</small></h1>
                <Panel>
                    <PanelHeader noButton>Contact Details</PanelHeader>
                    <PanelBody>
                        <div className="">
                            <div  style={{ width: '750px', margin: 'auto' }}>
                                <div className="row">
                                    <div className={classnames({ 'has-error': !firstName && hasError }, "form-group col-md-6")}>
                                        <label>First Name</label>
                                        <input className="form-control" value={firstName} onChange={(e) => this.handleChange('firstName', e)} />
                                    </div>
                                    <div className={classnames({ 'has-error': !lastName && hasError }, "form-group col-md-6")}>
                                        <label>Last Name</label>
                                        <input className="form-control" value={lastName} onChange={(e) => this.handleChange('lastName', e)} />
                                    </div>
                                </div>
                                <p>*Phone numbers are optional</p>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Cell Phone</label>
                                        <InputMask mask="(999) 999 9999" className="form-control" value={cellPhone} onChange={(e) => this.handleChange('cellPhone', e)}></InputMask>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Home Phone</label>
                                        <InputMask mask="(999) 999 9999" className="form-control" value={homePhone} onChange={(e) => this.handleChange('homePhone', e)}></InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Work Phone</label>
                                        <InputMask mask="(999) 999 9999" className="form-control" value={workPhone} onChange={(e) => this.handleChange('workPhone', e)}></InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classnames({ 'has-error': !email && hasError }, "form-group col-md-12")}>
                                        <label>Email</label>
                                        <input className="form-control" value={email} onChange={(e) => this.handleChange('email', e)}  />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classnames({ 'has-error': !contactTypeId && hasError }, "form-group col-md-12")}>
                                        <label>Contact Type</label>
                                        <select className="form-control" value={contactTypeId} onChange={(e) => this.handleChange('contactTypeId', e)}>
                                            <option value=""></option>
                                            {contactTypeSelects}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classnames({ 'has-error': !sourceId && hasError }, "form-group col-md-12")}>
                                        <label>Source</label>
                                        <select className="form-control" value={sourceId} onChange={(e) => this.handleChange('sourceId', e)}>
                                            <option value=""></option>
                                            {sourceSelects}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classnames({ 'has-error': !assignedAgentId && hasError }, "form-group col-md-12")}>
                                        <label>Assigned Agent</label>
                                        <select className="form-control" value={assignedAgentId} onChange={(e) => this.handleChange('assignedAgentId', e)}>
                                            <option value=""></option>
                                            {agentSelects}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelFooter>
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-primary pull-right p-r-20 p-l-20" onClick={() => this.handleSubmit()}>
                                    { this.state.sending ? <div className="button-spinner"></div> : "Save Contact" }
                                </button>
                            </div>
                        </div>
                    </PanelFooter>
                </Panel>
            </div>
        )
    }
}

export default NewContact;
