import React from 'react';
import ReportContainer from './ReportContainer'
import { DateOp } from '../../helpers/OperationTypes';
import { displayDateTimeUtc } from '../../helpers/Functions';
import { Link } from 'react-router-dom';


export default function NewContactReport() {
    return (
        <ReportContainer 
            title="New Contacts"
            description="These are contacts that have been flagged as new, ordered by most recent"
            reportType="new-contacts"
            startingRange="Last Week"
            startingRangeOp={DateOp.lastWeek}
            reportField="DateCreated"
            rangeDirection='past'
        >
            {data => (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date Active</th>
                            <th>Agent</th>
                            <th>Lender</th>
                            <th>Source</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.length > 0 && data.map((contact) => (
                            <tr key={contact.ContactId}>
                                <td><Link to={`/contact/${contact.ContactId}`}>{contact.Name || "Unknown"}</Link></td>
                                <td>{displayDateTimeUtc(contact.DateCreated)}</td>
                                <td>{contact.AgentName || 'Unassigned'}</td>
                                <td>{contact.LenderName || 'Unassigned' }</td>
                                <td>{contact.Source}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </ReportContainer>
    )
}