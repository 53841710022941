export function formatLeadsBySource(groups) {
    const results = [];
    groups.forEach(group => {
        const g = {
            count: group.length,
            name: group[0].Source || 'Unknown',
            sourceId: group[0].SourceId,
            color: getRandomColor(),
        }

        results.push(g);
    });

    return results;
}

export function countLeadsWhenGrouped(groups) {
    let count = 0;
    groups.forEach(group => {
        count += group.length;
    });
    return count;
}



export function formatLeadsPieChart(groups) {
    const dataSet = groups.map(g => {
        return { 'label': g.name, 'value': g.count, 'color': g.color };
    });

    return dataSet;
}

function getRandomColor() {
    const colors = [
        '#00acac', '#348fe2', '#49b6d6', '#ff5b57', '#f59c1a', '#90ca4b', '#8753de', '#fb5597', '#58b859', '#f59c1a', '#ffd900'
    ]
    
    return colors[Math.floor(Math.random() * colors.length)];
}