import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import OpportunityModal from '../components/pipeline/OpportunityModal';
import StageLane from '../components/pipeline/StageLane';
import { getData, putData, deleteData } from '../helpers/AxiosService';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { PageSettings } from '../config/page-settings';
import AgentOptions from '../components/shared/AgentOptions';
import { volumeFormatter } from '../helpers/Functions';

export default function Pipeline() {
    const cxt = useContext(PageSettings);
    const [showOppModal, setShowOppModal] = useState(false);
    const [pipeline, setPipeline] = useState([]);
    const [filteredPipeline, setFilteredPipeline] = useState([]);
    const [selectedOpp, setSelectedOpp] = useState({});
    const [loading, setLoading] = useState(false);
    const [agentId, setAgentId] = useState(0);
    const [type, setType] = useState('');

    async function getPipeline() {
        setLoading(true);
        try {
            const resp = await getData('api/pipeline');
            if (!cxt.userInfo.IsAdmin) {
                setAgentId(cxt.userInfo.UserId);
                const agentPipeline = filterPipeline(resp.data, cxt.userInfo.UserId);
                setPipeline(agentPipeline);
                setFilteredPipeline(agentPipeline);
            } else {
                setPipeline(resp.data);
                setFilteredPipeline(resp.data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    useEffect(() => {
        getPipeline();

        document.body.style.background = '#fff';
        const nav = document.getElementById('header');
        nav.style.boxShadow = '0px 3px 3px -2px rgb(0 0 0 / 10%), 0px 3px 4px 0px rgb(0 0 0 / 7%), 0px 1px 8px 0px rgb(0 0 0 / 6%)';

        return () => {
            document.body.style.background = '#e8ebef';
            nav.style.boxShadow = 'unset';
        }
    }, [])

    function toggleModal() {
        setShowOppModal(!showOppModal);
    }

    function removeFromList(list, index) {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result];
    }

    function addToList(list, index, element) {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    }

    async function handleDragEnd(result) {
        const { destination, source } = result;
        if (!destination) return;
        const pipelineCopy = Object.assign([], pipeline);
        const destIdx = pipelineCopy.findIndex(s => s.StageName === destination.droppableId);
        const srcIdx = pipelineCopy.findIndex(s => s.StageName === source.droppableId);

        const srcStage = pipelineCopy[srcIdx];
        const destStage = pipelineCopy[destIdx];

        const [removedOpp, newSourceList] = removeFromList(srcStage.Opportunities, source.index);
        srcStage.Opportunities = newSourceList;

        destStage.Opportunities = addToList(destStage.Opportunities, destination.index, removedOpp);

        setPipeline(pipelineCopy);

        const updateTasks = [];
        destStage.Opportunities.forEach((opp, i) => {
            opp.Position = i;
            opp.Stage = destStage.StageName;
            try {
                updateTasks.push(putData('api/opportunities', opp));
            } catch (e) {
                console.log(e);
            }
        });

        await Promise.all(updateTasks);
        getPipeline();
    }

    function handleCardClick(opportunity) {
        setSelectedOpp(opportunity);
    }

    function handleTypeSelect(e) {
        const { value } = e.target;
        if (!value) {
            setType('');
            return;
        }
        setType(value);
    }
    
    function handleAgentSelect(e) {
        let { value } = e.target;
        if (!value || value === '0') {
            setAgentId(0);
            return;
        }
        value = Number(value);
        setAgentId(value);
    }

    useEffect(() => {
        const updatedPipeline = filterPipeline(pipeline);
        setFilteredPipeline(updatedPipeline);
    }, [agentId, type]);

    function filterPipeline(pipe, defaultUserId = null) {
        const updatedPipeline = [];
        pipe.forEach(stg => {
            const stage = { ...stg };
            stage.Opportunities = stg.Opportunities.filter(o => {
                let typeMatches = true;
                let agentMatches = true;
                if (type) {
                    typeMatches = o.Type === type;
                }
                if (defaultUserId) {
                    agentMatches = o.UserId === defaultUserId;
                } else if (agentId && agentId !== '0') {
                    agentMatches = o.UserId === agentId;
                }

                return (typeMatches && agentMatches);
            });
            updatedPipeline.push(stage);
        });

        return updatedPipeline;
    }

    useEffect(() => {
        if (Object.keys(selectedOpp).length && !showOppModal) {
            setShowOppModal(true);
        }
    }, [selectedOpp]);

    useEffect(() => {
        if (!showOppModal) {
            setSelectedOpp({});
        }
    }, [showOppModal]);

    function handleDeleteClick(opp) {
        cxt.messageConfirmation('Delete Opportunity', `Are you sure you want to delete this opportunity: ${opp.Name}?`, 
                    deleteStage.bind(null, opp.Id), "Opportunity deleted successfully", "Delete");
    }

    async function deleteStage(oppId) {
        cxt.toggleSweetAlert('confirm');
		try {
            await deleteData(`api/opportunities/${oppId}`, this);
            cxt.toggleSweetAlert('success');
            await getPipeline();
        } catch (e) {
            console.log(e);
            cxt.toggleSweetAlert('success');
        }
    }

    function pipelineCount() {
        let count = 0;
        filteredPipeline.forEach(stg => {
            count += stg.Opportunities.length;
        });
        return count;
    }

    function pipelineVolume() {
        let volume = 0;
        filteredPipeline.forEach(stg => {
            volume  += stg.Opportunities.reduce((total, crrnt) => {
                return total += crrnt.SalesPrice;
            }, 0);
        })
        return volumeFormatter(volume);
    }

    function pipelineGCI() {
        let gci = 0;
        filteredPipeline.forEach(stg =>  {
            gci += stg.Opportunities.reduce((total, crrnt) => {
                return  total += crrnt.GCI;
            }, 0)
        });

        return volumeFormatter(gci);
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-9">
                    <h1 className="page-header col-12 col-md-6">
                        Pipeline <small className="f-w-100">Manage opportunities in your pipeline</small>
                        { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i> }
                    </h1>
                </div>
                <div className="col-12 col-md-3">
                    <button 
                        className="btn btn-primary pull-right"
                        onClick={() => setShowOppModal(true)}
                    >
                        <i className="fas fa-plus m-r-5"></i> Opportunity
                    </button>
                </div>
            </div>
            <div className="row m-t-25">
                <div className="col-12 col-md-6">
                    <div className="d-flex">
                        <select 
                            className="form-control width-150 with-thin-shadow" 
                            onChange={handleTypeSelect}
                            value={type}
                        >
                            <option value="">Select Type</option>
                            <option value="Purchase">Purchase</option>
                            <option value="Listing">Listing</option>
                        </select>
                        { cxt.userInfo.IsAdmin && 
                            <AgentOptions 
                                all={true}
                                agentId={agentId}
                                handleSelect={handleAgentSelect}
                                className="form-control m-l-10 width-200 with-thin-shadow"
                            />
                        }
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <h4 className="pull-right">
                        {pipelineCount()} Deals {pipelineVolume()} Volume {pipelineGCI()} GCI
                    </h4>
                </div>
            </div>
            <div className="row">
                <PerfectScrollBar style={{ width: 'calc(100vw - 240px' }}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <div
                            className="d-flex flex-row"
                        >
                            { filteredPipeline.map((stage, idx) => {
                                return (
                                    <StageLane
                                        stage={stage}
                                        key={idx}
                                        handleCardClick={handleCardClick}
                                        handleDelete={handleDeleteClick}
                                    />
                                )
                            }) }
                        </div>
                    </DragDropContext>
                </PerfectScrollBar>
            </div>
            <OpportunityModal 
                toggleModal={toggleModal}
                isOpen={showOppModal}
                opportunity={selectedOpp}
                updateList={getPipeline}
            />
        </div>
    )
}