import React from 'react';
import ReactQuill from 'react-quill';
import { getData } from '../../helpers/AxiosService';
import PropTypes from 'prop-types';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


export default class EmailTextEditor extends React.Component{
    constructor(props){
        super(props);
        this.quillRef = null;
        this.reactQuillRef = null;
        this.state = {
            mergeFieldOptions: []
        }
    }

    static defaultProps = {
        templateIcon: 'far fa-envelope',
        onRef: () => {},
    }

    componentDidMount(){
        this.props.onRef(this);

        getData('api/template/fields').then(response => {
            this.setState({ mergeFieldOptions: response.data });
        });

        if (this.reactQuillRef) this.attachQuillRefs();
    }

    componentDidUpdate(){
        this.attachQuillRefs();
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    attachQuillRefs(){
        if (!this.reactQuillRef || typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    insertMergeField(val){
        let cursorPosition = this.quillRef.getSelection();
        cursorPosition = cursorPosition ? cursorPosition.index : 0;
        this.quillRef.insertText(cursorPosition, val);
    }

    insertVideoLink(link) {
        let lastIndex = this.quillRef.getLength() + 1;
        this.quillRef.insertText(lastIndex, `\n${link}`);
    }

    render(){
        const options = this.state.mergeFieldOptions.length === 0 ? null : this.state.mergeFieldOptions.map((option, i) => <DropdownItem key={i} onClick={() => this.insertMergeField(option)}>{option}</DropdownItem>);
        return(
            <div>
                <div className="row m-b-15">
                    <UncontrolledButtonDropdown className="m-l-10">
                        <DropdownToggle className="btn btn-white" caret> Insert Placeholder</DropdownToggle>
                        <DropdownMenu container="body">
                            { options }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    { this.props.templates && 
                    <UncontrolledButtonDropdown className="m-l-15">
                        <DropdownToggle className="btn btn-white" caret><i className={this.props.templateIcon}></i> Use a Template</DropdownToggle>
                        <DropdownMenu>
                            { this.props.templates.length > 0 && this.props.templates.map((t, i) => (
                                <DropdownItem key={i} onClick={() => this.props.handleSelectTemplate(t)}>{t.Name}</DropdownItem>
                            )) }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown> }
                </div>
                <ReactQuill 
                    ref={(el) => { this.reactQuillRef = el }}
                    value={this.props.text || ''} 
                    onChange={this.props.handleChange} 
                    style={{ height: this.props.editor.height + 'px', marginBottom: '20px' }} 
                />
            </div>
        )
    }
}

EmailTextEditor.propTypes = {
    text: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    editor: PropTypes.shape({
        height: PropTypes.number.isRequired
    })
}