import React from 'react';
import { getData, putData, deleteData } from '../helpers/AxiosService';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TransactionSummary from '../components/transactions/TransactionSummary';
import People from '../components/transactions/People';
import Documents from '../components/transactions/Documents';
import CloseTransaction from '../components/transactions/CloseTransaction';
import TransactionTasks from '../components/transactions/TransactionTasks';
import PropertyDetails from '../components/transactions/PropertyDetails';
import Workflows from '../components/transactions/Workflows';
import { PageSettings } from '../config/page-settings';

class Transaction extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            t: {
                Financials: {},
                PropertyDetails: {},
            },
            people: [],
            activePill: '1',
            closeTransactionModal: false,
            syncMlsModal: false,
            syncingWithMls: false,
            syncListingKey: null,
            changeMade: false,
            closingTransaction: false,
            markPastClients: true,
            emitChange: false,
            savingChanges: false,
            sourceOptions: [],
        }
        this.summaryRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.updateTransaction = this.updateTransaction.bind(this);
        this.closeTransaction = this.closeTransaction.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.timedFetchTasks = null;
        this.timedFetchAutoTasks = null;
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.fetchTransaction = this.fetchTransaction.bind(this);
    }

    componentDidMount(){
        this.fetchTransaction();
        this.fetchSourceOptions();
    }

    async fetchTransaction() {
        let transId = this.props.match.params.transactionId;
        const response = await getData(`api/transaction/${transId}`);
        let people = [];
        if (response.data.People) people = response.data.People;
        this.setState({ t: response.data, people: people, loading: false });
    }

    async fetchSourceOptions() {
        try {
            const resp = await getData('api/settings/contact/sources');
            this.setState({ sourceOptions: resp.data });
        } catch (e) {
            console.log(e);
        }
    }

    togglePill(pill) {
		if (this.state.activePill !== pill) {
			this.setState({
				activePill: pill
			});
		}
    }
    
    handleChange(e, updateTransaction = false){
        let { name, value } = e.target;
        if (typeof(value) === 'string'){
            value = value.replace("$", "").replace(/,/g, "");
        }
        this.setState({ changeMade: true, t: {...this.state.t, [name]: value }}, () => {
            if (updateTransaction) this.updateTransaction();
        });
    }

    handleCheckChange(e){
        const { name, checked } = e.target;
        this.setState({ [name]: checked })
    }

    handleDateChange(name, date){
        this.setState({ changeMade: true, t: {...this.state.t, [name]: date }}, this.updateTransaction);
    }

    handleStatusChange(status, statusId){
        this.setState({ t: { ...this.state.t, StatusId: statusId, Status: status }, changeMade: true }, () => this.updateTransaction(true));
    }

    handleSyncWithMls() {
        const { syncListingKey, t } = this.state;
        if (!syncListingKey) {
            this.context.addNotification('', 'MLS Id is required to proceed, or select Add Manually.', 'warning');
            return;
        }

        let transaction = { ...t };

        this.setState({ syncingWithMls: true });

        getData(`api/search/property?listingKey=${syncListingKey}`).then(resp => {
            const { data } = resp;
           
            let imgUrl = '';
            const img = data.Media && data.Media.length > 0 ? data.Media.find(x => x.Order == 0) : null;
            if (img) {
                imgUrl = img.MediaURL;
            }

            transaction.PropertyImage = imgUrl;
            transaction.ListingKey = data.ListingKeyNumeric;
            
            this.setState({ t: transaction, syncingWithMls: false, changeMade: true, syncMlsModal: false }, this.updateTransaction)
        });
    }

    async toggleModal(modal){
        if (modal === 'closeTransactionModal' && !this.state.closeTransactionModal) {
            await this.fetchTransaction(); 
        }

        this.setState({ [modal]: !this.state[modal] });
    }

    updateTransaction(updateState = true, markPastClients = false){
        if (this.state.changeMade){
            this.setState({ savingChanges: true });
            putData(`api/transaction?markPastClients=${markPastClients}`, this.state.t).then(response => {
                if (updateState){
                    this.setState({ t: response.data, changeMade: false });
                }
                this.dispatchChange();
                this.setState({ savingChanges: false });
            }).fail(() => this.setState({ savingChanges: false }));
        }
    }

    dispatchChange() {
        this.summaryRef.current.getTransactionFinancials();
        // update the task list in background so it reflects any dates changed by updating tasks
        // timeout is to prevent any race conditions
        this.timedFetchTasks = setTimeout(() => {
            this.tasks.fetchTasks();
            clearInterval(this.timedFetchTasks);
        }, 1500);

        this.timedFetchAutoTasks = setTimeout(() => {
            this.workflows.fetchAutoTasks();
            clearInterval(this.timedFetchAutoTasks);
        }, 1500);
    }

    closeTransaction(){
        this.setState({ closingTransaction: true });
        this.setState({ t: { ...this.state.t, StatusId: 3 }, changeMade: true }, () => this.updateTransaction(true, this.state.markPastClients));
        this.setState({ closingTransaction: false, closeTransactionModal: false });
    }

    handleDeleteClick() {
        // display confirmation message
        this.context.messageConfirmation("Delete Transaction?", 
            "Deleting a transaction will permanently delete the transaction and all related documents and details",
            this.deleteTransaction.bind(this), "Transaction deleted successfully!", "Delete"
            );
    }

    deleteTransaction() {
        deleteData(`api/transaction/${this.state.t.TransactionId}`, this).then(resp => {
            this.context.toggleSweetAlert('confirm');
            this.props.history.push('/transactions/transactions');
        });
    }

    render(){
        const { loading, t, closeTransactionModal, closingTransaction, syncMlsModal, syncingWithMls, syncListingKey, savingChanges } = this.state;
        return(
            <div className="transaction-container" style={{ marginBottom: '60px' }}>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
				<ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item"><Link to="/transactions/dashboard">Transaction Dashboard</Link></li>
					<li className="breadcrumb-item active">{t.Address}</li>
				</ol>
                <h1 className="page-header">{t.Address} {t.UnitNumber} <small className="f-w-100">{t.City}, {t.StateCode} {t.ZipCode}   <strong>{t.Status}</strong></small></h1>

                <Nav pills className="m-b-0 white bg-white">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '1' }, "text-center")}
                            onClick={() => { this.togglePill('1'); }}
                        >
                            <span className="f-s-20"><i className="far fa-newspaper"></i></span>
                            <span className="d-sm-none">Summary</span>
                            <span className="d-sm-block d-none">Summary</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '2' }, "text-center")}
                            onClick={() => { this.togglePill('2'); }}
                        >
                            <span className="f-s-20"><i className="far fa-list-alt"></i></span>
                            <span className="d-sm-none">Property Details</span>
                            <span className="d-sm-block d-none">Property Details</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '3' }, "text-center")}
                            onClick={() => { this.togglePill('3'); }}
                        >

                            <span className="f-s-20"><i className="far fa-check-square"></i></span>
                            <span className="d-sm-none">Tasks</span>
                            <span className="d-sm-block d-none">Tasks</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '4' }, "text-center")}
                            onClick={() => { this.togglePill('4'); }}
                        >
                            <span className="f-s-20"><i className="far fa-folder-open"></i></span>
                            <span className="d-sm-none">Documents</span>
                            <span className="d-sm-block d-none">Documents</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '5' }, "text-center")}
                            onClick={() => { this.togglePill('5'); }}
                        >
                            <span className="f-s-20"><i className="fas fa-users"></i></span>
                            <span className="d-sm-none">People</span>
                            <span className="d-sm-block d-none">People</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activePill === '6' }, "text-center")}
                            onClick={() => { this.togglePill('6'); }}
                        >
                            <span className="f-s-20"><i className="far fa-paper-plane"></i></span>
                            <span className="d-sm-none">Workflows</span>
                            <span className="d-sm-block d-none">Workflows</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activePill}  style={ this.state.activePill === '5' ? { background: 'none' } : {}}>
                    <TabPane tabId="1">
                        <TransactionSummary 
                            t={t} 
                            handleChange={this.handleChange} 
                            handleDateChange={this.handleDateChange} 
                            handleStatusChange={this.handleStatusChange} 
                            toggleModal={this.toggleModal}
                            financials={t.Financials}
                            updateTransaction={this.updateTransaction}
                            handleDeleteTransaction={this.handleDeleteClick}
                            sourceOptions={this.state.sourceOptions}
                            ref={this.summaryRef}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <PropertyDetails 
                            transactionId={this.props.match.params.transactionId} 
                            transaction={t} 
                            updateTransaction={this.updateTransaction}
                            handleAddressChange={this.handleChange}
                            fetchTransaction={this.fetchTransaction}
                            />
                    </TabPane>
                    <TabPane tabId="3">
                        <TransactionTasks 
                            transactionId={this.props.match.params.transactionId}
                            onRef={ref => (this.tasks = ref)}
                             />
                    </TabPane>
                    <TabPane tabId="4">
                        <Documents transactionId={this.props.match.params.transactionId} />
                    </TabPane>
                    <TabPane tabId="5">
                        <People 
                            transactionType={t.Type} 
                            transactionId={this.props.match.params.transactionId} 
                            transactionAgentId={t.AgentId}
                        />
                    </TabPane>
                    <TabPane tabId="6">
                        <Workflows 
                            transactionId={this.props.match.params.transactionId}
                            onRef={ref => (this.workflows = ref)} 
                            />
                    </TabPane>
                </TabContent>
                <CloseTransaction 
                    t={t} 
                    toggleModal={this.toggleModal} 
                    isOpen={closeTransactionModal} 
                    handleChange={this.handleChange}
                    handleDateChange={this.handleDateChange}
                    closingTransaction={closingTransaction}
                    handleCloseTransaction={this.closeTransaction}
                    people={this.state.people}
                    handleCheckChange={this.handleCheckChange}
                />
                <Modal isOpen={syncMlsModal}>
                    <ModalHeader toggle={() => this.toggleModal('syncMlsModal')}>Sync With MLS</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="form-group col-12">
                                <label>MLS Id</label>
                                <input className="form-control width-250" name="syncListingKey" value={syncListingKey} onChange={(e) => this.setState({ syncListingKey: e.target.value })} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary-outline" onClick={() => this.toggleModal('syncMlsModal')}>Cancel</button>
                        <button className="btn btn-primary" onClick={() => this.handleSyncWithMls()}>
                            { syncingWithMls ?
                                <div className="button-spinner"></div>
                                :
                                "Sync"
                            }
                            </button>
                    </ModalFooter>
                </Modal>
                <div className="status-footer bg-white fixed-bottom with-top-shadow" style={{ height: '60px', marginLeft: '220px', fontSize: '16px', padding: '20px 30px' }}>
                    { savingChanges ?
                    <div><i className="fas fa-spinner fa-pulse text-primary m-r-15"></i> Saving Changes...</div>
                    :
                    <div><i className="fas fa-check text-green m-r-15"></i> All Changes Saved</div>
                    }
                </div>
            </div>
        )
    }
}

export default Transaction;