import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddActivity from './AddActivity';
import PropTypes from 'prop-types';


function ActivityModal({contactId, isOpen, toggleModal, title = 'Add Activity', updateList = null }){
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('activity')}>{title}</ModalHeader>
            <ModalBody>
                <AddActivity toggleModal={toggleModal} contactId={contactId} title={title} updateList={updateList} />
            </ModalBody>
        </Modal>
    )
}

export default ActivityModal;

ActivityModal.propTypes = {
    contactId: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    updateList: PropTypes.func
}