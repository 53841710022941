import React from 'react';
import { Panel, PanelBody } from '../panel/panel';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { getData, putData } from '../../helpers/AxiosService';

class Notifications extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            settings: [],
            hasChanges: false
        }
    }

    componentDidMount(){
        this.fetchSettings();
    }

    fetchSettings(){
        getData('api/setting/notification').then(response => {
            this.setState({ settings: response.data, loading: false, hasChanges: false });
        })
    }

    handleChange(e, i){
        const { name } = e.target;
        const isChecked = e.target.checked;

        let settingCopy = this.state.settings.slice();
        settingCopy[i][name] = isChecked;
        this.setState({ settings: settingCopy, hasChanges: true });
    }

    handleSave(){
        this.setState({ loading: true });
        putData('api/setting/notification', this.state.settings).then(response => {
            this.fetchSettings();
        });
    }

    render(){
        const { loading, settings, hasChanges } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>

                <Panel>
                    <PanelBody>
                        <div className="row">
                            <div className="col-md-6">
                                Use the following settings to get notifications when the specified event occurs. 
                                Ensure that your Notification Email Address and Cell Phone are correct on your profile page.
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
                <div>
                    <button className={classnames({ "hide": !hasChanges }, "btn btn-primary pull-right m-b-15")} onClick={() => this.handleSave()}>Save Changes</button>
                </div>
                <div className="table-responsive with-thin-shadow">
                    <table className="table thick-rows table-bordered table-striped bg-white m-b-0">
                        <thead>
                            <tr>
                                <th>Notification</th>
                                <th>Description</th>
                                <th>Text</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            { settings.length > 0 &&
                                settings.map((setting, i) => (
                                    <tr key={i}>
                                        <td>{setting.NotificationName}</td>
                                        <td>{setting.Description}</td>
                                        <td className="with-checkbox">
                                            <div className="row">
                                                <div className={classnames({ 'hide': setting.EmailOnly })} style={{ margin: 'auto' }}>
                                                    <div className="checkbox checkbox-css">
                                                        <input 
                                                            name="IsText" 
                                                            type="checkbox" 
                                                            checked={setting.IsText} 
                                                            id={`text_${setting.SettingCode}`} 
                                                            onChange={e => this.handleChange(e, i)}
                                                            />
                                                        <label htmlFor={`text_${setting.SettingCode}`}>&nbsp;</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="with-checkbox">
                                            <div className="row">
                                                <div style={{ margin: 'auto' }}>
                                                    <div className="checkbox checkbox-css">
                                                        <input 
                                                            name="IsEmail" 
                                                            type="checkbox" 
                                                            checked={setting.IsEmail} 
                                                            id={`email_${setting.SettingCode}`} 
                                                            onChange={e => this.handleChange(e, i)}
                                                            />
                                                        <label htmlFor={`email_${setting.SettingCode}`}>&nbsp;</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12">
                    <button className={classnames({ "hide": !hasChanges }, "btn btn-primary pull-right m-b-15")} onClick={() => this.handleSave()}>Save Changes</button>
                </div>
            </div>
        )
    }
}

export default Notifications;