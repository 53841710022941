import React from 'react';

export default function AddByListingKey(props) {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h5>Add property from MLS</h5> 
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2 text-center m-t-20">
                    <label>MLS Id</label>
                    <input className="form-control width-250" name="mlsId" value={props.mlsId} onChange={props.handleChange} style={{ margin: 'auto' }}/> <br />
                    - or - <br />
                    <button className="btn btn-lime width-250 m-b-20 m-t-20" onClick={props.next}>Add Manually</button> <br />
                </div>
            </div>
        </div>
    )
}