import React from 'react';
import Select from 'react-select';

function Conditions(props) {
    switch(props.eventCode) {
        case 'tag_added':
        case 'contact_created':
            return props.eventConditions.map(ev => {
                return (
                    <div key={ev.Id}>
                        <div className="m-b-10"><strong>AND</strong></div>
                        <div className="form-group">
                            <label>{ev.Condition} is:</label>
                            <Select value={props.selectedConditions} isMulti closeMenuOnSelect={false} options={ev.Options} className="p-l-0 p-r-0" onChange={props.handleConditionChange}/>
                        </div>
                    </div>
                )
            });
        default:
            console.log('not found');
            return null;
    }
}

export default Conditions;