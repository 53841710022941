import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardTitle, CardBody, ModalFooter } from 'reactstrap';
import DynamicSearchFilters from '../leads/DynamicSearchFilters';
import { getData, postData, putData } from '../../helpers/AxiosService';
import { Op } from '../../helpers/OperationTypes';
import { PageSettings } from '../../config/page-settings';

export default function SalesListModal({ isOpen, toggleModal, selectedList, fetchLists }) {
    const cxt = useContext(PageSettings);
    const [filters, setFilters] = useState({});
    const [filterOptions, setFilterOptions] = useState([]);
    const [list, setList] = useState({ Name: '', Description: '' })
    const [saving, setSaving] = useState(false);

    async function fetchFilterOptions() {
        const resp = await getData('api/filter/filteroptions');
        
        let rawFilters = {};
        resp.data.forEach((f, idx) => {
            rawFilters[f.Field] = { PropertyName: f.Field, FilterType: f.FilterType, Operation: f.DefaultOperation, Value: '' };
        });

        setFilters(rawFilters);
        setFilterOptions(resp.data);
        
        if (selectedList && selectedList.Id) {
            setList(selectedList);
        }
    }

    useEffect(() => {
        const preppedFilters = prepSelectedFilters(filters);
        setFilters(preppedFilters);
    }, [list]);

    function handleFilterOperationChange(e) {
        const { name, value } = e.target;
        let newFilters = Object.assign({}, filters);
        newFilters[name].Operation = value;
        setFilters(newFilters);
    }

    function handleFilterValueChange(e) {
        const { name, value } = e.target;
        // cleaning out all the dollar characters
        let cleanValue = value;
        if (typeof value === 'string') {
            cleanValue = value.replace("$", "").replace(/,/g, "");
        }

        var newFilters = Object.assign({}, filters);
        newFilters[name].Value = cleanValue;

        setFilters(newFilters);
    }

    function handleMultiChange(value, { action, removedValue, name}) {
        let field = Object.assign({}, filters[name]);
        
        switch(action){
            case 'select-option':
                field.Value = [];
                value.forEach(tag => field.Value.push(tag));
                break;
            case 'remove-value':
                field.Value = field.Value.filter(tag => tag.value != removedValue.value)
                break;
            case 'clear':
                field.Value = [];
                break;
            default:
                break;
        }

        setFilters({...filters, [name]: field });
    }

    async function handleSaveList() {
        setSaving(true);

        const data = {
            ...list,
            Filters: generateFilterList(),
        }

        try {
            if (data.Id) {
                await putData('api/salesreport', data, { context: cxt });
            } else {
                await postData('api/salesreport', data, { context:  cxt });
            }
            fetchLists();
            setSaving(false);
            toggleModal();
        } catch (e) {
            console.log(e);
            setSaving(false);
        }

    }

    function generateFilterList() {
        const stateFilters = Object.assign({}, filters);
        let filterList = [];
        for (var key in stateFilters){
            let filter = stateFilters[key];
            let op = stateFilters[key].Operation;
                
            if ((filter.Value && filter.Operation) || op === Op.isEmpty || op === Op.isNotEmpty || op === Op.isNull || op === Op.isNotNull || op === Op.isTrue || op === Op.isFalse){
                filterList.push(stateFilters[key]);
            }
        }
        
        return filterList;
    }

   function prepSelectedFilters(rawFilters){
        let preppedFilters = Object.assign({}, rawFilters);

        if (list.Filters) {
            list.Filters.forEach((element, i) => {
                preppedFilters[element.PropertyName].Operation = element.OperationString;
                preppedFilters[element.PropertyName].Value = element.Value;
            });
        }
        
        return preppedFilters;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setList({...list, [name]: value });
    }

    useEffect(() => {
        fetchFilterOptions();
    }, []);

    return (
        <Modal size="xl" isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal()}>View & Filter List</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label>List Name</label>
                        <input name="Name" value={list.Name} onChange={handleChange} className="form-control" />
                    </div>
                    <div className="col-md-8 form-group">
                        <label>Description</label>
                        <input name="Description" value={list.Description} onChange={handleChange} className="form-control" />
                    </div>
                </div>
                <h5 className="m-t-25">Filters</h5>
                <DynamicSearchFilters
                    filters={filters}
                    filterOptions={filterOptions}
                    handleFilterOperationChange={handleFilterOperationChange}
                    handleFilterValueChange={handleFilterValueChange}
                    handleMultiChange={handleMultiChange}
                    clearAllFilters={fetchFilterOptions}
                    isFilterBuilder={true}
                />
            </ModalBody>
            <ModalFooter>
                <button className="btn" onClick={() => toggleModal()}>Cancel</button>
                <button className="btn btn-primary" onClick={() => handleSaveList()}>
                    { saving ? <div className="button-spinner"></div> : "Save" }
                </button>
            </ModalFooter>
        </Modal>
    )
}