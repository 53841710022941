import React from 'react';
import { Device } from '@twilio/voice-sdk';
import { postData } from '../../helpers/AxiosService';
import { useRowState } from 'react-table';
import { PageSettings } from '../../config/page-settings';

let device = null;

const callStates = {
    REGISTERING: 'Registering',
    READY: 'Ready',
    INCOMING: 'Incoming',
    CONNECTING: 'Connecting',
    ON_CALL: 'On Call',
    OFFLINE: 'Offline',
}

export default class DialerWindow extends React.Component {
    static contextType = PageSettings;
    static defaultProps = {
        closeDialer: () => {},
        contact: { ContactDetails: {} },
        phoneType: 'CellPhone',
    }

    constructor(props) {
        super(props);
        this.state = {
            muted: false,
            onPhone: false,
            callState: callStates.CONNECTING,
            call: null,
            minutes: 0,
            seconds: 0,
            interval: null,
        }
        this.handleHangup = this.handleHangup.bind(this);
    }

    componentDidMount() {
        postData(`voice/token?userId=${this.context.currentUserId}`).then(resp => {
            device = new Device(resp.data.token);
            device.on('registering', () => this.setState({ callState: callStates.REGISTERING }));
            device.on('registered', () => this.setState({ callState: callStates.READY }));
            if (this.props.contact) {
                this.handleCall();
            }
        }).fail(e => console.log(e));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contact && this.props.contact) {
            if (prevProps.contact.ContactId !== this.props.contact.ContactId) {
                console.log('dialing...')
            }
        }

        if (!prevProps.contact && this.props.contact) {
            console.log('initial dialing...');
        }
    }

    componentWillUnmount() {
        device.destroy();
    }

    async handleCall() {
        if (!this.state.onPhone) {
            this.setState({ 
                muted: false,
                onPhone: true,
                callState: callStates.CONNECTING
            });

            const { ContactDetails } = this.props.contact;
            const number = `+1${ContactDetails[this.props.phoneType]}`;

            let call = await device.connect({
                params: {
                    To: number,
                    contactId: this.props.contact.ContactId,
                    userId: this.context.currentUserId
                }
            });

            call.on('disconnect', () => {
                if (device) {
                    device.disconnectAll();
                }
                this.setState({ call: null, onPhone: false, callState: callStates.OFFLINE }, this.props.closeDialer);
            });

            this.setState({ call, callState: callStates.ON_CALL, onPhone: true }, this.startTimer);
        }
    }

    handleHangup() {
        if (device) {
            device.disconnectAll();
        }
        this.props.closeDialer();
    }

    handleToggleMute() {
        let { call, muted } = this.state;
        call.mute(!muted);
        this.setState({ muted: !muted });
    }

    startTimer() {
        const interval = setInterval(() => {
            const { minutes, seconds } = this.state;
            if (seconds === 59) {
                this.setState({ seconds: 0, minutes: minutes + 1 })
            } else {
                this.setState({ seconds: seconds + 1 });
            }
        }, 1000)
        this.setState({ interval });
    }

    stopTimer() {
        clearInterval(this.state.interval);
    }

    render() {
        const { muted, callState, minutes, seconds } = this.state;
        return (
            <div className="dialer-window" style={{ height: '100px' }}>
                <div className="float-right dialer-controls">
                    <div className="dialer-message">
                        <div className="dialer-text">
                            Calling { this.props.contact && <span><b>{this.props.contact.ContactDetails.Name} </b>({this.props.phoneType})</span>}
                        </div>
                        { callState === callStates.CONNECTING && 
                        <div className="m-t-10">
                            Connecting...
                        </div> }
                        { callState === callStates.ON_CALL && 
                        <div className="m-t-10">
                            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </div> }
                    </div>
                    <div className="dialer-actions">
                        { muted ?
                            <div className="text-center m-r-10 width-80">
                                <button className="btn btn-white btn-lg btn-icon btn-circle" onClick={() => this.handleToggleMute()}>
                                    <i className="fas fa-microphone-slash"></i>
                                </button>
                                <div>
                                    Muted
                                </div>
                            </div>
                            :
                            <div className="text-center m-r-10 width-80">
                                <button className="btn btn-white-outline btn-lg btn-icon btn-circle" onClick={() => this.handleToggleMute()}>
                                    <i className="fas fa-microphone"></i>
                                </button>
                                <div>
                                    Mute
                                </div>
                            </div>
                        }
                        <div className="text-center">
                            <button className="btn btn-danger btn-lg btn-icon btn-circle" onClick={this.handleHangup}>
                                <i className="fas fa-phone" style={{ transform: 'translateY(2px) rotate(-135deg)' }}></i>
                            </button>
                            <div>
                                Hang Up
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}   