import React from 'react';
import EmailTextEditor from './EmailTextEditor';
import { getData, postData } from '../../helpers/AxiosService';
import classnames from 'classnames';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class EmailTemplateEditor extends React.Component{
    constructor(props){
        super(props);
        this.state = this.getInitialState();

        this.handleBodyChange = this.handleBodyChange.bind(this);
    }

    getInitialState = () => {
        const initialState = {
            templateId: 0,
            templateName: '',
            templateSubject: '',
            templateBody: '',
            templateVideoGuid: '',
            videoThumbnailUrl: '',
            sharedWithGroup: false,
            editor: {
                height: 300
            },
            saving: false,
            hasError: false,
            videos: [],
        };

        return initialState;
    };

    componentDidMount(){
        if (this.props.template){
            this.setTemplate(this.props.template);
        }

        this.getUserVideos();
    }

    componentDidUpdate(prevProps){
        if (this.props.template && prevProps.template !== this.props.template){
            this.setTemplate(this.props.template);
        }
    }

    setTemplate(template){
        this.setState({
            templateId: template.EmailTemplateId || template.TextTemplateId,
            templateName: template.Name,
            templateSubject: template.Subject,
            templateBody: template.Body,
            sharedWithGroup: template.SharedWithGroup,
            templateVideoGuid: template.VideoGuid,
        });

        if (template.VideoGuid) {
            getData(`api/video/${template.VideoGuid}`).then(resp => {
                this.setState({ videoThumbnailUrl: resp.data.ThumbnailUrl });
            });
        }
    }

    getUserVideos() {
        getData('api/video/videos/user').then(resp => {
            this.setState({ videos: resp.data });
        });
    }

    handleSelectVideo(video) {
        this.setState({ templateVideoGuid: video.VideoGuid, videoThumbnailUrl: video.ThumbnailUrl });
    }

    handleSelectVideoForText(video) {
        this.handleSelectVideo(video);
        this.editor.insertVideoLink(`{{video_${video.VideoName}}}`);
    }

    handleClearVideo() {
        this.setState({ templateVideoGuid: null, videoThumbnailUrl: null });
    }
 
    handleChange(item, e){
        this.setState({ [item]: e.target.value });
    }

    handleBodyChange(value){
        this.setState({ templateBody: value });
    }

    toggleSharedWithGroup(){
        this.setState({ sharedWithGroup: !this.state.sharedWithGroup });
    }

    closeResetModal(){
        this.setState(this.getInitialState());
        this.props.toggleModal(true);
    }

    handleSave(){
        //validate
        if (!this.state.templateName || !this.state.templateSubject || !this.state.templateBody){
            this.setState({ hasError: true });
            return;
        }

        this.setState({ saving: true });

        let data = {
            EmailTemplateId: this.state.templateId, 
            Name: this.state.templateName,
            Subject: this.state.templateSubject,
            Body: this.state.templateBody,
            SharedWithGroup: this.state.sharedWithGroup,
            VideoGuid: this.state.templateVideoGuid,  
        }

        postData('api/template', data).then(response => {
            this.setState({ hasError: false, saving: false }, this.closeResetModal)
            this.props.fetchTemplates();
        });
    }

    handleTextSave(){
        if (!this.state.templateName || !this.state.templateBody){
            this.setState({ hasError: true });
            return;
        }

        this.setState({ saving: true });

        let data = {
            TextTemplateId: this.state.templateId, 
            Name: this.state.templateName,
            Body: this.state.templateBody,
            SharedWithGroup: this.state.sharedWithGroup,
            VideoGuid: this.state.templateVideoGuid,  
        }

        postData('api/template/text-template', data).then(response => {
            this.setState({ hasError: false, saving: false }, this.closeResetModal)
            this.props.fetchTemplates();
        });
    }

    render(){
        const { sharedWithGroup, templateSubject, templateName, 
            templateBody, editor, hasError, saving, videos, videoThumbnailUrl, templateVideoGuid } = this.state;
        return(
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className={classnames({ 'has-error': !templateName && hasError}, "form-group")}>
                            <label>Template Name</label>
                            <input className="form-control" value={templateName} onChange={e => this.handleChange('templateName', e)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="checkbox checkbox-css m-b-15">
                            <input type="checkbox" id="cssCheckbox2" checked={sharedWithGroup} onChange={() => this.toggleSharedWithGroup()}/>
                            <label htmlFor="cssCheckbox2">Share With Group</label>
                        </div>
                    </div>
                </div>
                { !this.props.isText &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className={classnames({ 'has-error': !templateSubject && hasError}, "form-group")}>
                                <label>Subject</label>
                                <input className="form-control" value={templateSubject} onChange={e => this.handleChange('templateSubject', e)} />
                            </div>
                        </div>
                    </div>
                }
                { videos && videos.length > 0 && !templateVideoGuid && !this.props.isText &&
                    <UncontrolledButtonDropdown className="m-b-15">
                        <DropdownToggle className="btn btn-white" caret><i className="fas fa-video"></i> Include A Video</DropdownToggle>
                        <DropdownMenu>
                            { videos.map((v, i) => (
                                <DropdownItem key={i} onClick={() => this.handleSelectVideo(v)}>{v.VideoName}</DropdownItem>
                            )) }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown> }
                { videos && videos.length > 0 && this.props.isText &&
                    <UncontrolledButtonDropdown className="m-b-15">
                        <DropdownToggle className="btn btn-white" caret><i className="fas fa-video"></i> Include A Video</DropdownToggle>
                        <DropdownMenu>
                            { videos.map((v, i) => (
                                <DropdownItem key={i} onClick={() => this.handleSelectVideoForText(v)}>{v.VideoName}</DropdownItem>
                            )) }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                }
                { !this.props.isText && videoThumbnailUrl && 
                    <div className="row m-b-15" style={{ position: 'relative' }}>
                        <div className="col-12">
                            <img src={videoThumbnailUrl} alt="video-thumbnail" className="w-100" />
                        </div>
                        <button 
                            className="btn btn-danger" 
                            style={{ position: 'absolute', top: '0', right: '10px' }}
                            onClick={() => this.handleClearVideo()}
                            >Remove Video</button>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <EmailTextEditor 
                                handleChange={this.handleBodyChange}
                                text={templateBody}
                                editor={editor}
                                onRef={ref => (this.editor = ref)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row m-t-35">
                    <div className="col-md-12">
                        {
                            this.props.isText ?
                            <button className="btn btn-primary pull-right m-l-10" onClick={() => this.handleTextSave()}>
                                { saving ? <div className="button-spinner"></div> : "Save" }
                            </button>
                            :
                            <button className="btn btn-primary pull-right m-l-10" onClick={() => this.handleSave()}>
                                { saving ? <div className="button-spinner"></div> : "Save" }
                            </button>
                        }
                        
                        <button className="btn btn-primary-outline pull-right" onClick={() => this.closeResetModal()}>Cancel</button>
                    </div>
               </div>
            </div>
        )
    }
}

export default EmailTemplateEditor;