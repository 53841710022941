import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function SourceModal({ isOpen, toggleModal, sourceOptions, handleChange, handleChangeSource, sending }){
    const sources = sourceOptions.length === 0 ? null : sourceOptions.map((source, i) => <option key={i} value={source.Key}>{source.Value}</option>)
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('sourceModal')}>Change Source</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label>Source</label>
                    <select name="selectedSourceId" className="form-control" onChange={e => handleChange(e)}>
                        <option></option>
                        { sources }
                    </select>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-lime-outline" onClick={() => toggleModal('sourceModal')}>Cancel</button>
                <button className="btn btn-lime" disabled={sending} onClick={() => handleChangeSource()}>
                    { sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}