import React from 'react';
import TransactionTaskReport from '../components/reports/TransactionTaskReport';
import CommissionChart from '../components/reports/CommissionChart';
import TransactionClosingReport from '../components/reports/TransactionClosingReport';
import TransactionByType from '../components/reports/TransactionByType';
import TransactionByStatus from '../components/reports/TransactionByStatus';
import CommissionsBySource from '../components/reports/CommissionsBySource';

class TransactionDashboard extends React.Component {
	render() {
		return (
			<div>
				<h1 className="page-header">Transaction Dashboard <small>Know exactly where you're at</small></h1>
				<div className="row">
					<div className="col-md-12 col-lg-4">
						<CommissionsBySource />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-lg-4">
						<CommissionChart />
					</div>
					<div className="col-md-12 col-lg-4">
						<TransactionByType />
					</div>
					<div className="col-md-12 col-lg-4">
						<TransactionByStatus />
					</div>
					<div className="col-md-12 col-lg-6">
						<TransactionClosingReport />
					</div>
					<div className="col-md-12 col-lg-6">
						<TransactionTaskReport />
					</div>
				</div>
			</div>
		)
	}
}

export default TransactionDashboard;