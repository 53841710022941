import React from 'react';
import { Panel, PanelHeader, PanelFooter } from '../panel/panel';
import Conversation from './Conversation';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import { getData, postData } from '../../helpers/AxiosService';
import { Link } from 'react-router-dom';


// var messages = [{ IsMine: true, Text: 'Hi friend', Timestamp: new Date(), AuthorFirstName: 'Jordan', AuthorLastName: 'Short' }, 
// { IsMine: false, Text: 'Well hello there', Timestamp: new Date(), AuthorFirstName: 'Phil' , AuthorLastName: 'Knight'}, 
// { IsMine: true, Text: 'What do you need from me?', Timestamp: new Date(), AuthorFirstName: 'Jordan', AuthorLastName: 'Short' }, 
// { IsMine: false, Text: 'Can you show me some houses?', Timestamp: new Date(), AuthorFirstName: 'Phil' , AuthorLastName: 'Knight'}, 
// { IsMine: true, Text: 'Of Horse!', Timestamp: new Date(), AuthorFirstName: 'Jordan', AuthorLastName: 'Short' }, 
// { IsMine: true, Text: '*Course', Timestamp: new Date(), AuthorFirstName: 'Jordan', AuthorLastName: 'Short' }, 
// { IsMine: true, Text: 'D*mn autocorrect!', Timestamp: new Date(), AuthorFirstName: 'Jordan', AuthorLastName: 'Short' }, 
// ]

class TextWindow extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messages: [],
            loading: true,
            message: '',
            sending: false,
            textTemplates: [],
        }
    }

    static defaultProps = {
        expand: false
    }

    componentDidMount(){
        if (this.props.number){
            this.fetchConversation();
        }
        this.props.onRef(this);
        this.fetchTextTemplates();
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    fetchTextTemplates = () => {
		getData('api/template/text-templates').then(response => this.setState({ textTemplates: response.data }));
	}

    fetchConversation(){
        const { number } = this.props;
        getData(`api/message/text/conversation/by-number?number=${number}`).then(response => {
            this.setState({ messages: response.data, loading: false }, this.scrollToBottom);
        });
    }

    scrollToBottom(){
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e){
        e.preventDefault();
        const { message } = this.state;

        if (!message) return;

        this.setState({ sending: true });
        let data = {
            ToAddress: this.props.number,
            Body: this.state.message,
            ToContactId: this.props.contactId
        }

        postData('api/message/text', data).then(response => {
            this.fetchConversation();
            this.setState({ message: '', sending: false });
        });
    }

    displayTemplateOptions(){
        return this.state.textTemplates.map(t => (
            <DropdownItem key={t.TextTemplateId} onClick={() => this.handlePopulateTemplate(t.Body)}>{t.Name}</DropdownItem>
        ));
    }

    handlePopulateTemplate(templateString){
        getData(`api/template/populate-template?contactId=${this.props.contactId}&template=${templateString}`).then(response => {
            this.setState({ message: response.data });
        });
    }

    render(){
        const { messages, loading, message, sending } = this.state;
        const { index } = this.props;
        return(
            <div className="text-window m-l-15">
                <Panel theme="primary" className="bg-silver with-shadow">
                    <PanelHeader noButton={true}>
                        
                        {this.props.name ? <Link className="d-inline" to={`/contact/${this.props.contactId}`}>{this.props.name}</Link> : this.props.number}
                        <span className="pull-right f-s-18">
                            <i className={classnames({ 'hide' : this.props.expand }, "fas fa-window-maximize m-r-10 cursor-pointer expand-text")} onClick={() => this.props.toggleExpand(index)}></i>
                            <i className={classnames({ 'hide': !this.props.expand }, "fas fa-window-minimize m-r-10 cursor-pointer expand-text")} onClick={() => this.props.toggleExpand(index)}></i> 
                            <i className="fas fa-times cursor-pointer" onClick={() => this.props.closeConversation(index)}></i>
                        </span>
                    </PanelHeader>
                    <div className={classnames("chats", { 'hide': !this.props.expand})}>
                        <div className={classnames({ 'show': loading }, 'fade')} id="modal-loader"><div className="spinner"></div></div>
                        <p className="text-center">This is the beginning of your message history with {this.props.name ? this.props.name : this.props.number}</p>
                        <Conversation messages={messages} loading={loading} />
                        <div ref={(el) => { this.messagesEnd = el; }} style={{ height: '10px', width: '100%' }}></div>
                    </div>
                    <PanelFooter className={classnames({ 'hide': !this.props.expand})}>
                        <form onSubmit={(e) => this.handleSubmit(e)} name="send_message_form" data-id="message-form">
                            <div className="input-group">
                                <textarea type="text" rows="3" className="form-control" name="message" placeholder="Enter your message here." value={message} onChange={(e) => this.handleChange(e)} />
                                {/* <input type="text" className="form-control" name="message" placeholder="Enter your message here." value={message} onChange={(e) => this.handleChange(e)} /> */}
                            </div>
                            <div className="row m-t-10">
                                <div className="col-4">
                                    <UncontrolledButtonDropdown direction="up">
                                        <DropdownToggle className="btn btn-sm btn-primary-outline">Templates</DropdownToggle>
                                        <DropdownMenu>
                                            { this.state.textTemplates.length > 0 ? 
                                                this.displayTemplateOptions()
                                                :
                                                <DropdownItem>No Templates To Display</DropdownItem>
                                            }
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                                <div className="col-8">
                                    <button disabled={sending} type="submit" className="btn btn-sm btn-primary" style={{ width: '100%' }}>
                                        { sending ?
                                            <div className="button-spinner"></div>
                                            :
                                            "Send"
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </PanelFooter>
                </Panel>
            </div>
        )
    }
}

export default TextWindow;