import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';


export default function TagAssign({ isOpen, toggleModal, tags, suggestions, handleTagSelect, handleSubmitTags, handleDeleteTag, sending }){
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('tagModal')}>Add A Tag</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label>Tags</label>
                    <ReactTags 
                        autoFocus={false} 
                        allowNew={true} 
                        tags={tags} 
                        suggestions={suggestions} 
                        onDelete={handleDeleteTag} 
                        onAddition={handleTagSelect}
                        minQueryLength={1}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-lime-outline" onClick={() => toggleModal('tagModal')}>Cancel</button>
                <button className="btn btn-lime" disabled={sending} onClick={() => handleSubmitTags()}>
                    { sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}