import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ReassignLender({ isOpen, toggleModal, lenderOptions, handleChange, handleReassignLender, sending }){
    const lenders = lenderOptions.length === 0 ? null : lenderOptions.map((lender, i) => <option key={i} value={lender.Key}>{lender.Value}</option>)
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('lenderModal')}>Reassign Lender</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label>Lender</label>
                    <select name="selectedLender" className="form-control" onChange={e => handleChange(e)}>
                        <option></option>
                        { lenders }
                    </select>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-lime-outline" onClick={() => toggleModal('lenderModal')}>Cancel</button>
                <button className="btn btn-lime" disabled={sending} onClick={() => handleReassignLender()}>
                    { sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}