import React from 'react';
import './FieldWrapper.css';

export default function FieldWrapper(props) {
    return (
        <div className="field-wrapper f-s-14">
            {props.children}
            <i 
                className="fas fa-pen cursor-pointer pull-right"
                onClick={() => props.handleEditClick(props.fieldName)}
            ></i>
        </div>
    )
}