import React from 'react';
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { displayFormalDateTimeUtc } from '../../../helpers/Functions';
import Parser from 'html-react-parser';
import './ActivityItem.css';


export default function ActivityItem({ item, iconCss, title, textColor, isEditable, handleEditClick }){
    return (
        <ListGroupItem>
            <ListGroupItemHeading className="m-b-4">
                <i className={`${iconCss} m-r-10 ${textColor}`}></i>{item.PerformedBy} - <span style={{ fontWeight: 'lighter', fontSize: '.75rem' }}>{title}</span>
                <span className="pull-right f-s-10 semi-bold">{displayFormalDateTimeUtc(item.DateCreated, true)}</span>
                { item.DateWatchedUtc && <span className="label label-lime m-l-10">Watched {displayFormalDateTimeUtc(item.DateWatchedUtc)}</span> }
                <br />
            </ListGroupItemHeading>
            { item.Recipient && <p className="semi-bold header">To: {item.Recipient}</p> }
            { item.Subject && <p className="semi-bold header">Subject: {item.Subject}</p>}
            <div className="row">
                <ListGroupItemText className="col-10" id="description">
                    {item.Description && Parser(item.Description)}
                </ListGroupItemText>
                { 
                    isEditable && 
                    <div className="col-2 text-center">
                        <i 
                            className="fas fa-pen cursor-pointer pull-right"
                            onClick={() => handleEditClick(item)}
                        ></i>
                    </div>
                }
            </div>
        </ListGroupItem>
    )
}