import React from 'react';
import ReportContainer from './ReportContainer'
import { DateOp } from '../../helpers/OperationTypes';
import { displayDateWorded } from '../../helpers/Functions';
import { Link } from 'react-router-dom';




export default function AnniversaryReport() {
    return (
        <ReportContainer 
            title="Upcoming Anniversaries"
            description="These are contacts that have upcoming purchase or sale transaction anniversaries"
            reportType="anniversary"
            startingRange="This Week"
            startingRangeOp={DateOp.thisWeek}
            reportField="Anniversary"
            rangeDirection='future'
        >
            {data => (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Anniversary Type</th>
                            <th>Anniversary Date</th>
                            <th>Agent</th>
                            {/* <th>Lender</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        { data.length > 0 && data.map((contact) => (
                            <tr key={contact.ContactId + contact.AnniversaryDate}>
                                <td><Link to={`/contact/${contact.ContactId}`}>{contact.Name || "Unknown"}</Link></td>
                                <td>{contact.AnniversaryType}</td>
                                <td>{displayDateWorded(contact.AnniversaryDate)}</td>
                                <td>{contact.AgentName || 'Unassigned'}</td>
                                {/* <td>{contact.LenderName || 'Unassigned' }</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </ReportContainer>
    )
}