import React from 'react';
import NewContactReport from '../components/reports/NewContactReport';
import ExpiringTimeframes from '../components/reports/ExpiringTimeframes';
import BirthdayReport from '../components/reports/BirthdayReport';
import AnniversaryReport from '../components/reports/AnniversaryReport';
import TaskReport from '../components/reports/TaskReport';
import ProfileProgress from '../components/user/ProfileProgress';
import LeadsBySource from '../components/reports/LeadsBySource';
import { PageSettings } from '../config/page-settings';
import Appointments from '../components/appointments/Appointments';

class Home extends React.Component {
	static contextType = PageSettings;
	render() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6 mt-auto">
						<h1 className="page-header">Dashboard <small>A quick look at some of your hottest action items</small></h1>
					</div>
					{ this.context.showProfileProgress && <div className="col-md-6">
						<div className="pull-right">
							<ProfileProgress />
						</div>
					</div> }
				</div>
				<div className="row">
					<div className="col-12 col-md-4">
						<LeadsBySource />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-lg-8">
						<NewContactReport />
					</div>
					<div className="col-md-12 col-lg-4">
						<Appointments appointmentOwner={this.context.userInfo.RoleId === 2 ? 'tenant' : 'user'} />
					</div>
					<div className="col-md-12 col-lg-6">
						<TaskReport />
					</div>
					<div className="col-md-12 col-lg-6">
						<ExpiringTimeframes />
					</div>
					<div className="col-md-12 col-lg-6">
						<BirthdayReport />
					</div>
					<div className="col-md-12 col-lg-6">
						<AnniversaryReport />
					</div>
				</div>
			</div>
		)
	}
}

export default Home;