import React from 'react';
import ReassignAgent from './MassActions/ReassignAgent';
import ReassignLender from './MassActions/ReassignLender';
import { putData, deleteData, postData, getData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';
import EmailModalBlank from '../email/EmailModalBlank';
import SourceModal from './MassActions/SourceModal';
import TagAssign from './MassActions/TagAssign';
import AssignWorkflow from './MassActions/AssignWorkflow';
import MassText from './MassActions/MassText';

class MassActions extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            agentModal: false,
            lenderModal: false,
            sourceModal: false,
            tagModal: false,
            workflowModal: false,
            textModal: false,
            emailBlank: false,
            selectedAgent: 0,
            selectedLender: 0,
            selectedWorkflow: 0,
            suggestions: [],
            selectedTags: [],
            sending: false,
            workflowOptions: [],
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleReassignAgent = this.handleReassignAgent.bind(this);
        this.handleReassignLender = this.handleReassignLender.bind(this);
        this.handleDeleteContacts = this.handleDeleteContacts.bind(this);
        this.handleChangeSource = this.handleChangeSource.bind(this);
        this.handleTagSelect = this.handleTagSelect.bind(this);
        this.handleSubmitTags = this.handleSubmitTags.bind(this);
        this.handleDeleteTag = this.handleDeleteTag.bind(this);
        this.handleAssignWorkflow = this.handleAssignWorkflow.bind(this);
    }

    componentDidMount(){
        this.setTagSuggestions(this.props.tagOptions);
        this.getWorkflowOptions();
    }

    componentDidUpdate(prevProps){
        if (this.props.tagOptions !== prevProps.tagOptions){
            this.setTagSuggestions(this.props.tagOptions);
        }
    }

    getWorkflowOptions() {
        getData('api/workflow/accessibleworkflows/Contact?onlyactive=true').then(resp => {
            this.setState({ workflowOptions: resp.data });
        });
    }

    toggleModal(modal){
        switch(modal){
            case 'agentModal':
                this.setState({ agentModal: !this.state.agentModal });
                break;
            case 'lenderModal':
                this.setState({ lenderModal: !this.state.lenderModal });
                break;
            case 'emailBlank':
                this.setState({ emailBlank: !this.state.emailBlank });
                break;
            case 'sourceModal':
                this.setState({ sourceModal: !this.state.sourceModal });
                break;
            case 'tagModal':
                this.setState({ selectedTags: [], tagModal: !this.state.tagModal });
                break;
            case 'workflowModal':
                this.setState({ workflowModal: !this.state.workflowModal });
                break;
            case 'textModal':
                this.setState({ textModal: !this.state.textModal });
                break;
            default:
                break;
        }
    }

    handleChange(e){
        const item = e.target.name;
        const val = e.target.value;
        this.setState({ [item]: val });
    }

    handleReassignAgent(){
        this.setState({ sending: true });
        let contacts = this.props.checkedContacts;
        putData(`api/contact/mass/reassign-agent/${this.state.selectedAgent}`, contacts, this).then(response => {
            this.setState({ sending: false });
            this.props.updateResults();
            this.toggleModal('agentModal');
        }).fail(() => this.setState({ sending: false }));
    }

    handleReassignLender(){
        this.setState({ sending: true });
        let contacts = this.props.checkedContacts;
        putData(`api/contact/mass/reassign-lender/${this.state.selectedLender}`, contacts, this).then(response => {
            this.setState({ sending: false });
            this.props.updateResults();
            this.toggleModal('lenderModal');
        }).fail(() => this.setState({ sending: false }));
    }

    handleDeleteClick(){
        this.context.messageConfirmation('Delete Contacts', `Are you sure you want to delete ${this.props.checkedContacts.length} contacts?`, 
                    this.handleDeleteContacts, "Contacts deleted successfully", "Delete");
    }

    handleDeleteContacts(){
        this.context.toggleSweetAlert('confirm');
        const contacts = this.props.checkedContacts;
        putData('api/contact/mass/delete', contacts, this).then(response => {
            if (response.data){
                this.context.toggleSweetAlert('success');
                this.props.updateResults();
            }
        })
    }

    handleChangeSource(){
        this.setState({ sending: true });
        let contacts = this.props.checkedContacts;
        putData(`api/contact/mass/change-source/${this.state.selectedSourceId}`, contacts, this).then(response => {
            this.setState({ sending: false });
            this.props.updateResults();
            this.toggleModal('sourceModal');
        }).fail(() => this.setState({ sending: false }));
    }

    handleAssignWorkflow() {
        this.setState({ sending: true });
        let contacts = this.props.checkedContacts;
        putData(`api/contact/mass/assign-workflow/${this.state.selectedWorkflow}`, contacts, this).then(() => {
            this.setState({ sending: false });
            this.props.updateResults();
            this.toggleModal('workflowModal');
        }).fail(() => this.setState({ sending: false }));
    }

    setTagSuggestions(suggestions){
		let sugList = [];
		suggestions.forEach((tag) => {
			sugList.push({ id: tag.TagId, name: tag.Name });
			return;
		});

		this.setState({ suggestions: sugList }); 
	}

    handleTagSelect(tag){
        var tag = { name: tag.name};
        var tags = [].concat(this.state.selectedTags, tag);
        this.setState({ selectedTags: tags });
    }

    handleDeleteTag(i){
        const tags = this.state.selectedTags.slice(0);
        tags.splice(i, 1);
        this.setState({ selectedTags: tags });
	}

    handleSubmitTags(){
        this.setState({ sending: true });
        let data = {
            Names: [],
            Contacts: this.props.checkedContacts
        };

        this.state.selectedTags.forEach(t => data.Names.push(t.name));

        postData('api/contact/mass/add-tags', data, this).then(response => {
            this.setState({ sending: false });
            this.props.updateResults();
            this.toggleModal('tagModal');
        });
    }

    render(){
        const { agentModal, sending, lenderModal, emailBlank, sourceModal, 
            tagModal, workflowModal, workflowOptions, textModal } = this.state;
        return(
            <div>
                <div className="btn-group with-thin-shadow">
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('emailBlank')}><i className="far fa-envelope"></i> Email</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('textModal')}><i className="far fa-comments"></i> Text</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('agentModal')}><i className="far fa-share-square"></i> Reassign Agent</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('lenderModal')}><i className="far fa-share-square"></i> Reassign Lender</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('tagModal')}><i className="fas fa-hashtag"></i> Add Tags</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('sourceModal')}><i className="far fa-user"></i> Update Source</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.toggleModal('workflowModal')}><i className="far fa-paper-plane"></i> Assign To Workflow</button>
                    <button disabled={this.props.disabled} className="btn btn-white" onClick={() => this.handleDeleteClick()}><i className="far fa-trash-alt"></i> Delete</button>
                </div>
                <ReassignAgent 
                    isOpen={agentModal} 
                    toggleModal={this.toggleModal} 
                    agentOptions={this.props.agentOptions} 
                    handleChange={this.handleChange}
                    handleReassignAgent={this.handleReassignAgent}
                    sending={sending}
                />
                <ReassignLender 
                    isOpen={lenderModal} 
                    toggleModal={this.toggleModal} 
                    lenderOptions={this.props.lenderOptions} 
                    handleChange={this.handleChange}
                    handleReassignLender={this.handleReassignLender}
                    sending={sending}
                />
                <EmailModalBlank 
                    checkedContacts={this.props.checkedContacts}
                    openModal={emailBlank}
                    toggleModal={this.toggleModal}
                    isMassEmail={true}
                />
                <SourceModal
                    isOpen={sourceModal}
                    toggleModal={this.toggleModal}
                    sourceOptions={this.props.sourceOptions}
                    handleChange={this.handleChange}
                    handleChangeSource={this.handleChangeSource}
                    sending={sending}
                />
                <TagAssign 
                    isOpen={tagModal}
                    toggleModal={this.toggleModal}
                    suggestions={this.state.suggestions}
                    tags={this.state.selectedTags}
                    handleTagSelect={this.handleTagSelect}
                    handleSubmitTags={this.handleSubmitTags}
                    handleDeleteTag={this.handleDeleteTag}
                    sending={sending}
                />
                <AssignWorkflow 
                    isOpen={workflowModal}
                    toggleModal={this.toggleModal}
                    handleChange={this.handleChange}
                    workflowOptions={workflowOptions}
                    handleAssignWorkflow={this.handleAssignWorkflow}
                    sending={sending}
                 />
                 <MassText 
                    isOpen={textModal}
                    toggleModal={this.toggleModal}
                    checkedContacts={this.props.checkedContacts}
                 />

            </div>
        )
    }
}

export default MassActions;