import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { CardBody, CardHeader, Card, Collapse } from 'reactstrap';
import { PageSettings } from '../config/page-settings';
import { getSupportArticle } from '../api/Support';


class ProfileSetup extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            activeStep: 0,
            mls: {},
            email: {},
            domain: {},
            website: {},
            notification: {},
            sources: {},
            contacts: {},
        }
    }

    async componentDidMount() {
        const mls = getSupportArticle('connect-mls');
        const email = getSupportArticle('connect-email');
        const domain = getSupportArticle('connect-domain');
        const website = getSupportArticle('access-site-admin');
        const notification = getSupportArticle('setup-notifications');
        const sources = getSupportArticle('setup-sources');
        const contacts = getSupportArticle('import-contacts');

        Promise.all([mls, email, domain, website, notification, sources, contacts]).then(values => {
            this.setState({
                mls: values[0],
                email: values[1],
                domain: values[2],
                website: values[3],
                notification: values[4],
                sources: values[5],
                contacts: values[6],
            });
        });
    }

    toggleCollapse(step) {
        step = step === this.state.activeStep ? 0 : step;
        this.setState({ activeStep: step });
    }

    render(){
        const { activeStep, mls, email, domain, website, notification, sources, contacts } = this.state;
        const { IsMlsConnected, IsEmailConnected, IsDomainSet, IsIdxSetup, IsNotificationSet, IsSourceSet, ImportContacts } = this.context.profileProgress;
        return(
            <div>
                <ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item active">Contact Import</li>
				</ol>
                <h1 className="page-header">Profile Setup <small className="f-w-100">Setup steps you need to be successful</small></h1>
                <div className="panel p-30">
                    <div id="accordion" className="card-accordion">
                        <Card>
                            <CardHeader className="card-header pointer-cursor" onClick={() => this.toggleCollapse(1)}>
                                <i className={"fa f-s-16 mr-2 " + (IsMlsConnected ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Connect to your MLS
                            </CardHeader>
                            <Collapse isOpen={activeStep === 1}>
                                <CardBody>
                                    <div dangerouslySetInnerHTML={{ __html: mls.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card>
                            <CardHeader className="card-header pointer-cursor collapsed" onClick={() => this.toggleCollapse(2)}>
                                <i className={"fa f-s-16 mr-2 " + (IsEmailConnected ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Connect your email
                            </CardHeader>
                            <Collapse isOpen={activeStep === 2}>
                                <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: email.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card>
                            <CardHeader className="card-header pointer-cursor collapsed" onClick={() => this.toggleCollapse(3)}>
                                <i className={"fa f-s-16 mr-2 " + (IsDomainSet ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Add your custom domain
                            </CardHeader>
                            <Collapse isOpen={activeStep === 3}>
                                <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: domain.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card>
                            <CardHeader className="card-header pointer-cursor collapsed" onClick={() => this.toggleCollapse(4)}>
                                <i className={"fa f-s-16 mr-2 " + (IsIdxSetup ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Setup your website content
                            </CardHeader>
                            <Collapse isOpen={activeStep === 4}>
                                <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: website.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card>
                            <CardHeader className="card-header pointer-cursor collapsed" onClick={() => this.toggleCollapse(5)}>
                                <i className={"fa f-s-16 mr-2 " + (IsNotificationSet ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Configure your notifications (at least one)
                            </CardHeader>
                            <Collapse isOpen={activeStep === 5}>
                                <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: notification.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card>
                            <CardHeader className="card-header pointer-cursor collapsed" onClick={() => this.toggleCollapse(6)}>
                                <i className={"fa f-s-16 mr-2 " + (IsSourceSet ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Customize your lead sources (at least one)
                            </CardHeader>
                            <Collapse isOpen={activeStep === 6}>
                                <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: sources.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card>
                            <CardHeader className="card-header pointer-cursor collapsed" onClick={() => this.toggleCollapse(7)}>
                                <i className={"fa f-s-16 mr-2 " + (ImportContacts ? 'fa-check-circle text-lime' : 'fa-circle text-danger')}></i> Import your contacts
                            </CardHeader>
                            <Collapse isOpen={activeStep === 7}>
                                <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: contacts.Content }}></div>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileSetup;