function Deferred(){
    this._done = [];
    this._fail = [];
}

Deferred.prototype = {
    execute: function(list, args){
        let i = list.length;
        args = Array.prototype.slice.call(args);

        while (i--) list[i].apply(null, args);
    },
    resolve: function(){
        this.execute(this._done, arguments);
    },
    reject: function(){
        this.execute(this._fail, arguments);
    },
    then: function(callback){
        this._done.push(callback);
        return this;
    },
    fail: function(callback){
        this._fail.push(callback);
        return this;
    }
}

export default Deferred;