import React, { useState, useEffect } from 'react';
import { getData } from '../helpers/AxiosService';
import classnames from 'classnames';
import { Panel } from '../components/panel/panel';
import { Link } from 'react-router-dom';
import { displayDateWorded } from '../helpers/Functions';

export default function SavedSearches() {
    const [ searches, setSearches ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    async function fetchSearches() {
        try {
            setLoading(true);
            const resp = await getData('api/search/user/searches');
            setSearches(resp.data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchSearches();
    }, []);

    function renderRows() {
        if (!searches || !searches.length) {
            return <tr><td colSpan={4}>No searches yet</td></tr>
        }
        return searches.map((search, i) => (
            <tr key={search.Id}>
                <td><strong>{search.SearchName}</strong></td>
                <td>{search.SearchId}</td>
                <td>{displayDateWorded(search.DateCreated)}</td>
                <td>
                    <Link to={`mls-search/${search.SearchId}/user`} className="btn btn-white m-r-5"><i className="far fa-edit text-primary"></i> Edit</Link>
                    {/* <button className="btn btn-white m-r-5" onClick={() => handleDeleteClick(search.Id)}><i className="far fa-times-circle text-danger"></i> Delete</button> */}
                </td>
            </tr>
        ))
    }

    return (
        <div>
            <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
            <div className="row m-b-15">
                <div className="col-md-12">
                    <Link to="mls-search" className="btn btn-lime p-l-40 p-r-40 pull-right m-t-10">New Saved Search</Link>
                    <h3 className="m-t-10">Saved Searches</h3>
                </div>
            </div>
            <Panel className="p-15">
                <div className="row">
                    <p className="col-md-6">
                        Saved searches are IDX searches that you'll use frequently and can be used in workflows and in pages on your IDX site. 
                    </p>
                </div>
            </Panel>
            <div className="table-responsive with-thin-shadow">
                <table className="table table-bordered table-striped thick-rows bg-white m-b-0">
                    <thead>
                        <tr>
                            <th>Search Name</th>
                            <th>Search Id</th>
                            <th>Date Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}