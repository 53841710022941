import React from 'react';

export function renderStateList(){
    return(
        [
            <option key={1} value="AL">AL</option>,
            <option key={2} value="AK">AK</option>,
            <option key={3} value="AR">AR</option>,	
            <option key={4} value="AZ">AZ</option>,
            <option key={5} value="CA">CA</option>,
            <option key={6} value="CO">CO</option>,
            <option key={7} value="CT">CT</option>,
            <option key={8} value="DC">DC</option>,
            <option key={9} value="DE">DE</option>,
            <option key={10} value="FL">FL</option>,
            <option key={11} value="GA">GA</option>,
            <option key={12} value="HI">HI</option>,
            <option key={13} value="IA">IA</option>,	
            <option key={14} value="ID">ID</option>,
            <option key={15} value="IL">IL</option>,
            <option key={16} value="IN">IN</option>,
            <option key={17} value="KS">KS</option>,
            <option key={18} value="KY">KY</option>,
            <option key={19} value="LA">LA</option>,
            <option key={20} value="MA">MA</option>,
            <option key={21} value="MD">MD</option>,
            <option key={22} value="ME">ME</option>,
            <option key={23} value="MI">MI</option>,
            <option key={24} value="MN">MN</option>,
            <option key={25} value="MO">MO</option>,	
            <option key={26} value="MS">MS</option>,
            <option key={27} value="MT">MT</option>,
            <option key={28} value="NC">NC</option>,	
            <option key={29} value="NE">NE</option>,
            <option key={30} value="NH">NH</option>,
            <option key={31} value="NJ">NJ</option>,
            <option key={32} value="NM">NM</option>,			
            <option key={33} value="NV">NV</option>,
            <option key={34} value="NY">NY</option>,
            <option key={35} value="ND">ND</option>,
            <option key={36} value="OH">OH</option>,
            <option key={37} value="OK">OK</option>,
            <option key={38} value="OR">OR</option>,
            <option key={39} value="PA">PA</option>,
            <option key={40} value="RI">RI</option>,
            <option key={41} value="SC">SC</option>,
            <option key={42} value="SD">SD</option>,
            <option key={43} value="TN">TN</option>,
            <option key={44} value="TX">TX</option>,
            <option key={45} value="UT">UT</option>,
            <option key={46} value="VT">VT</option>,
            <option key={47} value="VA">VA</option>,
            <option key={48} value="WA">WA</option>,
            <option key={49} value="WI">WI</option>,	
            <option key={50} value="WV">WV</option>,
            <option key={51} value="WY">WY</option>
        ]
    )
}