import React from 'react';
import { DateOp } from '../../helpers/OperationTypes';

export default function DatePastInverse({ filter, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10" 
                name={filter.Field}
                value={filters[filter.Field].Value} 
                onChange={handleFilterValueChange}
            >
                <option></option>
                <option value={DateOp.notToday}>Not Today</option>
                <option value={DateOp.threeDaysAgo}>More than 3 days ago</option>
                <option value={DateOp.sevenDaysAgo}>More than 7 days ago</option>
                <option value={DateOp.thirtyDaysAgo}>More than 30 days ago</option>
                <option value={DateOp.ninetyDaysAgo}>More than 90 days ago</option>
                <option value={DateOp.sixMonthsAgo}>More than 6 months ago</option>
            </select>
        </div>
    )
}