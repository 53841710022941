import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import EmailTemplateEditor from './EmailTemplateEditor';

function EmailTemplateModal(props){
    return(
        <div>
            <Modal isOpen={props.isOpen} size="lg">
                <ModalHeader toggle={() => props.toggleModal(true)}>Edit Template</ModalHeader>
                <ModalBody>
                    <EmailTemplateEditor 
                        toggleModal={props.toggleModal}
                        template={props.selectedTemplate}
                        fetchTemplates={props.fetchTemplates}
                        isText={props.isText}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EmailTemplateModal;