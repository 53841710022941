import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { postData, getData } from '../../helpers/AxiosService';
import { Alert } from 'reactstrap';
import { PageSettings } from '../../config/page-settings';
import classnames from 'classnames';


class EmailDocModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            toEmail: '',
            people: [],
            sending: false,
            emails: [],
            fetchingPeople: false,
            addCustomMessage: false,
            step: 1,
            customMessage: ''
        }
    }

    componentDidMount(){
        let id = this.props.transactionId;
        if (id){
            this.fetchPeople(id);
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen){
            this.fetchPeople(this.props.transactionId);
        }
    }

    fetchPeople(id){
        this.setState({ fetchingPeople: true });
        getData(`api/transaction/people/${id}`).then(response => this.setState({ people: response.data, fetchingPeople: false }));
    }

    handleChange(value){
        this.setState({ toEmail: value });
    }

    handleMessageChange(value){
        this.setState({ customMessage: value });
    }

    toggleCheckbox(email){
        let emailCopy = this.state.emails.slice();
        let idx = emailCopy.indexOf(email);
        if (idx === -1){
            emailCopy.push(email);
        } else {
            emailCopy.splice(idx, 1);
        }

        this.setState({ emails: emailCopy });
    }

    handleSend(){
        //validation
        if (this.props.documents.length === 0){
            this.context.addNotification("Wait!", "Please select at least one document to send.", "danger");
            return;
        }

        if (!this.state.email && this.state.emails.length === 0 && !this.state.toEmail){
            this.context.addNotification("Wait!", "Please select at least one recipient.", "danger");
            return;
        }

        if (this.state.addCustomMessage && !this.state.customMessage){
            this.context.addNotification("Wait!", "Please add a message or deselect Add Custom Message.", "danger");
            return;
        }
        
        this.setState({ sending: true });
        let emails = this.state.emails.slice();
        this.state.toEmail && emails.push(this.state.toEmail);
        let documentIds = this.props.documents.map(doc => doc.DocumentId);

        let data = {
            ToAddresses: emails,
            AttachDocumentIds: documentIds,
        };

        if (this.state.addCustomMessage){
            data.Body = this.state.customMessage;
        }

        postData('api/document/email', data).then(response => {
            this.context.addNotification("Success", "Your documents have been sent!")
            this.closeResetModal();
        })
    }

    closeResetModal(){
        this.setState({ toEmail: '', sending: false, emails: [], customMessage: '' });
        this.props.toggleModal();
    }

    changeStep(step){
        this.setState({ step });
    }

    toggleAddCustomMessage(){
        this.setState({ addCustomMessage: !this.state.addCustomMessage, step: 1 });
    }

    renderPeopleRows(){
        return this.state.people.map((person, i) => (
            <tr>
                <td className="with-checkbox">
                    <div className="checkbox checkbox-css">
                        <input type="checkbox" id={`email_${person.TransactionPeopleId}`} onChange={() => this.toggleCheckbox(person.Email)} />
                        <label htmlFor={`email_${person.TransactionPeopleId}`}>&nbsp;</label>
                    </div>
                </td>
                <td><strong>{person.FullName}</strong><br/>
                    {person.Email}
                </td>
                <td>{person.RoleName}</td>
            </tr>
        ))
    }

    render(){
        const { toEmail, people, fetchingPeople, addCustomMessage, step, customMessage } = this.state;
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.closeResetModal()}>Email Document</ModalHeader>
                <ModalBody>
                    <div className={classnames({ 'show': fetchingPeople }, 'fade')} id="modal-loader"><div className="spinner"></div></div>
                    { step === 1 && 
                        <div>
                            <h5>Select one or multiple emails, or add an email manually</h5>
                            { this.props.documents.length > 0 && <label>Documents To Send</label> }
                            {   this.props.documents.length > 0 ?
                                <div className="m-b-20 row">
                                    {this.props.documents.map(doc => (
                                        <div key={doc.DocumentId} className="col-md-6">
                                            <i className="far fa-check-circle text-lime"></i> {doc.Name}
                                        </div>
                                    ))}
                                </div>
                                :
                                <Alert color="danger" isOpen={true}>
                                    <div className="row">
                                        <strong>No documents have been selected to send.</strong>
                                    </div>
                                </Alert>
                            }
                            <div className="form-group">
                                <label>Email Addres</label>
                                <input className="form-control" type="text" value={toEmail} onChange={e => this.handleChange(e.target.value)} />
                            </div>
                            <div>
                                <table className="table">
                                    { people.length > 0 && this.renderPeopleRows() }
                                </table>
                            </div>
                        </div>
                    }
                    {addCustomMessage && step === 2 && <div>
                        <label>Custom Message</label>
                        <textarea className="form-control" rows="10" value={customMessage} onChange={(e) => this.handleMessageChange(e.target.value)}></textarea>
                    </div>}
                </ModalBody>
                <ModalFooter>
                    <div className="checkbox checkbox-css">
                        <input type="checkbox" id="mssgCheckbox" checked={addCustomMessage} onChange={() => this.toggleAddCustomMessage()}/>
                        <label htmlFor="mssgCheckbox">Add Custom Message</label>
                    </div>
                    {addCustomMessage && step === 2 && <button className="btn btn-primary-outline pull-left" onClick={() => this.changeStep(1)}>Back</button>}
                    <button className="btn btn-primary-outline" onClick={() => this.closeResetModal()}>Cancel</button>

                    {(!addCustomMessage || (addCustomMessage && step == 2)) && <button className="btn btn-primary m-l-5" onClick={() => this.handleSend()}>
                        {this.state.sending ? 
                            <div className="button-spinner"></div>
                            :
                            "Send"
                        }
                    </button>}
                    {addCustomMessage && step == 1 && <button className="btn btn-primary m-l-15" onClick={() => this.changeStep(2)}>Next</button>}
                </ModalFooter>
            </Modal>
        )
    }
}

EmailDocModal.contextType = PageSettings;
export default EmailDocModal;