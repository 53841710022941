import React from 'react';
import { getData, putData, postFormData } from '../../helpers/AxiosService';
import { renderStateList } from '../../helpers/StateService';
import house from '../../assets/NoImageHouse.jpg';
import { PageSettings } from '../../config/page-settings';

class PropertyDetails extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            property: {},
            changeMade: false,
            uploading: false,
        }

        this.handleFileChange = this.handleFileChange.bind(this);
    }

    componentDidMount(){
        this.fetchProperty();
    }

    fetchProperty(){
        const id = this.props.transactionId;
        getData(`api/transaction/${id}/property`).then(response => {
            this.setState({ property: response.data });
        })
    }

    handleChange(e){
        let { name, value } = e.target;
        this.setState({ property: {...this.state.property, [name]: value }, changeMade: true })
    }

    updateProperty(){
        if (this.state.changeMade){
            putData(`api/transaction/${this.props.transactionId}/property`, this.state.property).then(response => {
                this.setState({ property: response.data, changeMade: false });
            });
        }
    }

    async handleFileChange(e) {
        this.setState({ uploading: true });
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(`file${i}`, e.target.files[i]);
        }
        
        try {
            await postFormData(`api/transaction/${this.props.transactionId}/image`, formData, this);
            this.props.fetchTransaction();
        } catch (e) {
            console.log(e);
        }

        this.setState({ uploading: false });
    }

    render(){
        const { property, uploading } = this.state;
        const { handleAddressChange, updateTransaction, transaction, transactionId } = this.props;
        return(
            <div>
                <div className="row m-b-25">
                    <div className="col-md-6" style={{ maxHeight: '300px' }}>
                        <div className="" style={{ height: '100%', width: '100%', position: 'relative' }}>
                            { transaction.PropertyImage ?
                                <div style={{ width: '100%', height: '100%' }}>
                                    <img src={transaction.PropertyImage ? transaction.PropertyImage : house} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                                    <div style={{ position: 'absolute', left: '10px', bottom: '10px', width: '65px', height: '30px' }}>
                                        <input 
                                            style={{ opacity: '0', width: '100%', height: '100%', top: '0', left: '0', position: 'absolute' }} 
                                            type="file"
                                            onChange={this.handleFileChange} 
                                        />
                                        <button
                                            className="btn btn-sm btn-default"
                                            disabled={uploading}
                                        >
                                            { uploading ? 
                                            <i className="fas fa-spinner fa-pulse"></i>
                                            :
                                            <div><i className="fas fa-camera"></i> Edit</div>   
                                            }
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="cursor-pointer" style={{ width: '110px', height: '30px', position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '0', right: '0', margin: 'auto' }}>
                                    <input 
                                        style={{ opacity: '0', width: '100%', height: '100%', top: '0', left: '0', position: 'absolute' }} 
                                        type="file"
                                        onChange={this.handleFileChange} 
                                    />
                                    <button 
                                        className="btn btn-lime btn-sm w-100"
                                        disabled={uploading}
                                    >
                                        { uploading ? 
                                        <i className="fas fa-spinner fa-pulse"></i>
                                        :
                                        <div><i className="fas fa-plus"></i> Add Image</div>   
                                        }
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h5 className="m-t-10 text-primary with-border-bottom p-b-10 m-b-25 m-r-5 m-l-5">Address</h5>
                        <div className="row m-b-15">
                            <div className="col-md-8">
                                <label>Address</label>
                                <input 
                                    className="form-control" 
                                    name="Address" 
                                    value={transaction.Address} 
                                    onBlur={() => updateTransaction()} 
                                    onChange={(e) => handleAddressChange(e)} 
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Unit #</label>
                                <input 
                                    className="form-control" 
                                    name="UnitNumber" 
                                    value={transaction.UnitNumber || ''} 
                                    onBlur={() => updateTransaction()} 
                                    onChange={(e) => handleAddressChange(e)} 
                                />
                            </div>
                        </div>
                        <div className="row m-b-15">
                            <div className="col-md-8">
                                <label>City</label>
                                <input 
                                    className="form-control" 
                                    name="City" 
                                    value={transaction.City} 
                                    onBlur={() => updateTransaction()} 
                                    onChange={(e) => handleAddressChange(e)} 
                                />
                            </div>
                            <div className="col-md-4">
                                <label>State</label>
                                <select className="form-control" 
                                    name="StateCode" 
                                    value={transaction.StateCode} 
                                    onBlur={() => updateTransaction()} 
                                    onChange={(e) => handleAddressChange(e)}
                                >
                                    <option></option>
                                    { renderStateList() }
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Zip Code</label>
                                <input 
                                    className="form-control" 
                                    name="ZipCode" 
                                    value={transaction.ZipCode} 
                                    onBlur={() => updateTransaction()} 
                                    onChange={(e) => handleAddressChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="m-t-10 text-primary with-border-bottom p-b-10 m-b-25 m-r-5 m-l-5">PROPERTY DETAILS</h5>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Bedrooms</label>
                            <input 
                                className="form-control" 
                                name="Bedrooms" 
                                value={property.Bedrooms} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Property Type</label>
                            <input 
                                className="form-control" 
                                name="PropertyType" 
                                value={property.PropertyType} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Property SubType</label>
                            <input 
                                className="form-control" 
                                name="PropertySubType" 
                                value={property.PropertySubType} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Bathrooms</label>
                            <input 
                                className="form-control" 
                                name="Bathrooms" 
                                value={property.Bathrooms} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Acres</label>
                            <input 
                                className="form-control" 
                                name="Acres" 
                                value={property.Acres} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Sq. Footage</label>
                            <input 
                                className="form-control" 
                                name="SqFootage" 
                                value={property.SqFootage} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Year Built</label>
                            <input 
                                className="form-control" 
                                name="YearBuilt" 
                                value={property.YearBuilt} 
                                onBlur={() => this.updateProperty()} 
                                onChange={e => this.handleChange(e)} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PropertyDetails;