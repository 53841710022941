import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ReassignAgent({ isOpen, toggleModal, agentOptions, handleChange, handleReassignAgent, sending }){
    const agents = agentOptions.length === 0 ? null : agentOptions.map((agent, i) => <option key={i} value={agent.Key}>{agent.Value}</option>)
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('agentModal')}>Reassign Agent</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label>Agent</label>
                    <select name="selectedAgent" className="form-control" onChange={e => handleChange(e)}>
                        <option></option>
                        { agents }
                    </select>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-lime-outline" onClick={() => toggleModal('agentModal')}>Cancel</button>
                <button className="btn btn-lime" disabled={sending} onClick={() => handleReassignAgent()}>
                    { sending ?
                        <div className="button-spinner"></div>
                        :
                        "Save"
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}