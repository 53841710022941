import React from 'react';
import { Panel, PanelBody } from '../components/panel/panel';
import SendProperty from '../components/IDX/SendProperty';
import { TabContent, TabPane, DropdownToggle, DropdownMenu, Dropdown, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
// import MapView from '../components/IDX/MapView';
import Select from 'react-select';
import { getData, patchData, postData, putData } from '../helpers/AxiosService';
import houseImg from '../assets/NoImageHouse.jpg';
import SaveSearch from '../components/IDX/SaveSearch';
import querySearch from 'stringquery';
import Pagination from 'react-js-pagination';
import axios from 'axios';
import config from '../config';
import { PageSettings } from '../config/page-settings';
const { baseUrl } = config;

let token = null;
let CancelToken = axios.CancelToken;
let cancel;

class SearchResults extends React.Component {
    static contextType = PageSettings;
    constructor(props) {
        super(props);
        this.state = {
            contactId: null,
            results: [],
            showSendProperty: false,
            showSaveSearch: false,
            view: 'list',
            advancedOpen: false,
            searchOptions: {},
            existingSearchFilters:[],
            countyOptions: [],
            cityOptions: [],
            minPriceOptions: [],
            maxPriceOptions: [],
            minBedsOptions: [],
            minBathsOptions: [],
            minSqFtOptions: [],
            extFeaturesOptions: [],
            intFeaturesOptions: [],
            poolFeaturesOptions: [],
            yearBuiltOptions: [],
            minLotSizeOptions: [],
            garageSpacesOptions: [],
            basementTypeOptions: [],
            optionsLoaded: false,
            // this is only used when loading a saved search
            // that has a polygon filter in it
            initialPolyCoords: [],
            polygonCoords: [],
            mapCorners: [],
            checkedProperties: new Map(),
            searching: false,
            updatingSearch: false,
            PropertyType: '',
            CountyOrParish: '',
            // City: '',
            MinPrice: '',
            MaxPrice: '',
            PropertySubType: [
                { name: 'Single Family Residence', selected: false },
                { name: 'Townhouse', selected: false },
                { name: 'Condominium', selected: false },
            ],
            StandardStatus: [
                { name: 'Active', selected: false },
                { name: 'Active Under Contract', selected: false },
                { name: 'Pending', selected: false },
            ],
            MinBeds: '',
            MinBaths: '',
            MinSqFt: '',
            YearBuilt: '',
            MinLotSize: '',
            GarageSpaces: '',
            totalProps: 0,
            propsPerPage: 50,
            activePage: 1,
            mlsId: null,
            searchListingKey: '',
            searchingById: false,
            searchName: '',
            showSaveName: false,
            savingName: false,
            showSaveUserSearch: false,
        }

        this.toggleAdvanced = this.toggleAdvanced.bind(this);
        this.handlePolygonComplete = this.handlePolygonComplete.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleMapChange = this.handleMapChange.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.fetchUser();
        const { searchid } = this.props.match.params;
        if (searchid) {
            Promise.all([this.fetchOptions(), this.getExistingSearchFilters(searchid)]).then(() => {
                this.setExistingFilters();
            });
        } else {
            this.fetchOptions();
        }

        token = localStorage.getItem('access_token');

        // preload any contact id as a recipient of properties or saved searches
        if (this.props.location.search){
            let q = querySearch(this.props.location.search);

            if (q.c) {
                this.setState({ contactId: q.c });
            }
        }
    }

    async fetchUser() {
        try {
            const response = await getData('api/user/currentuser');
            this.setState({ mlsId: response.data.MLSId });
        } catch (err) {
            console.log('error retrieving user:', err);
        }
    }

    fetchOptions() {
        return getData('api/search/options').then(resp => {
            const { data } = resp;
            let searchOptions = {};
            let searchFields = {};
            data.forEach((f) => {
                searchOptions[f.DisplayName] = this.createSelectOptions(f.Options, f.DisplayName);
                searchFields[f.DisplayName] = {};
                searchFields[f.DisplayName].Options = searchOptions[f.DisplayName];
            });


            this.setState({ searchOptions, searchFields, rawSearchFields: data, optionsLoaded: true });
        }).fail(err => console.log(err));
    }

    getExistingSearchFilters(searchId) {
        let url = `api/search?searchId=${searchId}`;
        if (this.props.match.params.type === 'user') {
            url = `api/search/user/search/${searchId}`;
        }
        return getData(url).then(resp => {
            const filters = resp.data.Filters || [];
            this.setState({ existingSearchFilters: filters, searchName: resp.data.SearchName });
        });
    }

    setExistingFilters() {
        let searchFields = {...this.state.searchFields};
        this.state.existingSearchFilters.forEach(f => {
            let fieldObj = this.state.rawSearchFields.find(field => field.FieldName === f.Field);
            if (!fieldObj) {
                fieldObj = this.state.rawSearchFields.find(field => field.DisplayName === f.Field);
            }

            if (fieldObj.Field === 'polygon') {
                this.setState({ polygonCoords: f.PolygonCoords, initialPolyCoords: f.PolygonCoords });
                return;
            }

            let option = searchFields[fieldObj.DisplayName].Options.find(o => o.value === f.Value);
            if (option) {
                option.selected = true;
            }

            if (fieldObj.FieldName === 'PropertySubType') {
                let subTypes = this.state.PropertySubType.slice();
                let match = subTypes.find(x => x.name === f.Value);
                match.selected = true;
                this.setState({ PropertySubType: subTypes });
                return;
            }

            if (fieldObj.FieldName === 'StandardStatus') {
                let statuses = this.state.StandardStatus.slice();
                let match = statuses.find(x => x.name === f.Value);
                match.selected = true;
                this.setState({ StandardStatus: statuses });
                return;
            }

            this.setState({ [fieldObj.DisplayName]: { value: f.Value, label: option.label }});
        });

        this.setState({ searchFields }, this.handleSearch);
    }

    toggleSelectAll(e) {
        let select = e.target.checked;
        if (select){
            let checkedProperties = new Map();
            this.state.results.forEach(result => checkedProperties.set(result.ListingKeyNumeric.toString(), true));
            this.setState({ checkedProperties }, this.getCheckedContactIds);
        } else {
            this.setState({ checkedProperties: new Map() });
        }
    }

    handleCheckChange(e) {
        const listingKeyNumeric = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedProperties: prevState.checkedProperties.set(listingKeyNumeric, isChecked) }));
    }

    handleViewChange(view) {
        this.setState({ view });
    }

    handlePolygonComplete(coords) {
        this.setState({ polygonCoords: coords, changesMade: true }, () => {
            if (this.state.view === 'map') {
                this.handleSearch(null);
            }
        });
    }

    handleMapChange(corners) {
        this.setState({ mapCorners: corners }, () => {
            if (this.state.view === 'map') {
                this.handleSearch(null);
            }
        });
    }

    handlePageChange(pageNumber){
        this.setState({ activePage: pageNumber }, () => this.handleSearch(null, false));
    }

    handleListingKeyChange(val) {
        this.setState({ searchListingKey: val });
    }

    handleSelectChange(element, fieldName) {
        let fieldObj = this.state.rawSearchFields.find(f => f.FieldName === fieldName);
        if (!fieldObj) {
            fieldObj = this.state.rawSearchFields.find(f => f.DisplayName === fieldName);
        }
        let fieldOptions = this.state.searchFields[fieldObj.DisplayName].Options;
        let prevSelectedOption = fieldOptions.find(x => x.selected === true);
        // clear prev selected as this can have only one option selected
        if (prevSelectedOption) {
            prevSelectedOption.selected = false;
        }

        if (element.value) {
            let option = fieldOptions.find(x => x.value === element.value);
            option.selected = true;
            // annotation is used when loading saved searches to distinguish between fields
            // that use the same field such as MinPrice and MaxPrice both use ListPrice
            // option.Annotation = field;
        }
        
        let fieldsCopy = {...this.state.searchFields};
        fieldsCopy[fieldObj.DisplayName].Options = fieldOptions;
        this.setState({ searchFields: fieldsCopy,  [fieldName]: element, changesMade: true });
    }

    handleMultiSelectChange(elements, fieldName, { action, removedValue }) {
        let fieldObj = this.state.rawSearchFields.find(f => f.FieldName === fieldName);
        if (!fieldObj) {
            fieldObj = this.state.rawSearchFields.find(f => f.DisplayName === fieldName);
        }
        let fieldOptions = this.state.searchFields[fieldObj.DisplayName].Options;
        switch(action){
            case 'select-option':
                elements.forEach(element => {
                    let option = fieldOptions.find(x => x.value === element.value);
                    option.selected = true;
                });
                break;
            case 'remove-value':
                let optionToRemove = fieldOptions.find(x => x.value === removedValue.value);
                optionToRemove.selected = false;
                break;
            case 'clear':
                fieldOptions.forEach(x => x.selected = false);
                break;
            default:
                break;
        }

        let fieldsCopy = {...this.state.searchFields};
        fieldsCopy[fieldObj.DisplayName].Options = fieldOptions;

        this.setState({ searchFields: fieldsCopy, changesMade: true });
    }

    handleSubTypeChange(e) {
        const { name } = e.target;
        // set selected in state option
        let stateOptions = this.state.PropertySubType.slice();
        let stateOption = stateOptions.find(x => x.name === name);
        stateOption.selected = !stateOption.selected;
        
        // set selected in master search options
        let fieldOptions = this.state.searchFields['Home Type'].Options;
        let option = fieldOptions.find(x => x.value === name);
        option.selected = stateOption.selected;

        let fieldsCopy = {...this.state.searchFields};
        fieldsCopy['Home Type'].Options = fieldOptions;

        this.setState({ searchFields: fieldsCopy, PropertySubType: stateOptions, changesMade: true });
    }

    handleStatusChange(e) {
        const { name } = e.target;
        // set selected in state option 
        let stateOptions = this.state.StandardStatus.slice();
        let stateOption = stateOptions.find(x => x.name === name);
        stateOption.selected = !stateOption.selected;
        // set selected in master search options
        let fieldOptions = this.state.searchFields.Status.Options;
        let option = fieldOptions.find(x => x.value === name);
        option.selected = stateOption.selected;

        let fieldsCopy = {...this.state.searchFields};
        fieldsCopy.Status.Options = fieldOptions;

        this.setState({ searchFields: fieldsCopy, StandardStatus: stateOptions, changesMade: true });
    }

    toggleModal(modal) {
        switch(modal){
            case 'sendProperty':
                this.setState({ showSendProperty: !this.state.showSendProperty });
                break;
            case 'saveSearch':
                this.setState({ showSaveSearch: !this.state.showSaveSearch });
                break;
            case 'saveName':
                this.setState({ showSaveName: !this.state.showSaveName });
                break;
            case 'saveUserSearch':
                this.setState({ showSaveUserSearch: !this.state.showSaveUserSearch });
                break;
            default:
                break;
        }
    }

    toggleAdvanced() {
        this.setState({ advancedOpen: !this.state.advancedOpen });
    }

    createSelectOptions(rawOptions, field) {
        const options = rawOptions.map(o => ({ value: o.Key, label: o.Label }));
        return options;
    }

    formatSelectedValues(options){
        if (!options) return [];

        let selectedValues = options.length === 0 ? [] : options.reduce((valList, option) => {
            if (option.selected) {
                valList.push({ value: option.value, label: option.label });
            }
            return valList;
        }, []);

        return selectedValues;
    }

    handleSearchByListingKey(e) {
        if (e) e.preventDefault();
        const { searchListingKey } = this.state;
        if (!searchListingKey) {
            return;
        }

        this.setState({ searchingById: true, result: [] });
        getData(`api/search/property?listingKey=${searchListingKey}`).then(resp => {
            this.setState({ results: [resp.data], searchingById: false });
        }).fail(err => {
            console.log(err);
            this.setState({ searchingById: false });
        });
    }

    handleSearch(e, clearChecked = true) {
        if (e) e.preventDefault();
        this.setState({ searching: true, results: [] });

        const filters = this.getFilters();
        
        const includeGeocoding = this.state.view === 'map';
        // this allows us to cancel any outstanding http requests when a new
        // request is initiated so the results do not get reset by old requests
        if (cancel) {
            cancel();
        }

        if (clearChecked) {
            this.setState({ checkedProperties: new Map() });
        }

        axios.post(`${baseUrl}api/search?geocoding=${includeGeocoding}&page=${this.state.activePage}`, filters, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            headers: {
                'Authorization': `bearer ${token}`
           },
        }).then(resp => {
            this.setState({ results: resp.data.properties, searching: false, totalProps: resp.data.count });
        }).catch(err => console.log(err));
    }

    getFilters() {
        const { searchFields } = this.state;
        let filters = [];
        for (let key in searchFields) {
            let selected = searchFields[key].Options.reduce((valList, option) => {
                if (option.selected) {
                    let filter = {
                        Field: key,
                        Value: option.value,
                    }
                    
                    valList.push(filter);
                }
                return valList;
            }, [])
            
            filters = [...filters, ...selected];
        }

        // if poly coords are set, this should be the primary location filter
        if (this.state.polygonCoords.length) {
            let field = {
                Field: 'polygon',
                PolygonCoords: this.state.polygonCoords,
                ValueType: 'polygon'
            }

            filters.push(field);
        }
        // if its map view, check for poly coords
        // if no poly coords, check for map corners 
        // add one of those to filters
        if (this.state.view === 'map') {
            if (this.state.mapCorners.length) {
                let  field = {
                    Field: 'polygon',
                    PolygonCoords: this.state.mapCorners,
                    ValueType: 'polygon'
                }

                filters.push(field);
            }
        }

        return filters;
    }

    handleUpdateSearch() {
        this.setState({ updatingSearch: true });
        const { type } = this.props.match.params;
        if (type === 'user') {
            this.handleUpdateUserSearch();
        } else {
            this.handleUpdateContactSearch();
        }
    }

    handleUpdateUserSearch() {
        const filters = this.getFilters();
        const { searchName } = this.state;

        putData(`api/search/user/search/${this.props.match.params.searchid}`, { filters, searchName }).then(() => {
            this.setState({ updatingSearch: false, savingName: false });
            if (this.state.showSaveName) {
                this.toggleModal('saveName');
            }
        });
    }

    handleUpdateContactSearch() {
        const filters = this.getFilters();
        putData(`api/search/${this.props.match.params.searchid}`, filters).then(() => {
            this.setState({ updatingSearch: false });
        });
    }

    handleSaveName() {
        this.setState({ savingName: true });
        // slightly different handling for user searches
        const { type } = this.props.match.params;
        if (type === 'user') {
            this.handleUpdateUserSearch();
            return;
        }

        patchData(`api/search/${this.props.match.params.searchid}/name`, { SearchName: this.state.searchName }, this).then(resp => {
            this.setState({ savingName: false });
            this.toggleModal('saveName');
        }).fail({ savingName: false });
    }

    handleSaveUserSearch() {
        this.setState({ updatingSearch: true });
        const filters = this.getFilters();
        postData('api/search/user/search', { searchName: this.state.searchName, filters }, this).then(resp => {
            this.setState({ updatingSearch: false });
            this.toggleModal('saveUserSearch');
        }).fail(() => {
            this.setState({ updatingSearch: false });
        });
    }

    getSelectedPropertyKeys() {
        const { checkedProperties } = this.state;
        let selectedProps = [];
        checkedProperties.forEach((val, key, map) => {
            if (val) {
                selectedProps.push(key);
            }
        });

        return selectedProps;
    }

    clearPolyCoords() {
        this.setState({ initialPolyCoords: [], polygonCoords: [] });
    }

    findIfChecked(list, name) {
        if (!list || list.length === 0 || !name) return false;

        var result = list.find(o => o.name === name);

        if (!result) return false;

        return result.selected;
    }

    render() {
        const { results, showSendProperty, view, advancedOpen, searchOptions, searchFields, optionsLoaded, checkedProperties, searching, 
            showSaveSearch, PropertySubType, changesMade, updatingSearch, activePage, totalProps, propsPerPage,
            mlsId, searchListingKey, searchingById, StandardStatus, showSaveName, searchName, savingName, showSaveUserSearch } = this.state;
        const { searchid } = this.props.match.params;
        return (
            <div>
                { searchName && <h3>{searchName} { !optionsLoaded ? <div className="button-spinner"></div> : (searchid && <i className="fas fa-pen m-l-5 cursor-pointer f-s-14" onClick={() => this.toggleModal('saveName')}></i> )}</h3>}
                <form className="m-b-15">
                    <div className="row m-l-0">
                        <input 
                            className="form-control width-200"
                            placeholder="MLS Id"
                            value={searchListingKey}
                            onChange={e => this.handleListingKeyChange(e.target.value)}
                            />
                        <button className="btn btn-primary btn-sm m-l-10 width-100" onClick={(e) => this.handleSearchByListingKey(e)}>
                        { searchingById ?
                            <div className="button-spinner"></div> 
                            :
                            "Search"   
                        }
                        </button>
                    </div>
                    <div className="row m-l-0 m-t-15 m-b-15">OR</div>
                    <div className="row m-l-0">
                        <Select 
                            placeholder="Type" 
                            options={searchOptions.PropertyType} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => "Loading"}
                            className="p-l-0 p-r-0 col-md-3 m-r-5 m-b-10" 
                            value={this.state.PropertyType}
                            onChange={(value) => this.handleSelectChange(value, 'PropertyType')}
                            />
                        <Select 
                            placeholder="County" 
                            options={searchOptions.County} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => "Loading"}
                            isMulti="true"
                            className="p-l-0 p-r-0 col-md-4 m-r-5 m-b-10" 
                            value={optionsLoaded ? this.formatSelectedValues(searchFields['County'].Options) : []}
                            onChange={(values, {action, removedValue}) => this.handleMultiSelectChange(values, 'County', {action, removedValue})}
                            />
                        <Select 
                            placeholder="City" 
                            options={searchOptions.City} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => "Loading"}
                            isMulti="true"
                            className="p-l-0 p-r-0 col-md-4 m-r-5 m-b-10" 
                            value={optionsLoaded ? this.formatSelectedValues(searchFields['City'].Options) : []}
                            onChange={(values, {action, removedValue}) => this.handleMultiSelectChange(values, 'City', {action, removedValue})}
                            />
                        <Select 
                            placeholder="Min Price" 
                            options={searchOptions['Min Price']} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => "Loading"}     
                            className="p-l-0 p-r-0 col-md-3 m-r-5" 
                            value={this.state['Min Price']}
                            onChange={(value) => this.handleSelectChange(value, 'Min Price')}
                            />
                        <Select 
                            placeholder="Max Price" 
                            options={searchOptions['Max Price']} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => "Loading"}
                            className="p-l-0 p-r-0 col-md-3 m-r-5" 
                            value={this.state['Max Price']}
                            onChange={(value) => this.handleSelectChange(value, 'Max Price')}
                            />
                    </div>
                    <div className="row m-t-10 m-l-0">
                        <Dropdown isOpen={advancedOpen} toggle={this.toggleAdvanced}>
                            <DropdownToggle className="btn btn-white">
                                { advancedOpen ? 
                                    <span>Hide Advanced <i className="fas fa-angle-up m-l-5"></i></span>
                                    :
                                    <span>Advanced Search <i className="fas fa-angle-down m-l-5"></i></span>
                                }
                            </DropdownToggle>
                            <DropdownMenu modifiers={{ computeStyle: { gpuAcceleration: false }}} style={{ zIndex: 10000 }}>
                                <div className="p-15" style={{ width: '80vw' }}>
                                    <div className="checkbox checkbox-css checkbox-inline">
                                        <input type="checkbox" name="Single Family Residence" id="inlineCssCheckbox1" checked={this.findIfChecked(PropertySubType, "Single Family Residence")} onChange={(e) => this.handleSubTypeChange(e)} />
                                        <label htmlFor="inlineCssCheckbox1">Single Family</label>
                                    </div>
                                    <div className="checkbox checkbox-css checkbox-inline">
                                        <input type="checkbox" name="Townhouse" id="inlineCssCheckbox2" checked={this.findIfChecked(PropertySubType, "Townhouse")} onChange={(e) => this.handleSubTypeChange(e)} />
                                        <label htmlFor="inlineCssCheckbox2">Townhouse</label>
                                    </div>
                                    <div className="checkbox checkbox-css checkbox-inline">
                                        <input type="checkbox" name="Condominium" id="inlineCssCheckbox3" checked={this.findIfChecked(PropertySubType, "Condominium")} onChange={(e) => this.handleSubTypeChange(e)} />
                                        <label htmlFor="inlineCssCheckbox3">Condominium</label>
                                    </div>
                                    <br />
                                    <div className="checkbox checkbox-css checkbox-inline m-t-15">
                                        <input type="checkbox" name="Active" id="inlineCssCheckbox4" checked={this.findIfChecked(StandardStatus, "Active")} onChange={(e) => this.handleStatusChange(e)} />
                                        <label htmlFor="inlineCssCheckbox4">Active</label>
                                    </div>
                                    <div className="checkbox checkbox-css checkbox-inline m-t-15">
                                        <input type="checkbox" name={"Active Under Contract"} id="inlineCssCheckbox5" checked={this.findIfChecked(StandardStatus, "Active Under Contract")} onChange={(e) => this.handleStatusChange(e)} />
                                        <label htmlFor="inlineCssCheckbox5">Accepting Backup Offers</label>
                                    </div>
                                    <div className="checkbox checkbox-css checkbox-inline m-t-15">
                                        <input type="checkbox" name="Pending" id="inlineCssCheckbox6" checked={this.findIfChecked(StandardStatus, "Pending")} onChange={(e) => this.handleStatusChange(e)} />
                                        <label htmlFor="inlineCssCheckbox6">Pending</label>
                                    </div>
                                    <div className="row m-t-15">
                                        <div className="form-group col-md-4">
                                            <label>Beds</label>
                                            <Select 
                                                placeholder="Min Beds" 
                                                options={searchOptions.Bedrooms} 
                                                closeMenuOnSelect={true}   
                                                value={this.state.Bedrooms}   
                                                onChange={(value) => this.handleSelectChange(value, 'BedroomsTotal')}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Baths</label>
                                            <Select 
                                                placeholder="Min Baths" 
                                                options={searchOptions.Baths} 
                                                closeMenuOnSelect={true}  
                                                value={this.state.Baths}    
                                                onChange={(value) => this.handleSelectChange(value, 'BathroomsTotalInteger')}
                                            />
                                        </div>
                                        { mlsId !== 1001 && <div className="form-group col-md-4">
                                            <label>Square ft</label>
                                            <Select 
                                                placeholder="Square ft" 
                                                options={searchOptions['Min Square Ft']} 
                                                closeMenuOnSelect={true} 
                                                value={this.state['Min Square Ft']}     
                                                onChange={(value) => this.handleSelectChange(value, 'LivingArea')}
                                            />
                                        </div> }
                                    </div>
                                    { mlsId !== 1001 && <div className="row m-t-10">
                                        <div className="form-group col-md-4">
                                            <label>Ext. Features</label>
                                            <Select 
                                                isMulti
                                                isClearable={false}
                                                placeholder="Ext. Features" 
                                                value={optionsLoaded ? this.formatSelectedValues(searchFields['Exterior Features'].Options) : []}
                                                options={searchOptions['Exterior Features']} 
                                                closeMenuOnSelect={false}      
                                                onChange={(values, {action, removedValue}) => this.handleMultiSelectChange(values, 'ExteriorFeatures', {action, removedValue})}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Int. Features</label>
                                            <Select 
                                                isMulti
                                                isClearable={false}
                                                placeholder="Int. Features" 
                                                value={optionsLoaded ? this.formatSelectedValues(searchFields['Interior Features'].Options) : []}
                                                options={searchOptions['Interior Features']} 
                                                closeMenuOnSelect={false}      
                                                onChange={(values, {action, removedValue}) => this.handleMultiSelectChange(values, 'InteriorFeatures', {action, removedValue})}
                                            />
                                        </div>
                                        {/* <div className="form-group col-md-4">
                                            <label>Pool</label>
                                            <Select 
                                                isMulti
                                                isClearable={false}
                                                placeholder="Pool" 
                                                value={optionsLoaded ? this.formatSelectedValues(searchOptions[''].Options) : []}
                                                options={poolFeaturesOptions} 
                                                closeMenuOnSelect={false}      
                                                onChange={(values, {action, removedValue}) => this.handleMultiSelectChange(values, 'PoolFeatures', {action, removedValue})}
                                            />
                                        </div> */}
                                    </div>}
                                    { mlsId !== 1001 && <div className="row m-t-10">
                                        <div className="form-group col-md-4">
                                            <label>Year Built</label>
                                            <Select 
                                                placeholder="Year Built" 
                                                options={searchOptions['Year Built']} 
                                                closeMenuOnSelect={true}  
                                                value={this.state['Year Built']}    
                                                onChange={(value) => this.handleSelectChange(value, 'YearBuilt')}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Min. Lot Size</label>
                                            <Select 
                                                placeholder="Min Lot Size" 
                                                options={searchOptions['Lot size (acres)']} 
                                                closeMenuOnSelect={true}  
                                                value={this.state['Lot size (acres)']}    
                                                onChange={(value) => this.handleSelectChange(value, 'LotSizeArea')}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Garage Spaces</label>
                                            <Select 
                                                placeholder="Garage Spaces" 
                                                options={searchOptions['Garage Spaces']} 
                                                closeMenuOnSelect={true}   
                                                value={this.state['Garage Spaces']}   
                                                onChange={(value) => this.handleSelectChange(value, 'GarageSpaces')}
                                            />
                                        </div>
                                    </div>}
                                    { mlsId !== 1001 && <div className="row m-t-10">
                                        <div className="form-group col-md-4">
                                            <label>Basement</label>
                                            <Select 
                                                isMulti
                                                isClearable={false}
                                                placeholder="Basement" 
                                                value={optionsLoaded ? this.formatSelectedValues(searchFields['Basement Type'].Options) : []}
                                                options={searchOptions['Basement Type']} 
                                                closeMenuOnSelect={false}      
                                                onChange={(values, {action, removedValue}) => this.handleMultiSelectChange(values, 'Basement', {action, removedValue})}
                                            />
                                        </div>
                                    </div>}
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                        <button className="btn btn-primary btn-sm m-l-10 width-100" onClick={(e) => this.handleSearch(e)}>
                            { searching && <div className="button-spinner"></div>}
                            { !searching && "Search" }
                        </button>
                        { this.props.match.params.searchid && 
                            <button type="button" className="btn btn-primary-outline m-l-10 width-150" disabled={!changesMade} onClick={() => this.handleUpdateSearch()}>
                                { updatingSearch && <div className="button-spinner"></div>}
                                { !updatingSearch && "Save Search Changes" }
                            </button>
                        }
                    </div>
                </form>
                <Panel style={{ marginLeft: '-30px', marginRight: '-30px' }}>
                    <PanelBody>
                        <div className="row">
                            <div className="col-md-12 m-b-10">
                                <button 
                                    className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" 
                                    onClick={() => this.toggleModal('sendProperty')}
                                >
                                    <i className="far fa-paper-plane text-green"></i> Send Property
                                </button>
                                <button 
                                    className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5"
                                    onClick={() => this.toggleModal('saveSearch')}
                                >
                                    <i className="fas fa-bell text-primary"></i> Create Listing Alert
                                </button>
                                { !this.props.match.params.searchid && 
                                    <button 
                                        className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5"
                                        onClick={() => this.toggleModal('saveUserSearch')}
                                    >
                                        <i className="fas fa-cloud text-primary"></i> Save Search
                                    </button>
                                }
                            </div>
                        </div>
                        {/* <div className="btn-group">
                            <button 
                                className={classnames({ active: view === 'list' }, "btn btn-white")}
                                onClick={() => this.handleViewChange('list')}
                            >
                                <i className="far fa-list-alt"></i>
                            </button>
                            <button 
                                className={classnames({ active: view === 'map' }, "btn btn-white")}
                                onClick={() => this.handleViewChange('map')}
                            >
                                <i className="far fa-map"></i>
                            </button>
                        </div> */}
                        <TabContent activeTab={view} className="p-0 m-t-15 m-b-0">
                            <TabPane tabId="list">
                                { totalProps > 0 &&
                                <div>
                                    <strong>
                                        Displaying {(activePage - 1) * 50 + 1} - {activePage * 50 > totalProps ? totalProps : activePage * 50} of {totalProps}
                                    </strong>
                                </div>
                                }
                                <table className="table table-responsive" style={{ width: '99%'}}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="row">
                                                    <div style={{ margin: 'auto' }}>
                                                        <div className="checkbox checkbox-css">
                                                            <input type="checkbox" id="check_all" onChange={(e) => this.toggleSelectAll(e)}/>
                                                            <label htmlFor="check_all"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>Image</th>
                                            <th>MLS#</th>
                                            <th>Status</th>
                                            <th>List Price</th>
                                            <th>SqFt</th>
                                            <th>Bed</th>
                                            <th>Bath</th>
                                            <th>Address</th>
                                            <th>City</th>
                                            <th>Acres</th>
                                            <th>DOM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { results.length > 0 && results.map((p, idx) => {
                                            let imgSrc = houseImg;
                                            let primaryImgUrl = p.MediaUrl;
                                            if (primaryImgUrl) {
                                                imgSrc = primaryImgUrl;
                                            }
                                            return (
                                                <tr key={p.ListingKeyNumeric}>
                                                    <td className="with-checkbox valign-middle" onClick={e => e.stopPropagation()}>
                                                        <div className="row">
                                                            <div style={{ margin: 'auto' }}>
                                                                <div className="checkbox checkbox-css">
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={p.ListingKeyNumeric} 
                                                                        id={`check_${p.ListingKeyNumeric}`} 
                                                                        checked={checkedProperties.get(p.ListingKeyNumeric.toString())}
                                                                        onChange={(e) => this.handleCheckChange(e)}
                                                                        />
                                                                    <label htmlFor={`check_${p.ListingKeyNumeric}`}></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="with-img">
                                                        <img src={imgSrc} alt="property" style={{ maxWidth: '100px', maxHeight: '66px', objectFit: 'cover' }}/>
                                                    </td>
                                                    <td>{p.ListingKeyNumeric}</td>
                                                    <td>{p.StandardStatus}</td>
                                                    <td>${p.ListPrice}</td>
                                                    <td>{p.LivingArea}</td>
                                                    <td>{p.BedroomsTotal}</td>
                                                    <td>{p.BathroomsTotalInteger}</td>
                                                    <td>{p.UnparsedAddress}</td>
                                                    <td>{p.City}</td>
                                                    <td>{p.LotSizeAcres}</td>
                                                    <td>{p.DaysOnMarket}</td>
                                                </tr>
                                            )
                                        })}
                                        
                                        { results.length === 0 && !searching && <tr><td className="text-center" colSpan="12">No properties found</td></tr> }
                                        { searching && <tr><td className="text-center" colSpan="12"><div className="button-spinner"></div></td></tr> }

                                    </tbody>
                                </table>
                                <Pagination 
                                    activePage={activePage}
                                    itemsCountPerPage={propsPerPage}
                                    totalItemsCount={totalProps}
                                    onChange={this.handlePageChange}
                                />
                            </TabPane>
                            {/* <TabPane tabId="map">
                                <MapView 
                                    handleMapChange={this.handleMapChange}
                                    handlePolygonComplete={this.handlePolygonComplete}
                                    results={this.state.results} 
                                    searching={searching} 
                                    initialPolyCoords={this.state.initialPolyCoords} 
                                    clearPolyCoords={this.clearPolyCoords.bind(this)} 
                                    activePage={activePage}
                                    totalProps={totalProps}
                                    propsPerPage={propsPerPage}  
                                    handlePageChange={this.handlePageChange}                            
                                    />
                            </TabPane> */}
                        </TabContent>

                    </PanelBody>
                </Panel>
                <SendProperty
                    isOpen={showSendProperty}
                    toggleModal={this.toggleModal.bind(this, 'sendProperty')} 
                    getPropertyKeys={this.getSelectedPropertyKeys.bind(this)} 
                    contactId={this.state.contactId}
                 />
                <SaveSearch 
                    isOpen={showSaveSearch}
                    toggleModal={this.toggleModal.bind(this, 'saveSearch')}
                    getFilters={this.getFilters}
                    contactId={this.state.contactId}
                />
                <Modal isOpen={showSaveName}>
                    <ModalHeader toggle={() => this.toggleModal('saveName')}>Save Name</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label>Listing Alert Name</label>
                                <input name="searchName" className="form-control" onChange={(e) => this.setState({ searchName: e.target.value })} value={searchName} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary pull-right width-100" onClick={() => this.handleSaveName()}>
                            { savingName ? 
                                <div className="button-spinner"></div> 
                                :
                                "Save"
                            }
                        </button>
                        <button className="btn btn-primary-outline pull-right width-100" onClick={() => this.toggleModal('saveName')}>Cancel</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={showSaveUserSearch}>
                    <ModalHeader toggle={() => this.toggleModal('saveUserSearch')}>Save Search</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label>Search Name</label>
                                <input name="searchName" className="form-control" onChange={(e) => this.setState({ searchName: e.target.value })} value={searchName} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary pull-right width-100" onClick={() => this.handleSaveUserSearch()}>
                            { updatingSearch ? 
                                <div className="button-spinner"></div> 
                                :
                                "Save"
                            }
                        </button>
                        <button className="btn btn-primary-outline pull-right width-100" onClick={() => this.toggleModal('saveUserSearch')}>Cancel</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default SearchResults;