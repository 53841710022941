import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { postData, getData } from '../../helpers/AxiosService';
import './CreateTransaction.css';
import ListingStep2 from './steps/ListingStep2';
import Step3 from './steps/Step3';
import PurchaseStep2 from './steps/PurchaseStep2';
import { handleFile } from '../../helpers/FileUpload';
import { PageSettings } from '../../config/page-settings';
import AddContact from './steps/AddContact';
import AddByListingKey from './steps/AddByListingKey';
import AgentOptions from '../shared/AgentOptions';

class CreateTransactionModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sending: false,
            step: 1,
            transType: null,
            transaction: {},
            hasError: false, 
            uploadFile: {}, 
            statusOptions: [],
            includeAdditional: false,
            transactionContactId: 0,
            mlsId: '',
            loadingProperty: false,
            agentId: 0,
        }

        this.handleTransChange = this.handleTransChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setTransactionContactId = this.setTransactionContactId.bind(this);
    }

    componentDidMount(){
        if (this.props.contactId) {
            this.setTransactionContactId(this.props.contactId);
        }

        this.getCreateOptions();
        this.setState({ agentId: this.context.currentUserId });
    }

    getCreateOptions(){
        getData('api/transaction/create-options').then(response => {
            this.setState({ statusOptions: response.data });
        });
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleTransChange(e){
        let { name, value } = e.target;
        if (typeof(value) === 'string'){
            value = value.replace("$", "").replace(/,/g, "");
        }
        this.setState({ transaction: {...this.state.transaction, [name]: value }});
    }

    setTransactionContactId(id) {
        this.setState({ transactionContactId: id });
    }

    handleSubmit(){
        this.setState({ sending: true });
        if (this.state.type === "listing" ? !this.validateListingCreate() : !this.validatePurchaseCreate()){
            return;
        }
        let data = this.state.transaction;
        data.Type = this.state.transType.charAt(0).toUpperCase() + this.state.transType.slice(1);
        data.TransactionContactId = this.state.transactionContactId;
        data.IncludeAdditionalContacts = this.state.includeAdditional;
        data.imgData = this.state.uploadFile;
        data.PropertyImage = data.imageUrl;
        data.AgentId = this.state.agentId;

        const { propertyType, propertySubType, yearBuilt, bedroomsTotal, bathroomsTotalInteger, livingArea, acres } = this.state.transaction;
        data.PropertyDetails = {
            PropertyType: propertyType,
            PropertySubType: propertySubType,
            YearBuilt: yearBuilt,
            Bedrooms: bedroomsTotal,
            Bathrooms: bathroomsTotalInteger,
            SqFootage: livingArea,
            Acres: acres,
        }

        postData('api/transaction', data, this).then(response => {
            this.setState({ sending: false });
            this.props.toggleModal();
            this.props.redirectToTransaction(response.data.TransactionId);
        }).fail(() => this.setState({ sending: false }));
    }

    validateListingCreate(){
        let valid = true;

        if (!this.state.transaction.address || !this.state.transaction.city || !this.state.transaction.stateCode || !this.state.transaction.status || !this.state.transactions.listingPrice){
            valid = false;
            this.setState({ hasError: true });
        }

        return valid;
    }

    validatePurchaseCreate(){
        let valid = true;

        return valid;
    }

    handleImage(e){
        handleFile(e, this);
    }

    clearFile() {
        this.setState({ uploadFile: {} });
    }

    closeResetModal(){
        this.setState({ step: 1, transaction: {}, transType: null });
        this.props.toggleModal('transactionModal');
    }

    next(){
        this.setState({ step: ++this.state.step });
    }

    back(){
        this.setState({ step: --this.state.step });
    }

    renderNextBtn(){
        // if (this.state.step === 3 || (this.state.step === 4 && !this.state.transactionContactId)){
        if (this.state.step === 2 || this.state.step === 4 || this.state.step === 5){
            return (
                <button className="btn btn-primary pull-right" onClick={() => this.next()}>Next</button>
            )
        }
        return null;
    }

    renderBackBtn(){
        if (this.state.step > 1){
            return (
                <button className="btn btn-white pull-left" onClick={() => this.back()}>Back</button>
            )
        }
        return null;
    }

    renderFetchPropertyBtn() {
        if (this.state.step === 3) {
            return (
                <button className="btn btn-primary pull-right" onClick={() => this.fetchProperty()}>
                    { this.state.loadingProperty ? 
                        <div className="button-spinner"></div>
                        : 
                        "Next"
                    }
                </button>
            )
        }
    }

    fetchProperty() { 
        const { mlsId } = this.state;
        if (!mlsId) {
            this.context.addNotification('', 'MLS Id is required to proceed, or select Add Manually.', 'warning');
            return;
        }

        this.setState({ loadingProperty: true });
        getData(`api/search/property?listingKey=${mlsId}`).then(resp => {
            const { data } = resp;
           
            let imgUrl = '';
            const img = data.Media && data.Media.length > 0 ? data.Media.find(x => x.Order == 0) : null;
            if (img) {
                imgUrl = img.MediaURL;
            }

            let transaction = {
                listingPrice: data.ListPrice,
                address: `${data.StreetNumber} ${data.StreetDirPrefix} ${data.StreetName} ${data.StreetDirSuffix} ${data.StreetSuffix}`,
                unitNumber: data.UnitNumber,
                city: data.City,
                stateCode: data.StateOrProvince,
                zipCode: data.PostalCode,
                bedroomsTotal: data.BedroomsTotal,
                bathrooms: data.BathroomsTotalInteger,
                mlsId: mlsId,
                livingArea: data.LivingArea,
                propertyType: data.PropertyType,
                propertySubType: data.PropertySubType,
                acres: data.LotSizeAcres,
                yearBuilt: data.YearBuilt,
                imageUrl: imgUrl,
            }
            
            this.setState({ transaction, loadingProperty: false }, this.next)
        })
    }

    renderSubmitBtn(){
        const { step, sending } = this.state;
        if (step === 6) {
            return (
                <button className="btn btn-lime pull-right" onClick={() => this.handleSubmit()}>
                    { sending ? 
                        <div className="button-spinner"></div>
                        :
                        "Create Transaction"
                    }
                </button>
            )
        }
        return null;
    }

    renderIncludeAdditional(){
        if (this.state.step === 1 && this.props.hasAdditionalContacts){
            return (
                <div className="checkbox checkbox-css">
                    <input type="checkbox" id="includeAdditional" checked={this.state.includeAdditional} onChange={() => this.setState({ includeAdditional: !this.state.includeAdditional })} />
                    <label htmlFor="includeAdditional">Include Additional Contacts On Transaction</label>
                </div>
            )
        }
    }

    setType(type){
        this.setState({ transType: type, transaction: {}  }, this.next)
    }

    render(){
        const { transType, step, transaction, hasError, statusOptions, mlsId, agentId } = this.state;
        return (
            <Modal size="lg" isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.closeResetModal()}>Add Transaction</ModalHeader>
                <ModalBody>
                    { this.state.step === 1 && 
                    <div className="row p-t-75 p-b-75">
                        <div className="col-md-6 text-center">
                            <h2 className="text-ellipsis type-icon" onClick={() => this.setType('listing')}><i className="icon-direction"></i><br /><small>Listing</small></h2>
                        </div>
                        <div className="col-md-6 text-center">
                            <h2 className="text-ellipsis type-icon" onClick={() => this.setType('purchase')}><i className="icon-home"></i><br /><small>Purchase</small></h2>
                        </div>
                    </div>
                    }
                    { step === 2 &&
                    <div className="row p-t-75 p-b-75">
                        <div className="col-md-6 offset-md-3">
                            <label>Transaction Agent</label>
                            <AgentOptions
                                selectName="agentId"
                                agentId={agentId}
                                all={true}
                                handleSelect={this.handleChange}
                            />
                        </div>
                    </div>
                    }
                    { step === 3 &&
                    <AddByListingKey 
                        step={step}
                        handleChange={this.handleChange}
                        mlsId={mlsId}
                        next={this.next.bind(this)}
                    /> }
                    { step === 4 && transType === 'listing' &&
                    <ListingStep2
                        transType={transType}
                        step={step}
                        handleChange={this.handleTransChange}
                        transaction={transaction}
                        hasError={hasError}
                        statusOptions={statusOptions}
                     /> }
                    { step === 4 && transType === 'purchase' && 
                    <PurchaseStep2
                        transType={transType}
                        step={step}
                        handleChange={this.handleTransChange}
                        transaction={transaction}
                        hasError={hasError}
                        statusOptions={statusOptions}
                    /> }
                    { step === 5 &&
                    <Step3
                        transType={transType}
                        step={step}
                        handleChange={this.handleTransChange}
                        transaction={transaction}
                        hasError={hasError}
                        handleImage={this.handleImage}
                        clearFile={this.clearFile.bind(this)}
                        fileName={this.state.uploadFile.FileName}
                     /> }
                     { step === 6 && 
                        <AddContact 
                            transactionType={transType}
                            setTransactionContactId={this.setTransactionContactId}
                            transactionAgentId={agentId}
                        />
                     }
                </ModalBody>
                <ModalFooter>
                    <div style={{ width: '100%' }}>
                    {this.renderBackBtn()}
                    {this.renderNextBtn()}
                    {this.renderFetchPropertyBtn()}
                    {this.renderSubmitBtn()}
                    {this.renderIncludeAdditional()}
                   </div>
                </ModalFooter>
            </Modal>
        )
    }
}

CreateTransactionModal.contextType = PageSettings;
export default CreateTransactionModal;