import React from 'react';
// import { renderStateList } from '../../helpers/StateService';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { phoneClean } from '../../helpers/Functions';
import { PageSettings } from '../../config/page-settings';

class AddPerson extends React.Component{
    static defaultProps = {
        setContact: () => {},
        transactionId: 0,
        handleSavePerson: () => {},
    }

    constructor(props){
        super(props);
        this.state = {
            person: {},
        }
    }

    static contextType = PageSettings;

    handleChange(e, isPhone){
        let { name, value } = e.target;
        if (isPhone){
            value = phoneClean(value);
        }
        this.setState({ person: { ...this.state.person, [name]: value }});
    }

    handleSavePerson() {
        this.props.handleSavePerson(this.state.person);
    }

    render() {
        const { isCreateContact, hasError, sending } = this.props;
        const { person } = this.state;
        return(
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className={classnames({ 'has-error': hasError && !person.FirstName }, "form-group col-md-6")}>
                            <label>First Name</label>
                            <input className="form-control" name="FirstName" value={person.FirstName} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className={classnames({ 'has-error': hasError && !person.LastName }, "form-group col-md-6")}>
                            <label>Last Name</label>
                            <input className="form-control" name="LastName" value={person.LastName} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className={classnames({ 'has-error': hasError && !person.Email }, "form-group col-md-12")}>
                            <label>Email</label>
                            <input className="form-control" name="Email" value={person.Email} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className={classnames({ 'has-error': hasError && !person.CellPhone }, "form-group col-md-6")}>
                            <label>Cell Phone</label>
                            <InputMask mask="(999) 999 9999" className="form-control" name="CellPhone" value={person.CellPhone} onChange={e => this.handleChange(e, true)}></InputMask>
                        </div>
                        { !isCreateContact &&
                        <div className="form-group col-md-6">
                            <label>Office Phone</label>
                            <InputMask mask="(999) 999 9999" className="form-control" name="OfficePhone" value={person.OfficePhone} onChange={e => this.handleChange(e, true)}></InputMask>
                        </div> }
                        { !isCreateContact && 
                        <div className="form-group col-md-12">
                            <label>Company Name</label>
                            <input className="form-control" name="CompanyName" value={person.CompanyName} onChange={e => this.handleChange(e)} />
                        </div> }
                    </div>
                </div>
                <div className="col-md-12">
                    <button className="btn btn-primary pull-right p-r-40 p-l-40" onClick={() => this.handleSavePerson()}>
                        { sending ? <div className="button-spinner"></div> : "Save" }
                    </button>
                </div>
            </div>
        )
    }
    
}

export default AddPerson;