import React, { useContext, useEffect, useState } from 'react';
import { getData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';
import PropTypes from 'prop-types';

function AgentOptions(props) {
    const cxt = useContext(PageSettings);
    const [agents, setAgents] = useState([]);
    const { selectName, handleSelect, agentId } = props;
    
    useEffect(() => {
        async function fetchData() {
            try {
                const resp = await getData('api/user/admin/agent-option');
                setAgents(resp.data);
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, []);

    return (
        <select 
            className={"form-control " + props.className} 
            name={selectName} 
            value={agentId} 
            onChange={(e) => handleSelect(e)} 
        >
            { cxt.userInfo.IsAdmin && <option value={0}>All Agents</option> }
            { agents.map((option, idx) => <option key={idx} value={option.UserId}>{option.Name}</option>) }
        </select>
    )
}

export default AgentOptions;

AgentOptions.propTypes = {
    selectName: PropTypes.string,
    agentId: PropTypes.number.isRequired,
    all: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired,
}
AgentOptions.defaultProps = {
    selectName: 'selectedAgentId',
    all: false,
    handleSelect: () => {},
}