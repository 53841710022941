import React from 'react';
import classnames from 'classnames';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import ContactDetails from '../components/contact-details/ContactDetails';
import ContactActivity from '../components/contact-activity/ContactActivity.js';
import EmailModalBlank from '../components/email/EmailModalBlank';
import AddNote from '../components/AddNote';
import TaskModal from '../components/task-list/TaskModal';
import { getData } from '../helpers/AxiosService.js';
import moment from 'moment';
import CreateTransactionModal from '../components/transactions/CreateTransactionModal';
import ActivityModal from '../components/activity/ActivityModal';
import AppointmentModal from '../components/appointments/AppointmentModal';
import ActivityDescription from '../components/activity/ActivityDescription';
import { displayFormalDateTimeUtc } from '../helpers/Functions';


class ContactEntityDetails extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			entity: {},
			entityNotes: [],
			activeTab: '1',
			activeToggle: '1',
			emailBlank: false,
			addNote: false,
			addTask: false,
			activityOpen: false,
			appointmentOpen: false,
			loading: true,
			showCreateTransaction: false,
			selectedEmailTemplate: {},
			getPhoneCalls: false,
			notFound: false,
			selectedNote: {},
			activityDescription: false,
			selectedActivity: {},
		};
		
		this.toggleTab = this.toggleTab.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.stopLoading = this.stopLoading.bind(this);
		this.getContactEntityNotes = this.getContactEntityNotes.bind(this);
		this.setEntity = this.setEntity.bind(this);
		this.updateNotes = this.updateNotes.bind(this);
		this.toggleCreateTransactionModal = this.toggleCreateTransactionModal.bind(this);
		this.renderNotes = this.renderNotes.bind(this);
		this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
		this.updatePhoneCalls = this.updatePhoneCalls.bind(this);
		this.handleCallEditClick = this.handleCallEditClick.bind(this);
		this.updateList = this.updateList.bind(this);
		this.setContactActivityRef = this.setContactActivityRef.bind(this);
	}

	componentDidMount(){
		let contactId = this.props.match.params.contactId;
		this.getContactEntityNotes(contactId);
	}

	getContactEntityNotes(id){
		getData(`api/notes?contactId=${id}`).then(response => {
			this.setState({ entityNotes: response.data });
		});
	}

	setEntity(entity){
		this.setState({ entity: entity });
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	toggle(tab) {
		if (this.state.activeToggle !== tab) {
			this.setState({
				activeToggle: tab
			});
		}
	}

	stopLoading(notFound = false){
		this.setState({ loading: false, notFound });
	}

	toggleModal(name) {
		switch (name) {
			case 'emailBlank':	
				this.setState({ emailBlank: !this.state.emailBlank });
				break;
			case 'addNote':	
				this.setState({ addNote: !this.state.addNote, selectedNote: {} });
				break;
			case 'addTask':	
				this.setState({ addTask: !this.state.addTask });
				break;
			case 'modalAlert':	
				this.setState({ modalAlert: !this.state.modalAlert });
				break;
			case 'activity':	
				this.setState({ activityOpen: !this.state.activityOpen });
				break;
			case 'appointment':	
				this.setState({ appointmentOpen: !this.state.appointmentOpen });
				break;
			case 'ActivityDescription':	
				this.setState({ activityDescription: !this.state.activityDescription });
				break;
			default:
				break;
		}
	}

	updatePhoneCalls(){
		this.setState({ getPhoneCalls: !this.state.getPhoneCalls });
	}

	updateNotes(){
		this.getContactEntityNotes(this.props.match.params.contactId);
	}

	selectNoteToEdit(note) {
		this.setState({ selectedNote: note, addNote: true });
	}

	renderNotes(){
		return this.state.entityNotes.map((note, idx) => {
			return (
				<li className="media media-sm" key={idx}>
					<div className="media-body">
						<h5 className="media-heading">{note.AuthorName}</h5>
						<div className="row">
							<div className="col-9">
								<i className="text-muted">{displayFormalDateTimeUtc(note.DateCreated)}</i>
							</div>
							<div className="col-3">
								<i 
									className="fas fa-pen cursor-pointer pull-right"
									onClick={() => this.selectNoteToEdit(note)}
								></i>
							</div>
						</div>
						<p className="m-t-10">{note.Note}</p>
					</div>
				</li>
			);
		});
	}

	handleCallEditClick(call) {
		this.setState({ selectedActivity: call, activityDescription: true });
	}

	toggleCreateTransactionModal(){
		this.setState({ showCreateTransaction: !this.state.showCreateTransaction });
	}

	handleSelectTemplate(template){
		this.setState({ selectedEmailTemplate: template }, () => this.toggleModal('emailBlank'));
	}

	updateList() {
		this.contactActivityRef.getActivity(this.state.entity.Contact.ContactId);
		this.updatePhoneCalls();
	}

	setContactActivityRef(ref) {
		this.contactActivityRef = ref;
	}
	
	render() {
		const { contactId } = this.props.match.params
		const { loading, entityNotes, entity, showCreateTransaction, selectedEmailTemplate, notFound, selectedNote } = this.state;
		if (notFound && !loading) {
			return (
				<div className="text-center">Contact was not found</div>
			)
		}

		return (
			<div>
				<div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>

				<ContactDetails 
					contactId={contactId} 
					toggleTransactionModal={this.toggleCreateTransactionModal} 
					stopLoading={this.stopLoading} 
					setEntity={this.setEntity} 
					toggleModal={this.toggleModal}
					history={this.props.history}
					entityNotes={entityNotes}
					renderNotes={this.renderNotes}
					handleSelectTemplate={this.handleSelectTemplate}
					contactEntityId={entity.ContactEntityId}
					getPhoneCalls={this.state.getPhoneCalls}
					updatePhoneCalls={this.updatePhoneCalls}
					handleCallEditClick={this.handleCallEditClick}
					setContactActivityRef={this.setContactActivityRef}
				/>
				<EmailModalBlank 
					openModal={this.state.emailBlank} 
					toggleModal={this.toggleModal} 
					toAddress={entity.Contact && entity.Contact.Email} 
					contactId={entity.Contact && entity.Contact.ContactId}
					keyType="ContactEntityId"
					keyId={entity.ContactEntityId}
					body={selectedEmailTemplate.Body}
				/>
				<TaskModal 
                    keyType="ContactEntityId" 
                    keyValue={entity.ContactEntityId}
                    isOpen={this.state.addTask} 
                    toggleModal={this.toggleModal}
					task={{}}
                />
				<ActivityModal 
					updateList={this.updatePhoneCalls} 
					isOpen={this.state.activityOpen} 
					toggleModal={this.toggleModal} 
					contactId={entity.Contact && entity.Contact.ContactId} 
				/>
				<AppointmentModal
					isOpen={this.state.appointmentOpen}
					toggleModal={this.toggleModal}
					contactId={entity.Contact && entity.Contact.ContactId}
				/>
				<AddNote 
					toggleModal={this.toggleModal} 
					isOpen={this.state.addNote} 
					entity={entity} updateNotes={this.updateNotes} 
					selectedNote={selectedNote}
				/>
				<ActivityDescription
					toggleModal={this.toggleModal}
					isOpen={this.state.activityDescription}
					activity={this.state.selectedActivity}
					title="Edit Call Notes"
					updateList={this.updateList}
					// updateList={this.updatePhoneCalls}
				/>
				<CreateTransactionModal isOpen={showCreateTransaction} toggleModal={this.toggleCreateTransactionModal} contactId={contactId} hasAdditionalContacts={entity.AdditionalContacts && entity.AdditionalContacts.length > 0} />
			</div>
		)
	}
}

export default ContactEntityDetails;