import React, { useState, useEffect } from 'react';
import 'react-table/react-table.css';
import { Panel, PanelBody } from '../panel/panel.jsx';
import { UncontrolledTooltip } from 'reactstrap';
import { displayDate, displayFormalDateTimeUtc, formatPhoneNumber } from '../../helpers/Functions';
import { Link } from 'react-router-dom';
import CallWrapper from '../shared/CallWrapper';

function LeadTable(props){
    const [callDialog, setCallDialog] = useState(0);
    let page = props.activePage;
    let count = props.contactsPerPage;
    let start = page === 1 ? 0 : page * count - count;
    let end = start + count;

    useEffect(() => {
        document.addEventListener('click', handleClearPopover);

        return () => {
            document.removeEventListener('click', handleClearPopover);
        }
    }, []);

    function handleClearPopover(e) {
        if (!e.target.classList.contains('popover') && !e.target.classList.contains('popover-launch')) {
            togglePopover();
        }
    }

    function togglePopover(contactId = 0) {
        setCallDialog(contactId);
    }

    let contacts = props.data;
    // let contacts = props.data.length !== 0 ? props.data.slice(start, end) : [];
    const dataRows = contacts.length === 0 ? null : contacts.map((contact, i) => {
        return (
            <tr key={i} className="cursor-pointer" onClick={() => props.selectContactRow(contact.ContactId)}>
                <td className="with-checkbox valign-middle" onClick={e => e.stopPropagation()}>
                    <div className="row">
                        <div style={{ margin: 'auto' }}>
                            <div className="checkbox checkbox-css">
                                <input type="checkbox" name={contact.ContactId} id={`check_${contact.ContactId}`} checked={props.checkedContacts.get(contact.ContactId)} onChange={(e) => props.handleCheckChange(e)}/>
                                <label htmlFor={`check_${contact.ContactId}`}></label>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <label className="text-blue-darker m-b-3"><Link to={`contact/${contact.ContactId}`}>{contact.ContactDetails.Name}</Link></label><br />
                    {formatPhoneNumber(contact.ContactDetails.CellPhone)}<br />
                    {contact.ContactDetails.Email}<br />
                    <label className="text-blue-darker m-b-3 m-t-10">Last Contact</label><br />
                    {displayFormalDateTimeUtc(contact.ContactDetails.LastContact, true) || 'Not Contacted'}
                </td>
                <td>
                    <div className="form-group m-b-5">
                        <label className="m-b-3 text-blue-darker">Assigned Agent:</label>
                        <p className="m-b-5">{contact.Assigned.Agent}</p>
                    </div>
                    <div className="form-group m-b-5" style={{ whiteSpace: 'nowrap' }}>
                        <label className="m-b-3 text-blue-darker">Assigned Lender:</label>
                        <p className="m-b-5">{contact.Assigned.Lender}</p>
                    </div>
                </td>
                <td>
                    <label className="m-b-3 text-blue-darker">Type:</label><br />
                    {contact.TypeSource.Type}<br />
                    <label className="m-b-3 m-t-10 text-blue-darker">Source:</label><br />
                    {contact.TypeSource.Source}
                </td>
                <td>
                    <label className="m-b-3 text-blue-darker">Date Created</label><br />
                    {displayFormalDateTimeUtc(contact.DateCreated, true)}<br />
                    <label className="m-b-3 m-t-10 text-blue-darker">Last Active</label><br />
                    {displayFormalDateTimeUtc(contact.LastActive, true)}
                </td>
                
                <td>
                    <label className="m-b-3 text-blue-darker">Urgency:</label> <br />
                    {contact.Urgency.Urgency} <br />
                    <label className="m-b-3 m-t-10 text-blue-darker">Timeframe:</label>
                    <p>{displayDate(contact.Urgency.Timeframe)}</p>
                </td>
                <td className="align-middle">
                    <div>
                        <div className="row">
                            <div className="col-xl-6">
                                <CallWrapper 
                                    isOpen={callDialog === contact.ContactId}
                                    buttonId={`phoneCall-${contact.ContactId}`}
                                    togglePopover={togglePopover}
                                    contact={contact}
                                >
                                    { renderProps => (
                                        <React.Fragment>
                                            <button
                                                id={`phoneCall-${contact.ContactId}`} 
                                                onClick={(e) => renderProps.handleCallClick(e, contact)} 
                                                className="btn btn-white btn-sm col-xl-12 m-b-5 popover-launch"
                                            >
                                                <i className="fas fa-phone text-primary popover-launch"></i>
                                            </button>
                                        </React.Fragment>
                                    )}
                                </CallWrapper>
                            </div>
                            <div className="col-xl-6">
                                <button id="text" onClick={(e) => props.handleText(e, contact)} className="btn btn-white btn-sm col-xl-12 m-b-5"><i className="far fa-comment text-primary"></i></button>
                                <UncontrolledTooltip target="text">
                                    Send Text
                                </UncontrolledTooltip>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <button id="addWorkflow" onClick={(e) => props.handleAddCampaign(e, contact.ContactEntityId, contact.ContactId)} className="btn btn-white btn-sm col-xl-12 m-b-5"><i className="far fa-paper-plane text-primary"></i></button>
                                <UncontrolledTooltip target="addWorkflow">
                                    Add Workflow
                                </UncontrolledTooltip>
                            </div>
                            <div className="col-xl-6">
                                <button id="sendEmail" onClick={(e) => props.handleEmail(e, contact.ContactDetails.Email, contact.ContactEntityId, contact.ContactId)} className="btn btn-white btn-sm col-xl-12 m-b-5"><i className="fas fa-envelope text-primary"></i></button>
                                <UncontrolledTooltip target="sendEmail">
                                    Send Email
                                </UncontrolledTooltip>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <button id="addTask" className="btn btn-white btn-sm col-md-12 m-b-5" onClick={(e) => props.handleAddTask(e, contact.ContactEntityId)}><i className="fas fa-check-square text-primary"></i></button>
                                <UncontrolledTooltip target="addTask">
                                    Add Task
                                </UncontrolledTooltip>
                            </div>
                            <div className="col-xl-6">
                                <button id="createTransaction" className="btn btn-white btn-sm col-md-12" onClick={(e) => props.handleCreateTransaction(e, contact.ContactId)}><i className="far fa-clipboard text-primary"></i></button>
                                <UncontrolledTooltip target="createTransaction">
                                    Create Transaction
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        )
    })

    return (
        <table className="table table-striped bg-white">
            <thead>
                <tr>
                    <th>
                        <div className="row">
                            <div style={{ margin: 'auto' }}>
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" id="check_all" onChange={(e) => props.toggleSelectAll(e)}/>
                                    <label htmlFor="check_all"></label>
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>Contact Details</th>
                    <th>Assigned</th>
                    <th>Type/Source</th>
                    <th>Activity Dates</th>
                    <th>Urgency</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                { props.data.length == 0 ? 
                    <tr><td className="text-center" colSpan="7">No records found</td></tr>
                    :
                    dataRows
                }
            </tbody>
        </table>
    )
}

export default LeadTable;

