import React from 'react';
import FileUpload from '../../file-upload/FileUpload';

export default function Step3(props){
    const { handleChange, transaction, step, handleImage, fileName, clearFile } = props;
    const { note, imageUrl } = transaction;
    console.log('trans: ', transaction)
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Transaction Notes</label>
                        <textarea name="note" value={note} className="form-control" rows="7" onChange={(e) => handleChange(e)} />
                    </div>
                </div>
                <div className="col-md-6">
                    { imageUrl ? 
                        <div className="p-25">
                            <img src={imageUrl} alt="property" style={{ width: '100%', maxHeight: '231px', objectFit: 'cover' }}/>
                        </div>
                        :
                        <div className="form-group">
                            <label>Property Image</label>
                            <div style={{ width: '140px', margin: 'auto' }}>
                                <FileUpload 
                                handleFile={handleImage}
                                clearFile={clearFile} 
                                customSubmitHandling={true}
                                FileName={fileName} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}