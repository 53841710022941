import React from 'react';
import NumberFormat from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getData, postData } from '../../helpers/AxiosService';

class PropertyTable extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            properties: [],
            sweetAlertConfirm: false,
            sweetAlertSuccess: false,
            propToDelete: null
        }
        this.getContactProperties = this.getContactProperties.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount(){
        this.getContactProperties();
    }

    componentDidUpdate(prevProps){
        if (this.props.updateProperties != prevProps.updateProperties && this.props.updateProperties){
            this.getContactProperties();
        }
        if (this.props.contactId !== prevProps.contactId){
            this.getContactProperties();
        }
    }

    getContactProperties(){
        const id = this.props.contactId;
        if (!id) return;
        getData(`api/contact/properties?contactId=${id}`).then(response => {
            this.setState({ properties: response.data }, this.props.stopLoading);
        });
    }

    handleDelete(){
        let id = this.state.propToDelete;
        postData(`api/contact/property/delete?propertyId=${id}`).then(response => {
            this.toggleSweetAlert('confirm');
            this.toggleSweetAlert('success');
            this.getContactProperties();
        })
    }

    presentConfirmDelete(id){
        this.setState({ propToDelete: id }, this.toggleSweetAlert('confirm'));
    }

    toggleSweetAlert(name) {
		switch(name) {
			case 'primary':
				this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
				break;
			case 'info':
				this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
				break;
			case 'success':
				this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
				break;
			case 'warning':
				this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
				break;
			case 'error':
				this.setState({ sweetAlertError: !this.state.sweetAlertError });
				break;
			case 'confirm':
				this.setState({ sweetAlertConfirm: !this.state.sweetAlertConfirm });
				break;
			default:
				break;
		}
	}

    renderRows(){
        const { canEdit } = this.props;
        return this.state.properties.map((property, idx) => {
            return (
                <tr key={idx}>
                    <td>{property.Address}</td>
                    <td>{property.City}</td>
                    <td>{property.StateCode}</td>
                    <td>{property.ZipCode}</td>
                    <td><NumberFormat displayType={'text'} prefix={'$'} thousandSeparator={true} value={property.EstimatedValue} /></td>
                    <td><NumberFormat displayType={'text'} prefix={'$'} thousandSeparator={true} value={property.MortgageBalance} /></td>
                    <td><NumberFormat displayType={'text'} thousandSeparator={true} value={property.SquareFootage} /></td>
                    <td>{property.Bedrooms}</td>
                    <td>{property.Bathrooms}</td>
                    { canEdit && <td><div className="f-s-16" style={{whiteSpace: 'nowrap'}}>
                        <i className="far fa-edit pointer-cursor m-r-10" onClick={() => this.props.selectProperty(property)}></i>
                        <i className="far fa-trash-alt pointer-cursor" onClick={() => this.presentConfirmDelete(property.ContactPropertyId)}></i>
                    </div></td>}
                </tr>
            )
        });
    }

    render(){
        const { canEdit } = this.props;
        return (
            <div className="table-responsive">
                <table className="table table-bordered table-striped bg-white">
                    <thead>
                        <tr>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip Code</th>
                            <th>Estimated Value</th>
                            <th>Mortgage Balance</th>
                            <th>Square Footage</th>
                            <th>Bedrooms</th>
                            <th>Bathrooms</th>
                            { canEdit && <th>Edit</th>}
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.properties.length > 0 && this.renderRows() }
                        { this.state.properties.length == 0 && <tr><td className="text-center" colSpan="10">No properties to display</td></tr>}
                    </tbody>
                </table>
                {( this.state.sweetAlertConfirm && 
                    <SweetAlert
                        primary 
                        showCancel
                        confirmBtnText="Delete"
                        cancelBtnBsStyle="default"
                        title="Confirm Delete"
                        onConfirm={this.handleDelete}
                        onCancel={() => this.toggleSweetAlert('confirm')}
                    >
                        Are you sure you would like to delete this property?
                    </SweetAlert>
                )}
                {( this.state.sweetAlertSuccess && 
                    <SweetAlert
                        success 
                        confirmBtnText="Ok"
                        confirmBtnBsStyle="success"
                        title="Success!"
                        onConfirm={() => this.toggleSweetAlert('success')}
                    >
                        Property deleted successfully
                    </SweetAlert>
                )}
            </div>
        )
    }
}

export default PropertyTable;