import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import './CheckoutForm.css';

const createOptions = () => {
    return {
    classes: {
        base: 'stripe-form-control',
    },
      style: {
        base: {
          fontSize: '14px',
          color: '#424770',
          letterSpacing: '0.025em',
        //   fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
           padding: '10px',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    ev.preventDefault();
    let { token } = await this.props.stripe.createToken();
    if (token && token.id) {
        if (!this.props.paymentFailed) {
            this.props.onSubmit(token.id);
        } else {
            this.props.handleResubmit(token.id);
        }
    }
  }

  render() {
    const { paymentFailed } = this.props;
    return (
      <div className="register-content">
        { paymentFailed && 
        <label className="text-danger">Payment Method failed, please enter a different credit card</label>
        }
        <div className="m-b-20">
						<div className="m-b-15">Recurring Payment Information</div>
            <CardElement {...createOptions()} key={this.props.elementKey} />
        </div>
        <div className="register-buttons">
            
            <button 
                type="submit" 
                className="btn btn-lime btn-block btn-lg" 
                onClick={this.submit}
                disabled={this.props.sending ? true : false}> 
                { this.props.sending ? 
                    <div className="button-spinner-white"></div>
                    : 
                    "Complete Registration"
                }
            </button> 
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);