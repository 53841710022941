import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactSelect from '../ContactSelect';
import { postData, getData } from '../../helpers/AxiosService';
import ReactTags from 'react-tag-autocomplete';
import { PageSettings } from '../../config/page-settings';

function getInitialState() {
    const initState = {
        subject: '',
        message: '',
        disableReg: false,
        sending: false,
        contactSuggestions: [],
        recipients: [],
        isText: false
    }

    return initState;
}
class SendProperty extends React.Component {
    static defaultProps = {
        checkedProperties: [],
        getPropertyKeys: () => {},
    }

    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = getInitialState();
    }

    componentDidMount() {
        // take a passed in contact and add it to recipients
        if (this.props.contactId) {
            this.setContact(this.props.contactId);
        }

        this.fetchExistingContacts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contactId !== this.props.contactId) {
            this.setContact(this.props.contactId);
        }
    }

    setContact(contactId) {
        getData(`api/contact?contactId=${contactId}`).then(resp => {
            const { Contact } = resp.data;
            const contact = {
                id: Contact.ContactId,
                name: Contact.FirstName + ' ' + Contact.LastName,
            };

            this.handleAddRecipient(contact);
        });
    }

    fetchExistingContacts() {
        getData('api/contact/user').then(response => {
            const options = response.data.map(c => { 
                return { 
                    id: c.ContactId, 
                    name: `${c.FirstName} ${c.LastName}`
                }
            });
            this.setState({ contactSuggestions: options });
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    toggleForceReg() {
        this.setState({ disableReg: !this.state.disableReg });
    }

    toggleIsText() {
        this.setState({ isText: !this.state.isText });
    }

    handleAddRecipient(contact) {
        const recipients = [...this.state.recipients, contact]
        this.setState({ recipients });
    }

    handleRemoveRecipient(i) {
        const recipients = this.state.recipients.slice();
        recipients.splice(i, 1);
        this.setState({ recipients });
    }

    handleSendProperties() {
        this.setState({ sending: true });
        let contactIds = [];
        this.state.recipients.forEach(c => contactIds.push(c.id));
        const data = {
            ContactIds: contactIds,
            ListingKeys: this.props.getPropertyKeys(),
            Subject: this.state.subject,
            Message: this.state.message,
            DisableForcedRegistration: this.state.disableReg,
            MessageType: this.state.isText ? 'text' : 'email',
        }

        postData('api/search/send-property', data, this).then(resp => {
            this.context.addNotification("Success!", "Your message was sent successfully.");
            this.resetModal();
        }).fail(() => this.setState({ sending: false }));
    }

    resetModal() {
        this.setState(getInitialState(), this.fetchExistingContacts);
        if (this.props.contactId) {
            this.setContact(this.props.contactId);
        }
        this.props.toggleModal();
    }

    render() {
        const { isOpen, toggleModal, subject, message } = this.props;
        const { recipients, disableReg, isText } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg">
                <ModalHeader toggle={() => this.resetModal()}>Send Properties</ModalHeader>
                <ModalBody>
                    <div className="row">
                       <div className="col-md-12">
                            <div className="checkbox checkbox-css">
                                <input type="checkbox" id="isText" checked={isText} onChange={() => this.toggleIsText()} />
                                <label for="isText">Send As Text</label>
                            </div>
                        </div>
                   </div>
                   <div className="row">
                       <div className="col-md-12">
                            <div className="checkbox checkbox-css">
                                <input type="checkbox" id="cssCheckbox1" checked={disableReg} onChange={() => this.toggleForceReg()} />
                                <label for="cssCheckbox1">Disable force registration for property views</label>
                            </div>
                        </div>
                   </div>
                   <hr />
                    <div className="inbox m-b-25">
                        <div className="email-to">
                            <label className="control-label">To:</label>
                            <div className="email-to-input">
                                <ReactTags 
                                    classNames={this.state.tagClass} 
                                    placeholderText="Type contact name..." 
                                    tags={this.state.recipients} 
                                    suggestions={this.state.contactSuggestions} 
                                    onDelete={this.handleRemoveRecipient.bind(this)} 
                                    onAddition={this.handleAddRecipient.bind(this)} 
                                    allowNew={false} 
                                />
                            </div>
                        </div> 
                    </div> 
                   { !isText && <div className="row">
                       <div className="form-group col-md-12">
                           <label>Subject</label>
                           <input name="subject" className="form-control" onChange={(e) => this.handleChange(e)} value={subject} />
                       </div>
                   </div> }
                   <div className="row">
                       <div className="form-group col-md-12">
                           <label>Message</label>
                           <textarea name="message" className="form-control" rows="7" onChange={(e) => this.handleChange(e)} value={message} />
                       </div>
                   </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary pull-right width-100" onClick={() => this.handleSendProperties()} disabled={recipients.length === 0}>
                        { this.state.sending ? 
                            <div className="button-spinner"></div> 
                            :
                            "Send"
                        }
                    </button>
                    <button className="btn btn-primary-outline pull-right width-100" onClick={() => this.resetModal()}>Cancel</button>
                </ModalFooter>
            </Modal>
        )
    }
}

SendProperty.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default SendProperty;