import React from 'react';
import ReportContainer from './ReportContainer'
import { DateOp } from '../../helpers/OperationTypes';
import { displayDate } from '../../helpers/Functions';
import { Link } from 'react-router-dom';


export default function TransactionClosingReport() {
    return (
        <ReportContainer 
            title="Closing Transactions"
            description="Transactions which have a closing date in the specified timeframe"
            reportType="transaction-closing"
            startingRange="This Week"
            startingRangeOp={DateOp.thisWeek}
            reportField="ClosingDate"
            rangeDirection="future"
            includeAll={true}
        >
            {data => (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Transaction</th>
                            <th>Closing Date</th>
                            <th>Agent</th>
                            <th>Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.length > 0 && data.map((t) => (
                            <tr key={t.TransactionId}>
                                <td><Link to={`/transactions/transaction/${t.TransactionId}`}>{t.Address}</Link></td>
                                <td>{displayDate(t.ClosingDate)}</td>
                                <td>{t.AgentName || 'Unassigned'}</td>
                                <td>{t.Type}</td>
                                <td>{t.Status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </ReportContainer>
    )
}