import { displayDateUtc } from './Functions';

export function convertToCsv(contacts) {
    console.log(contacts);
    const csvString = [
        [
            "FirstName",
            "LastName",
            "Phone",
            "Email",
            "Source",
            "DateLastContacted",
            "DateLastActive",
            "ContactType",
        ],
        ...contacts.map(c => {
            const nameArr = c.ContactDetails.Name.split(' ');
            const firstName = nameArr[0];
            const lastName = nameArr[1];
            return [
                firstName,
                lastName,
                c.ContactDetails.CellPhone,
                c.ContactDetails.Email,
                c.TypeSource.Source,
                displayDateUtc(c.ContactDetails.LastContact),
                displayDateUtc(c.LastActive),
                c.TypeSource.Type
            ]
        })
    ]
        .map(e => e.join(','))
        .join('\n');

    return csvString;
}