import React from 'react';
import { Panel, PanelBody } from '../components/panel/panel.jsx';
import { postData, getData } from '../helpers/AxiosService.js';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import querySearch from 'stringquery';
import { PageSettings } from '../config/page-settings';

class CreateWorkflow extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            name: '',
            shareWithGroup: false,
            pauseOnComm: false,
            secondaryContacts: false,
            creating: false,
            hasError: false,
            type: '',
        }
    }

    componentDidMount() {
        let q = querySearch(this.props.location.search);
        this.setState({ type: q.type });
    }

    handleChange(e){
        const value = e.target.value;
        this.setState({ name: value });
    }

    toggleCheckbox(box){
        this.setState({ [box]: !this.state[box] });
    }

    createWorkflow(){
        if (!this.state.name){
            this.setState({ hasError: true });
            return;
        }

        this.setState({ creating: true });

        const data = {
            Name: this.state.name,
            ShareWithGroup: this.state.shareWithGroup,
            PauseOnCommunication: this.state.pauseOnComm,
            SecondaryContacts: this.state.secondaryContacts,
            Type: this.state.type,
        };

        postData('api/workflow', data, this).then(response => {
            this.setState({ creating: false, hasError: false });
            if (this.state.type === 'Contact'){
                this.props.history.push(`/workflows/workflow/${response.data.WorkflowId}`);
            } else {
                this.props.history.push(`/transactions/workflow/${response.data.WorkflowId}`);
            }
        });
    }

    handleCancel() {
        this.props.history.goBack();
    }

    render(){
        const { name, shareWithGroup, pauseOnComm, secondaryContacts, hasError, creating, type } = this.state;
        return(
            <div>
                <Panel>
                    <PanelBody>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={classnames({ "has-error": hasError && !name }, "form-group")}>
                                    <label>Name</label>
                                    <input className="form-control" value={name} onChange={(e) => this.handleChange(e)} />
                                </div>
                                { type !== 'Transaction' && <div>
                                    <label className="text-primary m-t-10 m-b-10">*These settings can be updated anytime</label>
                                    <div className="checkbox checkbox-css">
                                        <input type="checkbox" id="cssCheckbox1" checked={shareWithGroup} onChange={() => this.toggleCheckbox('shareWithGroup')} />
                                        <label htmlFor="cssCheckbox1">Share Workflow with group</label>
                                    </div>
                                    <div className="checkbox checkbox-css">
                                        <input type="checkbox" id="cssCheckbox2" checked={secondaryContacts} onChange={() => this.toggleCheckbox('secondaryContacts')} />
                                        <label htmlFor="cssCheckbox2">Send texts/emails to secondary contacts</label>
                                    </div>
                                    <div className="checkbox checkbox-css">
                                        <input type="checkbox" id="cssCheckbox3" checked={pauseOnComm} onChange={() => this.toggleCheckbox('pauseOnComm')} />
                                        <label htmlFor="cssCheckbox3">Pause workflow on communication from contact</label>
                                    </div>
                                </div> }
                            </div>
                        </div>
                        <div className="row m-t-25">
                            <div className="col-md-6">
                                <button className="btn btn-primary p-l-20 p-r-20" onClick={() => this.createWorkflow()}>{ creating ? <div className="button-spinner"></div> : "Save"}</button>
                                <button className="btn btn-white p-l-20 p-r-20 m-l-10" onClick={() => this.handleCancel()}>Cancel</button>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default CreateWorkflow;