const validTypes = ['xlsx', 'csv', 'jpg', 'png', 'pdf']

export function handleFile(event, self, types = validTypes){
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onload = f => {
        let preview = f.target.result;
        let fileString = f.target.result.slice(preview.indexOf('base64,') + 7);
        let uploadFile = {
            FileString: fileString,
            FileName: file.name,
            FileType: file.type,
            DataString: preview
        }
        if (validateFileType(file.name, types)){
            self.setState({ uploadFile });
        } else {
            alert("invalid file type");
            return;
        }
    }
    reader.readAsDataURL(file)
}


function validateFileType(name, types){
    let isValid = true;
    if (types.length === 0) return isValid;

    let fileExt = name.split('.').pop().toLowerCase();
    isValid = types.indexOf(fileExt) > -1;
    return isValid;
}
