import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import AddAppointment from './AddAppointment';


function AppointmentModal({contactId, isOpen, toggleModal }){
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('appointment')}>Appointment</ModalHeader>
            <ModalBody>
                <AddAppointment
                    close={toggleModal.bind(null, 'appointment')}
                    contactId={contactId} 
                />
            </ModalBody>
        </Modal>
    )
}

export default AppointmentModal;

AppointmentModal.propTypes = {
    contactId: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}