import React from 'react';
import Notifications from '../components/settings/Notifications';
import { Link } from 'react-router-dom';

export default function NotificationSettings(){
    return (
        <div>
            <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Notification Settings</li>
            </ol>
            <h1 className="page-header">Notification Settings <small className="f-w-100">Manage your notification settings</small></h1>
            <Notifications />
        </div>
    )
}