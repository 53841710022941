import React from 'react';
import { displayDate, volumeFormatter } from '../../helpers/Functions';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function OpportunityCard({ opportunity, handleCardClick, handleDelete }) {
    return (
        <div className="widget-card rounded bg-white m-b-10 with-thin-shadow" onClick={() => handleCardClick(opportunity)}>
            <div className="widget-card-content">
                <div>
                    <h4 className="d-inline-block">{opportunity.Name}</h4>
                    <div className="pull-right text-righ" onClick={e => e.stopPropagation()}>
                        <UncontrolledDropdown>
                            <DropdownToggle className="btn btn-white-outline border-none">
                                <i className="fas fa-ellipsis-h text-black"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={(e) => handleDelete(opportunity)}>Delete</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                <h5>
                    <Link to={`/contact/${opportunity.ContactId}`}>
                        {opportunity.ContactName}
                    </Link>
                </h5>
                <span className="badge bg-primary">{opportunity.Type}</span>
                { opportunity.SalesPrice && <span className="badge m-l-5">${volumeFormatter(opportunity.SalesPrice)} Price</span> }
                { opportunity.GCI && <span className="badge m-l-5">${volumeFormatter(opportunity.GCI)} GCI</span> }
                { opportunity.ProjectedCloseDate && <div className="m-t-10 m-b-0">Projected Close: {displayDate(opportunity.ProjectedCloseDate)}</div> }
            </div>
            <div className="widget-card-content bottom p-t-0">
                <span className="mb-0">Agent: {opportunity.UserName}</span>
            </div>
        </div>
    )
}