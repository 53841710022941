import React, { useState, useContext } from 'react';
import { Panel, PanelBody } from '../panel/panel.jsx';
import { CardTitle, UncontrolledTooltip } from 'reactstrap';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import CallWrapper from '../shared/CallWrapper.js';
import { PageSettings } from '../../config/page-settings.js';

export default function SalesListCard({ list, loading, handleEditClick, handleEmail, handleDeleteClick }) {
    const cxt = useContext(PageSettings);
    const [callDialog, setCallDialog] = useState(0);

    function togglePopover(contactId = 0) {
        console.log('hit')
        setCallDialog(contactId);
    }

    function handleText(contact){
        cxt.openConversation({ contactId: contact.ContactId, name: contact.ContactDetails.Name, number: contact.ContactDetails.CellPhone })
    }

    return (
        <Panel className="with-thin-shadow height-md">
            <PanelBody style={{ position: 'relative' }}>
                <div className="row">
                    <CardTitle tag="h4" className="m-b-20 col-6">
                        {list.Name} { list.Description && <i className="fas fa-info-circle text-primary f-s-14" id={`${list.Name.replaceAll(' ', '')}_cardTitle`}></i> }
                    </CardTitle>
                    { !!list.Id && <div className="col-6">
                        <button className="btn btn-icon pull-right m-l-5" onClick={() => handleDeleteClick(list.Id)}>
                            <i className="fas fa-trash"></i>
                        </button>
                        <button className="btn btn-icon pull-right" onClick={() => handleEditClick(list.Id)}>
                            <i className="fas fa-edit text-primary"></i>
                        </button>
                    </div> }
                </div>
                { list.Description && <UncontrolledTooltip target={`${list.Name.replaceAll(' ', '')}_cardTitle`}>
                    {list.Description}
                </UncontrolledTooltip> }
                <PerfectScrollBar className="m-t-20" style={{ height: '350px' }}>
                    {
                        loading ?
                        <div className="text-center spinner"></div>
                        :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Agent</th>
                                    <th>Source</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { list.Results && list.Results.length > 0 && list.Results.map((contact) => (
                                    <tr key={contact.ContactId}>
                                        <td><Link to={`/contact/${contact.ContactId}`} target="_blank" rel="noopener noreferrer">{contact.ContactDetails.Name || "Unknown"}</Link></td>
                                        <td>{contact.Assigned.Agent || 'Unassigned'}</td>
                                        <td>{contact.TypeSource.Source}</td>
                                        <td style={{ minWidth: '150px' }}>
                                            <CallWrapper
                                                    isOpen={callDialog === contact.ContactId}
                                                    buttonId={`phoneCall-${contact.ContactId}`}
                                                    togglePopover={togglePopover}
                                                    contact={contact}
                                                    placement="bottom"
                                                >
                                                    { renderProps => (
                                                        <React.Fragment>
                                                            <button 
                                                                id={`phoneCall-${contact.ContactId}`} 
                                                                className="btn btn-default m-r-5 btn-icon btn-circle popover-launch" 
                                                                onClick={(e) => renderProps.handleCallClick(e, contact)}
                                                            >
                                                                <i className="fas fa-phone text-primary popover-launch"></i>
                                                            </button>
                                                        </React.Fragment>
                                                    )}
                                                </CallWrapper>
                                                { contact.ContactDetails.CellPhone && <div className="d-inline">
                                                    <button
                                                        className="btn btn-default btn-icon btn-circle m-r-5"
                                                        id="text"
                                                        onClick={() => handleText(contact)}
                                                    >
                                                        <i className="far fa-comment text-primary"></i>
                                                    </button>
                                                    <UncontrolledTooltip target="text">
                                                        Send Text
                                                    </UncontrolledTooltip>
                                                </div> }
                                                { contact.ContactDetails.Email && <div className="d-inline">
                                                    <button 
                                                        className="btn btn-default btn-icon btn-circle"
                                                        id={`sendEmail-${contact.ContactId}`} 
                                                        onClick={() => handleEmail(contact.ContactDetails.Email, contact.ContactEntityId, contact.ContactId)} 
                                                    >
                                                        <i className="fas fa-envelope text-primary"></i>
                                                    </button>
                                                    <UncontrolledTooltip target={`sendEmail-${contact.ContactId}`}>
                                                        Send Email
                                                    </UncontrolledTooltip>
                                                </div> }
                                        </td>
                                    </tr>
                                ))}
                                { (!list.Results || !list.Results.length) && 
                                    <tr>
                                        <td colSpan="4" className="text-center">No Results</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }
                </PerfectScrollBar>
            </PanelBody>
        </Panel>
    )
}