import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings';
import { Op } from '../../helpers/OperationTypes';

const filters = {
    allAgents: { field: 'AssignedAgentId', value: '0', operation: Op.equals },
    leads: { field: 'ContactTypeId', value: [{ value: 1, label: 'Lead' }], operation: Op.equals}, 
    prospects: { field: 'ContactTypeId', value: [{ value: 2, label: 'Prospects' }], operation: Op.equals}, 
    sphere: { field: 'ContactTypeId', value: [{ value: 3, label: 'Sphere of Influence' }], operation: Op.equals}, 
    pastClients: { field: 'ContactTypeId', value: [{ value: 4, label: 'Past Clients' }], operation: Op.equals}, 
    isNew: { field: 'IsNew', value: true, operation: Op.equals },
    unassigned: { field: 'AssignedAgentId', value: 1, operation: Op.equals },
    noSavedSearch: { field: 'WithoutSearch', value: true, operation: Op.equals }
}

const SmartSearches = (props) => {
    const cxt = useContext(PageSettings);
    const isAdmin = cxt.userInfo.RoleId === 2;
    function handleSmartClick(filter) {
        const filters = JSON.stringify([filter]);
        props.history.push(`/contacts?filters=${filters}`);
    }
    return(
        <div>
            <div className="row">
                <div className="col-md-12 m-b-10">
                    <Link to="/contacts" className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5">All Contacts</Link>
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.leads)}>Leads</button>
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.prospects)}>Prospects</button>
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.sphere)}>Sphere of Influence</button>
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.pastClients)}>Past Clients</button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.isNew)}>New</button>
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.unassigned)}>Unassigned</button>
                    <button className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" onClick={() => handleSmartClick(filters.noSavedSearch)}>No Saved Search</button>
                    { isAdmin && <button 
                        className="btn btn-white p-l-30 p-r-30 m-r-5 m-b-5" 
                        onClick={() => handleSmartClick(filters.allAgents)}
                    >All Agents</button> }
                </div>
            </div>
        </div>
    )
}

export default SmartSearches;