import React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { phoneClean } from '../../helpers/Functions';
import { Link, withRouter } from 'react-router-dom';
import { postData, getData, deleteData } from '../../helpers/AxiosService.js';
import { renderStateList } from '../../helpers/StateService';
import { PageSettings } from '../../config/page-settings';
import { Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import AgentOptions from '../../components/shared/AgentOptions';



class UserProfile extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            user: {}, 
            sending: false,
            sendingReset: false,
            roleOptions: [],
            userIsAdmin: false,
            showResetModal: false,
            oldPassword: '',
            newPassword: '',
            confirmDeleteModal: false,
            leadControl: 'pond',
            reassignToId: 0,
            deleting: false,
            contacts: [],
        }

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleLeadControlChange = this.handleLeadControlChange.bind(this);
        this.handleReassignToSelect = this.handleReassignToSelect.bind(this);
    }

    componentDidMount() {
        if (this.props.userId){
            this.fetchUser(this.props.userId);
        }

        this.fetchRoleOptions();
        this.checkIsAdmin();
        this.fetchUserContacts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId){
            this.fetchUser(this.props.userId);
            this.fetchUserContacts();
        }
    }

    fetchUser(id) {
        getData(`api/user/userbyid/${id}`).then(response => {
            this.setState({ user: response.data }, this.props.stopLoading);
        });
    }

    async fetchUserContacts() {
        const resp = await getData(`api/contact/user?userId=${this.props.userId}`);
        this.setState({ contacts: resp.data });
    }

    fetchRoleOptions() {
        getData('api/contact/role-options').then(response => {
            this.setState({ roleOptions: response.data });
        });
    }

    checkIsAdmin() {
        getData('api/user/admin-permission?permission=CreateUser').then(resp => {
            this.setState({ userIsAdmin: resp.data });
        });
    }

    handleChange(item, e) {
        let val = e.target.value;

        if (item.includes('Phone') || item.includes('Fax')){
            val = phoneClean(val);
        }

        var userCopy = Object.assign({}, this.state.user);
        userCopy[item] = val;
        this.setState({ user: userCopy });
    }

    handlePasswordChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    toggleNotifyEmail() {
        var userCopy = Object.assign({}, this.state.user);
        userCopy.NotifyEmailSame = !this.state.user.NotifyEmailSame;
        this.setState({ user: userCopy });
    }

    handleSubmit(e) {
        e.preventDefault();

        //TO DO handle validation
        this.setState({ sending: true });

        postData('api/user/update', this.state.user, this).then(response => {
            //show a success message 
            this.setState({ sending: false });
            this.context.addNotification("Success!", "Updated successfully!");
        }).fail(error => this.setState({ sending: false }));

    }

    toggleResetModal() {
        this.setState({ oldPassword: '', newPassword: '', sendingReset: false, showResetModal: !this.state.showResetModal });
    }

    handleRequestPasswordReset() {
        this.setState({ sendingReset: true });
        let data = {
            UserId: this.props.userId,
            OldPassword: this.state.oldPassword,
            NewPassword: this.state.newPassword,
        }

        postData('api/account/changepassword', data, this).then(() => {
            this.setState({ sendingReset: false });
            this.toggleResetModal();
            this.context.addNotification("Success!", "Your password has been updated.");
        }).fail(err => {
			console.error(err);
			this.setState({ sendingReset: false });
		});
    }

    async handleDeleteClick() {
        const { user, contacts } = this.state;
        
        // if user has leads give option to reassign
        if (contacts.length) {
            this.setState({ confirmDeleteModal: true });
            return;
        }

        this.context.messageConfirmation('Delete User', `Are you sure you want to delete ${user.FirstName} ${user.LastName}?`, 
                    this.deleteUser.bind(null, this.props.userId), "User deleted successfully", "Delete");
    }

    async deleteUser(userId) {
        this.setState({ deleting: true });
        if (!this.state.contacts.length) {
            this.context.toggleSweetAlert('confirm');
        }
		try {
            await deleteData(`api/user/${userId}/${this.state.reassignToId}`, this);
            this.context.toggleSweetAlert('success');
            this.props.history.push('/admin/users')
        } catch (e) {
            console.log(e);
            this.context.toggleSweetAlert('success');
        }
    }

    handleLeadControlChange(e) {
        const { value } = e.target;
        this.setState({ leadControl: value, reassignToId: 0 });
    }

    handleReassignToSelect(e) {
        const { value } = e.target;
        this.setState({ reassignToId: value });
    }

    render() {
        const { user, sendingReset, roleOptions, userIsAdmin, oldPassword, newPassword, showResetModal, confirmDeleteModal, deleting } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 p-l-20 p-r-20">
                        <h5 className="m-b-25">User Information</h5>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">First Name</label>
                            <input className="form-control col-md-8" value={user.FirstName} onChange={(e) => this.handleChange('FirstName', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Last Name</label>
                            <input className="form-control col-md-8" value={user.LastName} onChange={(e) => this.handleChange('LastName', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Role</label>
                            <select className="form-control col-md-8" disabled={!userIsAdmin} value={user.RoleId} onChange={(e) => this.handleChange('RoleId', e)}>
                                <option></option>
                                {roleOptions.length > 0 && roleOptions.map(o => <option key={o.Key} value={o.Key}>{o.Value}</option>)}
                                {/* <option value={2}>Admin</option>
                                <option value={3}>Agent</option> */}
                            </select>
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Title</label>
                            <input className="form-control col-md-8" value={user.Title} onChange={(e) => this.handleChange('Title', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Address</label>
                            <input className="form-control col-md-8" value={user.Address} onChange={(e) => this.handleChange('Address', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">City</label>
                            <input className="form-control col-md-8" value={user.City} onChange={(e) => this.handleChange('City', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">State</label>
                            {/* <input className="form-control col-md-8" value={user.State} onChange={(e) => this.handleChange('State', e)} /> */}
                            <select className="form-control col-md-8" value={user.State} onChange={(e) => this.handleChange('State', e)} >
                                <option></option>
                                {renderStateList()}
                            </select>
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Zip</label>
                            <InputMask mask="99999" className="form-control col-md-5" value={user.Zip} onChange={(e) => this.handleChange('Zip', e)}></InputMask>
                        </div>
                        <h5 className="m-b-25 m-t-25">Email Information</h5>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Email</label>
                            <input className="form-control col-md-8" value={user.Email} onChange={(e) => this.handleChange('Email', e)} />
                        </div>
                        <div className="checkbox checkbox-css m-b-15">
                            <input type="checkbox" id="cssCheckbox2" checked={user.NotifyEmailSame} onChange={() => this.toggleNotifyEmail()}/>
                            <label htmlFor="cssCheckbox2">Notification Email same as Email</label>
                        </div>
                        <div className={classnames({ 'hide': user.NotifyEmailSame }, 'form-group row m-b-15')}>
                            <label className="col-form-label col-md-3">Notification Email</label>
                            <input className="form-control col-md-8" value={user.NotificationEmail} onChange={(e) => this.handleChange('NotificationEmail', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Email Signature</label>
                            <textarea className="form-control col-md-8" rows="7" value={user.EmailSignature} onChange={(e) => this.handleChange('EmailSignature', e)} ></textarea>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h5 className="m-b-20">User Login Information</h5>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Username Email</label>
                            <input className="form-control-plaintext col-md-8" type="text" readonly value={user.UserName} onChange={(e) => this.handleChange('UserName', e)} />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <button
                                    className="btn btn-white p-l-20 p-r-20"
                                    onClick={() => this.toggleResetModal()}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <button
                                    className="btn btn-white p-l-20 p-r-20"
                                    onClick={() => this.handleRequestPasswordReset()}
                                >
                                    { sendingReset ? 
                                        <div className="button-spinner"></div>
                                        :
                                        "Change Password"
                                    }
                                </button>
                            </div>
                        </div> */}
                        <h5 className="m-b-25 m-t-25">Contact Information</h5>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Cell Phone</label>
                            <InputMask mask="(999) 999 9999" className="form-control col-md-8" value={user.CellPhone} onChange={(e) => this.handleChange('CellPhone', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Home Phone</label>
                            <InputMask mask="(999) 999 9999" className="form-control col-md-8" value={user.HomePhone} onChange={(e) => this.handleChange('HomePhone', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Office Phone</label>
                            <InputMask mask="(999) 999 9999" className="form-control col-md-8" value={user.OfficePhone} onChange={(e) => this.handleChange('OfficePhone', e)} />
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">Fax</label>
                            <InputMask mask="(999) 999 9999" className="form-control col-md-8" value={user.Fax} onChange={(e) => this.handleChange('Fax', e)} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        { this.state.userIsAdmin && <button className="btn btn-outline-danger" onClick={this.handleDeleteClick}>Delete User</button> }
                        <Link to="/admin/users"><button className="btn btn-white pull-right m-l-10">Cancel Changes</button></Link>
                        {
                            user.UserId ? 
                            <button className="btn btn-white pull-right" onClick={(e) => this.handleSubmit(e)}> {this.state.sending ? <div className="button-spinner"></div> : "Update Profile"}</button>
                            :
                            <button className="btn btn-white pull-right" onClick={(e) => this.handleSubmit(e)}>{this.state.sending ? <div className="button-spinner"></div> : "Create New User"}</button>
                        }
                    </div>
                </div>

                <Modal isOpen={showResetModal}>
                    <ModalHeader toggle={() => this.toggleResetModal()}>Reset Password</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label>Old Password</label>
                            <input 
                                className="form-control"
                                name="oldPassword"
                                value={oldPassword}
                                onChange={e => this.handlePasswordChange(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>New Password</label>
                            <input 
                                className="form-control"
                                name="newPassword"
                                value={newPassword}
                                onChange={e => this.handlePasswordChange(e)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary-outline" onClick={() => this.toggleResetModal()}>Cancel</button>
                        <button className="btn btn-primary m-l-5" onClick={() => this.handleRequestPasswordReset()}>
                            {sendingReset ? 
                                <div className="button-spinner"></div>
                                :
                                "Submit"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={confirmDeleteModal}>
                    <ModalHeader toggle={() => this.setState({ confirmDeleteModal: false })}>Delete User</ModalHeader>
                    <ModalBody>
                        <h4>What would you like to do with {user.FirstName}'s leads?</h4>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                name="leadControl" 
                                type="radio" 
                                id="radio1"
                                value="pond" 
                                onChange={this.handleLeadControlChange}
                                checked={this.state.leadControl === 'pond'}
                            />
                            <label class="form-check-label" for="radio1">Send To Pond</label>
                        </div>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                name="leadControl" 
                                type="radio" 
                                id="radio2" 
                                value="reassign"
                                onChange={this.handleLeadControlChange}
                                checked={this.state.leadControl === 'reassign'}
                            />
                            <label class="form-check-label" for="radio2">Reassign To Another Agent</label>
                        </div>
                        <Collapse isOpen={this.state.leadControl === 'reassign'} style={{ marginTop: '15px' }}>
                            <AgentOptions 
                                agentId={this.state.reassignToId}
                                handleSelect={this.handleReassignToSelect}
                            />
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary-outline" onClick={() => this.setState({ confirmDeleteModal: false })}>Cancel</button>
                        <button className="btn btn-primary m-l-5" onClick={() => this.deleteUser(this.props.userId)}>
                            {deleting ? 
                                <div className="button-spinner"></div>
                                :
                                "Delete User"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withRouter(UserProfile);
