import React, { useContext } from 'react';
import FileUpload from '../components/file-upload/FileUpload';
import { getData } from '../helpers/AxiosService';
import { PageSettings } from '../config/page-settings.js';

function sendTestEmail(){
    getData('api/workflow/testemail').then(response => console.log("success!"));
}
function FileUploadPage(){
    const context = useContext(PageSettings)
    return(
        <div>
            

            <button className="btn btn-primary-outline m-r-10" onClick={() => context.addNotification("test", "this is the test", "success", "top", "top-right")}>Outline</button>
            <button className="btn btn-primary">Normal</button>
        </div>
    )
}

export default FileUploadPage;