import React from 'react';
import Home from './../pages/Home';
import ContactEntityDetails from '../pages/ContactEntityDetails';
import { Route, Switch, Redirect } from 'react-router-dom';
import ContactProperties from '../pages/ContactProperties';
import Leads from '../pages/Leads';
import NewContact from '../pages/NewContact';
import Users from '../pages/Users';
import User from '../pages/User';
import CreateUser from '../pages/CreateUser';
import Workflows from '../pages/Workflows';
import EditWorkflow from '../pages/EditWorkflow';
import CreateWorkflow from '../pages/CreateWorkflow';
import FileUploadPage from '../pages/FileUpload';
import EmailTemplates from '../pages/EmailTemplates';
import BulkUpload from '../pages/BulkUpload';
import Transactions from '../pages/Transactions';
import Transaction from '../pages/Transaction';
import Forbidden from '../pages/Forbidden';
import TextTemplates from '../pages/TextTemplates';
import NotificationSettings from '../pages/NotificationSettings';
import querySearch from 'stringquery';
import Tasks from '../pages/Tasks';
import TransactionDashboard from '../pages/TransactionDashboard';
import Commissions from '../pages/Commissions';
import EmailSettings from '../pages/EmailSettings';
import SearchResults from '../pages/Search';
import MapPage from '../pages/MapPage';
import IdxSettings from '../pages/IdxSettings';
import ProfileSetup from '../pages/ProfileSetup';
import Video from '../pages/Video';
import Videos from '../pages/Videos';
import Reporting from '../pages/Reporting';
import Calendar from '../pages/Calendar';
import Stages from '../pages/Stages';
import Pipeline from '../pages/Pipeline';
import TextCodes from '../pages/TextCodes';
import SalesDashboard from '../pages/SalesDashboard';
import Sources from '../pages/Sources';
import UrgencyTypes from '../pages/UrgencyTypes';
import SavedSearches from '../pages/SavedSearches';

const routes = (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/contact/:contactId" exact render={(props) => (
      <ContactEntityDetails key={props.match.params.contactId} {...props} />)
    } />
    <Route path="/contacts" exact render={(props) => {
      const queries = querySearch(props.location.search);
      const firstKey = Object.keys(queries)[0];
      return <Leads key={props.location.search ? queries[firstKey]  : null} {...props} />
    }} />
    <Route path="/profile/setup" exact component={ProfileSetup} />
    <Route path="/contact/propertydetails/:contactId" exact component={ContactProperties} />
    <Route path="/login" render={() => <Redirect to="/" />} />
    <Route path="/contacts/newcontact" exact component={NewContact} />
    <Route path="/contacts/import" exact component={BulkUpload} />
    <Route path="/contacts/:type" exact render={(props) => (
      <Leads key={props.location.query['Type']} {...props} />)
    } />
    <Route path="/admin/users" exact component={Users} />
    <Route path="/admin/user" exact component={CreateUser} />
    <Route path="/admin/user/:userId" exact component={User} />
    <Route path="/register" exact render={() => <Redirect to="/" />} />
    <Route path="/workflows" exact render={(props) => (
      <Workflows key="Contact" type="Contact" {...props} />
    )} />
    <Route path="/workflows/workflow" exact component={EditWorkflow} />
    <Route path="/workflows/workflow/:workflowId" exact render={(props => (
      <EditWorkflow type="Contact" {...props} />
    ))} />
    <Route path="/workflows/create" exact component={CreateWorkflow} />
    <Route path="/settings/sources" exact component={Sources} />
    <Route path="/settings/urgencytypes" exact component={UrgencyTypes} />
    <Route path="/settings/email" exact component={EmailSettings} />
    <Route path="/fileupload" exact component={FileUploadPage} />
    <Route path="/settings/email/templates" exact component={EmailTemplates} />
    <Route path="/transactions/dashboard" exact component={TransactionDashboard} />
    <Route path="/transactions/commissions" exact component={Commissions} />
    <Route path="/transactions/workflows" exact render={(props) => (
      <Workflows key="Transaction" type="Transaction" {...props} />
    )} />
    <Route path="/transactions/workflow/:workflowId" exact render={(props => (
      <EditWorkflow type="Transaction" {...props} />
    ))} />
    <Route path="/transactions/transactions" exact component={Transactions} />
    <Route path="/transactions/transaction/:transactionId" exact component={Transaction} />
    <Route path="/access-denied" exact component={Forbidden} />
    <Route path="/settings/text/templates" exact component={TextTemplates} />
    <Route path="/settings/pipeline/stages" exact component={Stages} />
    <Route path="/settings/notification" exact component={NotificationSettings} />
    <Route path="/settings/text-codes" exact component={TextCodes} />
    <Route path="/tasks" exact component={Tasks} />
    <Route path="/mls-search" exact component={SearchResults} />
    <Route path="/mls-search/:searchid" exact component={SearchResults} />
    <Route path="/mls-search/:searchid/:type" exact component={SearchResults} />
    <Route path="/searches" exact component={SavedSearches} />
    <Route path="/map" exact component={MapPage} />
    <Route path="/idx/settings" exact component={IdxSettings} />
    <Route path="/video" exact component={Video} />
    <Route path="/video/videos" exact component={Videos} />
    <Route path="/reports" component={Reporting} />
    <Route path="/calendar" exact component={Calendar} />
    <Route path="/pipeline" exact component={Pipeline} />
    <Route path="/sales/heat-sheet" exact component={SalesDashboard} />
  </Switch>
)


export default routes;