import React from 'react';
import { getData, postData, deleteData } from '../../helpers/AxiosService';
import classnames from 'classnames';

class WorkflowModalView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            enrolledWorkflows: [],
            sending: false,
            removing: false,
            workflowOptions: [],
            selectedWorkflowId: null,
            adding: false,
            hasError: false
        }
    }

    componentDidMount(){
        if (this.props.contactId){
            this.fetchContactWorkflows(this.props.contactId);
        }

        this.fetchAccessibleWorkflows();
    }

    componentDidUpdate(prevProps){
        if (prevProps.contactId !== this.props.contactId){
            this.fetchContactWorkflows(this.props.contactId);
        }
    }

    fetchContactWorkflows(contactId){
        getData(`api/workflow/contact/${contactId}`).then(response => {
            this.setState({ enrolledWorkflows: response.data });
        });
    }

    fetchAccessibleWorkflows(){
        getData('api/workflow/accessibleworkflows/Contact?onlyActive=true').then(response => {
            this.setState({ workflowOptions: response.data });
        });
    }

    handleChange(e){
        this.setState({ selectedWorkflowId: e.target.value });
    }

    handleAddWorkflow(){
        if (!this.state.selectedWorkflowId){
            this.setState({ hasError: true });
            return;
        }

        this.setState({ adding: true });

        let data = {
            WorkflowId: this.state.selectedWorkflowId,
            ContactId: this.props.contactId
        }

        postData('api/workflow/contact', data).then(response => {
            this.fetchContactWorkflows(this.props.contactId);
            this.setState({ adding: false, hasError: false, selectedWorkflowId: 0 });
        }).fail(error => this.setState({ adding: false, hasError: false, selectedWorkflowId: null }));
    }

    togglePauseWorkflow(contactWorkflowId){
        this.setState({ [`${contactWorkflowId}-sending`]: true });
        postData(`api/workflow/contact/togglePause/${contactWorkflowId}`).then(response => {
            if (response.data){
                this.fetchContactWorkflows(this.props.contactId);
                this.setState({ [`${contactWorkflowId}-sending`]: false });
            };
        });
    }

    removeFromWorkflow(contactWorkflowId){
        this.setState({ [`${contactWorkflowId}-removing`]: true });
        deleteData(`api/workflow/contact/${contactWorkflowId}`).then(response => {
            if (response.data){
                this.fetchContactWorkflows(this.props.contactId);
                this.setState({ [`${contactWorkflowId}-removing`]: false });
            };
        });
    }

    renderRows(){
        return this.state.enrolledWorkflows.map((w, i) => (
            <tr key={w.WorkflowId}>
                <td>{w.Name}</td>
                <td>{w.IsPaused ? "Paused" : "Active"}</td>
                <td className="with-btn text-right">
                    { 
                        w.IsComplete ?
                        <button className="btn btn-white m-r-5" disabled>
                            <span><i className="far fa-check-circle text-green"></i> Completed</span>
                        </button>
                        :
                        w.IsPaused ?
                        <button className="btn btn-white m-r-5" onClick={() => this.togglePauseWorkflow(w.ContactWorkflowId)}>
                         {this.state[`${w.ContactWorkflowId}-sending`] ? <div className="button-spinner"></div> : <span><i className="far fa-play-circle text-lime"></i> Resume</span>}
                        </button>
                        :
                        <button className="btn btn-white m-r-5" onClick={() => this.togglePauseWorkflow(w.ContactWorkflowId)}>
                         {this.state[`${w.ContactWorkflowId}-sending`] ? <div className="button-spinner"></div> : <span><i className="far fa-pause-circle text-warning"></i> Pause</span>}
                        </button>
                    }
                    <button className="btn btn-outline-danger" onClick={() => this.removeFromWorkflow(w.ContactWorkflowId)}>
                        {this.state[`${w.ContactWorkflowId}-removing`] ? <div className="button-spinner"></div> : <span><i className="far fa-times-circle"></i> Remove</span>}
                    </button>
                </td>
            </tr>
        ))
    }

    render(){
        let { workflowOptions, adding, hasError, selectedWorkflowId } = this.state;
        workflowOptions = workflowOptions.length === 0 ? null : workflowOptions.map(option => <option key={option.WorkflowId} value={option.WorkflowId}>{option.Name}</option>);
        return(
            <div>
                <h5>Assign Workflows</h5>
                <div className="row">
                    <div className={classnames({ 'has-error': hasError && !selectedWorkflowId }, "col-md-8 m-b-3")}>
                        <select className="form-control" value={selectedWorkflowId} onChange={e => this.handleChange(e)}>
                            <option value={0}></option>
                            {workflowOptions}
                        </select>
                    </div>
                    <div className="col-md-4 m-b-3">
                        <button type="button" className="btn btn-lime pull-right" onClick={() => this.handleAddWorkflow()}>
                        {adding ? <div className="button-spinner"></div> : "Add Workflow"}
                        </button>
                    </div>
                </div>
                <h5 className="m-t-40 m-b-20">Currently Assigned Workflows</h5>
                <div className="row">
                    <table className="table">
                        <tbody>
                            {this.state.enrolledWorkflows == 0 && <tr><td>Not currently enrolled in any workflows</td></tr>}
                            {this.state.enrolledWorkflows.length > 0 && this.renderRows()}
                        </tbody>
                    </table>
                </div>
                {/* <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-white m-t-40 pull-right"><span className="text-danger"><i className="far fa-times-circle"></i></span> Remove All Workflows</button>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default WorkflowModalView;
