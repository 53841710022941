import React, { Component } from 'react';
import './FileUpload.css';
import PropTypes from 'prop-types';

function FileUpload(props){
    return(
        <div className="FileUpload">
            {/* <div className="icon-container">
            </div> */}
            <div className="upload-wrapper">
                <i className="fas fa-upload" id="upload-icon"></i>
                <input className="hidden-upload" type="file" onChange={props.handleFile}/>
                <div className="upload-button">
                { !props.FileName && <button className="btn btn-primary">Choose File</button> }
                    {/* <span></span><br />
                    <div className="f-s-16">
                        Choose File
                    </div> */}
                </div>
            </div>
            <br/>
            {props.FileName && 
                <div className="confirm-container">
                    {props.FileName} 
                    { !props.customSubmitHandling && <button className="btn btn-primary" onClick={props.handleSubmit}>
                        { props.uploadingFile && <i className="fas fa-spinner fa-pulse m-r-5"></i>}
                        Submit
                    </button> }
                    <button className="btn btn-outline-primary" onClick={props.clearFile}>Remove File</button>
                </div>
            }
        </div>
    )
}

FileUpload.propTypes = {
    handleFile: PropTypes.func.isRequired,
    clearFile: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    uploadingFile: PropTypes.bool,
    // tells the fileupload to hide the submit button
    customSubmitHandling: PropTypes.bool,
}

export default FileUpload;