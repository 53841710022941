import React, { useState, useEffect, useRef, useContext } from 'react';
import { Panel, PanelBody } from '../components/panel/panel.jsx';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { deleteData, getData, postData, putData } from '../helpers/AxiosService.js';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PageSettings } from '../config/page-settings.js';

export default function Stages() {
    const [stages, setStages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [sendingUpdate, setSendingUpdate] = useState(false);
    const [selectedStage, setSelectedStage] = useState({});
    const prevShowEditRef = useRef();
    const cxt = useContext(PageSettings);

    async function fetchStages() {
        setLoading(true);
        try {
            const resp = await getData('api/stages');
            setStages(resp.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchStages();
    }, []);

    useEffect(() => {
        if (prevShowEditRef.current) {
            setSelectedStage({});
        }

        prevShowEditRef.current = showEdit;
    }, [showEdit])

    function handleEditClick(stg) {
        setSelectedStage(stg);
        setShowEdit(true);
    }

    function handleChange(e) {
        const { name, value } = e.target;
        const updatedStage = {...selectedStage, [name]: value };
        setSelectedStage(updatedStage);
    }

    async function handleSaveStage() {
        try {
            setSendingUpdate(true);
            if (selectedStage.Id) {
                await putData('api/stages', selectedStage);
            } else {
                selectedStage.Position = stages.length;
                await postData('api/stages', selectedStage);
            }

            await fetchStages();

            setSendingUpdate(false);
            setShowEdit(false);
        } catch (e) {
            console.log(e);
            setSendingUpdate(false);
            setShowEdit(false);
        }
    }

    function handleDeleteClick(stg) {
        cxt.messageConfirmation('Delete Stage', `Are you sure you want to delete this stage: ${stg.Name}?`, 
                    deleteStage.bind(null, stg.Id), "Stage deleted successfully", "Delete");
    }

    async function deleteStage(stageId) {
        cxt.toggleSweetAlert('confirm');
		try {
            await deleteData(`api/stages/${stageId}`, this);
            cxt.toggleSweetAlert('success');
            await fetchStages();
        } catch (e) {
            console.log(e);
            cxt.toggleSweetAlert('success');
        }
    }

    async function handleDragEnd(result) {
        const { destination, source } = result;

        if (
            destination.droppableId === source.droppableId
            && destination.index === source.index
        ) {
            return;
        }

        const stagesArr = Object.assign([], stages);
        const selectedStage = stages[source.index];
        stagesArr.splice(source.index, 1);
        stagesArr.splice(destination.index, 0, selectedStage);
        setStages(stagesArr);

        const updateTasks = [];
        stagesArr.forEach((stg, i) => {
            stg.Position = i;
            try {
                updateTasks.push(putData('api/stages', stg));
            } catch (e) {
                console.log(e);
            }
        });

        await Promise.all(updateTasks);
    }

    return (
        <div>
            <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
            <h1 className="page-header">Pipeline Stages <small className="f-w-100">Customize the stages in your pipeline</small></h1>
            <Panel>
                <PanelBody className="f-s-15">
                    <div className="row m-b-25">
                        <div className="col-md-12">
                            <span>*Drag to change the order of your stages</span>
                            <button 
                                className="btn btn-lime p-r-20 p-l-20 pull-right"
                                onClick={() => setShowEdit(true)}
                            >Add Stage</button>
                        </div>
                    </div>
                    <table className="table table-bordered m-b-0">
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}>Name</th>
                                <th style={{ width: '75%' }}>Description</th>
                            </tr>
                        </thead>
                    </table>
                    <div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="table-body">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {stages.length === 0 ? null : stages.map((stage, idx) => {
                                            return (
                                                <Draggable
                                                    draggableId={stage.Id}
                                                    key={stage.Id}
                                                    index={idx}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="p-15 border"
                                                            ref={provided.innerRef}
                                                            provided={provided}
                                                            key={stage.Id}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <i class="fas fa-grip-vertical m-r-15"></i>
                                                                    {stage.Name}
                                                                </div>
                                                                <div className="col-8">{stage.Description}</div>
                                                                <div className="col-1 f-s-16">
                                                                    <i 
                                                                        className="far fa-trash-alt pull-right cursor-pointer"
                                                                        onClick={() => handleDeleteClick(stage)}
                                                                    ></i>
                                                                    <i 
                                                                        className="far fa-edit pull-right m-r-10 cursor-pointer"
                                                                        onClick={() => handleEditClick(stage)}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </PanelBody>
            </Panel>
            <Modal isOpen={showEdit} >
                <ModalHeader toggle={() => setShowEdit(false)}>Create Stage</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Stage Name</label>
                            <input 
                                className="form-control w-100" 
                                name="Name" 
                                value={selectedStage.Name} 
                                onChange={(e) => handleChange(e)} 
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Description</label>
                            <textarea 
                                className="form-control w-100" 
                                rows="8"
                                name="Description" 
                                value={selectedStage.Description} 
                                onChange={(e) => handleChange(e)} 
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary-outline" onClick={() => setShowEdit(false)}>Cancel</button>
                    <button className="btn btn-primary" onClick={() => handleSaveStage()}>
                        { sendingUpdate ?
                            <div className="button-spinner"></div>
                            :
                            "Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}