import React from 'react';
import { getData } from '../../helpers/AxiosService';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import classnames from 'classnames';
import AgentOptions from '../shared/AgentOptions';
import { PageSettings } from '../../config/page-settings';
import { formatTransactionsBySource } from '../../modules/Commissions';
import DataTable from 'react-data-table-component';
import { CardTitle } from 'reactstrap';

const numberSort = (col, rowA, rowB) => {
    const a = Number(rowA[col] || 0);
    const b = Number(rowB[col] || 0);
    if (a > b) return 1;
    if (b > a) return -1;

    return 0;
}

const columns = [
    {
        name: 'Source',
        selector: row => row.Name,  
        sortable: true,
    },
    {
        name: '# of Transactions',
        selector: row => row.count || 0, 
        sortable: true,
        sortFunction: numberSort.bind(null, 'count'), 
    },
    {
        name: 'Gross',
        selector: row => row.gross || 0, 
        sortable: true,
        sortFunction: numberSort.bind(null, 'gross'), 
    },
    {
        name: 'Broker Split',
        selector: row => row.brokerSplit || 0, 
        sortable: true,
        sortFunction: numberSort.bind(null, 'brokerSplit'),
    },
    {
        name: 'Concessions',
        selector: row => row.concessions || 0,  
    },
    {
        name: 'Net',
        selector: row => row.net || 0,  
    },
    {
        name: 'Avg. Gross Commission',
        selector: row => row.avgGross || 0, 
        sortable: true,
        sortFunction: numberSort.bind(null, 'avgGross') 
    },
    {
        name: '# of Contacts',
        selector: row => row.CurrentContactsCount,  
    },
    {
        name: 'Conversion %',
        selector: row => row.conversionRatio || 0,  
        sortable: true,
        sortFunction: numberSort.bind(null, 'conversionRatio'),
    },
]
export default class ClosedTransactionSource extends React.Component{
    static contextType = PageSettings;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            startDate: moment().startOf('year'),
            endDate: moment().endOf('month'),
            selectedUserId: 0,
            loading: false,
        }
    }

    componentDidMount() {
        try {
            this.setState({ selectedUserId: this.context.currentUserId }, this.getReportData);

        } catch (e) {
            console.log(e);
        }
    }

    getReportData() {
        this.setState({ loading: true });

        const { startDate, endDate, selectedUserId } = this.state;
        let url = `api/report/closed-transactions?groupby=source&startDate=${startDate.format()}&endDate=${endDate.format()}&userId=${selectedUserId}`;
        
        const sourceReportPromise = getData(url)
        
        const sourcesPromise = getData(`api/settings/contact/sources?startDate=${startDate.format()}&endDate=${endDate.format()}`)
        
        Promise.all([sourceReportPromise, sourcesPromise]).then((values) => {
            const reportData = values[0].data;
            const sources = values[1].data;
            const transactionData = formatTransactionsBySource(reportData);
            const data = sources.map(source => {
                let matchingSource = transactionData.find((s, i) => s.sourceId === source.SourceId);
                if (!matchingSource) return source;

                source = {...source, ...matchingSource};
                source.conversionRatio = (source.count / source.CurrentContactsCount * 100).toFixed(2);
                source.avgGross = source.gross / source.count;
                return source;
            });
            
            this.setState({ loading: false, data });
        });
    }

    handleSelectAssigned(e) {
        const { value } = e.target;
        this.setState({ selectedUserId: value });
    }

    handleDateChange(item, date) {
        this.setState({ [item]: date });
    }

    render() {
        const { selectedUserId, startDate, endDate, loading, data } = this.state;
        return (
            <div>
                {/* <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div> */}
                <CardTitle className="m-t-20" tag="h4">
                    Source Performance
                    { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i>}
                </CardTitle>
                <div className="form-inline m-t-20">
                    <label className="m-r-15">Start Date: </label>
                    <DateTime 
                        closeOnSelect={true} 
                        value={startDate.format('MM/DD/YYYY')} 
                        onChange={(date) => this.handleDateChange('startDate', date)} 
                        timeFormat={false} 
                    />
                    <label className="m-r-15 m-l-15">End Date: </label>
                    <DateTime 
                        closeOnSelect={true} 
                        value={endDate.format('MM/DD/YYYY')} 
                        onChange={(date) => this.handleDateChange('endDate', date)} 
                        timeFormat={false} 
                    />
                    <div className="form-inline">
                        <label className="m-l-15 m-r-15">Filter by: </label>
                        <AgentOptions
                            all={true}
                            agentId={selectedUserId}
                            handleSelect={this.handleSelectAssigned.bind(this)}
                        /> 
                    </div>
                    <button 
                        className="btn btn-primary m-l-15 p-l-40 p-r-40"
                        onClick={() => this.getReportData()}
                    >Search</button>
                </div>
                <div className="m-t-25">
                    <DataTable
                        columns={columns}
                        data={data}
                    />
                </div>
                {/* <table className="table bg-white table-striped table-bordered m-t-40">
                    <thead>
                        <tr>
                            <th>Source</th>
                            <th># of Transactions</th>
                            <th>Gross</th>
                            <th>Broker Split</th>
                            <th>Concessions</th>
                            <th>Net</th>
                            <th>Avg. Gross Commission</th>
                            <th># of Contacts</th>
                            <th>Conversion %</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.length > 0 && 
                            data.map((source, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{source.Name}</td>
                                        <td>{source.count || 0}</td>
                                        <td>${source.gross || 0}</td>
                                        <td>${source.brokerSplit || 0}</td>
                                        <td>${source.concessions || 0}</td>
                                        <td>${source.net || 0}</td>
                                        <td>${source.gross / source.count || 0}</td>
                                        <td>{source.CurrentContactsCount}</td>
                                        <td>{source.conversionRatio || 0}</td>
                                    </tr>
                                )
                            })
                        }
                        { data.length === 0 &&
                            <tr>
                                <td className="text-center" colSpan="11">No transactions matching search criteria</td>
                            </tr>
                        }
                    </tbody>
                </table> */}
            </div>
        )
    }
}