import React from 'react';
import PropertyTable from '../../contact-property/PropertyTable';
import { Link } from 'react-router-dom';

export default function ContactProperties({ primary }){
    return (
        <div>
            <div className="row m-b-15">
                <div className="col-12">
                    <Link to={`/contact/propertydetails/${primary.ContactId}`} className="btn btn-lime btn-sm pull-right">Add/Edit Property</Link>
                </div>
            </div>
            <PropertyTable contactId={primary.ContactId}/>
        </div>
    )
}