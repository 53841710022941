import React from 'react';
import { getData } from '../helpers/AxiosService';
import classnames from 'classnames';


export default class ContactSelect  extends React.Component {

    static defaultProps = {
        onSelect: () => {},
        settingContact: false,
        // listType options can be contacts or transactionPeople
        listType: 'contacts',
        clearInput: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            existingOptions: [],
            selectedContactId: 0,
        }
    }

    fetchExistingContacts() {
        const { searchTerm } = this.state;

        getData(`api/transaction/people-search?include=${this.props.listType}&searchTerm=${searchTerm}`).then(response => {
            this.setState({ existingOptions: response.data });
        });
    }

    handleSearch(e) {
        const { value } = e.target;
        this.setState({ searchTerm: value, selectedContactId: 0 }, () => {
            if (this.state.searchTerm.length > 1) {
                this.fetchExistingContacts();
            }
        });
    }

    handleClick(contact) {
        let searchTerm = this.props.clearInput ? "" : contact.Value;
        
        this.setState({ selectedContactId: contact.Key, searchTerm });
        this.props.onSelect(contact);
    }

    render() {
        const { existingOptions, selectedContactId, searchTerm } = this.state;
        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <input 
                    value={searchTerm}
                    className="form-control" 
                    onChange={e => this.handleSearch(e)}
                    style={{ marginRight: 'auto', marginLeft: 'auto' }} 
                />
                <div className={classnames({ "show-list": searchTerm.length > 1 && !selectedContactId}, "dropdown-list text-left")} style={{ zIndex: '10000' }}>
                    <ul>
                        { existingOptions && existingOptions.map(o => (
                            <li 
                                key={o.Key} 
                                value={o.Key}
                                onClick={() => this.handleClick(o)}
                                >
                                { this.props.settingContact && selectedContactId == o.Key ?
                                    <span><i className="fas fa-spinner fa-spin"></i></span>
                                    :
                                    o.Value
                                }
                                </li>
                        )) }
                    </ul>
                </div>
            </div>
        )
    }
}