import React, { useEffect, useState } from 'react';
import { getData } from '../../helpers/AxiosService';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import RangePicker from './RangePicker';
import { DateOp } from '../../helpers/OperationTypes';
import { getDateRange } from '../../helpers/FilterHelper';
import { formatLeadsBySource, countLeadsWhenGrouped, formatLeadsPieChart } from '../../modules/Contacts';
import NVD3Chart from 'react-nvd3';

const donutChartOptions = {
    type: 'pieChart',
    showLegend: false,
    growOnHover: true,
    margin: { 'left': 10,'right':  10,'top': 10,'bottom': 10 },
};

function LeadsBySource(props) {
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState('Today');
    const [count, setCount] = useState(0);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const dateRange = getDateRange(DateOp.today);
        fetchData(dateRange);
    }, []);

    //TO DO: default to user's id then accept props to set to all users
    // pass a class to the RangePicker
    // some kind of loading would be great
    // should we try and do a change percentage?

    async function fetchData(dateRange) {
        try {
            const resp = await getData(`api/report/created-contacts?groupby=source&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
            setCount(countLeadsWhenGrouped(resp.data));
            const formattedLeads = formatLeadsBySource(resp.data)
            setData(formattedLeads);
            setChartData(formatLeadsPieChart(formattedLeads));
        } catch (err) {
            console.log(err);
        }
    }

    function handleRangeChange(e) {
        const { name, value } = e.target;
        setDateRange(name);
        const dateRange = getDateRange(value);
        fetchData(dateRange);
    }

    return (
        <div className="card border-0 bg-dark text-white mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-3 text-grey">
                            <b>Contacts Created</b>
                            <span className="ml-2">
                                <i className="fa fa-info-circle" id="popover1"></i>
                                <UncontrolledPopover trigger="hover" placement="top" target="popover1">
                                    <PopoverHeader>Contacts Created</PopoverHeader>
                                    <PopoverBody>New contacts that have been created during the selected timeframe</PopoverBody>
                                </UncontrolledPopover>
                            </span>
                            <span className="pull-right">
                                <RangePicker handleChange={handleRangeChange} value={dateRange} direction={'past'} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <h2 className="m-b-25 text-white">{count}</h2>
                        <ul className="chart-legend">
                            { data.map.length > 0 && data.map(g => (
                                <li>
                                    <i className="fa fa-circle fa-fw f-s-9 m-r-5" style={{ color: `${g.color}`}}></i>
                                    {g.count}
                                    <span>{g.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-6">
                        <div className="flex-grow-1 d-flex align-items-center nvd3-inverse-mode">
                            { 
                                chartData.length > 0 &&
                                <NVD3Chart type="pieChart" datum={chartData} height={180} options={donutChartOptions} x="label" y="value" /> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default LeadsBySource;