import React from "react";
import { deleteData, getData, putData } from "../helpers/AxiosService";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink } from 'reactstrap';
import TaskModal from '../components/task-list/TaskModal';
import moment from 'moment';
import { PageSettings } from '../config/page-settings';
import { Link } from 'react-router-dom';
import { displayFormalDateTimeUtc } from '../helpers/Functions';
import TaskRow from "./task-list/TaskRow";


class TaskView extends React.Component{
    static contextType = PageSettings;
    static defaultProps = {
        onRef: () => {}
    }

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            tasks: [],
            selectedUserId: 0,
            selectedUserName: 'All Users',
            due: 'all',
            userOptions: [],
            includeCompleted: false,
            taskModal: false,
            selectedTask: {},
            checkedTasks: new Map(),
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.fetchTasks = this.fetchTasks.bind(this);
        this.handleTaskClick = this.handleTaskClick.bind(this);
        this.markTaskComplete = this.markTaskComplete.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    componentDidMount(){
        this.props.onRef(this);
        if (this.props.viewType === 'report') {
            this.setState({ due: 'todayAndPastDue' });
        }

        const currentUser = this.context.userInfo.FirstName + ' ' + this.context.userInfo.LastName;
        this.setState({ selectedUserName: currentUser, selectedUserId: this.context.currentUserId }, this.fetchTasks);

        this.fetchUserOptions();
    }

    componentDidUpdate(prevProps){
        if (prevProps.taskType !== this.props.taskType || prevProps.typeId !== this.props.typeId){
            this.fetchTasks();
        }

        if (this.props.changeEmitted) {
            this.fetchTasks();
        }
    }

    componentWillUnmount() {
        this.props.onRef(null);
    } 

    fetchTasks() {
        this.setState({ loading: true, tasks: [] });
        const { includeCompleted, selectedUserId } = this.state;
        const { taskType, typeId } = this.props;

        let assignedTo = selectedUserId;
        let typeString = '';
        const typeValueString = typeId ? `&typeValue=${typeId}` : '';
        if (taskType === 'transaction') typeString = `&type=TransactionId`;
        if (taskType === 'contactEntity') typeString = '&type=ContactEntityId';
        if (this.hideSelects()) assignedTo = 0; 

        getData(`api/task/tasks?includeCompleted=${includeCompleted}&assignedId=${assignedTo}${typeString}${typeValueString}`).then(resp => {
            const tasks = this.formatDueCategory(resp.data);
            this.setState({ tasks, loading: false, checkedTasks: new Map() });
        }).fail(() => this.setState({ loading: false }));
    }

    hideSelects() {
        const { taskType } = this.props;
        return taskType === 'transaction' || taskType === 'contactEntity';
    }

    markTaskComplete(e, taskId){
        e.stopPropagation();
        putData(`api/task/${taskId}/mark-complete`).then(() => {
            this.fetchTasks();
        });
    }

    deleteTask(e, taskId) {
        e.stopPropagation();
        deleteData(`api/task/${taskId}/delete`).then(() => this.fetchTasks());
    }

    fetchUserOptions(){
        getData('api/user/agentoptions').then(response => this.setState({ userOptions: response.data }));
    }

    setDue(time){
        this.setState({ due: time });
    }

    handleCreateTaskClick(){
        this.setState({ selectedTask: {} }, this.toggleModal)
    }

    handleTaskClick(task){
        this.setState({ selectedTask: task }, this.toggleModal);
    }

    toggleModal(){
        const { taskModal, selectedTask } = this.state;
        this.setState({ taskModal: !this.state.taskModal, selectedTask: taskModal ? {} : selectedTask });
    }

    toggleIncludeCompleted(){
        this.setState({ includeCompleted: !this.state.includeCompleted }, this.fetchTasks);
    }

    handleSelectAssigned(user){
        this.setState({ selectedUserId: user.UserId, selectedUserName: user.Name }, 
            this.fetchTasks);
    }

    calculateShowTask(task){
        const { due } = this.state;
        let show = false;
        switch(task.Due){
            case "today":
                show = (due === "week" || due === "today" || due === "all" || due === "todayAndPastDue");
                break;
            case "week":
                show = (due === "week" || due === "all");
                break;
            case "pastdue":
                show = due === "pastdue" || due === "all" || due === "todayAndPastDue";
                break;
            default:
                show = due === "all";
                break;
        }
        
        if (task.IsCompleted && due === "todayAndPastDue") show = true;

        return show;
    }

    formatDueCategory(tasks) {
        tasks.forEach(task => {
            let dueDateDisplay = 'Unscheduled';
            let dueDate = moment.utc(task.DueDate);

            if (!task.DueDate) {
                task.DueDate = null;
                return;
            }
            
            const today = moment.utc().local();
            const dueDateLocal = moment(dueDate).local();
            
            if (dueDateLocal.isSame(today, 'day')) {
                task.Due = 'today';
            } else if (dueDateLocal.isSameOrAfter(today, 'day') && dueDateLocal.isSameOrBefore(moment.utc().local().add(7, 'day'), 'day')) {
                task.Due = 'week';
            } else if (dueDateLocal.isBefore(today, 'day')) {
                task.Due = 'pastdue';
            }
            
            if (task.DueDate) {
                dueDateDisplay = displayFormalDateTimeUtc(dueDate, true);
            }
    
            task.DueDateDisplay = dueDateDisplay;
        });

        return tasks;
    }
    
    handleCheckChange(e) {
        const item = Number(e.target.name);
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedTasks: prevState.checkedTasks.set(item, isChecked) }));
    }

    toggleSelectAll(e){
        let select = e.target.checked;
        if (select){
            let checkedTasks = new Map(this.state.checkedTasks);
            // let checkedTasks = new Map();
            this.state.tasks.forEach(t => checkedTasks.set(t.TaskId, true));
            this.setState({ checkedTasks }, this.getCheckedTaskIds);
        } else {
            this.setState({ checkedTasks: new Map() });
        }
    }

    getCheckedTaskIds(){
        let ids = [];
        for (const [id, val] of this.state.checkedTasks.entries()){
            if (val){
                ids.push(Number(id));
            }
        }

        return ids;
    }

    renderTaskList(){
        return this.state.tasks.map((task, i) => {
            let show = this.calculateShowTask(task);
            let linkPath = task.KeyType === 'ContactEntityId' ? `/contact/${task.RouteId}` : `/transactions/transaction/${task.RouteId}`;
            if (this.props.viewType === 'report') {
                return (
                    <TaskRow 
                            key={task.TaskId}
                            task={task} 
                            show={show} 
                            linkPath={linkPath} 
                            handleTaskClick={this.handleTaskClick} 
                            markTaskComplete={this.markTaskComplete} 
                            deleteTask={this.deleteTask}
                        />
                )
            }

            return (
                <div className="d-flex align-items-center m-b-10">
                    <div>
                        <div className="checkbox checkbox-css">
                            <input type="checkbox" name={task.TaskId} id={`check_${task.TaskId}`} checked={this.state.checkedTasks.get(task.TaskId)} onChange={(e) => this.handleCheckChange(e)}/>
                            <label htmlFor={`check_${task.TaskId}`}></label>
                        </div>
                    </div>
                    <div style={{ flexGrow: '1' }}>
                        <TaskRow 
                            key={task.TaskId}
                            task={task} 
                            show={show} 
                            linkPath={linkPath} 
                            handleTaskClick={this.handleTaskClick} 
                            markTaskComplete={this.markTaskComplete} 
                            deleteTask={this.deleteTask}
                        />
                    </div>
                </div>
            )
        });
    }

    handleMassDeleteClick() {
        const checkedTasks = this.getCheckedTaskIds();
        this.context.messageConfirmation('Delete Tasks', `Are you sure you want to delete ${checkedTasks.length} tasks?`, 
                    this.handleDeleteTasks.bind(this), "Tasks deleted successfully", "Delete");
    }

    handleDeleteTasks() {
        this.context.toggleSweetAlert('confirm');
        const tasks = this.getCheckedTaskIds();
        putData('api/task/mass-delete', tasks, this).then(response => {
            this.context.toggleSweetAlert('success');
            this.fetchTasks();
        });
    }

    renderAssignedBtn(){
        return this.state.userOptions.length > 0 && this.state.userOptions.map((user, i) => (
            <DropdownItem key={i} onClick={() => this.handleSelectAssigned(user)}>{user.Name}</DropdownItem>
        ));
    }

    render(){
        const { due, selectedUserName, includeCompleted, loading } = this.state;
        const { viewType } = this.props;
        const actualChecktedTasks = this.getCheckedTaskIds();
        const hasChecked = actualChecktedTasks.length > 0;
        return(
            <div>
                { viewType !== 'report' && <div className="row">
                    <div className="col-12 p-t-15 p-b-15">
                        <Nav className="pull-right primary" pills>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: due === 'all' })}
                                    onClick={() => { this.setDue('all'); }}
                                >
                                    <span><i className="far fa-list-alt fa-fw m-r-5"></i> All Tasks</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: due === 'today' })}
                                    onClick={() => { this.setDue('today'); }}
                                >
                                    <span><i className="far fa-calendar-check fa-fw"></i> Due Today</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: due === 'week' })}
                                    onClick={() => { this.setDue('week'); }}
                                >
                                    <span><i className="far fa-calendar-alt fa-fw"></i> Due This Week</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: due === 'pastdue' })}
                                    onClick={() => { this.setDue('pastdue'); }}
                                >
                                    <span><i className="far fa-calendar-alt fa-fw"></i> Past Due</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div> }
                <div className="m-b-25 row">
                    { !this.hideSelects() && <div className={viewType === 'report' ? 'form-inline col-12' : 'form-inline col-md-6'}>
                        <label>Filter by: </label>
                        <UncontrolledButtonDropdown className="m-l-15">
                            <DropdownToggle className="btn-sm btn-primary-outline" caret>{selectedUserName}</DropdownToggle>
                            <DropdownMenu modifiers={{ computeStyle: { gpuAcceleration: false }}}>
                                <DropdownItem onClick={() => this.handleSelectAssigned({ UserId: 0, Name: 'All Users' })}>All Users</DropdownItem>
                                { this.renderAssignedBtn() }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <button className="btn btn-sm btn-primary-outline m-l-15" onClick={() => this.toggleIncludeCompleted()}>
                            { includeCompleted ? 
                                "Hide Completed"
                                : 
                                "Show Completed"
                            }
                        </button>
                    </div> }
                    { viewType !== 'report' && <div className={this.hideSelects() ? "col-12" : "col-md-6"}>
                        <button className="btn btn-lime p-r-40 p-l-40 pull-right" onClick={() => this.handleCreateTaskClick()}>Create Task</button>
                    </div> }
                </div>
                <div style={{ position: 'relative' }}>
                    <div className={classnames({ 'show': loading }, 'fade m-t-40')} id="modal-loader"><div className="spinner"></div></div>
                    { this.props.viewType !== 'report' &&<div className="row m-b-10">
                        <div className="col-12">
                            <div className="checkbox checkbox-css d-inline">
                                <input type="checkbox" id="check_all" onChange={(e) => this.toggleSelectAll(e)}/>
                                <label htmlFor="check_all">Select All</label>
                            </div>
                            { hasChecked && <div className="d-inline m-l-10">({actualChecktedTasks.length} selected)</div> }
                            <div className={classnames({ 'invisible': !hasChecked }, "pull-right")}>
                                <button className="btn btn-default" onClick={() => this.handleMassDeleteClick()}><i className="fas fa-trash m-r-5"></i>Delete</button>
                            </div>
                        </div>
                    </div> }
                    { this.state.tasks.length > 0 && this.renderTaskList()}
                </div>
                <TaskModal 
                    keyType={this.props.taskType === "contactEntity" ? "ContactEntityId" : "TransactionId"} 
                    keyValue={this.props.typeId}
                    task={this.state.selectedTask} 
                    isOpen={this.state.taskModal} 
                    toggleModal={this.toggleModal} 
                    updateTaskList={this.fetchTasks} 
                />
            </div>
        )
    }
}

TaskView.propTypes = {
    taskType: PropTypes.string.isRequired
}

export default TaskView;
