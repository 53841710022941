import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';
import { getData, postData } from '../../helpers/AxiosService';
import { phoneClean } from '../../helpers/Functions';
import { PageSettings } from '../../config/page-settings';


class VideoText extends React.Component{
    static contextType = PageSettings;
    static defaultProps = {
        submitVideo: () => {},
        onRef: () => {},
    }

    constructor(props) {
        super(props);
        this.state = {
            textMessage: '',
            sendingText: false,
            loadingContacts: false,
            showPhoneError: false,
            suggestions: [],
            tags: [],
        }
    }

    componentDidMount() {
        this.props.onRef(this);
        this.getContacts();
    }
    
    componentWillUnmount() {
        this.props.onRef(null);
    }

    getContacts() {
        this.setState({ loadingContacts: true });
        getData('api/contact/user?cellOnly=true').then(resp => {
            const contacts = resp.data.map(c => { 
                return { id: c.ContactId, name: `${c.FirstName} ${c.LastName}`, phone: c.CellPhone }
            });

            this.setState({ suggestions: contacts, loadingContacts: false });
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags, showPhoneError: false })
    }

    handleAddition(tag){
        if (!tag.id) {
            tag.phone = tag.name;
        }
        const tags = [].concat(this.state.tags, tag);
        this.setState({ tags, showPhoneError: false });
    }

    handleTagValidate(tag) {
        if (tag.id) {
            return true;
        } else {
            const phone = phoneClean(tag.name);
            if (phone.length !== 10) {
                this.setState({ showPhoneError: true });
                return false;
            }
            
            return true;
        }
    }
    
    suggestionsFilter(suggestion, query) {
        return suggestion.name.toLowerCase().includes(query.toLowerCase()) || suggestion.phone.includes(query);
    }

    suggestionComponent({ item, query }) {
        return (
            <span className="cursor-pointer" id={item.id}>
              {item.name} <br /> {item.phone}
            </span>
          )
    }

    async handleSendByText() {
        this.setState({ sendingText: true });
        let videoGuid = this.props.videoGuid;

        if (!videoGuid) {
            videoGuid = await this.props.submitVideo();

            if (!videoGuid) {
                alert('video error');
                return;
            }
        }

        let message = {
            ToAddresses: this.state.tags.map(t => t.phone),
            Body: this.state.textMessage,
            MessageType: 'text',
            VideoName: this.props.videoName, 
        }

        postData(`api/video/${videoGuid}/messages`, message, this).then(response => {
            //this.resetVideoState();
            this.setState({ sendingText: false, textModal: false });
            this.props.toggleModal('text');
            this.context.addNotification("Success!", "Your message was sent successfully.");
        }).fail(() => this.setState({ sendingText: false }));
    }

    render() {
        const { sendingText, loadingContacts, showPhoneError } = this.state;

        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.props.toggleModal('text')}>Send Video Via Text</ModalHeader>
                <ModalBody>
                    { loadingContacts && <div className="button-spinner"></div> }
                    { showPhoneError && <span>Please enter a valid contact or phone number</span>}
                    { !loadingContacts && <ReactTags 
                        placeholderText={"Name or phone number"}
                        autoFocus={false} 
                        allowNew={true} 
                        tags={this.state.tags} 
                        suggestions={this.state.suggestions} 
                        onDelete={this.handleDelete.bind(this)} 
                        onAddition={this.handleAddition.bind(this)}
                        minQueryLength={1}
                        suggestionsFilter={this.suggestionsFilter.bind(this)}
                        suggestionComponent={this.suggestionComponent.bind(this)}
                        onValidate={this.handleTagValidate.bind(this)}
                    /> }
                    <div className="form-group m-t-25">
                        <label>Message</label>
                        <textarea 
                            className="form-control" 
                            rows="4"
                            name="textMessage"
                            value={this.state.textMessage}
                            onChange={(e) => this.handleChange(e)}
                        ></textarea>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary-outline width-100" onClick={() => this.props.toggleModal('text')}>Cancel</button>
                    <button className="btn btn-primary width-100" onClick={() => this.handleSendByText()}>
                        { sendingText ?
                            <div className="button-spinner"></div>    
                            :
                            "Send"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default VideoText;

VideoText.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    videoGuid: PropTypes.string,
    submitVideo: PropTypes.func,
    videoName: PropTypes.string.isRequired,
}