import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../pages/Login';
import Register from '../pages/Register';
import UnauthHome from './pages/UnauthHome';
import Activation from './pages/Activation';
import LoginHelp from './pages/LoginHelp';
import PrivacyPolicy from './pages/PrivacyPolicy';

const routes = (
  <Switch>
    <Route path="/" exact render={() => {
      //window.location = "http://realconnectsample.herokuapp.com";
      return <Redirect to="/login" />
    }} />
    {/* <Route path="/" exact component={Login} /> */}
    <Route path="/login" component={Login} />
    <Route path="/account-recovery" exact component={LoginHelp} />
    <Route path="/register" exact component={Register} />
    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
    <Route path="/activation" exact render={(props) => (
      <Activation {...props} btnText="Activate Account" subTitle="Welcome to Real Connect One! Please set your password." />
      )} />
    <Route path="/activation/:userId" render={(props) => (
      <Activation {...props} btnText="Activate Account" subTitle="Welcome to Real Connect One! Please set your password." />
      )} />
    <Route path="/reset/:userId" render={(props) => (
      <Activation {...props} btnText="Reset Password" subTitle="Please enter your new password." />
      )} />
    {/* <Redirect to="/login" /> */}
  </Switch>
)


export default routes;