import React from 'react';
import { DateOp } from '../../helpers/OperationTypes';

export default function DayForward({ filter, handleFilterValueChange, filters }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10" 
                name={filter.Field}
                value={filters[filter.Field].Value} 
                onChange={handleFilterValueChange}
            >
                <option></option>
                <option value={DateOp.today}>Today</option>
                <option value={DateOp.thisWeek}>This Week</option>
                <option value={DateOp.thisMonth}>This Month</option>
                <option value={DateOp.next30}>Next 30 Days</option>
                <option value={DateOp.next90}>Next 90 Days</option>
            </select>
        </div>
    )
}