import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { postData, getData, putData } from '../../helpers/AxiosService';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import classnames from 'classnames';

momentLocalizer();

class TaskModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            taskId: 0,
            title: '',
            taskType: 0,
            assignedToId: 0,
            dueDate: new Date(),
            due: '',
            // KeyType: '',
            // KeyValue: 0,
            notes: '', 
            sending: false, 
            assignToOptions: [],
            taskTypeOptions: [],
            acting: false,
            hasError: false,
        }
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount(){
        // this.updateKeys();
        getData('api/task/task-options').then(response => {
            this.setState({ assignToOptions: response.data.Users, taskTypeOptions: response.data.TaskTypes });
        }).fail(error => console.log(error))
    }

    componentDidUpdate(prevProps){
        if ((prevProps.task && prevProps.task.TaskId !== this.props.task.TaskId) || (prevProps.task && prevProps.task.IsCompleted !== prevProps.task.IsCompleted)){
            let task = this.props.task;
            if (typeof(task.DueDate) === 'string'){
                console.log(task.DueDate);
                task.DueDate = moment.utc(task.DueDate).toDate();
            }
            
            this.setState({ 
                taskId: task.TaskId, 
                title: task.Title, 
                taskType: task.TaskTypeId, 
                assignedToId: task.AssignedUserId, 
                dueDate: task.DueDate, 
                due: task.Due,
                notes: task.Notes,
            });
        }
    }

    handleChange(item, val){
        this.setState({[item]: val});
    }

    handleDateChange(date){
        this.setState({ dueDate: date });
    }

    markTaskComplete(){
        this.setState({ acting: true });
        putData(`api/task/${this.state.taskId}/mark-complete`).then(response => {
            this.props.updateTaskList();
            this.setState({ acting: false });
            this.props.toggleModal();
        }).fail(() => this.setState({ acting: false }));
    }

    reOpenTask(){
        this.setState({ acting: true });
        putData(`api/task/${this.state.taskId}/re-open`).then(response => {
            this.props.updateTaskList();
            this.setState({ acting: false });
            this.props.toggleModal();
        }).fail(() => this.setState({ acting: false }));
    }

    handleSubmit(){
        const { title, taskType, assignedToId, dueDate, notes, taskId } = this.state;
        let data = {
            TaskId: taskId,
            TaskTypeId: taskType,
            AssignedUserId: assignedToId,
            Title: title,
            DueDate: moment.utc(dueDate).format(),
            Notes: notes,
            KeyType: this.props.keyType,
            KeyValue: this.props.keyValue
        };
        
        if (!data.TaskTypeId || !data.AssignedUserId || !data.Title){
            this.setState({ hasError: true });
            return;
        }
        
        this.setState({ sending: true });
        
        postData('api/task', data).then(() => {
            this.props.toggleModal('addTask');
            this.props.updateTaskList && this.props.updateTaskList();
            this.setState({ sending: false, title: '', taskType: 0, assignedToId: 0, due: null, notes: '' });
        }).fail(err => this.setState({ sending: false }));
    }

    render(){
        const { title, taskType, assignedToId, dueDate, notes, acting, hasError } = this.state;
        const assignOptions = this.state.assignToOptions.length === 0 ? null : this.state.assignToOptions.map((user, idx) => {
            return <option key={idx} value={user.UserId}>{user.Name}</option>
        });
        const taskTypes = this.state.taskTypeOptions.lenth === 0 ? null : this.state.taskTypeOptions.map((type, idx) => {
            return <option key={idx} value={type.TaskTypeId}>{type.TaskType}</option>
        });
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.props.toggleModal('addTask')}>{this.props.task ? "Edit Task" : "Add Task"}</ModalHeader>
                <ModalBody>
                    <div className={classnames({ "has-error": hasError && !title}, "form-group m-b-15")}>
                        <label className="form-label">Task Title</label>
                        <input className="form-control" value={title} onChange={(e) => this.handleChange('title', e.target.value)}/>
                    </div>
                    <div className={classnames({ "has-error": hasError && !taskType}, "form-group m-b-15")}>
                        <label className="form-label">Task Type</label>
                        <select className="form-control" value={taskType} onChange={(e) => this.handleChange('taskType', e.target.value)}>
                            <option value="">Select...</option>
                            {taskTypes}
                        </select>
                    </div>
                    <div className={classnames({ "has-error": hasError && !assignedToId}, "form-group m-b-15")}>
                        <label className="form-label">Assigned To</label>
                        <select className="form-control" value={assignedToId} onChange={(e) => this.handleChange('assignedToId', e.target.value)}>
                            <option value="">Unassigned</option>
                            {assignOptions}
                        </select>
                    </div>
                    <div className="form-group m-b-15">
                        <label className="form-label">Due</label>
                        <DateTimePicker 
                            value={dueDate}
                            onChange={this.handleDateChange}
                            min={new Date()}
                        />
                        {/* <DateTime inputProps={{ placeholder: 'Click to select' }} closeOnSelect={true} onChange={this.handleDateChange} value={dueDate}/> */}
                    </div>
                    <div className="form-group m-b-15">
                        <label className="form-label">Notes</label>
                        <textarea className="form-control" rows="7" value={notes} onChange={(e) => this.handleChange('notes', e.target.value)}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    { this.props.task.TaskId && !this.props.task.IsCompleted && 
                        <button className="btn btn-primary-outline" onClick={() => this.markTaskComplete()}>
                            { acting ? 
                                <div className="button-spinner"></div>   
                                :
                                "Mark Complete" 
                            }   
                        </button> }
                    { this.props.task.TaskId && this.props.task.IsCompleted && 
                        <button className="btn btn-primary-outline" onClick={() => this.reOpenTask()}>
                            { acting ? 
                                <div className="button-spinner"></div>   
                                :
                                "Re-open Task" 
                            }
                        </button> }
                    <button className="btn btn-primary-outline" onClick={() => this.props.toggleModal('addTask')}>Cancel</button>
                    <button className="btn btn-primary m-l-5" onClick={() => this.handleSubmit()}>
                        {this.state.sending ? 
                            <div className="button-spinner"></div>
                            :
                            "Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

TaskModal.propTypes = {}

export default TaskModal;