import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './TaskRow.css';

export default function TaskRow({ task, show, linkPath, handleTaskClick, markTaskComplete, deleteTask }) {
    task.CssClass = 'text-blue-darker';
    return (
        <div key={task.TaskId} className={classnames({ 'hide': !show }, "col-md-12 m-b-10")} style={{ border: '1px solid #e2e7eb', borderRadius: '3px' }}>
            <div className="bg-white p-t-10 p-b-10 cursor-pointer d-flex justify-content-between" onClick={() => handleTaskClick(task)}>
                <div className="task-icon-container">
                    <i className={classnames(task.CssClass, task.IconCss, 'm-r-10')}></i>
                </div>
                <div className="row task-body">
                    <div className="col-4">
                        <span className="text-primary">{task.Title}</span><br />
                        <span>{task.AssignedUser}</span><br />
                        <span>{task.TaskType}</span>
                    </div>
                    <div className="col-4">
                        <Link to={linkPath} className="text-inverse">{task.AssociatedWith}</Link><br />
                        <span className={classnames("text-grey-darker", { 'text-green': task.IsCompleted, 'text-danger': task.Due === 'pastdue' })}>
                            {task.IsCompleted ? "Complete" : task.Due === 'pastdue' ? "Past Due" : "Open" }
                        </span>
                    </div>
                    <div className="col-4 text-right overflow-visible">
                        <span className={classnames("text-inverse", { 'text-danger': task.Due === 'pastdue' })}>{task.DueDateDisplay}</span><br />
                        <span>{task.IsFromWorkflow ? "Generated from workflow" : ""}</span><br />
                        <span className="text-danger cursor-pointer" onClick={(e) => deleteTask(e, task.TaskId)}>Delete</span> |
                        <span className="text-primary cursor-pointer" onClick={(e) => markTaskComplete(e, task.TaskId)}> Mark Complete </span>
                    </div>
                </div>
            </div>
        </div> 
    )
}