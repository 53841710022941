import React from 'react';
import Auth from '../../auth/Auth';
import { postData } from '../../helpers/AxiosService';
import { Card, CardBody } from 'reactstrap';
import querySearch from 'stringquery';



class Activation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            password: '',
            sending: false
		}
    }
    
    auth = new Auth();

	componentDidMount() {
		if (this.props.location.search){
            let q = querySearch(this.props.location.search);
			this.setState({ code: q.cde });
        } 
	}

	componentWillUnmount() {
    }
    
    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e){
        e.preventDefault();
		if (!this.state.password) return;

        this.setState({ sending: true });
        let data = {
            Password: this.state.password,
            UserId: this.props.match.params.userId,
            Code: decodeURIComponent(this.state.code)
        }

        postData('api/account/resetpassword', data).then(response => {
            this.auth.login(response.data.Email, this.state.password, this.props.history);
        }).fail(err => {
			console.error(err);
			this.setState({ sending: false });
		});
    }
  
	render() {
		return (
			<div>
				{/* <div className="login-cover">
					<div className="login-cover-image" style={{ backgroundImage: 'url(' + this.state.activeBg + ')'}}></div>
					<div className="login-cover-bg"></div>
				</div> */}
		
				<div className="login login-v2">
					{/* <Card>
						<CardBody> */}
							<div className="login-header">
								<div className="brand">
									<span></span> <b>Real Connect</b> One
									<small className="text-black">{this.props.subTitle}</small>
								</div>
								<div className="icon">
									<i className="fa fa-lock text-lime"></i>
								</div>
							</div>
							<div className="login-content">
								<form onSubmit={e => this.handleSubmit(e)} className="margin-bottom-0">
									<div className="form-group m-b-20">
										<input 
											type="text" 
											name="password" 
											className="form-control form-control-lg bg-white text-black" 
											placeholder="Password" 
											required 
											value={this.state.password}
											onChange={e => this.handleChange(e)}
											/>
									</div>
									<div className="login-buttons">
										<button type="submit" className="btn btn-lime btn-block btn-lg">
											{ this.state.sending ?
												<div className="button-spinner"></div>
												:
												this.props.btnText
											}
										</button>
									</div>
								</form>
							</div>
						{/* </CardBody>
					</Card> */}
				</div>
			</div>
		)
	}
}

export default Activation;