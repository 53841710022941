import React from 'react';
import { Op } from '../../helpers/OperationTypes';
import Select from 'react-select';

export default function MultiSelect({ filter, handleFilterOperationChange, handleFilterValueChange, filters, handleMultiChange }) {
    return (
        <div className="form-group row m-b-5">
            <label className="col-form-label col-md-3">{filter.Label}</label>
            <select 
                className="form-control col-md-4 m-r-10 m-b-3" 
                name={filter.Field} 
                value={filters[filter.Field].Operation} 
                onChange={handleFilterOperationChange} 
            >
                <option></option>
                <option value={Op.equals}>Includes</option>
                <option value={Op.notEqualTo}>Excludes</option>
            </select>
            <Select 
                value={filters[filter.Field].Value} 
                isMulti 
                name={filter.Field}
                closeMenuOnSelect={false} 
                options={filter.MultiOptions} 
                className="p-l-0 p-r-0 col-md-4 input-height" 
                onChange={handleMultiChange}
            />
        </div>
    )
}