import React from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { phoneClean } from '../helpers/Functions';
import classnames from 'classnames';
import { postData } from '../helpers/AxiosService.js';
import Auth from '../auth/Auth';
import logo from '../assets/rcoLogo.svg';
import CheckoutForm from '../components/stripe/CheckoutForm';
import {Elements, StripeProvider} from 'react-stripe-elements';
import querySearch from 'stringquery';
import axios from 'axios';
import config from '../config';
import '../assets/css/register.css';
const { baseUrl, stripeApiKey } = config;

const subscriptions = [
	{ id: '1001', name: 'No Contract ($249/mo)' },
	{ id: '1002', name: '3 Mo Contract ($249/mo)' },
	{ id: '1003', name: '6 Mo Contract ($224/mo)' },
	{ id: '1004', name: 'RCO Growth Plan (1st mo free)' }
]

class Register extends React.Component {
    state = {
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Password: '',
        sending: false,
		hasError: false,
		step: '1',
		paymentFailed: false,
		tenantId: 0,
		stripeCustomerId: 0,
		elementKey: 1,
		registerErrorMsg: '',
		subscription: '1001',
    }

	auth = new Auth();
	
	componentDidMount() {
		if (this.props.location.search){
			let q = querySearch(this.props.location.search);

			if (q.sub) {
				this.setState({ subscription: q.sub });
			}
		}
	}

    handleChange(e, item){
        let cleanValue = phoneClean(e.target.value);
        this.setState({ [item]: cleanValue });
	}
	
	handleSubmitRegisterInfo(e) {
		e.preventDefault();

		if (!this.validate()) return;
		
		this.setState({ sending: true });
		const { FirstName, LastName, PhoneNumber, Email, Password } = this.state;
		const data = {
			FirstName,
			LastName, 
			PhoneNumber: phoneClean(PhoneNumber), 
			Email, 
			Password,
		}

		axios.post(`${baseUrl}api/account/register`, data).then(response => {
			const { data } = response;
			if (data.Successful) {
				this.setState({ sending: false, tenantId: data.TenantId, step: '2' });
			} 
		}).catch(error => {
			let errMsg = '';
			if (error.response.data.ModelState) {
				const { ModelState } = error.response.data;
				for (const key in ModelState) {
					const messages = ModelState[key];
					for (let i = 0; i < messages.length; i++) {
						errMsg = errMsg + messages[i] + "  ";
					}
				}
			}

			this.setState({ sending: false, hasError: true, registerErrorMsg: errMsg });
		});
	}

    handleSubmit(cardToken){
        if (this.validate()){
            this.setState({ sending: true });
			const { Email, Password } = this.state;

            let data = {
				Email, 
				cardToken,
				Subscription: this.state.subscription,
			}

			axios.post(`${baseUrl}api/account/register/finalize`, data).then(response => {
				if (!response.data.Successful) {
					this.handlePaymentFailed(response.data.TenantId, response.data.StripeCustomerId);
				} else {
					this.auth.login(Email, Password, this.props.history);
					this.setState({ sending: false });
				}
			}).catch(error => {
				let errMsg = '';
				if (error.response.data.ModelState) {
					const { ModelState } = error.response.data;
					for (const key in ModelState) {
						const messages = ModelState[key];
						for (let i = 0; i < messages.length; i++) {
							errMsg = errMsg + messages[i] + "  ";
						}
					}
				}

				this.setState({ sending: false, hasError: true, registerErrorMsg: errMsg });
			});
        }
	}

	handlePaymentFailed(tenantId, stripeCustomerId) {
		this.setState({ sending: false, paymentFailed: true, tenantId, stripeCustomerId, elementKey: this.state.elementKey + 1 });
	}

	resubmitPaymentMethod(cardToken) {
		this.setState({ sending: true });
		const { tenantId, Email, Password } = this.state;

		postData(`api/account/payment-method/${tenantId}?token=${cardToken}`).then(response => {
			if (!response.data.Successful) {
				this.handlePaymentFailed(response.data.TenantId, response.data.StripeCustomerId);
			} else {
				this.auth.login(Email, Password, this.props.history);
				this.setState({ sending: false });
			}
		}).fail(() => this.setState({ sending: false }));
	}
	
    validate(){
        let isValid = true;

        for (let key in this.state){
            if (key === "sending" || key === "hasError" || "paymentFailed" || "tenantId" || "stripeCustomerId") continue;

            if (!this.state[key]) isValid = false
        }

        this.setState({ hasError: !isValid });
        return isValid;
    }
  
	render() {
        const { FirstName, LastName, PhoneNumber, Email, Password, hasError, sending, step, paymentFailed, registerErrorMsg, subscription } = this.state;
		return (
			<div className="register-container">
				<div className="brand-container">
					<img src={logo} alt="Real Connect" />
					<h3 className="m-t-25 f-w-500">The ONE platform you need to grow every aspect of your business</h3>
				</div>
				<div className="info-container">
					<div className="with-thin-shadow bg-white p-25 register-info">
						<div className="login-header">
							<h3>Create your account</h3>
							{ hasError && <small className="text-danger">{registerErrorMsg}</small> }
						</div>
						{ step === '1' && 
						<div className="login-content">
							<div className="m-b-15">Enter your contact information</div>
							<form onSubmit={(e) => this.handleSubmitRegisterInfo(e)} className="margin-bottom-0">
								<label className="control-label">Name</label>
								<div className="row row-space-10">
									<div className={classnames({ 'has-error': hasError && !FirstName }, "col-md-6 m-b-15")}>
										<input type="text" className="form-control" placeholder="First name" onChange={(e) => this.handleChange(e, 'FirstName')} value={FirstName} />
									</div>
									<div className={classnames({ 'has-error': hasError && !LastName }, "col-md-6 m-b-15")}>
										<input type="text" className="form-control" placeholder="Last name" onChange={(e) => this.handleChange(e, 'LastName')} value={LastName} />
									</div>
								</div>
								<label className="control-label">Cell Phone</label>
								<div className="row m-b-15">
									<div className={classnames({ 'has-error': hasError && !PhoneNumber }, "col-md-12")}>
										<InputMask mask="(999) 999 9999" className="form-control" placeholder="Cell Phone"  onChange={(e) => this.handleChange(e, 'PhoneNumber')} value={PhoneNumber}></InputMask>
									</div>
								</div>
								<label className="control-label">Email</label>
								<div className="row m-b-15">
									<div className={classnames({ 'has-error': hasError && !Email }, "col-md-12")}>
										<input type="text" className="form-control" placeholder="Email address"  onChange={(e) => this.handleChange(e, 'Email')} value={Email}  />
									</div>
								</div>
								<label className="control-label">Password</label>
								<div className="row m-b-15">
									<div className={classnames({ 'has-error': hasError && !Password }, "col-md-12")}>
										<input type="password" className="form-control" placeholder="Password"  onChange={(e) => this.handleChange(e, 'Password')} value={Password}  />
									</div>
								</div>
								<label className="control-label">Plan</label>
								<div className="row m-b-15">
									<div className="col-md-12">
										<select className="form-control" onChange={(e) => this.handleChange(e, 'subscription')} value={subscription}>
											{ subscriptions.map(s => (
												<option key={s.id} value={s.id}>{s.name}</option>
											)) }
										</select>
									</div>
								</div>
								<div className="register-buttons">
									<button type="submit" className="btn btn-lime btn-block btn-lg">
										{ sending ? 
											<div className="button-spinner-white"></div>
											: 
											"Next"
										}
									</button>
								</div>
								<div className="m-t-20 m-b-40 p-b-40 text-inverse">
									Already a member? Click <Link to="/login">here</Link> to login.
								</div>
							</form>
						</div> }
						{ step === '2' && 
						<StripeProvider apiKey={stripeApiKey}>
							<Elements>
								<CheckoutForm 
									onSubmit={this.handleSubmit.bind(this)} 
									sending={sending} 
									paymentFailed={paymentFailed}
									handleResubmit={this.resubmitPaymentMethod.bind(this)}
									elementKey={this.state.elementKey}
								/>
							</Elements>
						</StripeProvider> }
						
						<div style={{ position: 'absolute', bottom: '15px', left: 0, width: '100%' }}>
							<hr />
							<p className="text-center">
								&copy; Real Connect One All Right Reserved 2019
							</p> 
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Register;