import React, { useState, useEffect, useContext } from  'react';
import { deleteData, getData, postFormData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';

export default function ContactDocuments(props) {
    const cxt = useContext(PageSettings);
    const [documents, setDocuments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const { contactId, contactEntityId } = props;
    async function fetchDocuments() {
        if (!contactId) return;

        try {
            const resp = await getData(`api/contact-document/${contactId}`);
            setDocuments(resp.data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchDocuments();
    }, [])

    async function handleFileChange(e) {
        setUploading(true);
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(`file${i}`, e.target.files[i]);
        }
        
        try {
            await postFormData(`api/contact-document/${contactEntityId}`, formData, { context:  cxt });
            fetchDocuments();
        } catch (e) {
            console.log(e);
        }

        setUploading(false);
    }

    function handleDeleteClick(doc) {
        cxt.messageConfirmation('Delete Document', `Are you sure you want to delete ${doc.FileName}?`, 
                    handleDelete.bind(null, doc.Id), "Document deleted successfully", "Delete");
    }

    async function handleDelete(id) {
        cxt.toggleSweetAlert('confirm');
        try {
            await deleteData(`api/contact-document/${id}`, { context: cxt });
            fetchDocuments(); 
        } catch (e) {
            console.log(e);
        }
    }

    function renderRows() {
        return documents.map((doc, i) => (
            <tr key={doc.Id}>
                <td>
                    <a 
                        href={doc.FileLocation}
                        target="_blank"
                    >
                        {doc.FileName}
                    </a>
                </td>
                <td className="text-right">
                    <a 
                        download
                        href={doc.FileLocation}
                        className="btn btn-white m-r-5" 
                        target="_blank"
                    >
                        <i className="fas fa-download text-primary"></i>
                    </a>
                     <button 
                        id={`delete_${i}`} 
                        className="btn btn-white m-r-5" 
                        onClick={() => handleDeleteClick(doc)}
                    >
                        <i className="far fa-times-circle text-danger"></i>
                    </button>
                </td>
            </tr>
        ));
    }

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="pull-right text-right cursor-pointer" style={{ width: '110px', height: '30px', position: 'relative' }}>
                        <input 
                            style={{ opacity: '0', width: '100%', height: '100%', top: '0', left: '0', position: 'absolute' }} 
                            type="file"
                            multiple 
                            onChange={handleFileChange} 
                        />
                        <button 
                            className="btn btn-lime btn-sm w-100"
                            disabled={uploading}
                        >
                            { uploading ? 
                            <i className="fas fa-spinner fa-pulse"></i>
                            :
                            "Add Documents"    
                            }
                        </button>
                    </div>
                </div>
            </div>
            { documents.length === 0 && <div className="w-100 text-center">Upload documents for this contact</div> }
            { documents.length > 0 && <div className="table-responsive m-t-25">
                <table className="table">
                    <thead>
                        <tr>
                            <td>Document</td>
                            <td className="text-right">Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </div> }
        </div>
    )
}