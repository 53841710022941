import React from 'react';
import { Panel, PanelBody } from '../components/panel/panel.jsx';
import classnames from 'classnames';
import LeadTable from '../components/leads/LeadTable';
import { getData, postData } from '../helpers/AxiosService.js';
import { Op } from '../helpers/OperationTypes.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { PageSettings } from '../config/page-settings.js';
import EmailModalBlank from '../components/email/EmailModalBlank';
import ActivityModal from '../components/activity/ActivityModal';
import WorkflowModal from '../components/workflows/WorkflowModal';
import TaskModal from '../components/task-list/TaskModal';
import Pagination from 'react-js-pagination';
import MassActions from '../components/leads/MassActions.js';
import CreateTransactionModal from '../components/transactions/CreateTransactionModal.js';
import SmartSearches from '../components/leads/SmartSearches.js';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { convertToCsv } from '../helpers/ContactsToCsv';
import DynamicSearchFilters from '../components/leads/DynamicSearchFilters.js';
import { parseQueryString } from '../helpers/Functions.js';


class Leads extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: [],
            pages: 120,
            activeTab: '1',
            showAdvanced: false, 
            filters: null,
            filterOptions: [],
            savedSearches: [],
            saveModal: false,
            searchName: '',
            sending: false,
            selectedSavedSearch: '',
            selectedSavedSearchName: 'Select a Saved Search',
            noNameError: false,
            pageTitle: 'All Contacts',
            searchTerm: '',
            deleting: false,
            toAddress: '',
            emailOpen: false,
            activityOpen: false,
            selectedEntityId: null,
            selectedContactId: null,
            workflowOpen: false,
            addTaskModal: false,
            transactionModal: false,
            totalContacts: 0,
            contactsPerPage: 50,
            activePage: 1,
            checkedContacts: new Map(),
            isContactsWithoutSearch: false,
            agentOptions: [],
            lenderOptions: [],
            urgencyOptions: [],
            typeOptions: [],
            propertyTypeOptions: [],
            sourceOptions: [],
            tagOptions: [],
        }
        this.handleFilterOperationChange = this.handleFilterOperationChange.bind(this);
        this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
        this.submitFilteredSearch = this.submitFilteredSearch.bind(this);
        this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
        this.clearAllFilters = this.clearAllFilters.bind(this);
        this.getSavedSearches = this.getSavedSearches.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.selectContactRow = this.selectContactRow.bind(this);
        this.handlePhoneCall = this.handlePhoneCall.bind(this);
        this.handleText = this.handleText.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleAddCampaign = this.handleAddCampaign.bind(this);
        this.handleAddTask = this.handleAddTask.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.handleCreateTransaction = this.handleCreateTransaction.bind(this);
        this.handleExportContacts = this.handleExportContacts.bind(this);
    }

    async componentDidMount(){
        //load with sidebar minified
        this.context.handleSetPageSidebarMinified(true);
        await this.fetchFilterOptions();
        // Find based on specific 'contacts' key
        if (window.history.state 
            && window.history.state.key 
            && window.history.state.filterState
            && window.history.state.filterState.data.length > 0) {
            window.history.state.filterState.loading = false;
            this.setState(window.history.state.filterState);
            return;
        }

        if (this.props.location.search){
            const params = parseQueryString(this.props.location.search);
            this.applyQueryParams(params);
        } else {
            this.submitFilteredSearch();
        }

        this.getSavedSearches();
        this.getSelectOptions();
    }

    componentWillUnmount(){
        this.context.handleSetPageSidebarMinified(false);
        this.clearAllFilters();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     console.log('prev: ', prevState)
    //     console.log('current:', this.state.filters)
    //     if (JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters)) {

    //             //if (this.state.filters.CEDateCreated && this.state.filters.CEDateCreated.Value) return;
    //             console.log('updating')
    //             window.history.replaceState({ key: 'contacts', filterState: this.state }, 'contacts');
    //     }
    // }

    updateWindowHistory() {
        window.history.replaceState({ key: 'contacts', filterState: this.state }, 'contacts');
    }

    async fetchFilterOptions() {
        const resp = await getData('api/filter/filteroptions');
        const filters = {};
        resp.data.forEach((f, idx) => {
            filters[f.Field] = { PropertyName: f.Field, FilterType: f.FilterType, Operation: f.DefaultOperation, Value: '', TableName: f.TableName };
        });
        
        this.setState({ filters, filterOptions: resp.data });
    }

    getSelectOptions() {
        getData('api/filter/selectoptions').then(response => {
            this.setState({
                agentOptions: response.data.AgentOptions,
                lenderOptions: response.data.LenderOptions,
                sourceOptions: response.data.SourceOptions,
                typeOptions: response.data.ContactTypeOptions,
                urgencyOptions: response.data.UrgencyOptions,
                propertyTypeOptions: response.data.PropertyTypeOptions,
                tagOptions: response.data.TagOptions
            })
        })
    }

    applyQueryParams(queryObj){
        if (queryObj.filters && queryObj.filters.length) {
            queryObj.filters.forEach(f => {
                    if (f.field === 'WithoutSearch') {
                        this.setState({ isContactsWithoutSearch: true }, this.submitFilteredSearch);
                        return;
                    }
                    this.handleFilterOperationChange({ target: { name: f.field, value: f.operation }});
                    this.handleFilterValueChange({ target: { name: f.field, value: f.value }}, true);
            })
        }
    }

    createCheckedContacts(contacts){
        let checkedContacts = new Map();
        contacts.length > 0 && contacts.forEach(contact => {
            checkedContacts.set(contact.ContactId, false);
        });

        this.setState({ checkedContacts });
    }

    handlePageChange(pageNumber){
        this.setState({ activePage: pageNumber }, () => this.submitFilteredSearch(null, true));
    }

    handlePerPageChange(val){
        this.setState({ contactsPerPage: val }, this.submitFilteredSearch);
    }

    getSavedSearches(){
        getData('api/filter/savedsearch').then(response => {
            this.setState({ savedSearches: response.data });
        })
    }

    closeSaveModal(){
        this.setState({ saveModal: false, searchName: '', noNameError: false });
    }

    selectContactRow(contactId){
        this.props.history.push(`/contact/${contactId}`);
    }

    toggleAdvancedSearch(){
        this.setState({ showAdvanced: !this.state.showAdvanced })
    }
    
    handleFilterOperationChange(e){
        const { name, value } = e.target;
        var filters = Object.assign({}, this.state.filters);
        filters[name].Operation = value;
        this.setState({ filters: filters});
    }

    handleFilterValueChange(e, submitSearch = false){
        const { name, value } = e.target;
        // cleaning out all the dollar characters
        let cleanValue = value;
        if (typeof value === 'string') {
            cleanValue = value.replace("$", "").replace(/,/g, "");
        }

        var filters = Object.assign({}, this.state.filters);
        filters[name].Value = cleanValue;

        this.setState({ filters: filters }, () => {
            if (submitSearch) this.submitFilteredSearch();
        });
    }

    handleMultiChange(value, { action, removedValue, name}, submitSearch = false){
        let field = Object.assign({}, this.state.filters[name]);
        
        switch(action){
            case 'select-option':
                field.Value = [];
                value.forEach(tag => field.Value.push(tag));
                break;
            case 'remove-value':
                field.Value = field.Value.filter(tag => tag.value != removedValue.value)
                break;
            case 'clear':
                field.Value = [];
                break;
            default:
                break;
        }

        this.setState({ filters: {...this.state.filters, [name]: field }}, () => {
            if (submitSearch) {
                this.submitFilteredSearch();
            }
        });
    }

    handleSearchTermChange(val){
        this.setState({ searchTerm: val });
    }

    handleExportContacts(){
        const csvString = convertToCsv(this.state.data);
        const content = encodeURI('data:text/csv;charset=utf-8,' + csvString);

        var link = document.createElement("a");
        link.setAttribute("href", content);
        link.setAttribute("download", "ContactExport.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    submitFilteredSearch(e, preserveChecked = false){
        if (e)
            e.preventDefault();

        this.setState({ loading: true })
        let data = {
            SearchTerm: this.state.searchTerm,
            Filters: this.generateFilterList(),
            PageNumber: this.state.activePage,
            Take: this.state.contactsPerPage,
        }

        if (this.state.isContactsWithoutSearch) {
            data.ContactsWithoutSearch = true;
        }

        postData(`api/filter/filteredsearch`, data, this).then(response => {
            document.getElementById('check_all').checked = false;
            let totalContacts = 0;
            if (response.data.length) {
                totalContacts = response.data[0].MaxRows;
            }
            this.setState({ data: response.data, loading: false, totalContacts, isContactsWithoutSearch: false, checkedContacts:  preserveChecked ? this.state.checkedContacts : new Map() });            
        }).fail(() => {
            document.getElementById('check_all').checked = false;
            this.setState({ loading: false, isContactsWithoutSearch: false, checkedContacts:  new Map() })
        });
    }

    generateFilterList() {
        const stateFilters = Object.assign({}, this.state.filters);
        let filterList = [];
        for (var key in stateFilters){
            let filter = stateFilters[key];
            let op = stateFilters[key].Operation;
                
            if ((filter.Value && filter.Operation) || op === Op.isEmpty || op === Op.isNotEmpty || op === Op.isNull || op === Op.isNotNull || op === Op.isTrue || op === Op.isFalse){
                filterList.push(stateFilters[key]);
            }
        }
        
        return filterList;
    }

    clearAllFilters(){
        this.fetchFilterOptions();
    }

    handleSaveNameChange(val){
        this.setState({ searchName: val });
    }

    handleSearchSave(){
        if (!this.state.searchName){
            this.setState({ noNameError: true });
            return;
        }

        this.setState({ sending: true });
        let data = {
            Name: this.state.searchName,
            SearchFilters: this.generateFilterList()
            // SearchProperties: this.generateFilterList(false)
        }

        postData('api/filter/savedsearch', data).then(response => {
            this.setState({ sending: false, saveModal: false, selectedSavedSearch: response.data.SavedSearchId });
            this.getSavedSearches();
        }).fail(() => {
            this.setState({ sending: false, saveModal: false });
        })
    }

    handleSearchSelect(searchId, searchName){
        this.clearAllFilters();
        this.setState({ selectedSavedSearch: searchId, selectedSavedSearchName: searchName, showAdvanced: true });
        let searchIndex = this.state.savedSearches.findIndex((element, i) => element.SavedSearchId == searchId);
        if (searchIndex != -1){
            let search = this.state.savedSearches[searchIndex];
            this.setFilters(search);
        }
    }

    handleSearchDelete(){
        this.setState({ deleting: true });
        postData(`api/filter/searchdelete?searchId=${this.state.selectedSavedSearch}`).then(response => {
            this.getSavedSearches();
            this.setState({ deleting: false, selectedSavedSearch: '', selectedSavedSearchName: 'Select a Saved Search' })
        })
    }

    async setFilters(search){
        await this.fetchFilterOptions();
        let searchFilters = Object.assign({}, this.state.filters);
        search.SearchFilters.forEach((element, i) => {
            searchFilters[element.PropertyName].Operation = element.OperationString;
            searchFilters[element.PropertyName].Value = element.Value;
        });
        
        this.setState({ filters: searchFilters}, this.submitFilteredSearch);
    }

    handlePhoneCall(e, contactId){
        e.stopPropagation();
        this.setState({ selectedContactId: contactId }, this.toggleModal('activity'));
    }

    handleText(e, contact){
        e.stopPropagation();

        this.context.openConversation({ contactId: contact.ContactId, name: contact.ContactDetails.Name, number: contact.ContactDetails.CellPhone })
    }

    handleEmail(e, email, entityId, contactId){
        e.stopPropagation();
        this.setState({ toAddress: email, selectedEntityId: entityId, selectedContactId: contactId }, this.toggleModal('emailBlank'));
    }

    handleAddCampaign(e, entityId, contactId){
        e.stopPropagation();
        this.setState({ selectedEntityId: entityId, selectedContactId: contactId }, this.toggleModal('workflow'));
    }

    handleAddTask(e, entityId){
        e.stopPropagation();
        this.setState({ selectedEntityId: entityId}, this.toggleModal('addTask'));
    }

    handleCreateTransaction(e, contactId){
        e.stopPropagation();
        this.setState({ selectedContactId: contactId }, this.toggleModal('transactionModal'));
    }

    toggleModal(modal){
        switch(modal){
            case 'emailBlank':
                this.setState({ emailOpen: !this.state.emailOpen });
                break;
            case 'activity':
                this.setState({ activityOpen: !this.state.activityOpen });
                break;
            case 'workflow':
                this.setState({ workflowOpen: !this.state.workflowOpen });
                break;
            case 'searchsave':
                this.setState({ saveModal: !this.state.saveModal });
                break;
            case 'addTask':
                this.setState({ addTaskModal: !this.state.addTaskModal });
                break;
            case 'transactionModal':
                this.setState({ transactionModal: !this.state.transactionModal });
                break;
            default:
                break;
        }
    }

    handleCheckChange(e){
        const item = Number(e.target.name);
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedContacts: prevState.checkedContacts.set(item, isChecked) }));
    }

    getCheckedContactIds(){
        let ids = [];
        for (const [id, val] of this.state.checkedContacts.entries()){
            if (val){
                ids.push(Number(id));
            }
        }

        return ids;
    }

    toggleSelectAll(e){
        let select = e.target.checked;
        if (select){
            let checkedContacts = new Map(this.state.checkedContacts);
            // let checkedContacts = new Map();
            this.state.data.forEach(contact => checkedContacts.set(contact.ContactId, true));
            this.setState({ checkedContacts }, this.getCheckedContactIds);
        } else {
            this.setState({ checkedContacts: new Map() });
        }
    }

    countFilters() {
        let count = 0;
        for (let key in this.state.filters) {
            if (this.state.filters[key].Operation && this.state.filters[key].Value) {
                count++;
            }
        }

        return count;
    }

    handleSorting(e) {
        const { value } = e.target;
        var contacts = this.state.data.slice();
        
        switch (value) {
            case 'dc-desc':
                contacts.sort((x, y) => {
                    const date1 = new Date(x.DateCreated);
                    const date2 = new Date(y.DateCreated);
                    return date2 - date1;
                });
                break;
            case 'dc-asc':
                contacts.sort((x, y) => {
                    const date1 = new Date(x.DateCreated);
                    const date2 = new Date(y.DateCreated);
                    return date1 - date2;
                });
                break;
            case 'lc-desc':
                contacts.sort((x, y) => {
                    if (!y.ContactDetails.LastContact && !x.ContactDetails.LastContact) return -1;
                    // if (!y.ContactDetails.LastContact) return 1;
                    if (!x.ContactDetails.LastContact) return 1;

                    const date1 = new Date(x.DateCreated);
                    const date2 = new Date(y.DateCreated);
                    return date2 - date1;
                });
                break;
            case 'lc-asc':
                contacts.sort((x, y) => {
                    if (!y.ContactDetails.LastContact && !x.ContactDetails.LastContact) return 0;
                    // if (!y.ContactDetails.LastContact) return -1;
                    if (!x.ContactDetails.LastContact) return -1;

                    const date1 = new Date(x.DateCreated);
                    const date2 = new Date(y.DateCreated);
                    return date1 - date2;
                });
                break;
            default:
                break;
        }

        this.setState({ data: contacts });
    }


    render(){
        const { checkedContacts, pageTitle, loading, agentOptions, 
            lenderOptions, sourceOptions, savedSearches,  showAdvanced, filterOptions,
            tagOptions, filters, data, selectedSavedSearchName, 
            activePage, totalContacts, contactsPerPage } = this.state;
        const actualCheckedContacts = this.getCheckedContactIds();
        let hasChecked = actualCheckedContacts.length > 0;
        let appliedFilterCount = this.countFilters();
        const highContact = activePage * contactsPerPage > totalContacts ? totalContacts : activePage * contactsPerPage;
        const lowContact = totalContacts === 0 ? 0 : activePage * contactsPerPage - contactsPerPage + 1;
        return (
            <div>
				<div className={classnames({ 'show': loading }, 'fade')} id="mini-sidebar-page-loader"><div className="spinner"></div></div>
                { data && data.length > 0 && 
                <ol className="breadcrumb pull-right">
                    <button className="btn btn-white" onClick={() => this.handleExportContacts()}><i className="far fa-hdd"></i> Export</button>
				</ol> }
                <h1 className="page-header">{pageTitle} <small className="f-w-100">Manage, search and filter your {pageTitle.toLowerCase()}</small></h1>
                <div className="row">
                    <div className="col-md-9">
                        <SmartSearches history={this.props.history} />
                    </div>
                    <div className="col-md-3 text-right">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle className="btn btn-white width-200" caret>{selectedSavedSearchName}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.handleSearchSelect('', 'Select a Saved Search')}>Select a Saved Search</DropdownItem>
                                { savedSearches.map((search, idx) => <DropdownItem key={idx} onClick={() => this.handleSearchSelect(search.SavedSearchId, search.Name)}>{search.Name}</DropdownItem>) }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <button className={classnames({ 'hide': !this.state.selectedSavedSearch }, 'btn btn-outline-danger pull-right m-l-10')} onClick={() => this.handleSearchDelete()}>
                            { this.state.deleting ? <div className="button-spinner"></div> : "Delete Search" }
                        </button>
                    </div>
                </div>
                <button 
                    className="btn btn-primary m-t-10 m-b-20 width-200" 
                    onClick={this.toggleAdvancedSearch}>
                    Advanced Search {appliedFilterCount > 0 && <span>({appliedFilterCount})</span> } 
                    <i className={classnames({ 'fas fa-chevron-down': !showAdvanced, 'fas fa-chevron-up': showAdvanced }, 'm-l-10')}></i>
                </button>
                <Panel theme="primary">
                    <PanelBody className={classnames({ 'hide': !this.state.showAdvanced })}>
                        <Collapse isOpen={this.state.showAdvanced}>
                            { filters &&
                            <DynamicSearchFilters
                                filters={filters}
                                filterOptions={filterOptions}
                                handleFilterOperationChange={this.handleFilterOperationChange}
                                handleFilterValueChange={this.handleFilterValueChange}
                                handleMultiChange={this.handleMultiChange}
                                clearAllFilters={this.clearAllFilters}
                                submitFilteredSearch={this.submitFilteredSearch}
                                toggleModal={this.toggleModal}
                            />
                            }       
                        </Collapse>
                    </PanelBody>
                </Panel>
                <div className="m-b-25">
                    <MassActions 
                        disabled={!hasChecked}
                        agentOptions={agentOptions}
                        checkedContacts={actualCheckedContacts}
                        updateResults={this.submitFilteredSearch}
                        lenderOptions={lenderOptions}
                        sourceOptions={sourceOptions}
                        tagOptions={tagOptions}
                        handleExportContacts={this.handleExportContacts}
                    />
                </div>
                <Panel className="col-12 bg-white p-15 p-b-0">
                    <div className="row">
                        <div className="col-md-2">
                            <select className="form-control" value={this.state.contactsPerPage} onChange={(e) => this.handlePerPageChange(e.target.value)} style={{ height: '34px' }}>
                                <option value={10} >10</option>
                                <option value={25} >25</option>
                                <option value={50} >50</option>
                                <option value={100} >100</option>
                            </select>
                            { actualCheckedContacts && actualCheckedContacts.length > 0 &&
                                <div style={{ marginTop: '16px' }}>{actualCheckedContacts.length} contact(s) selected</div>
                            }
                        </div>
                        <div className="col-md-6"> 
                            <Pagination 
                                activePage={activePage}
                                itemsCountPerPage={contactsPerPage}
                                totalItemsCount={totalContacts}
                                onChange={this.handlePageChange}
                            />
                            <span className="display-inline">
                                Showing {lowContact} - {highContact} of {totalContacts}
                            </span>
                        </div>
                        {/* <div className="col-md-2">
                            <select className="form-control" onChange={(e) => this.handleSorting(e)}>
                                <option>Sort By</option>
                                <option value="dc-desc">Date Created: Most Recent</option>
                                <option value="dc-asc">Date Created: Oldest</option>
                                <option value="lc-desc">Last Contact: Most Recent</option>
                                <option value="lc-asc">Last Contact: Oldest</option>
                                <option value="la-desc">Last Active: Most Recent</option>
                                <option value="la-asc">Last Active: Oldest</option>
                                <option value="tf-asc">Timeframe: Soonest</option>
                                <option value="tf-desc">Timeframe: Furthest</option>
                            </select>
                        </div> */}
                        <div className="col-md-4">
                            <form onSubmit={(e) => this.submitFilteredSearch(e)}>
                                <div className="form-group row text-right w-100">
                                    {/* <label className="col-form-label col-md-3">Search</label> */}
                                    {/* <input type="text" className="form-control col-md-6 m-r-5" value={this.state.searchTerm} onChange={(e) => this.handleSearchTermChange(e.target.value)} /> */}
                                    <div className="search-bar m-r-5" style={{ height: '33px' }}>
                                        <div ref={this.dropdownRef} style={{ height: '100%' }}>
                                            <div className="search-icon"><i className="fa fa-search"></i></div>
                                            <input 
                                                className="search-input"
                                                placeholder="Search name, email, source..."
                                                value={this.state.searchTerm}
                                                onChange={(e) => this.handleSearchTermChange(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-lime btn-sm">Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <LeadTable 
                        handlePhoneCall={this.handlePhoneCall} 
                        handleText={this.handleText} 
                        handleEmail={this.handleEmail} 
                        handleAddCampaign={this.handleAddCampaign}
                        handleAddTask={this.handleAddTask}
                        selectContactRow={this.selectContactRow} 
                        handleFetchData={this.handleFetchData} 
                        pages={this.state.pages} 
                        data={data} 
                        totalContacts={this.state.totalContacts}
                        contactsPerPage={this.state.contactsPerPage}
                        activePage={this.state.activePage}
                        checkedContacts={checkedContacts}
                        handleCheckChange={this.handleCheckChange}
                        toggleSelectAll={this.toggleSelectAll}
                        handleCreateTransaction={this.handleCreateTransaction}
                    />
                    <div className="row bg-white">
                        <div className="col-md-2">
                            <select className="form-control" value={this.state.contactsPerPage} onChange={(e) => this.handlePerPageChange(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className="col-md-19"> 
                            <Pagination 
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.contactsPerPage}
                                totalItemsCount={this.state.totalContacts}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </Panel>


                <Modal isOpen={this.state.saveModal}>
                    <ModalHeader toggle={() => this.closeSaveModal()}>Save Search</ModalHeader>
                    <ModalBody>
                        <div className={classnames({ 'has-error': this.state.noNameError && !this.state.searchName }, 'form-group')}>
                            <input className="form-control m-b-15" placeholder="Search Name" value={this.state.searchName} onChange={(e) => this.handleSaveNameChange(e.target.value)} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => this.closeSaveModal()}>Cancel</button>
                        <button className="btn btn-primary  m-l-5" onClick={() => this.handleSearchSave()}>
                            {this.state.sending ? 
                                <div className="button-spinner"></div>
                                :
                                "Save"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
                <EmailModalBlank 
                    toAddress={this.state.toAddress} 
                    openModal={this.state.emailOpen} 
                    toggleModal={this.toggleModal} 
                    keyType="ContactEntityId"
                    keyId={this.state.selectedEntityId}
                    contactId={this.state.selectedContactId}
                    />
                <ActivityModal 
                    title={'Log Phone Call'} 
                    isOpen={this.state.activityOpen} 
                    toggleModal={this.toggleModal} 
                    contactId={this.state.selectedContactId} 
                />
                <WorkflowModal isOpen={this.state.workflowOpen} toggleModal={this.toggleModal} contactId={this.state.selectedContactId} />
                <TaskModal isOpen={this.state.addTaskModal} task={{}} toggleModal={this.toggleModal} contactEntityId={this.state.selectedEntityId} />
                <CreateTransactionModal isOpen={this.state.transactionModal} toggleModal={this.toggleModal} contactId={this.state.selectedContactId} />
            </div>
        )
    }
}

Leads.contextType = PageSettings;
export default Leads;