const icons = {
    1: { icon: 'fas fa-phone', title: 'Called', textColor:  'text-lime' },
    2: { icon: 'fas fa-phone', title: 'Left Message', textColor:  'text-lime' },
    3: { icon: 'fas fa-phone', title: 'No Answer', textColor:  'text-lime' },
    4: { icon: 'fas fa-envelope', title: 'Emailed', textColor:  'text-primary' },
    5: { icon: 'fas fa-comments', title: 'Sent Text', textColor:  'text-orange' },
    6: { icon: 'fas fa-clipboard', title: 'Made Offer', textColor:  'text-green-darker' },
    7: { icon: 'fas fa-clipboard', title: 'Offer Accepted', textColor:  'text-green-darker' },
    8: { icon: 'fas fa-envelope', title: 'Received Email', textColor:  'text-primary' },
    9: { icon: 'fas fa-comment', title: 'Received Text', textColor:  'text-orange' },
    10: { icon: 'far fa-check-square', title: 'Task Completed', textColor:  'text-blue-darker' },
    11: { icon: 'fas fa-key', title: 'Tour Requested', textColor:  'text-blue-darker' },
    12: { icon: 'fas fa-heart', title: 'Favorited Property', textColor:  'text-red-darker' },
    13: { icon: 'fas fa-info-circle', title: 'Information Requested', textColor:  'text-primary' },
    14: { icon: 'fas fa-home', title: 'Property Viewed', textColor:  'text-green' },
    15: { icon: 'fas fa-search', title: 'Search Performed', textColor:  'text-primary' },
    16: { icon: 'fas fa-edit', title: 'Pre-qualify Requested', textColor:  'text-primary' },
    17: { icon: 'fas fa-money-bill-alt', title: 'Property Valuation Requested', textColor:  'text-green' },
    20: { icon: 'far fa-calendar-alt', title: 'Appointment Requested', textColor: 'text-purple' },
    21: { icon: 'fas fa-sign', title: 'Showing', textColor: 'text-purple' },
    22: { icon: 'fas fa-laptop-house', title: 'Open House', textColor: 'text-red-darker' },
}

export function getActivityDetails(activityId){
    return icons[activityId];
}

