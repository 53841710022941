import React from 'react';
import { postData, getData, putData } from '../helpers/AxiosService';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { PageSettings } from '../config/page-settings';
import { displayDateWorded } from '../helpers/Functions';
import VideoEmail from '../components/video/VideoEmail';
import VideoText from '../components/video/VideoText';
import { Panel } from '../components/panel/panel.jsx';

class Videos extends React.Component{
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            videos: [],
            updatingVideo: '',
            deletingVideo: '',
            emailModal: false,
            textModal: false,
            targetVideo: { 
                VideoName: '',
            },
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.fetchUserVideos();
    }

    fetchUserVideos() {
        getData('api/video/videos/user').then(resp => {
            this.setState({ videos: resp.data, loading: false });
        }).fail(() => this.setState({ loading: false }));
    }

    handleDeleteClick(videoGuid) {
        this.context.messageConfirmation('Delete Video', 'Are you sure you want to delete this video?', 
            this.handleDeleteVideo.bind(this, videoGuid), "Video deleted successfully", "Delete");
    }

    handleDeleteVideo(videoGuid) {
        this.setState({ deletingVideo: videoGuid });
        this.context.toggleSweetAlert('confirm');

        postData(`api/video/${videoGuid}`, null, this).then(() => {
            this.fetchUserVideos();
            this.setState({ deletingVideo: '' });
        }).fail(err => {
            this.setState({ deletingVideo: '' });
            console.log('error', err);
        });
    }

    handleMarkVideoPermanent(videoGuid) {
        this.setState({ updatingVideo: videoGuid });
        putData(`api/video/${videoGuid}/permanent`, null, this).then(() => {
            this.fetchUserVideos();
            this.setState({ updatingVideo: '' });
        }).fail(() => this.setState({ updatingVideo: '' }));
    }

    handleMessageClick(video, type) {
        this.setState({ targetVideo: video }, () => { 
            this.toggleModal(type);
        });
    }

    toggleModal(modal) {
        switch(modal) {
            case 'email': 
                this.setState({ emailModal: !this.state.emailModal });
                break;
            case 'text': 
                this.setState({ textModal: !this.state.textModal });
                break;
            default: 
                break;
        }
    }

    renderRows() {
        const { videos, updatingVideo, deletingVideo } = this.state;

        return videos.map((v, i) => {
            return (
                <tr key={v.VideoGuid}>
                    <td className="with-img">
                        <img src={v.ThumbnailUrl} alt="video" style={{ maxWidth: '100px', maxHeight: '72px', objectFit: 'cover' }}/>
                    </td>
                    <td>{v.VideoName}</td>
                    <td>{v.VideoGuid}</td>
                    <td>{v.SentCount}</td>
                    <td>{v.WatchedCount}</td>
                    <td>
                        { v.IsPermanent ?
                            "Permanent"
                            :
                            displayDateWorded(v.ExpirationDate)    
                        }
                    </td>
                    <td style={{ minWidth: '280px' }}>
                        <button 
                            className="btn btn-white m-r-10" 
                            id={"email-btn" + v.VideoGuid}
                            onClick={() => this.handleMessageClick(v, 'email')}
                        >
                            <i className="far fa-envelope text-primary"></i>
                        </button>
                        <UncontrolledTooltip target={"email-btn" + v.VideoGuid}>Email Video</UncontrolledTooltip>
                        <button 
                            className="btn btn-white m-r-10" 
                            id={"text-btn" + v.VideoGuid}
                            onClick={() => this.handleMessageClick(v, 'text')}
                        >
                            <i className="far fa-comment text-lime"></i>
                        </button>
                        <UncontrolledTooltip target={"text-btn" + v.VideoGuid}>Text Video</UncontrolledTooltip>
                        <a href={v.VideoPageUrl} target="_blank" rel="noopener noreferrer" className="btn btn-white m-r-10">
                            <i class="fas fa-play text-green"></i>
                        </a>
                        { !v.IsPermanent && 
                            <button 
                                className="btn btn-white m-r-10" 
                                id={"keep-btn" + v.VideoGuid} 
                                onClick={() => this.handleMarkVideoPermanent(v.VideoGuid)}>
                                    { updatingVideo === v.VideoGuid ? <div className="button-spinner"></div> : <i className="fas fa-lock"></i> }
                            </button> 
                        }
                        { !v.IsPermanent && <UncontrolledTooltip target={"keep-btn" + v.VideoGuid}>Keep Permanently</UncontrolledTooltip> }
                        <button 
                            className="btn btn-outline-danger" 
                            id={"delete-btn" + v.VideoGuid} 
                            onClick={() => this.handleDeleteClick(v.VideoGuid)}>
                                { deletingVideo === v.VideoGuid ? <div className="button-spinner"></div> : <i className="far fa-trash-alt"></i> }
                        </button>
                        <UncontrolledTooltip target={"delete-btn" + v.VideoGuid}>Delete Video</UncontrolledTooltip>
                    </td>

                </tr>
            )
        })
    }

    render() {
        const { loading, emailModal, textModal, targetVideo } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <ol className="breadcrumb pull-right">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>
					<li className="breadcrumb-item active">Videos</li>
				</ol>
                <h1 className="page-header">Videos <small className="f-w-100">Manage your videos</small></h1>
                <Panel className="bg-white p-15">
                <div className="row m-b-25">
                        <div className="col-md-12">
                            <Link to="/video" className="btn btn-lime p-l-40 p-r-40 pull-right m-t-10">New Video</Link>
                            <p className="col-md-6">
                                Videos will only be available until their expiration date (two weeks from creation), unless marked as keep permanently. 
                                You'll want to mark any videos that you plan to reuse, such as in workflows, as keep permanently.
                            </p>
                        </div>
                    </div>
                </Panel>
                <div className="table-responsive with-thin-shadow">
                    <table className="table table-bordered bg-white table-td-valign-middle m-b-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Video Name</th>
                                <th>Video Id</th>
                                <th>Sent</th>
                                <th>Watched</th>
                                <th>Expiration Date</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.videos.length !== 0 && this.renderRows()}
                            {this.state.videos.length === 0 && <tr><td colSpan="4" className="text-center">No videos to display</td></tr>}
                        </tbody>
                    </table>
                </div>

                <VideoEmail 
                    isOpen={emailModal}
                    toggleModal={this.toggleModal}
                    videoName={targetVideo.VideoName}
                    submittedVideoGuid={targetVideo.VideoGuid}
                    thumbnailImg={targetVideo.ThumbnailUrl}
                />

                <VideoText 
                    isOpen={textModal}
                    toggleModal={this.toggleModal}
                    videoName={targetVideo.VideoName}
                    videoGuid={targetVideo.VideoGuid}
                />
            </div>
        )
    }
}

export default Videos;