import React from 'react';
import { ListGroup } from 'reactstrap';
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { displayFormalDateTime } from '../../../helpers/Functions';
import { Link } from 'react-router-dom';
import './ActivityItem.css';
import classnames from 'classnames';

function SavedSearches({ searches, deleteSavedSearch, deletingSearch, contactId }){
    return (
        <div>
            <div className="row m-b-15">
                <div className="col-12">
                    <Link to={`/mls-search?c=${contactId}`} className="btn btn-primary pull-right">Create Listing Alert</Link>
                </div>
            </div>
            <ListGroup>
                {searches.map((m, i) => {
                    return (
                        <ListGroupItem>
                            <ListGroupItemHeading className="m-b-4">
                                <i className={`fa fa-map m-r-10 text-primary`}></i>
                                {m.SearchName} - 
                                <span style={{ fontWeight: 'lighter', fontSize: '.75rem' }}>
                                    {displayFormalDateTime(m.DateCreated, true)}
                                </span>
                                <span className="pull-right f-s-10 semi-bold">
                                    <Link to={`/mls-search/${m.SearchId}`}><i className="far fa-edit"></i> Edit</Link>
                                    <span className="cursor-pointer" onClick={() => deleteSavedSearch(m.SearchId)}>
                                        <i className={ deletingSearch ? "fas fa-spinner fa-pulse m-l-15" : "far fa-trash-alt m-l-15"}></i> Delete
                                    </span>
                                </span>
                                <br />
                            </ListGroupItemHeading>
                        </ListGroupItem>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default SavedSearches;