import { DateOp } from './OperationTypes';
import moment from 'moment';

export const filterTypes = {
    'string': 0,
    'singleSelect': 1,
    'multiSelect': 2,
    'boolean': 3,
    'DateForward': 4,
    'DatePast': 5,
    'Date': 6,
    'DayForward': 7,
    'DatePastInverse': 8,
}

export function getDateRange(dateOp) {
    let range = {
        startDate: null,
        endDate: null,
    }

    switch (dateOp) {
        case DateOp.today:
            range.startDate = moment().startOf('day').format();
            range.endDate = moment().endOf('day').format();
            break;
        case DateOp.lastWeek:
            range.startDate = moment().subtract(7, 'd').startOf('day').format();
            range.endDate = moment().endOf('day').format();
            break;
        case DateOp.last30:
            range.startDate = moment().subtract(30, 'd').startOf('day').format();
            range.endDate = moment().endOf('day').format();
            break;
        case DateOp.last90:
            range.startDate = moment().subtract(90, 'd').startOf('day').format();
            range.endDate = moment().endOf('day').format();
            break;
        case DateOp.last6mos:
            range.startDate = moment().subtract(6, 'month').startOf('day').format();
            range.endDate = moment().endOf('day').format();
            break;
        case DateOp.thisWeek:
            range.startDate = moment().startOf('week').startOf('day').format();
            range.endDate = moment().endOf('week').endOf('day').format();
            break;
        case DateOp.next30:
            range.startDate = moment().startOf('day').format();
            range.endDate = moment().add(30, 'd').endOf('day').format();
            break;
        case DateOp.next90:
            range.startDate = moment().startOf('day').format();
            range.endDate = moment().add(90, 'd').endOf('day').format();
            break;
        case DateOp.thisMonth:
            range.startDate = moment().startOf('month').format();
            range.endDate = moment().endOf('month').format();
            break;
        case DateOp.allFuture: 
            range.startDate = moment().startOf('day').format();
            break;
        default:
            break
    }

    return range;
}