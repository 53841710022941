import React from 'react';
import TaskView from '../TaskView';

export default function TransactionTasks(props){
    return (
        <div>
            <TaskView 
                taskType="transaction"
                typeId={props.transactionId}
                onRef={props.onRef}
            />
        </div>
    )
}