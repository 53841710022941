import React from 'react';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification.jsx';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import SearchForm from './search/form.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';

import { PageSettings } from './../../config/page-settings.js';
import TextMessages from './dropdown/text-messages.jsx';
import appLogo from '../../assets/rcoLogo.svg';
import AddActivityButton from './add-activity.jsx';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
		this.state = { collapseMegaMenu: false };
	}

	toggleMegaMenu() {
		this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
	}
	render() {
		return (
			<PageSettings.Consumer>
				{({toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, updateConversation, dialerWindow}) => (
					<div id="header" className={'header navbar-default' + (dialerWindow ? ' withDialer' :'')}>
						<div className="navbar-header">
							{pageTwoSidebar && (
								<button type="button" className="navbar-toggle pull-right" onClick={toggleMobileSidebar}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{pageTwoSidebar && (
								<button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{/* <Link to="/" className="navbar-brand"><b>RealConnect</b> One</Link> */}
							<div style={{ maxWidth: '150px', padding: '10px', display: 'inline-block' }}>
								<img src={appLogo} alt="logo" style={{ width: '100%' }}/>
							</div>
							
							{!pageTwoSidebar && (
								<button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{pageHeaderMegaMenu && (
								<button type="button" className="navbar-toggle p-0 m-r-0" onClick={this.toggleMegaMenu}>
									<span className="fa-stack fa-lg text-inverse m-t-2">
										<i className="far fa-square fa-stack-2x"></i>
										<i className="fa fa-cog fa-stack-1x"></i>
									</span>
								</button>
							)}
						</div>
						
						{pageHeaderMegaMenu && (
							<DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
						)}
						
						<ul className="navbar-nav navbar-right">
							{/* <SearchForm /> */}
							<AddActivityButton />
							<TextMessages updateConversation={updateConversation} />
							<DropdownNotification />
							
							{pageHeaderLanguageBar && (
								<DropdownLanguage />
							)}
							
							<DropdownProfile history={this.props.history} />
							
							{pageTwoSidebar && (
								<li className="divider d-none d-md-block"></li>
							)}
							
							{pageTwoSidebar && (
								<li className="d-none d-md-block">
									<Link to="/" onClick={toggleRightSidebar} className="f-s-14">
										<i className="fa fa-th"></i>
									</Link>
								</li>
							)}
						</ul>
					</div>
				)}
			</PageSettings.Consumer>
		)
	}
}

export default Header;
