import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';


function CloseTransaction({ isOpen, toggleModal, handleChange, handleDateChange, t, closingTransaction, handleCloseTransaction, handleCheckChange, people }){
    let peopleHasContacts = people.findIndex(p => p.ContactId) !== -1;
    const { BrokerSplitPerc, GrossCommission, Concessions } = t.Financials;
    const p = BrokerSplitPerc / 100;
    const brokerSplit = (p*GrossCommission);
    const agentCommission = GrossCommission - Concessions - brokerSplit;
    
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => toggleModal('closeTransactionModal')}>Finalize Transaction Details</ModalHeader>
            <ModalBody>
                <div className="alert alert-info m-b-15">
                    <h5><i className="fa fa-info-circle"></i> Confirm Details</h5>
                    <p>Please make sure the below items reflect the final details of this transaction at closing. If something doesn't match, please take the time to update them now as these items will be used in the reporting for your transactions.</p>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Sales Price</label>
                        <NumberFormat prefix={'$'} thousandSeparator={true} name="SalesPrice" className="form-control" value={t.SalesPrice} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Closing Date</label>
                        <DateTime inputProps={{ placeholder: 'Click to select' }} timeFormat={false} value={t.DateClosed ? moment(t.DateClosed).format('MM/D/YYYY') : t.DateClosed} closeOnSelect={true} onChange={(date) => handleDateChange('DateClosed', date)} />
                    </div>
                </div>
                <div className="p-r-30 p-l-30">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Gross Commissions</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                prefix={'$'} 
                                thousandSeparator={true} 
                                displayType={'text'} 
                                value={t.Financials && GrossCommission} 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Concessions</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                prefix={'$'} 
                                thousandSeparator={true} 
                                displayType={'text'} 
                                value={t.Financials && Concessions} 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Broker Split</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                prefix={'$'} 
                                thousandSeparator={true} 
                                displayType={'text'} 
                                value={t.Financials && brokerSplit} 
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <label>Net Commission</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <NumberFormat 
                                prefix={'$'} 
                                thousandSeparator={true} 
                                displayType={'text'} 
                                value={t.Financials && agentCommission} 
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                { peopleHasContacts && 
                    <div className="checkbox checkbox-css" style={{ position: 'relative', left: '-70px'}}>
                        <input name="markPastClients" type="checkbox" id="cssCheckbox1" defaultChecked onChange={(e) => handleCheckChange(e)} />
                        <label htmlFor="cssCheckbox1">Mark contacts as Past Clients</label>
                    </div>
                }
                <button className="btn btn-primary-outline" onClick={() => toggleModal('closeTransactionModal')}>Cancel</button>
                <button className="btn btn-primary" onClick={() => handleCloseTransaction()}>
                    { closingTransaction ?
                        <div className="button-spinner"></div>
                        :
                        "Confirm and Close"
                    }
                    </button>
            </ModalFooter>
        </Modal>
    )
}

export default CloseTransaction;