import React, { useEffect, useRef, useState } from 'react';
import { getData } from '../../helpers/AxiosService';
import Select from 'react-select';
import PropTypes from 'prop-types';

function ContactOptions(props) {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState({ value: 0, label: '' });
    const contactsRef = useRef(contacts);

    let getContactPromise = null;

    const { contactId } = props;
    useEffect(() => {
        async function fetchData() {
            try {
                getContactPromise = getData('api/contact/user');
                const response = await getContactPromise;
                
                const options = response.data.map(c => { 
                    return { 
                        value: c.ContactId, 
                        label: `${c.FirstName} ${c.LastName}`
                    }
                });
                setContacts(options);
                contactsRef.current = options;

                if (contactId) {
                    const contact = options.find(c => c.value === contactId);
                    handleSelectContact(contact);
                }

                getContactPromise = null;
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, []);

    // useEffect(() => {
    //     async function updateContact() {
    //         if (contactId && contactId !== selectedContact.value) {
    //             if (!contactsRef.current.length && !getContactPromise) {
    //                 await getContactPromise;
    //             }
    //             const contact = contactsRef.current.find(c => c.value === contactId);
    //             handleSelectContact(contact);
    //         }
    //     }

    //     updateContact();
    // }, [props.contactId])

    function handleSelectContact(option) {
        props.setContactId(option.value);
        setSelectedContact(option);
    }

    return (
        <Select 
            value={selectedContact} 
            name="contactId"
            closeMenuOnSelect={true} 
            options={contacts} 
            onChange={handleSelectContact}
        />
    )
}

export default ContactOptions;

ContactOptions.defaultProps = {
    contactId: 0,
}

ContactOptions.propTypes = {
    contactId: PropTypes.number,
    setContactId: PropTypes.func.isRequired
}