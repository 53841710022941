import React from 'react';
import EmailSetup from '../components/email/EmailSetup';
import { Panel, PanelBody } from '../components/panel/panel';
import { CardTitle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import { getData, deleteData } from '../helpers/AxiosService';
import { ListGroupItem } from 'reactstrap';
import gmailIcon from '../assets/Gmail_Icon.png';
import outlookIcon from '../assets/outlookLogo.png'

class EmailSettings extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showModal: false,
            emailConfig: null,
            deleting: false,
            office365Emails: [2,3,4,5]
        }
    }

    componentDidMount() {
        this.getEmailConfig();
    }

    getEmailConfig() {
        getData('api/emailconfig').then(response => {
            this.setState({ emailConfig: response.data, loading: false });
        });
    }

    handleDisconnect(){
        this.setState({ deleting: true });
        deleteData(`api/emailconfig/${this.state.emailConfig.EmailConfigId}`).then(() => {
            this.setState({ emailConfig: null, deleting: false });
        }).fail(() => this.setState({ deleting: false }));
    }

    toggleModal(reloadPage = false) {
        this.setState({ showModal: !this.state.showModal });
        if (reloadPage) {
            window.location.reload();
        }
    }

    renderLogo() {
        const { office365Emails, emailConfig } = this.state;
        if (office365Emails.includes(emailConfig.EmailProviderId)) {
            return outlookIcon;
        }

        if (emailConfig.EmailProviderId == 1) {
            return gmailIcon;
        }
    }

    render() {
        const { loading, showModal, emailConfig, deleting } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <Panel>
                    <PanelBody>
                    <CardTitle tag="h3" className="m-b-20">Connect Mailbox <i className="fas fa-envelope text-primary"></i></CardTitle>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="lead">A connected email is critical to your success.</p>
                            <p>Connecting your email will allow you to send email directly from the platform. 
                                It will also allow you to have incoming emails be linked directly to the appropriate contact's profile, allowing your to keep track of all your communications in one place.</p>
                            <p>This will email will also be used with all your workflows to send automated emails to your clients.  This makes sure they get an email from a friendly email address they already recognize!</p>
                            { !emailConfig && <button className="btn btn-primary-outline p-r-40 p-l-40" onClick={() => this.toggleModal()}>Connect Email</button> }
                        </div>
                    </div>
                    { emailConfig &&
                    <div>
                        <CardTitle tag="h5" className="m-b-20 m-t-35">Connected Email</CardTitle>
                        <ListGroupItem>
                            <div className="row m-l-0 m-r-0">
                                <img src={this.renderLogo()} alt="Gmail Icon" className="m-r-20" style={{ width: '64px' }} />
                                <div className="media-body valign-middle">
                                    <b className="text-inverse">{emailConfig.Email}</b> {emailConfig.HasError && <span className="label label-danger">Connection Error</span>} <br />
                                    <span>{emailConfig.ProviderName}</span> <br />
                                    <span>{!emailConfig.HasError ? "Connected" : "Not Connected - Needs Attention"}</span>
                                </div>
                                <button className="btn btn-white" onClick={() => this.handleDisconnect()} style={{ maxHeight: '34px' }}>
                                    { deleting ? 
                                        <div className="button-spinner"></div>
                                        :
                                        "Disconnect"
                                    }
                                </button>
                            </div>
                        </ListGroupItem>
                    </div>
                    }
                    { emailConfig && emailConfig.HasError && 
                        <div className="m-t-15">
                            <span className="label label-danger m-r-10">Connection Error</span> Your email has run into a connection error. To fix this, simply disconnect your email and reconnect.
                        </div>
                    }
                    </PanelBody>
                </Panel>

                <Modal isOpen={showModal}>
                    <ModalHeader toggle={() => this.toggleModal(false)}>Connect Email</ModalHeader>
                    <ModalBody>
                        <EmailSetup toggleModal={this.toggleModal.bind(this)} />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default EmailSettings;