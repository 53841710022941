import React from 'react';
import { postData } from '../../helpers/AxiosService';
import { getDateFilters } from '../../helpers/OperationTypes';
import { PageSettings } from '../../config/page-settings';
import Report from './Report.js';


class ReportContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: [],
            dateRange: ''
        }
    }
    
    static contextType = PageSettings;

    componentDidMount(){
        this.setState({ dateRange: this.props.startingRange })
        this.getReportData(getDateFilters(this.props.reportField, this.props.startingRangeOp));
    }

    handleRangeChange(e) {
        const { name, value } = e.target;
        this.setState({ dateRange: name });
        const filters = getDateFilters(this.props.reportField, value);
        this.getReportData(filters);
    }

    getReportData(filters){
        const data ={
            Filters: filters
        }

        postData(`api/report/new-contacts?type=${this.props.reportType}`, data, this).then(response => {
            this.setState({ data: response.data, loading: false });
        });
    }

    render(){
        const { loading, data, dateRange } = this.state;
        return(
            <Report 
                {...this.props}
                loading={loading}
                handleRangeChange={this.handleRangeChange.bind(this)}
                dateRange={dateRange}   
            >
                {/* Expects a render function as a child, that function should accept the data from the result 
                of the report, then display that data in a table, chart etc... */}
                {this.props.children(data)}
            </Report>
        )
    }
}

export default ReportContainer;