import React from 'react';
import { getData, postData, putData } from '../../helpers/AxiosService';
import classnames from 'classnames';
import { displayFormalDateTime } from '../../helpers/Functions';
import { PageSettings } from '../../config/page-settings';


class Workflows extends React.Component {
    static contextType = PageSettings;
    static defaultProps = {
        onRef: () => {}
    }
    constructor(props) {
        super(props);
        this.state = {
            workflows: [],
            selectedWorkflowId: 0, 
            tasks: [],
            showAdd: false,
        }
    }

    componentDidMount() {
        this.props.onRef(this);
        this.fetchWorkflows();
        this.fetchAutoTasks();
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    fetchWorkflows() {
        getData('api/workflow/accessibleworkflows/Transaction?onlyActive=true').then(response => {
            this.setState({ workflows: response.data });
        });
    }

    fetchAutoTasks() {
        getData(`api/workflow/${this.props.transactionId}/auto-tasks`).then(response => {
            this.setState({ tasks: response.data });
        });
    }

    handleChange(e) {
        this.setState({ selectedWorkflowId: e.target.value });
    }

    handleApplyClick() {
        const { selectedWorkflowId } = this.state;
        if (!selectedWorkflowId) return;

        postData(`api/workflow/${this.props.transactionId}/workflow-transaction?workflowId=${selectedWorkflowId}`).then(response => {
            this.fetchAutoTasks();
            alert('SUCCESS!');
        });
    }

    toggleShowAdd() {
        if (this.state.workflows.length === 0) {
            this.context.addNotification("Wait", 
            "You have not published any transaction workflows. You can do this in the Transaction > Workflows section.", 
            "warning", "top", "top-right", 5000);
            return;
        }
        this.setState({ showAdd: !this.state.showAdd });
    }

    toggleIsCancelled(id) {
        if (!id) return;
        this.setState({ isCancelling: true, cancellingId: id });
        putData(`api/workflow/transaction/${id}/toggle-cancel`).then(() => {
            this.fetchAutoTasks();
            this.setState({ isCancelling: false, cancellingId: 0 });
        }).fail(() => this.setState({ isCancelling: false, cancellingId: 0 }));
    }

    renderTaskList(){
        const { isCancelling, cancellingId } = this.state;
        return this.state.tasks.map((task) => {
            let executeDate = 'Unscheduled';
            if (task.ExecuteDate) {
                executeDate = displayFormalDateTime(task.ExecuteDate, true);
            }
            return (
                <div key={task.Id} className="col-md-12 m-b-10" style={{ border: '1px solid #e2e7eb'}}>
                    <div className="p-10 bg-white p-t-20 p-b-20">
                        <div className="media media-xs overflow-visible">
                            <div className="media-body valign-middle">
                                <span className="text-primary"><i className={classnames(task.CssClass, task.IconCss, 'm-r-10 f-s-20')}></i><b>Send {task.TaskType}</b></span> <br />
                                <span>{task.TaskTypeId === 1 ? task.EmailTemplateName : task.TextTemplateName}</span><br />
                                <span className="text-grey-darker">
                                    Status: 
                                    <span className={classnames("m-l-10", { 'text-green': task.IsComplete, 'text-danger': task.IsCancelled })}>
                                        {task.IsComplete ? "Complete" : task.IsCancelled ? "Cancelled" : "Open"}
                                    </span>
                                </span>
                            </div>
                            <div className="media-body valign-middle">
                                <span>Recipient Role: {task.RecipientRoleName}</span>
                            </div>
                            <div className="media-body valign-middle text-right overflow-visible">
                                <b className={classnames("text-inverse", { 'text-danger': task.Due === 'pastdue' })}>{executeDate}</b><br />
                                <span>Controlled by: {task.DateControl}</span><br />
                                { !task.IsComplete && 
                                <span onClick={() => this.toggleIsCancelled(task.Id)}>
                                    {cancellingId === task.Id && isCancelling ?
                                        <span><div className="button-spinner pull-right m-r-25"></div></span>
                                        :
                                        !task.IsCancelled ?
                                        <span className="text-danger hover-underline cursor-pointer">Cancel Task</span>
                                        :
                                        <span className="text-primary hover-underline cursor-pointer">Reinstate Task</span>
                                    }
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
            )
        });
    }

    render() {
        const { workflows, selectedWorkflowId, tasks, showAdd } = this.state;
        return (
            <div>
                <div className="row">
                    { !showAdd ? 
                        <div className="col-md-12">
                            <button className="btn btn-lime pull-right" onClick={() => this.toggleShowAdd()}>Add Workflow To Transaction</button>
                        </div>
                        :
                        <div className="col-md-12">
                            <button className="btn btn-lime-outline pull-right m-l-10" onClick={() => this.toggleShowAdd()}>Cancel</button>
                            <button disabled={!selectedWorkflowId} className="btn btn-lime pull-right m-l-20" onClick={() => this.handleApplyClick()}>Apply Workflow</button>
                            <select 
                                className="form-control pull-right col-md-4" 
                                value={selectedWorkflowId} 
                                onChange={(e) => this.handleChange(e)}>
                                <option></option>
                                { workflows.map(w => <option key={w.WorkflowId} value={w.WorkflowId}>{w.Name}</option>)}
                            </select>
                        </div>
                    }
                </div>
                { tasks.length > 0 && <h5 className="m-t-40 text-primary with-border-bottom p-b-10 m-b-25">AUTOMATED TASKS</h5> }
                <div className="row">
                    <div className="col-md-9">
                        { this.renderTaskList() }
                    </div>
                </div>
            </div>
        )
    }
}

export default Workflows;