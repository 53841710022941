import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Auth from '../../../auth/Auth';
import { Link } from 'react-router-dom';
import { PageSettings } from '../../../config/page-settings';
import ProfileProgress from '../../user/ProfileProgress';

class DropdownProfile extends React.Component {
	static contextType = PageSettings;
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
		
		this.auth = new Auth();
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	handleLogout(){
		this.auth.logout(this.props.history);
	}

	render() {
		const { userInfo } = this.context;
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
				<DropdownToggle className="dropdown-toggle" tag="a">
					<div className="image image-icon bg-black text-grey-darker">
						<i className="fa fa-user"></i>
					</div>
					<span className="d-none d-md-inline">{userInfo.FirstName} {userInfo.LastName}</span> <b className="caret"></b>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul" modifiers={{ computeStyle: { gpuAcceleration: false }}}>
					<DropdownItem className="p-0"><ProfileProgress type={'menu'} /></DropdownItem>
					<div className="dropdown-divider"></div>
					<Link to={`/admin/user/${this.context.userInfo.UserId}`} style={{ textDecoration: 'none' }}><DropdownItem>Edit Profile</DropdownItem></Link>
					<div className="dropdown-divider"></div>
					<DropdownItem onClick={() => this.handleLogout()}>Log Out</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default DropdownProfile;
