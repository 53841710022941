import React from 'react';
import { Panel, PanelBody } from '../components/panel/panel.jsx';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { getData, putData } from '../helpers/AxiosService.js';
import { PageSettings } from '../config/page-settings';
import { Op } from '../helpers/OperationTypes.js';


class Users extends React.Component{
    static contextType = PageSettings;
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            users: [],
            userIdToUpdate: 0,
            updatingRR: false,
        }
    }

    componentDidMount(){
        getData('api/user/tenantusers').then(response => {
            this.setState({ loading: false, users: response.data });
        });
    }

    handleRRobinChange(userId) {
        this.setState({ updatingRR: true, userIdToUpdate: userId });
        const userIdx = this.state.users.findIndex(u => u.UserId === userId);
        const user = this.state.users.find(u => u.UserId === userId);
        const data = {
            UserId: userId,
            RoundRobin: !!!user.RoundRobin,
        }

        putData('api/user/round-robin', data, this).then(() => {
            let users = this.state.users.slice();
            users[userIdx].RoundRobin = data.RoundRobin;

            this.setState({ users, updatingRR: false, userIdToUpdate: 0 });
        }).fail(() => this.setState({ updatingRR: false, userIdToUpdate: 0 }));
    }

    renderUserTable(){
        return this.state.users.map((user, i) => {
            return (
                <tr key={i}>
                    <td><Link to={`/admin/user/${user.UserId}`}>{user.Name}</Link></td>
                    <td>{user.RoleName}</td>
                    <td>{user.Title}</td>
                    <td className="with-checkbox">
                        {user.RoleId !== 4 && <div>{ this.state.updatingRR && this.state.userIdToUpdate === user.UserId ?
                            <div className="button-spinner"></div>
                            :
                            <div className="row">
                                <div style={{ margin: 'auto' }}>
                                    <div className="checkbox checkbox-css">
                                        <input type="checkbox" checked={!!user.RoundRobin} id={`rRobin_checkbox_${i}`} onChange={() => this.handleRRobinChange(user.UserId)} />
                                        <label for={`rRobin_checkbox_${i}`}>&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>}
                    </td>
                    <td>{user.Email}</td>
                    <td>
                        <Link 
                            to={`/contacts?filters=${JSON.stringify([{ field: 'AssignedAgentId', value: user.UserId, operation: Op.equals }])}`}
                        >
                            {user.LeadCount} Assigned Leads
                        </Link>
                    </td>
                </tr>
            )
        })
    }

    render(){
        const { loading } = this.state;
        return (
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>

                <h1 className="page-header">Users <small className="f-w-100">Manage application users</small></h1>
                <Panel>
                    <PanelBody>
                        <div className="row m-b-25">
                            <div className="col-md-12">
                                <Link to="/admin/user" className="btn btn-lime p-r-20 p-l-20 pull-right">Add New User</Link>
                            </div>
                        </div>
                        <table className="table table-bordered table-striped table-hover bg-white">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Title</th>
                                    <th>Include Round Robin</th>
                                    <th>Email</th>
                                    <th>Leads</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users && this.renderUserTable()}
                            </tbody>
                        </table>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default Users;