import React, { useState, useEffect } from 'react';
import { getData } from '../../../helpers/AxiosService';
import { CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function LeadPerformance({ userId, startDate, endDate }) {
    const [leads, setLeads] = useState([]);
    const [reportData, setReportData] = useState({});
    const [loading, setLoading] = useState(true);
    const [sourceId, setSourceId] = useState(0);
    const [sources, setSources] = useState([]);

    useEffect(() => {
        getSources();
        fetchReport();
    }, []);

    async function fetchReport() {
        // sometime userId not set yet
        if (userId === null) return;

        setLoading(true);

        try {
            const response = await getData(`api/report/performance/lead-performance?userId=${userId}&startDate=${startDate}&endDate=${endDate}&sourceId=${sourceId}`);
            setLeads(response.data.Leads);
            setReportData(response.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function getSources() {
        try {
            const response = await getData('api/settings/contact/sources');
            setSources(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchReport();
    }, [userId, startDate, endDate, sourceId])

    function filterBySource(sourceId) {
        setSourceId(sourceId);
    }

    return (
        <div className="p-t-20">
            <CardTitle tag="h4" className="m-b-20">
                Lead Performance
                { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i>}
            </CardTitle>
            <div className="row m-b-20">
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">LEADS</div>
                            <div className="stats-number">{reportData.LeadCount}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">DIALS</div>
                            <div className="stats-number">{reportData.Calls}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">CONTACTED</div>
                            <div className="stats-number">{reportData.Contacted}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">CONVERSATIONS</div>
                            <div className="stats-number">{reportData.Conversations}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">TEXTS</div>
                            <div className="stats-number">{reportData.Texts}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">EMAILS</div>
                            <div className="stats-number">{reportData.Emails}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">APPOINTMENTS</div>
                            <div className="stats-number">{reportData.Appointments}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">CALLS/LEAD</div>
                            <div className="stats-number">{reportData.AverageCallAttempts}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <select className="form-control" value={sourceId} onChange={e => filterBySource(e.target.value)}>
                        <option value={0}>All Sources</option>
                        { sources.map((src) => <option key={src.SourceId} value={src.SourceId}>{src.Name}</option>) }
                    </select>
                </div>
            </div>
            <table className="m-t-20 table table-bordered bg-white">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Source</th>
                        <th>Dials</th>
                        <th>Contacts</th>
                        <th>Conversations</th>
                        <th>Texts</th>
                        <th>Emails</th>
                        <th>Appointments</th>
                    </tr>
                </thead>
                <tbody>
                    { leads.map((lead) => (
                        <tr key={lead.ContactId}>
                            <td><Link to={`/contact/${lead.ContactId}`} className="text-primary">{lead.Name}</Link></td>
                            <td>{lead.Source}</td>
                            <td>{lead.CallCount}</td>
                            <td>{lead.ContactedCount}</td>
                            <td>{lead.ConversationCount}</td>
                            <td>{lead.TextCount}</td>
                            <td>{lead.EmailCount}</td>
                            <td>{lead.AppointmentCount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

LeadPerformance.propTypes = {

}