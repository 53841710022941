import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getData, postData, deleteData } from '../helpers/AxiosService';
import classnames from 'classnames';
import { PageSettings } from '../config/page-settings.js';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Op } from '../helpers/OperationTypes';
import { Panel } from '../components/panel/panel';

class UrgencyTypes extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            urgencyTypes: [], 
            showAdd: false,
            urgencyDescription: '',
            hasError: false,
            sending: false,
            selectedUrgency: {},
            btnDropdownOpen: false,
            loading: true,
        }
        this.toggleButton = this.toggleButton.bind(this);
    }

    componentDidMount(){
        this.getUrgencyTypes();
    }

    getUrgencyTypes(){
        getData('api/settings/contact/urgencytypes').then(response => {
            this.setState({ urgencyTypes: response.data, loading: false });
        });
    }

    toggleModal(){
        this.setState({ hasError: false, urgencyDescription: '', showAdd: !this.state.showAdd, selectedUrgency: {} });
    }

    toggleButton(buttonId) {
        this.setState({
          [`${buttonId}-isOpen`]: !this.state[`${buttonId}-isOpen`]
        });
    }

    handleChange(e, item){
        let value = e.target.value;
        this.setState({ [item]: value });
    }

    handleAddNew(){
        if (!this.state.urgencyDescription){
            this.setState({ hasError: true });
            return;
        };

        this.setState({ sending: true });

        let data = {
            UrgencyTypeId: this.state.selectedUrgency.UrgencyTypeId,
            Description: this.state.urgencyDescription,
        };

        postData('api/settings/contact/urgencytypes', data).then(response => {
            this.getUrgencyTypes();
            this.setState({ sending: false });
            this.toggleModal();
        }).fail(error => this.toggleModal());
    }

    handleEditClick(urgencyIndex){
        let selectedUrgency = this.state.urgencyTypes[urgencyIndex];
        this.setState({ selectedUrgency, urgencyDescription: selectedUrgency.Description, showAdd: true });
    }

    handleDeleteClick(urgencyId){
        this.context.messageConfirmation('Delete Urgency Type', 'Are you sure you would like to delete this urgency type?', this.handleDelete.bind(this, urgencyId), 'Urgency Type deleted!');
    }

    handleDelete(urgencyId){
        deleteData(`api/settings/contact/urgencytypes?urgencyId=${urgencyId}`).then(response => {
            this.getUrgencyTypes();
            this.context.toggleSweetAlert('confirm');
            this.context.toggleSweetAlert('success');
        }).fail(error => {
            this.getUrgencyTypes();
            this.context.toggleSweetAlert('confirm');
        });
    }

    handleReassignClick(currentUrgency, newUrgency){
        this.context.messageConfirmation('Reassign Urgency Type Contacts', `Do you want to reassign all contacts tied to ${currentUrgency.Description} to the ${newUrgency.Description}?`, this.handleReassign.bind(this, currentUrgency.UrgencyTypeId, newUrgency.UrgencyTypeId), 'Contacts Reassigned!', 'Reassign')
    }

    handleReassign(urgencyId, newUrgencyId){
        console.log(urgencyId, newUrgencyId);
        let data = {
            UrgencyTypeId: urgencyId,
            NewUrgencyTypeId: newUrgencyId
        }

        postData('api/settings/contact/urgencytypes/reassign', data).then(response => {
            this.getUrgencyTypes();
            this.context.toggleSweetAlert('confirm');
            this.context.toggleSweetAlert('success');
        }).fail(error => {
            this.getUrgencyTypes();
            this.context.toggleSweetAlert('confirm');
        });
    }

    renderOptions(currentUrgency){
        return this.state.urgencyTypes.map((urgency, i) => {
            if (urgency.UrgencyTypeId == currentUrgency.UrgencyTypeId) return;

            return <DropdownItem onClick={() => this.handleReassignClick(currentUrgency, urgency)}>{urgency.Description}</DropdownItem>
        })
    }

    createUrgencyQueryString(urgencyId, urgencyName) {
        const filter = { field: 'UrgencyTypeId', value: [{ value: urgencyId, label: urgencyName }], operation: Op.equals };
        return `filters=${JSON.stringify([filter])}`;
    }

    renderRows(){
        return this.state.urgencyTypes.map((urgency, i) => (
            <tr key={urgency.UrgencyTypeId}>
                <td><strong>{urgency.Description}</strong></td>
                <td>{urgency.CreatedByName}</td>
                <td>{urgency.Type}</td>
                <td><Link to={`/contacts?${this.createUrgencyQueryString(urgency.UrgencyTypeId, urgency.Description)}`}>{urgency.CurrentContactsCount}</Link></td>
                <td>
                    <button className={classnames({ "invisible": urgency.IsDefault }, "btn btn-white m-r-5")} onClick={() => this.handleEditClick(i)}><i className="far fa-edit text-primary"></i> Edit</button>
                    <button className={classnames({ "invisible": urgency.IsDefault }, "btn btn-white m-r-5")} onClick={() => this.handleDeleteClick(urgency.UrgencyTypeId)} disabled={urgency.CurrentContactsCount > 0}><i className="far fa-times-circle text-danger"></i> Delete</button>
                </td>
            </tr>
        ))
    }

    render(){
        const { showAdd, urgencyDescription, hasError, loading } = this.state;
        return(
            <div>
                <div className={classnames({ 'show': loading }, 'fade')} id="page-loader"><div className="spinner"></div></div>
                <div className="row m-b-15">
                    <div className="col-md-12">
                        <button className="btn btn-lime p-l-40 p-r-40 pull-right m-t-10" onClick={() => this.toggleModal()}>New Urgency Type</button>
                        <h3 className="m-t-10">Urgency Types</h3>
                    </div>
                </div>
                <Panel className="p-15">
                    <div className="row">
                        <p className="col-md-6">
                            Urgency types allow you to create custom statuses to track how active and ready to move
                            forward a contact is. 
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-md-6">
                            We've provided some defaults for you, but feel free to create as many of your own as you would like. 
                        </p>
                    </div>
                </Panel>
                <div className="table-responsive with-thin-shadow">
                    <table className="table table-bordered table-striped thick-rows bg-white m-b-0">
                        <thead>
                            <tr>
                                <th>Urgency Description</th>
                                <th>Created By</th>
                                <th>Type</th>
                                <th>Current Contacts</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.urgencyTypes.length !== 0 && this.renderRows()}
                        </tbody>
                    </table>
                </div>
                <Modal isOpen={showAdd}>
                    <ModalHeader toggle={() => this.toggleModal()}>Add Urgency Type</ModalHeader>
                    <ModalBody>
                        <div className={classnames({ "has-error": hasError && !urgencyDescription }, "form-group")}>
                            <label>Urgency Description</label>
                            <input className="form-control" value={urgencyDescription} onChange={(e) => this.handleChange(e, 'urgencyDescription')} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => this.toggleModal()}>Cancel</button>
                        <button className="btn btn-primary m-l-5" onClick={() => this.handleAddNew()}>
                            {this.state.sending ? 
                                <div className="button-spinner"></div>
                                :
                                "Save"
                            }
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

UrgencyTypes.contextType = PageSettings;
export default UrgencyTypes;