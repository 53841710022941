import moment from 'moment';

export const Op = {
    equals: 'Equals',
    greaterThan: 'GreaterThan',
    greaterThanOrEqual: 'GreaterThanOrEqualTo',
    lessThan: 'LessThan',
    lessThanOrEqual: 'LessThanOrEqualTo',
    contains: 'Contains',
    startsWith: 'StartsWith',
    endsWith: 'EndsWith',
    notEqualTo: 'NotEqualTo',
    isNull: 'IsNull',
    isNotNull: 'IsNotNull',
    isEmpty: 'IsEmpty',
    isNotEmpty: 'IsNotEmpty',
    isTrue: 'IsTrue',
    isFalse: 'IsFalse'
}

export const DateOp = {
    today: 'Today',
    thisWeek: 'ThisWeek',
    thisMonth: 'ThisMonth',
    next30: 'Next30Days',
    next90: 'Next90Days',
    lastWeek: 'LastWeek',
    last30: 'Last30Days',
    last90: 'Last90Days',
    last6mos: 'Last6Months',
    allFuture: 'AllFuture',
    notToday: 'NotToday',
    threeDaysAgo: 'ThreeDaysAgo',
    sevenDaysAgo: 'SevenDaysAgo',
    thirtyDaysAgo: 'ThirtyDaysAgo',
    ninetyDaysAgo: 'NinetyDaysAgo',
    sixMonthsAgo: 'SixMonthsAgo',
}

export function getDateFilters(field, dateOp) {
    let filters = [];

    switch (dateOp) {
        case DateOp.today:
            const filt1 = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().startOf('day').format() };
            const filt2 = { PropertyName: field, 'Operation': Op.lessThanOrEqual, 'Value': moment().endOf('day').format() };
            filters.push(filt1, filt2);
            break;
        case DateOp.lastWeek:
            const wFilt1 = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().subtract(7, 'd').startOf('day') };
            filters.push(wFilt1);
            break;
        case DateOp.last30:
            const thirty1 = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().subtract(30, 'd').startOf('day') };
            filters.push(thirty1);
            break;
        case DateOp.last90:
            const ninety = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().subtract(90, 'd').startOf('day') };
            filters.push(ninety);
            break;
        case DateOp.last6mos:
            const sixmos = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().subtract(6, 'month').startOf('day') };
            filters.push(sixmos);
            break;
        case DateOp.thisWeek:
            const week = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().startOf('week').startOf('day').format() };
            const week2 = { PropertyName: field, 'Operation': Op.lessThanOrEqual, 'Value': moment().endOf('week').endOf('day').format() };
            filters.push(week, week2);
            break;
        case DateOp.next30:
            const next30 = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().startOf('day').format() };
            const next302 = { PropertyName: field, 'Operation': Op.lessThanOrEqual, 'Value': moment().add(30, 'd').endOf('day').format() };
            filters.push(next30, next302);
            break;
        case DateOp.next90:
            const next90 = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().startOf('day').format() };
            const next902 = { PropertyName: field, 'Operation': Op.lessThanOrEqual, 'Value': moment().add(90, 'd').endOf('day').format() };
            filters.push(next90, next902);
            break;
        case DateOp.thisMonth:
            const month = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().startOf('month').format() };
            const month2 = { PropertyName: field, 'Operation': Op.lessThanOrEqual, 'Value': moment().endOf('month').format() };
            filters.push(month, month2);
            break;
        case DateOp.allFuture: 
            const allFut = { PropertyName: field, 'Operation': Op.greaterThanOrEqual, 'Value': moment().startOf('day').format() };
            filters.push(allFut);
            break;
        default:
            break;
    }

    return filters;
}