import React from 'react';
import DocumentTable from './DocumentTable';
import { getData, deleteData, putData, postData, getDownloadDoc, postFormData } from '../../helpers/AxiosService';
import { Alert } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings';
import EmailDocModal from './EmailDocModal';


class Documents extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            folders: [],
            archivedDocs: false, 
            alertMessage: "",
            showMessage: "",
            rename: {}, 
            documentsToSend: [],
            emailDocModal: false,
            uploadingFile: false,
        }

        this.toggleFolderCheck = this.toggleFolderCheck.bind(this);
        this.toggleDocumentCheck = this.toggleDocumentCheck.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.handleDeleteDocumentClick = this.handleDeleteDocumentClick.bind(this);
        this.handleDeleteCheckedClick = this.handleDeleteCheckedClick.bind(this);
        this.archiveDocument = this.archiveDocument.bind(this);
        this.toggleRename = this.toggleRename.bind(this);
        this.handleRename = this.handleRename.bind(this);
        this.submitRename = this.submitRename.bind(this);
        this.toggleFolderRename = this.toggleFolderRename.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSendDocumentClick = this.handleSendDocumentClick.bind(this);
        this.archiveDocument = this.archiveDocument.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    componentDidMount(){
        let id = this.props.transactionId;
        if (id){
            this.fetchDocumentFolders(id);
        }
    }

    fetchDocumentFolders(id){
        const {  archivedDocs } = this.state;
        getData(`api/document/transaction-documents?transactionId=${id}
                &includeArchivedDocs=${archivedDocs}`)
        .then(response => {
            this.setState({ folders: response.data });
        });
    }

    toggleFolderCheck(folder, idx){
        let isChecked = this.state.folders[idx].checked;
        let folders = this.state.folders.slice();
        folder.Documents.forEach((doc, i) => {
            if (!isChecked){
                folders[idx].Documents[i].checked = true;
            } else {
                folders[idx].Documents[i].checked = false;
            }
        });

        folders[idx].checked = !isChecked;
        
        this.setState({ folders });
    }

    toggleDocumentCheck(docIdx, folderIdx){
        let folders = this.state.folders.slice();
        folders[folderIdx].Documents[docIdx].checked = !folders[folderIdx].Documents[docIdx].checked;
        this.setState({ folders });
    }

    toggleAlertMessage(message){
        this.setState({ showMessage: !this.state.showMessage, alertMessage: message });
    }

    handleDeleteCheckedClick() {
        let folders = this.state.folders;
        let documentCount = 0;
        for (let i = 0; i < folders.length; i++){
            folders[i].Documents.forEach(doc => {
                if (doc.checked){
                    documentCount++;
                }
            });
        }
        this.context.messageConfirmation(
            'Delete Documents', 
            `Are you sure you would like to delete ${documentCount} documents?`, this.deleteCheckedDocuments.bind(this), 
            'Document deleted!'
        );
    }

    deleteCheckedDocuments(){
        let folders = this.state.folders;
        for (let i = 0; i < folders.length; i++){
            folders[i].Documents.forEach(doc => {
                if (doc.checked){
                    this.deleteDocument(doc.DocumentId, false);
                }
            });
        }
        this.context.toggleSweetAlert('confirm');
        this.context.toggleSweetAlert('success');
    }

    handleDeleteDocumentClick(document) {
        this.context.messageConfirmation(
            'Delete Document', 
            `Are you sure you would like to delete ${document.Name}`, this.deleteDocument.bind(this, document.DocumentId), 
            'Document deleted!'
        );
    }

    deleteDocument(id, clearAlerts = true){
        deleteData(`api/document?documentId=${id}`).then(() => {
            this.fetchDocumentFolders(this.props.transactionId);
            if (clearAlerts) {
                this.context.toggleSweetAlert('confirm');
                this.context.toggleSweetAlert('success');
            }
        }).fail((err) => {
            if (clearAlerts) {
                this.context.toggleSweetAlert('confirm');
            }
            console.log(err);
        });
    }

    archiveCheckedDocuments(){
        let folders = this.state.folders;
        for (let i = 0; i < folders.length; i++){
            folders[i].Documents.forEach(doc => {
                if (doc.checked){
                    this.archiveDocument(doc.DocumentId);
                }
            });
        }
    }

    archiveDocument(id){
        this.toggleAlertMessage('Archiving')
        putData(`api/document/archive?documentId=${id}`).then(response => {
            this.fetchDocumentFolders(this.props.transactionId);
            this.toggleAlertMessage();
        }).fail(err => this.toggleAlertMessage());
    }

    handleRename(e){
        this.setState({ rename: { ...this.state.rename, Name: e.target.value }});
    }

    toggleRename(item){
        if (!item) item = {};
        this.setState({ rename: item,  showRename: !this.state.showRename });
    }

    submitRename(){
        const { rename } = this.state;
        if (!rename.Name){
            this.setState({ hasError: true });
            return;
        }

        putData(`api/document/rename?documentId=${rename.DocumentId}&name=${rename.Name}`, null, this, this.props.history).then(response => {
            this.setState({ rename: {}, hasError: false })
            this.fetchDocumentFolders(this.props.transactionId);
            this.toggleRename();
        }).fail(() => this.toggleRename())
    }

    toggleFolderRename(id, name){
        this.setState({ renameFolderId: id, folderRename: name });
    }

    handleFolderRename(e){
        this.setState({ folderRename: e.target.value });
    }

    submitFolderRename(){
        if (!this.state.folderRename){
            this.setState({ hasError: true });
            return;
        }

        putData(`api/document/folder/rename?folderId=${this.state.renameFolderId}&name=${this.state.folderRename}`).then(response => {
            this.setState({ folderRename: "", renameFolderId: null,  hasError: false })
            this.fetchDocumentFolders(this.props.transactionId);
        })
    }

    async handleFileChange(e) {
        this.setState({ uploadingFile: true });
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(`file${i}`, e.target.files[i]);
        }

        const { transactionId } = this.props;
        const folderId = e.target.name;

        try {
            await postFormData(`api/document/${transactionId}/${folderId}`, formData, this);
            this.fetchDocumentFolders(transactionId);
        } catch (error) {
            console.log(error);
        }

        this.setState({ uploadingFile: false });
    }

    downloadMultipleDocuments(){
        let folders = this.state.folders.slice();
        for (let i = 0; i < folders.length; i++){
            folders[i].Documents.forEach(doc => {
                if (doc.checked){
                    this.downloadDocument(doc.DocumentId);
                }
            });
        };
    }

    downloadDocument(id){
        getDownloadDoc(`api/document/download?documentId=${id}`);
    }

    toggleModal(){
        this.setState({ emailDocModal: !this.state.emailDocModal, documentsToSend: [] });
    }

    handleSendMultipleDocs(){
        let folders = this.state.folders.slice();
        let checkedDocuments = [];
        for (let i = 0; i < folders.length; i++){
            folders[i].Documents.forEach(doc => {
                if (doc.checked){
                    checkedDocuments.push(doc);
                }
            });
        };

        this.setState({ documentsToSend: checkedDocuments, emailDocModal: true });
    }

    handleSendDocumentClick(doc){
        let documentsToSend = [ doc ];
        this.setState({ documentsToSend, emailDocModal: true });
    }

    toggleShowArchived(){
        this.setState({ archivedDocs: !this.state.archivedDocs }, () => this.fetchDocumentFolders(this.props.transactionId));
    }

    addFolder(){
        const { transactionId } = this.props;
        postData(`api/document/folder?transactionId=${transactionId}`).then(response => {
            this.fetchDocumentFolders(transactionId);
        });
    }

    archiveFolder(folderId){
        putData(`api/document/archive-folder?folderId=${folderId}`).then(response => {
            this.setState({ archivedDocs: false }, () => this.fetchDocumentFolders(this.props.transactionId))
        });
    }

    deleteFolder(folderId){
        deleteData(`api/document/delete-folder?folderId=${folderId}`).then(response => {
            this.fetchDocumentFolders(this.props.transactionId);
        });
    }

    render(){
        const { folders, showMessage, alertMessage, rename, showRename, folderRename, renameFolderId, 
            emailDocModal, documentsToSend, archivedDocs, uploadingFile  } = this.state;
        let hasChecked = false;
        for (let i = 0; i < folders.length; i++){
            let checked = folders[i].Documents.some( doc => doc.checked );
            if (checked) hasChecked = true;
        }
        let hasDocs = false;
        folders.forEach(folder => {
            if (folder.Documents && folder.Documents.length > 0){
                hasDocs = true;
            }
        })

        return(
            <div>
                <div className="row m-b-25">
                    <div className="col-md-6">
                        <h4 className="m-t-10 text-primary">Documents</h4>
                        <h6>Add, Organize, and Short Transaction Documents</h6>
                        <div className="row m-t-20">
                            <div className="col-md-12">
                                { archivedDocs ? 
                                    <span className="text-primary cursor-pointer" onClick={() => this.toggleShowArchived ()}><strong>Hide Archived</strong></span>
                                    :
                                    <span className="text-primary cursor-pointer" onClick={() => this.toggleShowArchived ()}><strong>Show Archived</strong></span>
                                }
                                <span className="text-primary m-l-25 cursor-pointer" onClick={() => this.addFolder()}><strong><i className="far fa-plus-square"></i> Add Folder</strong></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {hasChecked && 
                            <div className="btn-group pull-right">
                                <button className="btn btn-white btn-sm" onClick={() => this.downloadMultipleDocuments()}><i className="fas fa-download text-primary"></i> Download</button>
                                <button className="btn btn-white btn-sm" onClick={() => this.handleSendMultipleDocs()}><i className="fas fa-share text-primary"></i> Share</button>
                                <button className="btn btn-white btn-sm" onClick={() => this.handleDeleteCheckedClick()}><i className="fas fa-trash text-primary"></i> Delete</button>
                                <button className="btn btn-white btn-sm" onClick={() => this.archiveCheckedDocuments()}><i className="fas fa-folder text-primary"></i> Archive</button>
                            </div>
                        }
                    </div>
                </div>
                <Alert color="yellow" isOpen={showMessage}>
                    <div className="row">
                        <div style={{ width: '25px', marginRight: '15px'}}>
                            <div className="button-spinner"></div>
                        </div>
                        <strong>{alertMessage}</strong>
                    </div>
                </Alert>
                <Alert color="info" isOpen={!hasDocs}>
                    <i className="far fa-bell fa-2x pull-left m-r-10"></i>
                    <p><strong>To get started with documents, simply click Add Folder and then begin adding documents to that folder. <br />
                    If you already have a folder, click Add Document to get started.</strong></p>
                </Alert>
                <DocumentTable 
                    folders={folders} 
                    selectFolder={this.toggleFolderCheck} 
                    toggleDocSelect={this.toggleDocumentCheck} 
                    deleteDocument={this.handleDeleteDocumentClick}
                    archiveDocument={this.archiveDocument}
                    rename={rename}
                    showRename={showRename}
                    toggleRename={this.toggleRename}
                    handleRename={this.handleRename}
                    submitRename={this.submitRename}
                    toggleFolderRename={this.toggleFolderRename}
                    downloadDocument={this.downloadDocument}
                    sendDocument={this.handleSendDocumentClick}
                    archiveFolder={this.archiveFolder}
                    deleteFolder={this.deleteFolder}
                    handleFileChange={this.handleFileChange}
                    uploadingFile={uploadingFile}
                />

                <Modal isOpen={renameFolderId}>
                    <ModalHeader toggle={() => this.setState({ renameFolderId: null })}>Rename</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label>Name</label>
                            <input className="form-control" value={folderRename}  onChange={(e) => this.handleFolderRename(e)} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => this.setState({ renameFolderId: null })}>Cancel</button>
                        <button className="btn btn-primary" onClick={() => this.submitFolderRename()}>Submit</button>
                    </ModalFooter>
                 </Modal>

                 <EmailDocModal isOpen={emailDocModal} toggleModal={this.toggleModal} documents={documentsToSend} transactionId={this.props.transactionId} />
            </div>
        )
    }
}

Documents.contextType = PageSettings;
export default Documents;