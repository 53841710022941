/* eslint-disable no-undef */
import React from 'react';
import houseImg from '../../assets/NoImageHouse.jpg';
import { GoogleMap, useLoadScript, Marker, InfoWindow, Polygon, DrawingManager, useGoogleMap } from '@react-google-maps/api';

// const {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow,
//   Polygon,
//   MAP
// } = require("react-google-maps");
// const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");

const mapLibraries = ['geometry', 'drawing', 'places'];

const InteractiveMap = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCSV2auAHXTmdy8eJ77m2lQhv9lZprGhp8",
    libraries: mapLibraries,
    version: '3.34'
  });

  const renderMap = () => {
    return (
      <GoogleMap
        onLoad={map => {
          props.onRef(map);
        }}
        onDragEnd={props.onIdle}
        onZoomChanged={props.onIdle}
        center={props.center}
        zoom={12}
        mapContainerStyle={props.containerStyle}
      >
        {/* initialPolyCoords is if there is a polygon loaded from a saved search */}
        { props.initialPolyCoords.length > 0 && 
          <Polygon 
            options={{
              fillColor: `#ffff00`,
              fillOpacity: .5,
              strokeWeight: 2,
              clickable: false,
              editable: true,
              zIndex: 1,
            }}
            editable
            path={props.initialPolyCoords}
            onLoad={p => {
              props.onPolyRef(p);
              props.handlePolygonComplete(p)
            }}
          />
        }
        <DrawingManager
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                google.maps.drawing.OverlayType.POLYGON
              ],
            },
            polygonOptions: {
              fillColor: `#ffff00`,
              fillOpacity: .5,
              strokeWeight: 2,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          }}
          onPolygonComplete={(p) => props.handlePolygonComplete(p)}
        />
        {props.results.length > 0 && props.results.map(p => {
          let imgSrc = houseImg;
          let firstImg = p.Media.find(x => x.Order === 0);
          if (firstImg) {
              imgSrc = firstImg.MediaURL;
          }
          let markerRef;
          return (
            <Marker 
              onLoad={m => markerRef = m}
              key={p.ListingKeyNumeric}
              position={{ lat: p.Lat, lng: p.Lng }}
              onMouseOver={() => props.handleKeyChange(p.ListingKeyNumeric)}
            />
          )
        })}
        {props.results.length > 0 && props.results.map(p => {
          let imgSrc = houseImg;
          let firstImg = p.Media.find(x => x.Order === 0);
          if (firstImg) {
              imgSrc = firstImg.MediaURL;
          }

          const renderWindow = () => {
            console.log(props.results);
            return (
                <InfoWindow 
                  key={p.ListingKeyNumeric}
                  options={{ maxWidth: 290,  pixelOffset: new google.maps.Size(0, -40) }}
                  onCloseClick={() => props.handleKeyChange(null)}
                  position={{ lat: p.Lat, lng: p.Lng }}
                >
                  <div className="media" key={p.ListingKeyNumeric}>
                      <div className="media-left">
                          <img src={imgSrc} alt="" className="media-object" style={{ width: '90px' }} />
                      </div>
                      <div className="media-body">
                          <span className="text-primary"><strong>{p.UnparsedAddress}</strong></span> <br />
                          {p.City} <br />
                          <span><strong>${p.ListPrice}</strong></span> <br />
                          {p.BedroomsTotal} beds {p.BathroomsTotalInteger} baths {p.LivingArea} sq ft
                      </div>
                  </div>
                </InfoWindow>
            )
          }

          return p.ListingKeyNumeric === props.activeKey ? renderWindow() : null;
        })}
      </GoogleMap>
    )
  }
  return isLoaded ? renderMap() : <div>Loading Map...</div>
}

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();
    this.clearControl = React.createRef();
    this.state = {
      activeKey: null,
    }
  }

  handleActiveKeyChange(key) {
    this.setState({ activeKey: key });
  }

  render() {
    return (
      <div>
        <button className="pull-right" onClick={() => this.props.clearDrawing()}>Clear Polygon</button>
        <InteractiveMap
          onIdle={() => this.props.handleMapChange(this.map)}
          containerStyle={{ height: `calc(100vh - 330px)`, width: '100%' }}
          results={this.props.results}
          center={this.props.center}
          onRef={ref => (this.map = ref)}
          onPolyRef={poly => this.props.onInitialPolyLoad(poly)}
          handlePolygonComplete={this.props.handlePolygonComplete}
          activeKey={this.state.activeKey}
          handleKeyChange={this.handleActiveKeyChange.bind(this)}
          initialPolyCoords={this.props.initialPolyCoords}
        />
      </div>
    );
  }
}

export default Map;


  
// const InteractiveMap = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       ref={map => {
//         props.onRef(map);
//       }}
//       onDragEnd={props.onIdle}
//       onZoomChanged={props.onIdle}
//       center={props.center}
//       defaultZoom={12}
//     >
//       {/* initialPolyCoords is if there is a polygon loaded from a saved search */}
//       { props.initialPolyCoords.length > 0 && 
//         <Polygon 
//           options={{
//             fillColor: `#ffff00`,
//             fillOpacity: .5,
//             strokeWeight: 2,
//             clickable: false,
//             editable: true,
//             zIndex: 1,
//           }}
//           editable
//           path={props.initialPolyCoords}
//           ref={p => {
//             props.onPolyRef(p);
//           }}
//         />
//        }
//       <DrawingManager
//         defaultOptions={{
//           drawingControl: true,
//           drawingControlOptions: {
//             position: google.maps.ControlPosition.TOP_CENTER,
//             drawingModes: [
//               google.maps.drawing.OverlayType.POLYGON
//             ],
//           },
//           polygonOptions: {
//             fillColor: `#ffff00`,
//             fillOpacity: .5,
//             strokeWeight: 2,
//             clickable: false,
//             editable: true,
//             zIndex: 1,
//           },
//         }}
//         onPolygonComplete={(p) => props.handlePolygonComplete(p)}
//       />
//       <ClearButton 
//         clearDrawing={props.clearDrawing}
//         ref={ref => {
//           props.onClearControlRef(ref);
//         }}
//       />
//       {props.results.length > 0 && props.results.map(p => {
//         let imgSrc = houseImg;
//         let firstImg = p.Media.find(x => x.Order === 0);
//         if (firstImg) {
//             imgSrc = firstImg.MediaURL;
//         }
//         return (
//           <Marker 
//             key={p.ListingKey}
//             position={{ lat: p.Lat, lng: p.Lng }}
//             onMouseOver={() => props.handleKeyChange(p.ListingKey)}
//           >
//             {p.ListingKey === props.activeKey && 
//               <InfoWindow 
//                 options={{ maxWidth: 290 }}
//                 onCloseClick={() => props.handleKeyChange(null)}
//               >
//                 <div className="media" key={p.ListingKey}>
//                     <div className="media-left">
//                         <img src={imgSrc} alt="" className="media-object" style={{ width: '90px' }} />
//                     </div>
//                     <div className="media-body">
//                         <span className="text-primary"><strong>{p.UnparsedAddress}</strong></span> <br />
//                         {p.City} <br />
//                         <span><strong>${p.ListPrice}</strong></span> <br />
//                         {p.BedroomsTotal} beds {p.BathroomsTotalInteger} baths {p.LivingArea} sq ft
//                     </div>
//                 </div>
//               </InfoWindow>
//             }
//           </Marker>
//         )
//       })}
//     </GoogleMap>
//   ))
// );