import React, { useState, useEffect } from 'react';
import { getData } from '../../../helpers/AxiosService';
import { CardTitle } from 'reactstrap';

export default function AgentActivity({ userId, startDate, endDate }) {
    const [users, setUsers] = useState([]);
    const [reportData, setReportData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchReport();
    }, []);

    async function fetchReport() {
        // sometime userId not set yet
        if (userId === null) return;

        setLoading(true);

        try {
            const response = await getData(`api/report/performance/agent-activity?userId=${userId}&startDate=${startDate}&endDate=${endDate}`);
            setUsers(response.data.Users);
            setReportData(response.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchReport();
    }, [userId, startDate, endDate])

    return (
        <div className="p-t-20">
            <CardTitle tag="h4" className="m-b-20">
                Agent Activity
                { loading && <i className="fas fa-spinner fa-pulse text-primary m-l-10"></i>}
            </CardTitle>
            <div className="row m-b-20">
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">NEW LEADS</div>
                            <div className="stats-number">{reportData.NewLeads}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">CALLS</div>
                            <div className="stats-number">{reportData.Calls}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">TEXTS</div>
                            <div className="stats-number">{reportData.Texts}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">EMAILS</div>
                            <div className="stats-number">{reportData.Emails}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">APPTS CREATED</div>
                            <div className="stats-number">{reportData.ApptsCreated}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">APPTS SCHEDULED</div>
                            <div className="stats-number">{reportData.ApptsScheduled}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">SHOWINGS</div>
                            <div className="stats-number">{reportData.Showings}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">OPEN HOUSES</div>
                            <div className="stats-number">{reportData.OpenHouses}</div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2">
                    <div className="widget widget-stats inverse-mode m-b-10">
                        <div className="stats-content">
                            <div className="stats-title text-grey f-w-600 m-b-10">OFFERS MADE</div>
                            <div className="stats-number">{reportData.OffersMade}</div>
                        </div>
                    </div>
                </div>
            </div>
            <table className="table table-bordered bg-white">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>New Leads</th>
                        <th>Calls</th>
                        <th>Texts</th>
                        <th>Emails</th>
                        <th>Appts Created</th>
                        <th>Appts Scheduled</th>
                        <th>Showings</th>
                        <th>Open Houses</th>
                        <th>Offers Made</th>
                    </tr>
                </thead>
                <tbody>
                    { users.map((user) => (
                        <tr key={user.UserId}>
                            <td>{user.Name}</td>
                            <td>{user.NewLeads}</td>
                            <td>{user.Calls}</td>
                            <td>{user.Texts}</td>
                            <td>{user.Emails}</td>
                            <td>{user.ApptsCreated}</td>
                            <td>{user.ApptsScheduled}</td>
                            <td>{user.Showings}</td>
                            <td>{user.OpenHouses}</td>
                            <td>{user.OffersMade}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}