import React from 'react';
import { Panel, PanelBody } from '../panel/panel.jsx';
import classnames from 'classnames';
import { CardTitle, UncontrolledTooltip } from 'reactstrap';
import PerfectScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import RangePicker from './RangePicker';

export default function Report({ title, description, loading, handleRangeChange, dateRange, children, rangeDirection, includeAll }) {
    const uniqueTitle = title.replace(' ', '');
    return (
        <Panel className="with-thin-shadow height-md">
            <PanelBody style={{ position: 'relative' }}>
                <CardTitle tag="h4" className="m-b-20">{title} <i className="fas fa-info-circle text-primary f-s-14" id={`${uniqueTitle}_cardTitle`}></i></CardTitle>
                <UncontrolledTooltip target={`${uniqueTitle}_cardTitle`}>
                    {description}
                </UncontrolledTooltip>
                <div className={classnames({ 'show': loading }, 'fade')} id="modal-loader"><div className="spinner"></div></div>
                <div className="form-inline m-l-15">
                    <label>Report date range: </label>
                    <RangePicker handleChange={handleRangeChange} value={dateRange} direction={rangeDirection} includeAll={includeAll} />
                </div>
                <PerfectScrollBar className="height-sm m-t-20">
                    { children }
                </PerfectScrollBar>
            </PanelBody>
        </Panel>
    )
}