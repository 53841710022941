import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import EmailTextEditor from '../email/EmailTextEditor';
import '../email/EmailModal.css';
import { postData, getData } from '../../helpers/AxiosService';
import { PageSettings } from '../../config/page-settings';
import PropTypes from 'prop-types';


class EmailVideo extends React.Component{
    static contextType = PageSettings;
    static defaultProps = {
        submitVideo: () => {},
        resetVideoState: () => {},
    }
    
    constructor(props){
        super(props);
        this.quillRef = null;
        this.reactQuillRef = null;
        this.state = {
            dropdownOpen: false,
			cc: false,
			tags: [],
			tagsCc: [],
			tagsBcc: [],
            suggestions: [],
            subject: '',
			text: '',
			editor: {
				height: 200
            },
            attachments: [],
            templateOptions: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
    }

    componentDidMount(){
        if (this.props.toAddress){
            const tags = [].concat(this.state.tags, {id: 1, name: this.props.toAddress})
		    this.setState({ tags })
        }

        this.fetchTemplateOptions();

        const mergeFieldPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-mergeField .ql-picker-item'));

        mergeFieldPickerItems.forEach(item => item.textContent = item.dataset.value);
    }

    componentDidUpdate(prevProps){
        if (this.props.toAddress !== prevProps.toAddress){
            const tags = [].concat(this.state.tags, {id: 1, name: this.props.toAddress})
		    this.setState({ tags })
        }
    }

    fetchTemplateOptions(){
        getData('api/template/user').then(response => {
            this.setState({ templateOptions: response.data });
        });
    }

    handleTagDelete (i) {
		const tags = this.state.tags.slice(0)
		tags.splice(i, 1)
		this.setState({ tags })
	}

	handleTagAddition (tag) {
		const tags = [].concat(this.state.tags, tag)
		this.setState({ tags })
	}

	handleTagCcDelete (i) {
		const tagsCc = this.state.tagsCc.slice(0)
		tagsCc.splice(i, 1)
		this.setState({ tagsCc })
	}

	handleTagCcAddition (tag) {
		const tagsCc = [].concat(this.state.tagsCc, tag)
		this.setState({ tagsCc })
	}

	handleCc(e) {
		e.preventDefault();
		this.setState(state => ({
			cc: true
		}));
	}

	handleChange(value) {
		this.setState({ text: value })
    }

    handleSubjectChange(value){
        this.setState({ subject: value });
    }

    handleTagValidate(tag){
        return tag.name.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }

    handleAttach(event){
        const reader = new FileReader();
        const file = event.target.files[0];
        let attachments = this.state.attachments.slice();

        reader.onload = f => {
            let preview = f.target.result;
            let fileString = f.target.result.slice(preview.indexOf('base64,') + 7);
            let uploadFile = {
                FileString: fileString,
                FileName: file.name,
                FileType: file.type,
                DataString: preview
            }
            attachments.push(uploadFile);
            this.setState({ attachments })
        }
        reader.readAsDataURL(file)
    }

    renderAttachments(){
        return this.state.attachments.map((doc, i) => (
            <div key={i} className="col-md-4">
                <Alert color="secondary" className="m-b-10" toggle={() => this.removeAttachment(i)}>
                    {doc.FileName}
                    <span></span>
                </Alert>
            </div>
        ));
    }

    removeAttachment(index){
        let attachments = this.state.attachments.slice();
        attachments.splice(index, 1);

        this.setState({ attachments });
    }

    async handleSend(){
        //TO DO, validate there is a message and subject
        this.setState({ sending: true });
        let videoGuid = this.props.submittedVideoGuid;

        if (!videoGuid) {
            videoGuid = await this.props.submitVideo();

            if (!videoGuid) {
                alert('video error');
                return;
            }
        }

        let message = {
            ToAddresses: this.getTagNames(this.state.tags),
            CCAddresses: this.getTagNames(this.state.tagsCc),
            AttachmentFiles: this.state.attachments,
            Subject: this.state.subject,
            Body: this.state.text,
            MessageType: 'email',
            ToContactId: this.props.contactId,
            VideoName: this.props.videoName, 
        }

        postData(`api/video/${videoGuid}/messages`, message, this).then(response => {
            this.props.resetVideoState();
            this.setState({ sending: false }, () => this.closeResetModal());
            this.context.addNotification("Success!", "Your message was sent successfully.");
        }).fail(() => this.setState({ sending: false }));
    }

    getTagNames(tags){
        let names = [];
        tags.forEach(tag => {
            names.push(tag.name);
        });

        return names;
    }

    closeResetModal(){
        this.setState({ tags: [], tagsCc: [], subject: '', text: '' }, () => this.props.toggleModal('email'));
    }

    handleSelectTemplate(template){
        this.setState({ text: template.Body });
    }

    render(){
        const { templateOptions } = this.state;
        return(
            <Modal isOpen={this.props.isOpen} size="xl">
                <ModalHeader toggle={() => this.closeResetModal()}>Send Video Email</ModalHeader>
                <ModalBody>
                    <div className="vertical-box-column bg-white inbox">
                        <div className="vertical-box">
                            <div className="vertical-box-row bg-white" style={{ height: '800px'}}>
                                <div className="vertical-box-cell">
                                    <div className="vertical-box-inner-cell">
                                        <div className="overflow-scroll height-full p-15">
                                            <div className="m-b-20 row">
                                                <button className="btn btn-sm btn-white" onClick={() => this.handleSend()}><i className="far fa-envelope"></i> Send</button>
                                                
                                                <div className="email-upload-wrapper m-l-10">
                                                    <input className="email-hidden-upload" type="file" onChange={(e) => this.handleAttach(e)} />
                                                    <button className="btn btn-sm btn-white"><i className="fas fa-paperclip"></i> Attach</button>
                                                </div> 
                                            </div> 
                                            <form action="/" method="POST" name="email_to_form">
                                                <div className="email-to">
                                                    <span className="float-right-link">
                                                        {!this.state.cc && <Link to="/email/compose" onClick={this.handleCc.bind(this)} className="m-r-5">Cc</Link>}
                                                    </span>
                                                    <label className="control-label">To:</label>
                                                    <div className="email-to-input">
                                                        <ReactTags
                                                            classNames={this.state.tagClass} 
                                                            placeholder="" 
                                                            tags={this.state.tags} 
                                                            suggestions={this.state.suggestions} 
                                                            onDelete={this.handleTagDelete.bind(this)} 
                                                            onAddition={this.handleTagAddition.bind(this)} 
                                                            allowNew={true} 
                                                            onValidate={this.handleTagValidate.bind(this)} 
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.cc &&
                                                    <div className="email-to">
                                                        <label className="control-label">Cc:</label>
                                                        <div className="email-to-input">
                                                            <ReactTags 
                                                                classNames={this.state.tagClass} 
                                                                placeholder="" 
                                                                tags={this.state.tagsCc} 
                                                                suggestions={this.state.suggestions} 
                                                                onDelete={this.handleTagCcDelete.bind(this)} 
                                                                onAddition={this.handleTagCcAddition.bind(this)} 
                                                                allowNew={true} 
                                                                onValidate={this.handleTagValidate.bind(this)} 
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="email-subject">
                                                    <input 
                                                        type="text" 
                                                        value={this.state.subject} 
                                                        onChange={e => this.handleSubjectChange(e.target.value)} 
                                                        className="form-control form-control-lg" 
                                                        placeholder="Subject" 
                                                    />
                                                </div>
                                                <div className="row m-t-25">
                                                    { this.state.attachments.length > 0 && this.renderAttachments() }
                                                </div>
                                                { this.props.thumbnailImg && <div className="m-b-25 width-300">
                                                    <img className="w-100" src={this.props.thumbnailImg} alt="video-thumbnail" />
                                                </div> }
                                                <div className="email-content">
                                                    <EmailTextEditor 
                                                        text={this.state.text}
                                                        handleChange={this.handleChange}
                                                        editor={this.state.editor}
                                                        handleSelectTemplate={this.handleSelectTemplate}
                                                        templates={templateOptions}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary-outline p-l-40 p-r-40 m-r-5" onClick={() => this.closeResetModal()}>Discard</button>
                    <button type="submit" className="btn btn-primary p-l-40 p-r-40" onClick={() => this.handleSend()}>Send</button> 
                </ModalFooter>
            </Modal>
        )
    }
}

export default EmailVideo;

EmailVideo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    // if the modal should preload with an email address already populated
    toAddress: PropTypes.string,
    // src url for thumbnail
    thumbnailImg: PropTypes.string,
    videoName: PropTypes.string.isRequired,
    // available after a video has been submitted
    submittedVideoGuid: PropTypes.string,
    resetVideoState: PropTypes.func.isRequired,
    submitVideo: PropTypes.func,
}